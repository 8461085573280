import { createTypeAsyncAction } from '@gu-corp/redux-async-lib';
import AdminGApiClient from './gapi/admin-gapi-client';
import * as AwsAccountQuery from './gapi/query/aws-account';
import { IStore } from '~/stores/configure-store';
export * from './gapi/query/aws-account';

export const listAWSAccounts = createTypeAsyncAction(
  'ADMIN_LIST_AWS_ACCOUNTS',
  async (args: AwsAccountQuery.QueryToListAWSAccountsArgs) => {
    const client = new AdminGApiClient({});
    return client.listAWSAccounts(args);
  },
);

export const createAWSAccount = createTypeAsyncAction('ADMIN_CREATE_AWS_ACCOUNT', async () => {
  const client = new AdminGApiClient({});
  return client.createAWSAccount();
});

export const removeAWSAccountAction = createTypeAsyncAction(
  'ADMIN_REMOVE_AWS_ACCOUNT',
  async (args: AwsAccountQuery.MutationRemoveAwsAccountArgs) => {
    const client = new AdminGApiClient({});
    return client.removeAWSAccount(args);
  },
);
