/**
 *
 * Validator definition module
 *
 */

// Cluster type and instance spec
export const VALIDATE_CLUSTER_TYPE_NODES: {
  [key: string]: {
    allowed: string[];
    minimum: number;
    rpslimit: number;
  };
} = {
  small: {
    allowed: ['small', 'medium', 'large', 'xlarge', '2xlarge', 'business', 'mega', 'metal'],
    minimum: 3,
    rpslimit: 1,
  },
  medium: {
    allowed: ['small', 'medium', 'large', 'xlarge', '2xlarge', 'business', 'mega', 'metal'],
    minimum: 3,
    rpslimit: 10,
  },
  large: {
    allowed: ['small', 'medium', 'large', 'xlarge', '2xlarge', 'business', 'mega', 'metal'],
    minimum: 5,
    rpslimit: 200,
  },
  mega: {
    allowed: ['small', 'medium', 'large', 'xlarge', '2xlarge', 'business', 'mega', 'metal'],
    minimum: 5,
    rpslimit: 1000,
  },
  enterprise: {
    allowed: ['small', 'medium', 'large', 'xlarge', '2xlarge', 'business', 'mega', 'metal'],
    minimum: 5,
    rpslimit: 1000,
  },
};

// Uuid v4 format
export const UUID_V4_PATTERN =
  /^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$/;

// Hex address format
export const VALIDATE_HEX_ADDR_PATTERN = /^[0-9a-fA-F]+$/;

// Postal code format
export const VALIDATE_POSTAL_CODE_PATTERN = /^[0-9]{3}-?[0-9]{4}$/;

// Security description format
export const VALIDATE_SECURITY_DESC_PATTERN =
  /^[0-9a-zA-Z ._\-\:\/\(\)\#\,\@\[\]\+\=\&\;\{\}\!\$\*]*$/;
export const VALIDATE_SECURITY_DESC_LENGTH = 100;

export const VALIDATE_EMAIL_PATTERN =
  /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;

// Network/Cluster/Node name, description
export const MIN_NETWORK_NAME_LENGTH = 1;
export const MAX_NETWORK_NAME_LENGTH = 50;
export const MAX_NETWORK_DESCRIPTION_LENGTH = 1000;
export const MIN_CLUSTER_NAME_LENGTH = 1;
export const MAX_CLUSTER_NAME_LENGTH = 50;
export const MIN_NODE_NAME_LENGTH = 1;
export const MAX_NODE_NAME_LENGTH = 50;

// Network create
export const CREATE_NETWORK_MIN_NUMBER_OF_NODE = 1;
export const CREATE_NETWORK_MAX_NUMBER_OF_NODE = 15;

// Cluter create
export const CREATE_CLUSTER_MIN_NUMBER_OF_NODE = 1;
export const CREATE_CLUSTER_MAX_NUMBER_OF_NODE = 15;

// Node create
export const CREATE_NODE_MIN_NUMBER_OF_NODE = 1;
export const CREATE_NODE_MAX_NUMBER_OF_NODE = 15;

// Organization name
export const VALIDATE_ACCOUNT_UUID_PATTERN = /^[0-9a-zA-Z]{4}\-[0-9a-zA-Z]{4}\-[0-9a-zA-Z]{4}$/;
export const MAX_ORGANIZATION_NAME_LENGTH = 50;

// Profile name
export const MAX_PROFILE_NAME_LENGTH = 50;

// Coupon name
export const MAX_COUPON_NAME_LENGTH = 50;

// mongoose concurrency error
export const ERR_MESSAGE_MONGOOSE_VERSION_ERROR = 'VersionError: No matching document found for id';

// Domain name
export const VALIDATE_DOMAIN_NAME_PATTERN =
  /^([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
export const VALIDATE_CERT_ARN_PATTERN = /^arn:aws:acm:\S+:\d+:\w+\/\S+$/;

// Validate node external clef
export const MIN_GETH_SUPPORT_EXTERNAL_CLEF_VERSION = '1.11.0';
