import React from 'react';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

interface IProps {
  className?: string;
  onClick?: any;
  id?: string;
}

const optionButton: React.StatelessComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  className,
  onClick,
  id,
}) => {
  return (
    <div className={classes.root} data-testid="loading">
      <CircularProgress />
    </div>
  );
};

const styles: StyleRules = {
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default withStyles(styles)(optionButton);
