import React from 'react';
import classNames from 'classnames';
import firebase from 'firebase';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import ImgIcon from '~/components/common/img-icon';
import ProfileSettingDialog from '../profile-setting-dialog';
import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  denimColor,
  whiteColor,
  nightRiderColor,
  pattensBlueColor,
} from '~/styles/themes/common-styles/color';
import { IAdminProfile } from '~/types/admin-types';
import { connect } from 'react-redux';

interface IDispProps {
  resetStore: () => void;
}

interface IProps extends IDispProps, WithTranslation, WithStyles<typeof styles> {
  profile: IAdminProfile;
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
}

interface IState {
  openProfileSettingDialog: boolean;
}

class ProfilePopover extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      openProfileSettingDialog: false,
    };
  }

  render() {
    const { classes, profile, open, anchorEl, onClose } = this.props;
    const { openProfileSettingDialog } = this.state;

    return (
      <>
        <ProfileSettingDialog
          profile={profile}
          open={openProfileSettingDialog}
          onClose={this.onCloseProfileSettingDialog}
        />
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={onClose}
          anchorEl={anchorEl}
          classes={{ paper: classes.popoverPaper }}
          data-testid="user-popover"
        >
          <div className={classes.userInfo}>
            <img
              className={classes.avatarImg}
              src={profile?.photoUrl || 'images/avatar-default.svg'}
              alt=""
            />
            <div>
              <Typography variant="overline" component="div" className={classes.displayNameText}>
                {profile?.name}
              </Typography>
              <Typography variant="caption" component="div">
                {profile?.email}
              </Typography>
            </div>
          </div>
          <Divider />
          <List className={classes.list} data-testid="profile-menu-list">
            <ListItem
              data-testid="profile-setting-item"
              button
              className={classes.listItem}
              onClick={this.onOpenProfileSettingDialog}
            >
              <Trans i18nKey={'setting'}></Trans>
            </ListItem>
            <ListItem
              data-testid="profile-logout-button"
              button
              id="admin-top-menu-signout"
              className={classes.listItem}
              onClick={this.onSignOut}
            >
              <Trans i18nKey={'logout'}></Trans>
            </ListItem>
          </List>
        </Popover>
      </>
    );
  }

  onOpenProfileSettingDialog = () => {
    this.setState({
      openProfileSettingDialog: true,
    });
  };
  onCloseProfileSettingDialog = () => {
    this.setState({
      openProfileSettingDialog: false,
    });
  };

  onSignOut = async () => {
    await firebase.auth().signOut();
    this.props.resetStore();
  };
}

const styles = createStyles({
  popoverPaper: {
    marginTop: 8,
    borderRadius: 4,
    width: 250,
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 10px',
  },
  avatarImg: {
    width: 42,
    height: 42,
    borderRadius: '50%',
    marginRight: 10,
  },
  displayNameText: {
    lineHeight: '18px',
  },
  list: {
    padding: '0px 8px 8px 8px',
  },
  listItem: {
    marginTop: 8,
    marginBottom: 8,
    borderRadius: 4,
  },
});

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({
  resetStore: () => dispatch({ type: 'RESET_STORE' }),
});

export default withStyles(styles)(
  withTranslation('admin')(connect(mapStateToProps, mapDispatchToProps)(ProfilePopover)),
);
