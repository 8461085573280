import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import promiseMiddleware from 'redux-promise';
import {
  typePendingReducerSet,
  TypeReduxPendingState,
  createTypeReduxInitialState,
  typeReduxMiddleware,
} from '@gu-corp/redux-async-lib';
import assign from 'object-assign';
import { composeWithDevTools } from 'redux-devtools-extension';

import * as AppReducer from './reducers/app-reducer';

export const appReducer = combineReducers({
  ...typePendingReducerSet,
  appState: AppReducer.reducer,
});

export interface IStore extends TypeReduxPendingState {
  appState: AppReducer.IState;
}

export const InitialState: IStore = assign(createTypeReduxInitialState(), {
  appState: AppReducer.initialState,
});

const middlewares = [typeReduxMiddleware, promiseMiddleware];

export const configureStore = (initialState: IStore = InitialState, clientEnv: string = '') => {
  // load 'Redux DevTools Extension' if exists (ONLY when env=development)
  const env: string =
    typeof document === 'undefined' ? process.env.NODE_ENV || '' : clientEnv || '';

  // see https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
  const composedEnhancers =
    env === 'development' && typeof window === 'object'
      ? composeWithDevTools(applyMiddleware(...middlewares))
      : compose(applyMiddleware(...middlewares));

  // Reset state
  const rootReducer = (state, action) => {
    return action.type === 'RESET_STORE' ? InitialState : appReducer(state, action);
  };

  // create redux store
  return createStore(rootReducer, initialState, composedEnhancers);
};
