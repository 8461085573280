import { createTypeAsyncAction } from '@gu-corp/redux-async-lib';
import AdminGApiClient from './gapi/admin-gapi-client';
import * as summary from './gapi/query/summary';

export * from './gapi/query/summary';

export const listSalesSummary = createTypeAsyncAction(
  'ADMIN_LIST_SALES_SUMMARY',
  async (args: summary.QueryListSalesSummaryArgs) => {
    const client = new AdminGApiClient({});
    return await client.listSalesSummary(args);
  },
);

export const getNetworkSummary = createTypeAsyncAction(
  'ADMIN_GET_NETWORK_SUMMARY',
  async (args: summary.QueryGetNetworkSummaryArgs) => {
    const client = new AdminGApiClient({});
    return await client.getNetworkSummary(args);
  },
);

export const listAccountLicensesSummary = createTypeAsyncAction(
  'LIST_ACCOUNT_LICENSES_SUMMARY',
  async (args: summary.QueryListAccountLicensesSummaryArgs) => {
    const client = new AdminGApiClient({});
    return client.listAccountLicensesSummary(args);
  },
);

export const listAccountBillingsSummary = createTypeAsyncAction(
  'LIST_ACCOUNT_BILLINGS_SUMMARY',
  async (args: summary.QueryListAccountBillingsSummaryArgs) => {
    const client = new AdminGApiClient({});
    return client.listAccountBillingsSummary(args);
  },
);

export const listAdminSummary = createTypeAsyncAction(
  'LIST_ADMIN_SUMMARY',
  async (args: summary.QueryListAdminSummaryArgs) => {
    const client = new AdminGApiClient({});
    return client.listAdminSummary(args);
  },
);

export const listAccountCouponsSummary = createTypeAsyncAction(
  'LIST_ACCOUNT_COUPONS_SUMMARY',
  async (args: summary.QueryListAccountCouponsSummaryArgs) => {
    const client = new AdminGApiClient({});
    return client.listAccountCouponsSummary(args);
  },
);

export const listAllCouponsSummary = createTypeAsyncAction(
  'LIST_ALL_COUPONS_SUMMARY',
  async (args: summary.QueryListAllCouponsSummaryArgs) => {
    const client = new AdminGApiClient({});
    return client.listAllCouponsSummary(args);
  },
);
