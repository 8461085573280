import React, { useEffect, useMemo, useState } from 'react';
// Route
import { Link as RouterLink } from 'react-router-dom';
// Material UI style difinition
import { withStyles, createStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { WithStyles } from '@mui/styles';
// Component
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Layout from '~/components/common/Layout';
import AccountChart from './account-chart';
import UserChart from './user-chart';
import DashboardSummary from './summary';
import NotificationSummary from './notifications';
import { Grid } from '@mui/material';
// Redux
import { IStore } from '~/stores/configure-store';
import { withAdminProfile } from '~/hooks/with-admin-profile';
import { compose } from 'redux';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { AppRoutePath } from '~/AppRouter';
import { withAuthorization } from '~/hooks/with-authorization';
import {
  useGetSummaryStatsQuery,
  useAdminNotificationQuery,
  useUserNotificationQuery,
} from './hook';
import { displayCurrency } from '~/utilities/payment-utils';
import { DEFAULT_DASHBOARD_STATS_POLLING_INTERVAL } from '~/constants/polling';
import { useSelector } from 'react-redux';

interface IProps extends WithStyles<typeof styles> {}

const Dashboard = (props: IProps) => {
  const { classes } = props;
  const { t } = useTranslation('admin');
  const profile = useSelector((store: IStore) => store.appState.profile);

  const role = profile?.role || '';
  const showAccountsStats = ['owner', 'admin', 'operator', 'billing_operator'].includes(role);
  const showUsersStats = ['owner', 'admin', 'operator'].includes(role);
  const showRecentUsersInMonth = ['owner', 'admin', 'operator'].includes(role);
  const showSaleStats = ['owner', 'admin', 'operator', 'billing_operator'].includes(role);
  const showNetworkStats = ['owner', 'admin', 'operator'].includes(role);
  const showAlertStats = ['owner', 'admin', 'operator'].includes(role);

  const summaryStats = useGetSummaryStatsQuery({
    interval: DEFAULT_DASHBOARD_STATS_POLLING_INTERVAL,
  });
  const adminNotifications = useAdminNotificationQuery({
    interval: DEFAULT_DASHBOARD_STATS_POLLING_INTERVAL,
    disabled: !showAlertStats,
  });
  const userNotifications = useUserNotificationQuery({
    interval: DEFAULT_DASHBOARD_STATS_POLLING_INTERVAL,
    disabled: !showAlertStats,
  });

  return (
    <Layout>
      <div className={classes.root}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Link
            data-testid="navigate-to-dashboard"
            component={RouterLink}
            to={AppRoutePath.Dashboard}
            color="text.primary"
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            {t('dashboard')}
          </Link>
        </Breadcrumbs>
        <div className={classes.summaryArea}>
          <Grid container spacing={2}>
            {showAccountsStats && (
              <Grid item xs={4}>
                <DashboardSummary
                  data-testid="account-summary"
                  title={t('num_of_accounts')}
                  content={summaryStats.data?.numOfAccounts}
                  loading={!!summaryStats.loading}
                  link={AppRoutePath.Accounts}
                />
              </Grid>
            )}
            {showUsersStats && (
              <Grid item xs={4}>
                <DashboardSummary
                  data-testid="user-summary"
                  title={t('num_of_users')}
                  content={summaryStats.data?.numOfUsers}
                  loading={!!summaryStats.loading}
                  link={AppRoutePath.Users}
                />
              </Grid>
            )}
            {showRecentUsersInMonth && (
              <Grid item xs={4}>
                <DashboardSummary
                  data-testid="users-in-month-summary"
                  title={t('recent_users_in_month')}
                  content={summaryStats.data?.recentUserInThisMonth}
                  loading={!!summaryStats.loading}
                  link={AppRoutePath.Users}
                />
              </Grid>
            )}
            {showSaleStats && (
              <Grid item xs={4}>
                <DashboardSummary
                  data-testid="sales-summary"
                  title={t('estimated_sales_in_month')}
                  content={
                    summaryStats.data?.estimatedSalesInThisMonth
                      ? `$ ${displayCurrency(summaryStats.data.estimatedSalesInThisMonth)}`
                      : undefined
                  }
                  loading={!!summaryStats.loading}
                  link={AppRoutePath.Sales}
                />
              </Grid>
            )}
            {showNetworkStats && (
              <Grid item xs={4}>
                <DashboardSummary
                  data-testid="num-of-networks-summary"
                  title={t('num_of_networks')}
                  content={summaryStats.data?.numOfNetworks}
                  loading={!!summaryStats.loading}
                  link={AppRoutePath.Networks}
                />
              </Grid>
            )}
            {showNetworkStats && (
              <Grid item xs={4}>
                <DashboardSummary
                  data-testid="num-of-nodes-summary"
                  title={t('num_of_nodes')}
                  content={summaryStats.data?.numOfNodes}
                  loading={!!summaryStats.loading}
                  link={AppRoutePath.Networks}
                />
              </Grid>
            )}
          </Grid>
        </div>
        <div className={classes.chartsArea}>
          <Grid sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid item xs={6}>
              <AccountChart />
            </Grid>
            <Grid item xs={6}>
              <UserChart />
            </Grid>
          </Grid>
        </div>
        <div className={classes.notificationArea}>
          {showAlertStats && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <NotificationSummary
                  title={t('admin_notifications')}
                  link="/admin-notifications"
                  notifications={adminNotifications.data || []}
                  loading={!!adminNotifications.loading}
                />
              </Grid>
              <Grid item xs={12}>
                <NotificationSummary
                  title={t('user_notifications')}
                  link="/user-notifications"
                  notifications={userNotifications.data || []}
                  loading={!!userNotifications.loading}
                />
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </Layout>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: 'auto',
      paddingRight: 0,
    },
    summaryArea: {
      marginTop: 20,
      marginBottom: 20,
    },
    chartsArea: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 24,
      [theme.breakpoints.up('sm')]: {
        marginTop: 32,
      },
    },
    notificationArea: {
      marginTop: 20,
      marginBottom: 20,
    },
  });

export default compose(
  withFirebaseUser(),
  withAdminProfile,
  withAuthorization(['owner', 'admin', 'operator', 'billing_operator']),
  withStyles(styles),
)(Dashboard);
