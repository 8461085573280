import * as React from 'react';

// component
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid';

import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Theme } from '@mui/material/styles';

// Styles
import { withStyles, createStyles, WithStyles } from '@mui/styles';

// i18n
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import Popover from '@mui/material/Popover';
import { useCallback, useState } from 'react';
import Badge from '@mui/material/Badge';
import { ListProposalHistory } from '~/types/network-types';
import FilterForm from './filter-form';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button } from '@mui/material';

interface QuickSearchToolbarProps {
  onFilterPage: (params) => void;
  listProposalHistory: ListProposalHistory;
}

interface Props extends QuickSearchToolbarProps, WithTranslation, WithStyles<typeof styles> {}
const CustomDataGridToolbar = (props: Props) => {
  const { classes, listProposalHistory, onFilterPage } = props;
  const { t } = useTranslation('admin');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const handleOpenFilterDialog = useCallback((e) => {
    setFilterAnchorEl(e.currentTarget);
  }, []);

  const handleCloseFilterDialog = useCallback(() => {
    setFilterAnchorEl(null);
  }, []);

  return (
    <div className={classes.root}>
      <Popover
        open={Boolean(filterAnchorEl)}
        anchorEl={filterAnchorEl}
        onClose={handleCloseFilterDialog}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        elevation={1}
      >
        <FilterForm
          filter={listProposalHistory.filter}
          onClose={handleCloseFilterDialog}
          onFilterPage={onFilterPage}
        />
      </Popover>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <Button
          data-testid="open-filter-button"
          startIcon={
            <Badge
              badgeContent={
                Object.values(listProposalHistory.filter ?? {}).filter(
                  (val) => val != null && val != '',
                ).length
              }
              color="primary"
            >
              <FilterListIcon />
            </Badge>
          }
          onClick={handleOpenFilterDialog}
        >
          {t('filters')}
        </Button>
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 1, 0.5),
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    textField: {
      flex: 0.5,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      margin: theme.spacing(1, 0.5, 1.5),
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  });

export default withStyles(styles)(withTranslation('admin')(CustomDataGridToolbar));
