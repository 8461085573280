import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Icon from '@mui/material/Icon';
// React i18next
import { withTranslation, WithTranslation } from 'react-i18next';
import { container } from '~/styles/themes/common-styles/grid-member';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
// Component
import AuthLayout from '../common/AuthLayout';
import { defaultFont } from '~/styles/themes/common-styles/font-guest';
import { LGPelorousDenim } from '~/styles/themes/common-styles/misc';
import { nightRiderColor } from '~/styles/themes/common-styles/color';
import { parseBCP47String } from '~/utilities/utils';
import { checkBrowserSupport } from '~/utilities/app-utils';
import Cookies from 'js-cookie';
import { SelectChangeEvent } from '@mui/material/Select';
import { compose } from 'redux';
import FirebaseAdminUi from '~/components/common/firebase-admin-ui';

interface IProps extends WithStyles<typeof styles>, WithTranslation {}

interface IState {}

class AuthPage extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.checkBrowserSupport();
  }

  private checkBrowserSupport = () => {
    if (!checkBrowserSupport(navigator.userAgent)) {
      window.alert(this.props.t('not_supported_browser_alert'));
    }
  };

  public render() {
    const { classes, t, i18n } = this.props;
    return (
      <AuthLayout>
        <div className={classes.root}>
          <div className={classes.paperArea}>
            <div style={{ width: 480 }}>
              <Paper className={classes.paper} elevation={1}>
                <FirebaseAdminUi />
              </Paper>
              <div className={classes.bottom}>
                <div>{t('copyright_2016_openid_foundation')}</div>
                <div className={classes.flex}>
                  {/* <div className={classes.aboutAccount}>
                    {t('about_account')}
                  </div>
                  <div>
                    {t('chooser_privacy_policy')}
                  </div> */}
                </div>
              </div>
              <Select
                data-testid="change-language-button"
                value={parseBCP47String(i18n.language).language || 'en'}
                className={classes.selectLanguage}
                disableUnderline={true}
                onChange={this.onChangeLng}
                IconComponent={(props) => (
                  <div {...props} className={props.className + ' ' + classes.selectIcon}>
                    <Icon>unfold_more</Icon>
                  </div>
                )}
              >
                {languages.map((language) => (
                  <MenuItem key={language.value} value={language.value}>
                    {language.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </AuthLayout>
    );
  }

  private onChangeLng = (event: SelectChangeEvent) => {
    this.props.i18n.changeLanguage(event.target.value as string);
    Cookies.set('lang', event.target.value);
  };
}

const languages = [
  {
    value: 'ja',
    name: 'Japanese',
  },
  {
    value: 'en',
    name: 'English',
  },
];
const styles = createStyles({
  container,
  root: {
    backgroundImage: `url('/images/backgrounds/guest_main_bg.svg')`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  },
  paperArea: {
    ...container,
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 100,
    paddingBottom: 50,
    justifyContent: 'center',
  },
  paper: {
    maxWidth: 540,
    width: '100%',
    padding: 60,
    borderRadius: 10,
  },
  bottom: {
    ...defaultFont,
    display: 'flex',
    fontSize: 12,
    color: nightRiderColor,
    justifyContent: 'space-between',
    marginTop: 30,
    maxWidth: 540,
  },
  flex: {
    display: 'flex',
  },
  aboutAccount: {
    marginRight: 20,
  },
  selectLanguage: {
    backgroundColor: 'white',
    width: 137,
    borderRadius: 4,
    marginTop: 30,
    float: 'right',
    paddingLeft: 7,
    fontSize: 12,
    fontWeight: 300,
    height: 17,
    border: '1px solid #239BA7',
    '& .MuiSelect-select': {
      paddingLeft: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiOutlinedInput-notchedOutline:hover': {
      border: 0,
    },
  },
  selectIcon: {
    top: 0,
    right: 0,
    backgroundImage: LGPelorousDenim,
    borderRadius: '0px 4px 4px 0px',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    width: 16,
    '& span': {
      fontSize: 16,
    },
    height: 17,
  },
});

export default compose(withFirebaseUser(), withStyles(styles), withTranslation())(AuthPage);
