import * as Gtypes from '../../../../gapi/gtypes';

const adminPreferenceQuery = `{
  language
  timezone
}`;

const adminProfileQuery = `{
  id
  adminUuid
  name
  email
  preference ${adminPreferenceQuery}
  photoUrl
  role
  createAt
}`;

export const getAdminProfileQuery = `query {
  getAdminProfile ${adminProfileQuery}
}`;
export type GET_ADMIN_PROFILE_RESULT_TYPE = {
  getAdminProfile: Gtypes.AdminProfile;
};

export const updateAdminProfileMutation = `mutation ($input: UpdateAdminProfileInput!) {
  updateAdminProfile(input: $input) {
    adminUuid
    name
    preference ${adminPreferenceQuery}
  }
}`;
export type MutationUpdateAdminProfileArgs = Gtypes.MutationUpdateAdminProfileArgs;
export type UPDATE_ADMIN_PROFILE_RESULT_TYPE = {
  updateAdminProfile: Gtypes.UpdateAdminProfileResult;
};
