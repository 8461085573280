import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';

interface IProps extends ButtonProps {
  isLoading?: boolean;
  label: string;
  submittingLabel?: string;
}

const SubmitButton: React.FunctionComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  label,
  submittingLabel,
  isLoading,
  ...other
}) => {
  return (
    <Button className={classes.root} variant="contained" color="primary" {...other}>
      {isLoading ? submittingLabel : label}
      {isLoading && <CircularProgress size={16} className={classes.buttonProgress} />}
    </Button>
  );
};

const styles = createStyles({
  root: {
    textTransform: 'none',
  },
  buttonProgress: {
    marginLeft: '8px',
  },
});

export default withStyles(styles)(SubmitButton);
