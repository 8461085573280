import React, { useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { nightRiderColor } from '~/styles/themes/common-styles/color';
import { AlertType } from '~/types/admin-types';
import { Paper, Popover, TextField } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid';
import { AllAlertTypes } from '..';

interface IProps extends GridFilterInputValueProps, WithTranslation, WithStyles<typeof styles> {}

const TypeFilter: React.FunctionComponent<IProps> = (props) => {
  const { classes, t, item, applyValue } = props;
  const { forUser = [] } = item.value ?? {};

  const [isShowFilter, setShowFilter] = React.useState(false);

  const anchorRefElement = React.useRef(null);

  const handleFilterChange = useCallback(
    (value) => {
      const newVal = { ...item.value, ...value };
      applyValue({
        ...item,
        value: Object.values(newVal).some((val: any) => val.length > 0) ? newVal : undefined,
      });
    },
    [applyValue, item],
  );

  const onForUserItemCheckboxChange = useCallback(
    (type: AlertType, checked: boolean) => {
      handleFilterChange({
        forUser: checked
          ? forUser.includes(type)
            ? forUser
            : [...forUser, type]
          : forUser.filter((item) => item !== type),
      });
    },
    [forUser, handleFilterChange],
  );

  const handleOpenMenu = useCallback(() => {
    setShowFilter((current) => !current);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setShowFilter(false);
  }, []);

  const renderComponent = useMemo(() => {
    return (
      <List disablePadding>
        {AllAlertTypes.map((type) => (
          <ListItem key={type} className={classNames(classes.listItem)}>
            <Checkbox
              data-testid="checkbox-button"
              checked={forUser.includes(type)}
              onChange={(event, checked) => onForUserItemCheckboxChange(type, checked)}
              className={classes.listItemCheckbox}
              color="primary"
            />
            <ListItemText>{t(type)}</ListItemText>
          </ListItem>
        ))}
      </List>
    );
  }, [classes.listItem, classes.listItemCheckbox, forUser, onForUserItemCheckboxChange, t]);

  return (
    <div>
      <TextField
        select
        label={t('filter')}
        ref={anchorRefElement}
        onClick={handleOpenMenu}
        fullWidth
        disabled={isShowFilter}
        SelectProps={{
          native: true,
        }}
        className={classes.textField}
      ></TextField>
      <Popover
        open={isShowFilter}
        anchorEl={anchorRefElement.current}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {renderComponent}
      </Popover>
    </div>
  );
};

const styles = createStyles({
  textField: {
    position: 'relative',
  },
  popup: {
    right: 0,
    position: 'fixed',
    background: '#fff',
  },
  popoverPaper: {
    marginTop: 5,
    position: 'fixed',
  },
  list: {
    padding: 0,
  },
  listItem: {
    width: 270,
    fontSize: 15,
    color: nightRiderColor,
    paddingTop: 8,
    paddingBottom: 8,
  },
  nestedListItem: {
    paddingLeft: 36,
    height: 40,
  },
  listItemCheckbox: {
    padding: 5,
  },
});

export default withStyles(styles)(withTranslation('admin')(TypeFilter));
