import React from 'react';
import classNames from 'classnames';
import chartjs from 'chart.js';
import { Bar, ChartData } from 'react-chartjs-2';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
const Chart = require('react-chartjs-2').Chart;

import { peranoColor, aliceBlueColor, gunPowder2Color } from '~/styles/themes/common-styles/color';

export type IData = {
  key: string;
  value: number;
}[];

interface IProps {
  className?: string;
  data: IData;
  labelColor?: string;
  axesColor?: string;
  tickFontSize?: number;
  displayXAxes?: boolean;
  bgColor?: string;
  bgHoverColor?: string;
  xAxesStepSize?: number;
  yAxesStepSize?: number;
  minYAxesValue?: number;
  minXAxesValue?: number;
  maxXAxesValue?: number;
  maxYAxesValue?: number;
}

interface IBarChartRounded {
  cornerRadius?: number;
}

const BarChart: React.StatelessComponent<IProps & IBarChartRounded & WithStyles<typeof styles>> = ({
  classes,
  className,
  labelColor,
  axesColor,
  tickFontSize,
  data,
  displayXAxes,
  cornerRadius,
  bgColor,
  bgHoverColor,
  xAxesStepSize,
  yAxesStepSize,
  minYAxesValue,
  minXAxesValue,
  maxXAxesValue,
  maxYAxesValue,
}) => {
  const labels: string[] = [];
  const values: number[] = [];
  const bgColors: string[] = [];
  data.forEach((item, index) => {
    labels.push(item.key);
    values.push(item.value);
    bgColors.push(bgColor || '');
  });

  const chartData: ChartData<chartjs.ChartData> = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: bgColors,
        hoverBackgroundColor: bgHoverColor,
      },
    ],
  };

  const options: chartjs.ChartOptions & IBarChartRounded = {
    maintainAspectRatio: false,
    cornerRadius: cornerRadius,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      display: false,
      xAxes: [
        {
          gridLines: {
            display: displayXAxes,
            color: axesColor,
          },
          ticks: {
            fontColor: labelColor,
            fontSize: tickFontSize,
            stepSize: xAxesStepSize,
            min: minXAxesValue,
            max: maxXAxesValue,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: axesColor,
          },
          ticks: {
            fontColor: labelColor,
            fontSize: tickFontSize,
            stepSize: yAxesStepSize,
            min: minYAxesValue,
            max: maxYAxesValue,
            precision: 0,
          },
        },
      ],
    },
  };

  return (
    <div className={classes.root}>
      <Bar
        data={chartData}
        height={200}
        options={options}
        plugins={[
          {
            beforeInit: (chart, easing) => {
              if (!chart.options.cornerRadius) {
                return;
              }

              Chart.elements.Rectangle.prototype.draw = function () {
                const ctx = this._chart.ctx;
                const vm = this._view;
                let left, right, top, bottom, signX, signY, borderSkipped;
                let borderWidth = vm.borderWidth;

                // If radius is less than 0 or is large enough to cause drawing errors a max
                // radius is imposed. If cornerRadius is not defined set it to 0.
                let cornerRadius = this._chart.config.options.cornerRadius;
                if (cornerRadius < 0) {
                  cornerRadius = 0;
                }
                if (typeof cornerRadius == 'undefined') {
                  cornerRadius = 0;
                }

                if (!vm.horizontal) {
                  left = vm.x - vm.width / 2;
                  right = vm.x + vm.width / 2;
                  top = vm.y;
                  bottom = vm.base;
                  signX = 1;
                  signY = bottom > top ? 1 : -1;
                  borderSkipped = vm.borderSkipped || 'bottom';
                }

                // Canvas doesn't allow us to stroke inside the width so we can
                // adjust the sizes to fit if we're setting a stroke on the line
                if (borderWidth) {
                  // borderWidth shold be less than bar width and bar height.
                  const barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
                  borderWidth = borderWidth > barSize ? barSize : borderWidth;
                  const halfStroke = borderWidth / 2;
                  // Adjust borderWidth when bar top position is near vm.base(zero).
                  const borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
                  const borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
                  const borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
                  const borderBottom =
                    bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
                  // not become a vertical line?
                  if (borderLeft !== borderRight) {
                    top = borderTop;
                    bottom = borderBottom;
                  }
                  // not become a horizontal line?
                  if (borderTop !== borderBottom) {
                    left = borderLeft;
                    right = borderRight;
                  }
                }

                ctx.beginPath();
                ctx.fillStyle = vm.backgroundColor;
                ctx.strokeStyle = vm.borderColor;
                ctx.lineWidth = borderWidth;

                // Corner points, from bottom-left to bottom-right clockwise
                // | 1 2 |
                // | 0 3 |
                const corners = [
                  [left, bottom],
                  [left, top],
                  [right, top],
                  [right, bottom],
                ];

                // Find first (starting) corner with fallback to 'bottom'
                const borders = ['bottom', 'left', 'top', 'right'];
                let startCorner = borders.indexOf(borderSkipped, 0);
                if (startCorner === -1) {
                  startCorner = 0;
                }

                function cornerAt(index) {
                  return corners[(startCorner + index) % 4];
                }

                // Draw rectangle from 'startCorner'
                let corner = cornerAt(0);
                ctx.moveTo(corner[0], corner[1]);

                for (let i = 1; i < 4; i++) {
                  corner = cornerAt(i);
                  let nextCornerId = i + 1;
                  if (nextCornerId == 4) {
                    nextCornerId = 0;
                  }

                  const width = corners[2][0] - corners[1][0];
                  const height = corners[0][1] - corners[1][1];
                  const x = corners[1][0];
                  const y = corners[1][1];

                  let radius = cornerRadius;

                  // Fix radius being too large
                  if (radius > Math.abs(height) / 1.5) {
                    radius = Math.floor(Math.abs(height) / 1.5);
                  }
                  if (radius > Math.abs(width) / 1.5) {
                    radius = Math.floor(Math.abs(width) / 1.5);
                  }

                  if (height < 0) {
                    // Negative values in a standard bar chart
                    const xTl = x;
                    const xTr = x + width;
                    const yTl = y + height;
                    const yTr = y + height;

                    const xBl = x;
                    const xBr = x + width;
                    const yBl = y;
                    const yBr = y;

                    // Draw
                    ctx.moveTo(xBl + radius, yBl);
                    ctx.lineTo(xBr - radius, yBr);
                    ctx.quadraticCurveTo(xBr, yBr, xBr, yBr - radius);
                    ctx.lineTo(xTr, yTr + radius);
                    ctx.quadraticCurveTo(xTr, yTr, xTr - radius, yTr);
                    ctx.lineTo(xTl + radius, yTl);
                    ctx.quadraticCurveTo(xTl, yTl, xTl, yTl + radius);
                    ctx.lineTo(xBl, yBl - radius);
                    ctx.quadraticCurveTo(xBl, yBl, xBl + radius, yBl);
                  } else {
                    ctx.moveTo(x + radius, y);
                    ctx.lineTo(x + width - radius, y);
                    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
                    ctx.lineTo(x + width, y + height - radius);
                    ctx.quadraticCurveTo(x + width, y + height, x + width, y + height);
                    ctx.lineTo(x + radius, y + height);
                    ctx.quadraticCurveTo(x, y + height, x, y + height);
                    ctx.lineTo(x, y + radius);
                    ctx.quadraticCurveTo(x, y, x + radius, y);
                  }
                }

                ctx.fill();
                if (borderWidth) {
                  ctx.stroke();
                }
              };
            },
          },
        ]}
      />
    </div>
  );
};

BarChart.defaultProps = {
  axesColor: aliceBlueColor,
  tickFontSize: 11,
  labelColor: gunPowder2Color,
  displayXAxes: false,
  cornerRadius: 0,
  bgColor: peranoColor,
  bgHoverColor: peranoColor,
  xAxesStepSize: undefined,
  yAxesStepSize: undefined,
  minXAxesValue: 0,
  minYAxesValue: 0,
  maxXAxesValue: undefined,
  maxYAxesValue: undefined,
};

const styles: StyleRules = {
  root: {
    position: 'relative',
  },
};

export default withStyles(styles)(BarChart);
