import { createTypeAsyncAction } from '@gu-corp/redux-async-lib';
import { IStore } from '~/stores/configure-store';
import AdminGApiClient from './gapi/admin-gapi-client';
import * as AlertQueries from './gapi/query/alert';
import { QueryGetAdminAlertArgs } from '~/gapi/gtypes';
export * from './gapi/query/alert';

export const listAccountAlerts = createTypeAsyncAction(
  'LIST_ACCOUNT_ALERTS',
  async (args: AlertQueries.QueryListAccountAlertsArgs, store: IStore) => {
    const { accountAlertList } = store.appState;
    const client = new AdminGApiClient({});
    return client.listAccountAlerts({
      ...args,
      page: {
        pageIndex: accountAlertList.pageInfo.pageIndex,
        pageSize: accountAlertList.pageInfo.pageSize,
        ...args.page,
      },
      filter: {
        ...accountAlertList.filter,
        ...args.filter,
      },
      order: args.order || accountAlertList.order,
    });
  },
);

export const listAdminNotifications = createTypeAsyncAction(
  'LIST_ADMIN_NOTIFICATIONS',
  async (args: AlertQueries.QueryListAdminAlertsArgs, store: IStore) => {
    const { adminNotificationList } = store.appState;
    const client = new AdminGApiClient({});
    return client.listAdminAlerts({
      ...args,
      page: {
        pageIndex: adminNotificationList.pageInfo.pageIndex,
        pageSize: adminNotificationList.pageInfo.pageSize,
        ...args.page,
      },
      filter: {
        ...adminNotificationList.filter,
        ...args.filter,
        forAll: [],
        forUser: [],
      },
    });
  },
);

export const listUserNotifications = createTypeAsyncAction(
  'LIST_USER_NOTIFICATIONS',
  async (args: AlertQueries.QueryListAdminAlertsArgs, store: IStore) => {
    const { userNotificationList } = store.appState;
    const client = new AdminGApiClient({});
    return client.listAdminAlerts({
      ...args,
      page: {
        pageIndex: userNotificationList.pageInfo.pageIndex,
        pageSize: userNotificationList.pageInfo.pageSize,
        ...args.page,
      },
      filter: {
        ...userNotificationList.filter,
        ...args.filter,
        forAll: [],
        forAdmin: [],
      },
    });
  },
);

export const listAdminNotificationStats = createTypeAsyncAction(
  'LIST_ADMIN_NOTIFICATION_STATS',
  async (args: AlertQueries.QueryListAdminAlertsArgs, store: IStore) => {
    const client = new AdminGApiClient({});
    return client.listAdminAlerts({
      ...args,
      page: {
        pageIndex: 0,
        ...args.page,
      },
      filter: {
        ...args.filter,
        forAll: [],
        forUser: [],
        forAdmin: ['error'],
      },
    });
  },
);

export const listUserNotificationStats = createTypeAsyncAction(
  'LIST_USER_NOTIFICATION_STATS',
  async (args: AlertQueries.QueryListAdminAlertsArgs, store: IStore) => {
    const client = new AdminGApiClient({});
    return client.listAdminAlerts({
      ...args,
      page: {
        pageIndex: 0,
        ...args.page,
      },
      filter: {
        ...args.filter,
        forAll: [],
        forAdmin: [],
        forUser: ['error'],
      },
    });
  },
);

export const getAdminAlert = createTypeAsyncAction(
  'GET_ADMIN_ALERT',
  async (args: QueryGetAdminAlertArgs, store: IStore) => {
    const client = new AdminGApiClient({});
    return client.getAdminAlert(args);
  },
);
