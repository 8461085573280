import * as Gtypes from '../../../../gapi/gtypes';

export type QueryGetAccountStatsArgs = Gtypes.QueryGetAccountStatsArgs;
export const getAccountStatsQuery = `query($startDate: String!, $endDate: String!, $groupBy: AccountStatsGroupBy!) {
  getAccountStats(startDate: $startDate, endDate: $endDate, groupBy: $groupBy) {
    key
    count
  }
}`;
export type GET_ACCOUNT_STATS_RESULT_TYPE = {
  getAccountStats: Array<{ key: string; count: number }>;
};

export type QueryGetUserStatsArgs = Gtypes.QueryGetUserStatsArgs;
export const getUserStatsQuery = `query($startDate: String!, $endDate: String!, $groupBy: AccountStatsGroupBy!) {
  getUserStats(startDate: $startDate, endDate: $endDate, groupBy: $groupBy) {
    key
    count
  }
}`;
export type GET_USER_STATS_RESULT_TYPE = {
  getUserStats: Array<{ key: string; count: number }>;
};

export const getSummaryStatsQuery = `query {
  getSummaryStats {
    numOfAccounts
    numOfUsers
    recentUserInThisMonth
    numOfNetworks
    numOfNodes
    estimatedSalesInThisMonth
  }
}`;
export type GET_SUMMARY_STATS_RESULT_TYPE = {
  getSummaryStats: Gtypes.SummaryStats;
};
