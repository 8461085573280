import React, { useCallback, useState } from 'react';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { denimColor, nightRiderColor } from '~/styles/themes/common-styles/color';
import { defaultFont } from '~/styles/themes/common-styles/font';
import ChangeAccountPrimaryOwnerDialog from './change-primary-owner-dialog';
import DeleteAccountUserDialog from './delete-account-member-dialog';
import OptionButton from '~/components/common/option-button';
import { IAccountMember, IAccountSummary } from '~/types/admin-types';

interface IProps extends WithStyles<typeof styles> {
  account: IAccountSummary;
  member: IAccountMember;
  listAccountMembers: () => void;
}

const AccountMemberOption = (props: IProps) => {
  const { classes, member, account, listAccountMembers } = props;
  const { t } = useTranslation('admin');
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [openChangePrimaryOwnerDialog, setOpenChangePrimaryOwnerDialog] = useState(false);
  const [openRemoveMemberDialog, setOpenRemoveMemberDialog] = useState(false);

  const transferable =
    member.hasProfile && member.role === 'owner' && !Boolean(member.isPrimaryOwner);

  const onOpenAdminUserPopover = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setMenuAnchorEl(e.currentTarget);
  }, []);

  const onCloseOptionPopover = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const onOpenChangePrimaryOwnerDialog = useCallback(() => {
    setOpenChangePrimaryOwnerDialog(true);
  }, []);

  const onCloseChangePrimaryOwnerDialog = useCallback(
    (isRefersh?: boolean) => {
      setOpenChangePrimaryOwnerDialog(false);
      setMenuAnchorEl(null);
      if (isRefersh) {
        listAccountMembers();
      }
    },
    [listAccountMembers],
  );

  const onOpenRemoveMemberDialog = useCallback(() => {
    setOpenRemoveMemberDialog(true);
  }, []);

  const onCloseRemoveMemberDialog = useCallback(
    (isRefersh?: boolean) => {
      setOpenRemoveMemberDialog(false);
      setMenuAnchorEl(null);
      if (isRefersh) {
        listAccountMembers();
      }
    },
    [listAccountMembers],
  );

  return (
    <>
      <ChangeAccountPrimaryOwnerDialog
        member={member}
        account={account}
        open={openChangePrimaryOwnerDialog}
        onClose={onCloseChangePrimaryOwnerDialog}
      />
      <DeleteAccountUserDialog
        member={member}
        account={account}
        open={openRemoveMemberDialog}
        onClose={onCloseRemoveMemberDialog}
      />
      <Popover
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={onCloseOptionPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <List>
          <ListItem
            button
            className={classes.listOptionItem}
            disabled={!transferable}
            onClick={onOpenChangePrimaryOwnerDialog}
          >
            <img className={classes.transferIcon} src="/images/icons/access_ico.png" alt="" />
            {t('transfer_primary_owner')}
          </ListItem>
          <ListItem button className={classes.listOptionItem} onClick={onOpenRemoveMemberDialog}>
            <img className={classes.deleteIcon} src="/images/icons/delete_ico.png" alt="" />
            {t('delete_member')}
          </ListItem>
        </List>
      </Popover>
      <OptionButton id="admin-user-option" onClick={onOpenAdminUserPopover} />
    </>
  );
};

const styles = createStyles({
  root: {},
  tableHeadCustom: {
    marginTop: 16,
  },
  networkUuid: {
    cursor: 'pointer',
    color: denimColor,
  },
  menuIcon: {},
  listOptionItem: {
    height: 46,
    width: 250,
    ...defaultFont,
    fontSize: 15,
    color: nightRiderColor,
  },
  deleteIcon: {
    marginRight: 8,
  },
  transferIcon: {
    marginRight: 8,
  },
});

export default withStyles(styles)(AccountMemberOption);
