import { createTypeAsyncAction } from '@gu-corp/redux-async-lib';
import AdminGApiClient from './gapi/admin-gapi-client';
import * as account from './gapi/query/account';
import { IStore } from '~/stores/configure-store';
import {
  MutationAddAccountMemberArgs,
  MutationChangeAccountPrimaryOwnerArgs,
  MutationRemoveAccountMemberArgs,
  MutationUpdateAdminUserArgs,
} from '~/gapi/gtypes';
export * from './gapi/query/account';

export const listAccounts = createTypeAsyncAction(
  'ADMIN_LIST_ACCOUNTS',
  async (args: account.QueryListAccountsArgs, store: IStore) => {
    const { accountList } = store.appState;
    const client = new AdminGApiClient({});
    return client.listAccounts({
      ...args,
      page: {
        pageIndex: accountList.pageInfo.pageIndex,
        pageSize: accountList.pageInfo.pageSize,
        ...args.page,
      },
      filter: {
        ...accountList.filter,
        ...args.filter,
      },
      order: args.order || accountList.order,
    });
  },
);

export const getAccount = createTypeAsyncAction(
  'GET_ACCOUNT',
  async (args: account.QueryGetAccountArgs) => {
    const client = new AdminGApiClient({});
    return client.getAccount(args);
  },
);

export const listAccountMembers = createTypeAsyncAction(
  'ADMIN_LIST_ACCOUNT_MEMBERS',
  async (args: account.QueryListAccountMembersArgs) => {
    const client = new AdminGApiClient({});
    return client.listAccountMembers(args);
  },
);

export const addAccountMember = createTypeAsyncAction(
  'ADD_ACCOUNT_MEMBER',
  async (args: MutationAddAccountMemberArgs) => {
    const client = new AdminGApiClient({});
    return client.addAccountMember(args);
  },
);

export const changeAccountPrimaryOwner = createTypeAsyncAction(
  'CHANGE_ACCOUNT_PRIMARY_OWNER',
  async (args: MutationChangeAccountPrimaryOwnerArgs) => {
    const client = new AdminGApiClient({});
    return client.changeAccountPrimaryOwner(args);
  },
);

export const removeAccountMember = createTypeAsyncAction(
  'REMOVE_ACCOUNT_MEMBER',
  async (args: MutationRemoveAccountMemberArgs) => {
    const client = new AdminGApiClient({});
    return client.removeAccountMember(args);
  },
);

export const listAccountNetworks = createTypeAsyncAction(
  'ADMIN_LIST_ACCOUNT_NETWORKS',
  async (args: account.QueryListAccountNetworksArgs) => {
    const client = new AdminGApiClient({});
    return client.listAccountNetworks(args);
  },
);

export const listAdminUsers = createTypeAsyncAction('ADMIN_LIST_ADMIN_USERS', async () => {
  const client = new AdminGApiClient({});
  return await client.listAdminUsers();
});

export const listMemberUsers = createTypeAsyncAction('ADMIN_LIST_MEMBER_USERS', async () => {
  const client = new AdminGApiClient({});
  return await client.listMemberUsers();
});

export const createAdminUser = createTypeAsyncAction(
  'ADMIN_CREATE_ADMIN',
  async (args: account.MutationCreateAdminUserArgs) => {
    const client = new AdminGApiClient({});
    return await client.createAdminUser(args);
  },
);

export const updateAdminUser = createTypeAsyncAction(
  'ADMIN_UPDATE_ADMIN_USER',
  async (args: MutationUpdateAdminUserArgs) => {
    const client = new AdminGApiClient({});
    return await client.updateAdminUser(args);
  },
);

export const deleteAdminUser = createTypeAsyncAction(
  'ADMIN_DELETE_ADMIN',
  async (args: account.MutationDeleteAdminUserArgs) => {
    const client = new AdminGApiClient({});
    return await client.deleteAdminUser(args);
  },
);

export const changeAccountPlan = createTypeAsyncAction(
  'ADMIN_CHANGE_ACCOUNT_PLAN',
  async (args: account.MutationChangeAccountPlanArgs) => {
    const client = new AdminGApiClient({});
    return await client.changeAccountPlan(args);
  },
);

export const removeEnterpriseAccount = createTypeAsyncAction(
  'ADMIN_REMOVE_ENTERPRISE_ACCOUNT',
  async (args: account.MutationRemoveEnterpriseAccountArgs) => {
    const client = new AdminGApiClient({});
    return await client.removeEnterpriseAccount(args);
  },
);

export const addCouponToAccount = createTypeAsyncAction(
  'ADMIN_ADD_COUPON_TO_ACCOUNT',
  async (args: account.MutationAddCouponToAccountArgs) => {
    const client = new AdminGApiClient({});
    return await client.addCouponToAccount(args);
  },
);

export const editCouponToAccount = createTypeAsyncAction(
  'ADMIN_EDIT_COUPON_TO_ACCOUNT',
  async (args: account.MutationEditCouponToAccountArgs) => {
    const client = new AdminGApiClient({});
    return await client.editCouponToAccount(args);
  },
);
