import React from 'react';
import { connect } from 'react-redux';
// Component
import Button from '@mui/material/Button';
import SubmitButton from '~/components/common/custom-submit-button';
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import { IStore } from '~/stores/configure-store';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';
import { romanColor, whiteSmokeColor, dimGrayColor } from '~/styles/themes/common-styles/color';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';
import * as AwsAccountActions from '~/stores/actions/aws-account-action';
import { IAWSAccountList } from '~/types/admin-types';

interface IStateProps {
  awsAccountList: IAWSAccountList;
}

interface IDispProps {
  createAWSAccount: () => Promise<AwsAccountActions.CREATE_AWS_ACCOUNT_RESULT_TYPE>;
  listAWSAccounts: (
    args: AwsAccountActions.QueryToListAWSAccountsArgs,
  ) => Promise<AwsAccountActions.LIST_AWS_ACCOUNTS_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
}

interface IState {
  isSubmitting: boolean;
}

class AddAWSDialog extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  public render() {
    const { classes, open, onClose, t } = this.props;
    const { isSubmitting } = this.state;

    return (
      <CustomDialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
        <CustomDialogTitle>
          <div id="admin-aws-accounts-add-title">{t('add_user')}</div>
        </CustomDialogTitle>
        {/* Render Form Field */}
        <CustomDialogContent>
          <div className={classes.dialogText}>{t('are_you_sure_you_want_to_add_aws_account')}</div>
        </CustomDialogContent>
        {/* Render Submit Button */}
        <CustomDialogActions>
          <Button
            data-testid="aws-account-dialog-cancel-btn"
            id="admin-aws-accounts-add-cancel"
            disabled={isSubmitting}
            className={classes.leftBtn}
            variant="outlined"
            onClick={onClose}
          >
            {t('cancel')}
          </Button>
          <SubmitButton
            data-testid="aws-account-dialog-submit-btn"
            id="admin-aws-accounts-add-submit"
            isLoading={isSubmitting}
            label={t('create')}
            submittingLabel={t('creating')}
            disabled={isSubmitting}
            onClick={this.onSubmit}
          />
        </CustomDialogActions>
      </CustomDialog>
    );
  }

  listAWSAccounts = (args: AwsAccountActions.QueryToListAWSAccountsArgs) => {
    const { awsAccountList, listAWSAccounts } = this.props;
    listAWSAccounts({
      filter: {
        ...awsAccountList.filter,
        ...args.filter,
      },
    });
  };

  private onSubmit = async () => {
    const { onClose, createAWSAccount } = this.props;
    this.setState({ isSubmitting: true });
    try {
      await createAWSAccount();
      this.listAWSAccounts({});
    } catch (error: any) {
      console.log(error.message);
    }
    this.setState({ isSubmitting: false });
    onClose();
  };
}

const styles = createStyles({
  root: {},
  dialogPaper: {
    maxWidth: 700,
  },
  dialogText: {
    textAlign: 'center',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
    marginTop: 12,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

const mapStateToProps = (store: IStore): IStateProps => ({
  awsAccountList: store.appState.awsAccountList,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  createAWSAccount: () => dispatch(AwsAccountActions.createAWSAccount()),
  listAWSAccounts: (args: AwsAccountActions.QueryToListAWSAccountsArgs) =>
    dispatch(AwsAccountActions.listAWSAccounts(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation('admin')(AddAWSDialog)),
);
