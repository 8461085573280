import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { whiteColor, blackRussianColor } from '~/styles/themes/common-styles/color';
import { IStore } from '~/stores/configure-store';
import Layout from '~/components/common/Layout';
import QuickSearchDataGridToolbar from '~/components/common/custom-data-grid-toolbar';
import PlanFilterInput from './plan-filter';
import AddAWSDialog from './add-aws-dialog';
import DeleteAWSAccountOption from './delete-aws-account-option';
import * as AwsAccountActions from '~/stores/actions/aws-account-action';
import { IAWSAccountList, AWSAccountPlan, IAdminUser } from '~/types/admin-types';
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridFilterModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { Button, Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withAdminProfile } from '~/hooks/with-admin-profile';
import { compose } from 'redux';
import { DEFAULT_ACIDLEN_AMAZON } from '~/constants/common';
import { AppRoutePath } from '~/AppRouter';
import { withAuthorization } from '~/hooks/with-authorization';

const SEARCH_INPUT_DELAY = 300;

interface StateProps {
  awsAccountList: IAWSAccountList;
  isLoading: boolean;
  profile?: IAdminUser;
}

interface DispProps {
  listAWSAccounts: (
    args: AwsAccountActions.QueryToListAWSAccountsArgs,
  ) => Promise<AwsAccountActions.LIST_AWS_ACCOUNTS_RESULT_TYPE>;
}

interface Props
  extends StateProps,
    DispProps,
    RouteComponentProps<{}>,
    WithTranslation,
    WithStyles<typeof styles> {}

interface State {
  openAddAWSDialog: boolean;
  searchText: string;
  filterModel?: GridFilterModel;
}

class AwsAccounts extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openAddAWSDialog: false,
      searchText: '',
      filterModel: {
        items: [
          {
            columnField: DataGridFieldEnum.PLAN,
            operatorValue: 'equals',
            value: undefined,
          },
        ],
      },
    };
  }

  async componentDidMount() {
    const { awsAccountList } = this.props;

    if (awsAccountList?.filter.text) {
      this.setState({
        searchText: awsAccountList.filter.text,
      });
    }
    if (!awsAccountList.awsAccounts) {
      this.listAWSAccounts({});
    }
  }

  listAWSAccounts = (args: AwsAccountActions.QueryToListAWSAccountsArgs) => {
    const { awsAccountList, listAWSAccounts } = this.props;

    listAWSAccounts({
      filter: {
        ...awsAccountList.filter,
        ...args.filter,
      },
    });
  };

  onPlanChange = (plan?: AWSAccountPlan) => {
    this.listAWSAccounts({ filter: { plan } });
  };

  private debouncedOnSearchInputChange = _.debounce((text: string) => {
    this.listAWSAccounts({ filter: { text } });
  }, SEARCH_INPUT_DELAY);

  private onSearchInputChange = (value: string) => {
    this.setState({
      searchText: value,
    });
    this.debouncedOnSearchInputChange(value);
  };

  private onFilterModelChange = (model: GridFilterModel) => {
    this.setState({
      filterModel: model,
    });
    this.listAWSAccounts({
      filter: {
        plan:
          model.items[0]?.columnField === DataGridFieldEnum.PLAN ? model.items[0].value : undefined,
      },
    });
  };

  public render() {
    const { classes, t, awsAccountList, isLoading, profile } = this.props;
    const { openAddAWSDialog, searchText, filterModel } = this.state;

    const items: Array<Item> = (awsAccountList.awsAccounts || []).map((aws, index) => ({
      id: `${index}-${aws.awsAccountId}`,
      email: aws.email,
      awsAccoutId: `${aws.awsAccountId.padStart(DEFAULT_ACIDLEN_AMAZON, '0')}`,
      name: aws.name,
      plan: aws.plan,
      uuid: aws.uuid,
      userCount: aws.userCount,
      createAt: new Date(aws.createAt),
    }));

    const columns: GridColumns = [
      {
        field: DataGridFieldEnum.EMAIL,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        headerName: t('aws_account'),
        width: 450,
      },
      {
        field: DataGridFieldEnum.AWS_ACCOUNT_ID,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        headerName: t('aws_account_id'),
        width: 150,
      },
      {
        field: DataGridFieldEnum.NAME,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        headerName: t('display_name'),
        width: 450,
      },
      {
        field: DataGridFieldEnum.PLAN,
        disableColumnMenu: true,
        sortable: false,
        filterable: true,
        headerName: t('plan'),
        filterOperators: [
          {
            label: t('data_grid.equals'),
            value: 'equals',
            InputComponent: PlanFilterInput,
            getApplyFilterFn: () => null,
          },
        ],
      },
      {
        field: DataGridFieldEnum.UUID,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        headerName: t('uuid'),
        width: 250,
      },
      {
        field: DataGridFieldEnum.USER_COUNT,
        sortable: true,
        filterable: false,
        headerName: t('users'),
        width: 150,
      },
      {
        field: DataGridFieldEnum.CREATED_AT,
        sortable: true,
        filterable: false,
        headerName: t('created'),
        valueFormatter: ({ value }) => t('create_date_value', { date: value }),
        width: 200,
      },
      {
        field: DataGridFieldEnum.ACTION,
        sortable: false,
        filterable: false,
        headerName: t('action'),
        type: 'actions',
        disableColumnMenu: true,
        renderHeader: () => <></>,
        headerClassName: classes.cellAction,
        renderCell: (params: GridCellParams) => {
          return ['owner', 'admin'].includes(profile?.role || '') ? (
            <DeleteAWSAccountOption item={params.row as Item}></DeleteAWSAccountOption>
          ) : undefined;
        },
      },
    ];

    return (
      <Layout>
        <AddAWSDialog open={openAddAWSDialog} onClose={this.onCloseAddAWSDialog} />
        <div className={classes.root}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Link
              data-testid="navigate-to-dashboard"
              component={RouterLink}
              to={AppRoutePath.Dashboard}
              color="text.primary"
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            </Link>
            <Typography color="text.primary">{t('aws_accounts')}</Typography>
          </Breadcrumbs>
          {['owner', 'admin'].includes(profile?.role || '') && (
            <div className={classes.topLine}>
              <Button
                data-testid="add-aws-account-button"
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<AddIcon />}
                onClick={this.onOpenAddAWSDialog}
              >
                {t('add_aws')}
              </Button>
            </div>
          )}
          <div className={classes.dataGridWrapper}>
            <DataGrid
              disableVirtualization={true}
              rows={items}
              columns={columns}
              components={{
                Toolbar: QuickSearchDataGridToolbar,
              }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  placeholder: t('search_aws_account_placeholder'),
                  isQuickSearch: true,
                  onChange: (event) => this.onSearchInputChange(event.target.value),
                  clearSearch: () => this.onSearchInputChange(''),
                },
              }}
              classes={{ root: classes.gridRoot }}
              localeText={{
                toolbarColumns: t('data_grid.columns'),
                toolbarFilters: t('data_grid.filters'),
                toolbarDensity: t('data_grid.density'),
                filterPanelColumns: t('data_grid.columns'),
                filterPanelOperators: t('data_grid.operators'),
                columnsPanelTextFieldLabel: t('data_grid.find_column'),
                columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
                columnsPanelDragIconLabel: t('data_grid.reorder_column'),
                columnsPanelShowAllButton: t('data_grid.show_all'),
                columnsPanelHideAllButton: t('data_grid.hide_all'),
                toolbarDensityCompact: t('data_grid.density_compact'),
                toolbarDensityStandard: t('data_grid.density_standard'),
                toolbarDensityComfortable: t('data_grid.density_comfortable'),
                filterOperatorContains: t('data_grid.contains'),
                filterOperatorEquals: t('data_grid.equals'),
                filterOperatorStartsWith: t('data_grid.startsWith'),
                filterOperatorEndsWith: t('data_grid.endsWith'),
                filterOperatorIsEmpty: t('data_grid.isEmpty'),
                filterOperatorIsNotEmpty: t('data_grid.isNotEmpty'),
                filterOperatorIsAnyOf: t('data_grid.isAnyOf'),
                filterOperatorIs: t('data_grid.is'),
                filterOperatorNot: t('data_grid.isNot'),
                noRowsLabel: t('data_grid.no_rows'),
              }}
              isRowSelectable={() => false}
              disableSelectionOnClick
              checkboxSelection={false}
              loading={isLoading}
              filterMode="server"
              filterModel={filterModel}
              onFilterModelChange={this.onFilterModelChange}
            />
          </div>
        </div>
      </Layout>
    );
  }

  private onOpenAddAWSDialog = () => {
    this.setState({
      openAddAWSDialog: true,
    });
  };

  private onCloseAddAWSDialog = () => {
    this.setState({
      openAddAWSDialog: false,
    });
  };
}

const styles = (theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: 'auto',
      paddingRight: 0,
    },
    dataGridWrapper: {
      height: 500,
      width: '100%',
      marginTop: 24,
      backgroundColor: whiteColor,
      [theme.breakpoints.up('sm')]: {
        marginTop: 32,
      },
    },
    cellAction: {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
    },
    gridRoot: {
      '& .MuiDataGrid-row': {
        maxHeight: 'none !important',
      },
      '& .MuiDataGrid-cell': {
        whiteSpace: 'pre-wrap',
        maxHeight: 'none !important',
        outline: 'none !important',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    topLine: {
      marginTop: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
    },
    button: {
      margin: 0,
    },
  });

const mapStateToProps = (store: IStore) => ({
  profile: store.appState.profile,
  awsAccountList: store.appState.awsAccountList,
  isLoading: AwsAccountActions.listAWSAccounts.isPending(store),
});

const mapDispatchToProps = (dispatch): DispProps => ({
  listAWSAccounts: (args: AwsAccountActions.QueryToListAWSAccountsArgs) =>
    dispatch(AwsAccountActions.listAWSAccounts(args)),
});

export default compose(
  withFirebaseUser(),
  withAdminProfile,
  withAuthorization(['owner', 'admin', 'operator']),
  withStyles(styles),
  withTranslation('admin'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AwsAccounts);

export type Item = {
  email: string;
  id: string;
  name: string;
  plan: AWSAccountPlan;
  uuid: string;
  userCount: number;
  awsAccoutId: string;
  createAt: Date;
};

enum DataGridFieldEnum {
  EMAIL = 'email',
  ID = 'id',
  AWS_ACCOUNT_ID = 'awsAccoutId',
  NAME = 'name',
  PLAN = 'plan',
  UUID = 'uuid',
  USER_COUNT = 'userCount',
  ACTION = 'action',
  CREATED_AT = 'createAt',
}
