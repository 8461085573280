import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  defaultFont,
  defaultFontBold,
  defaultFontRegular,
} from '~/styles/themes/common-styles/font';
import { nightRiderColor, lightSlateGreyColor } from '~/styles/themes/common-styles/color';
import { IAccountSummary } from '~/types/admin-types';

interface Props extends RouteComponentProps<{}>, WithStyles<typeof styles>, WithTranslation {
  account: IAccountSummary;
}

interface DispProps {}

interface State {}

class AccountContact extends React.Component<Props & DispProps, State> {
  constructor(props: Props & DispProps) {
    super(props);
  }

  public render() {
    const { classes, t, account } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.left}>
          <div className={classes.leftItem}>
            <div className={classes.label}>{t('accountant_Email')}</div>
            <div className={classes.value}>{account.contact?.accountantEmail || t('no_set')}</div>
          </div>
          <div className={classes.leftItem}>
            <div className={classes.label}>{t('system_admin_Email')}</div>
            <div className={classes.value}>{account.contact?.systemAdminEmail || t('no_set')}</div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = createStyles({
  root: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  left: {},
  leftItem: {
    marginTop: 15,
  },
  leftTopItem: {
    marginTop: 0,
  },
  label: {
    ...defaultFontBold,
    fontSize: 12,
    color: nightRiderColor,
  },
  value: {
    marginTop: 4,
    ...defaultFont,
    fontSize: 15,
    color: lightSlateGreyColor,
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  rightTop: {
    textAlign: 'right',
  },
  rightBottom: {
    textAlign: 'right',
  },
});

export default withStyles(styles)(withTranslation('admin')(withRouter(AccountContact)));
