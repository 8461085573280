import React from 'react';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  persianGreenColor,
  lightSlateGreyColor,
  denimColor,
  dodgerBlue2Color,
  whiteColor,
} from '~/styles/themes/common-styles/color';
import Table from '@mui/material/Table';
import TableBodyCustom from '~/components/common/table-body';
import TableBodyCellCustom from '~/components/common/table-body-cell';
import TableBodyRowCustom from '~/components/common/table-body-row';
import TableHeadCustom from '~/components/common/table-head';
import TableHeadRowCustom from '~/components/common/table-head-row';
import TableHeadCellCustom from '~/components/common/table-head-cell';

import { INetworkSummary } from '~/types/admin-types';
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';

interface Props extends WithStyles<typeof styles>, WithTranslation {
  summary: INetworkSummary;
}

interface DispProps {}

interface State {}

class NetworkDetailProposal extends React.Component<Props & DispProps, State> {
  public render() {
    const { classes, t, summary } = this.props;
    const { proposals } = summary;

    const columns: GridColumns = [
      {
        field: DataGridFieldEnum.ADDRESS,
        disableColumnMenu: true,
        filterable: true,
        sortable: false,
        headerName: t('Address'),
        width: 400,
      },
      {
        field: DataGridFieldEnum.NODE,
        disableColumnMenu: true,
        filterable: true,
        sortable: false,
        headerName: t('node'),
        width: 250,
        renderCell: (params: GridCellParams) => {
          const proposal = params.row;
          return proposal.targetNodeUuid ? (
            <div className={classes.nodeCell}>
              <div>{proposal.accountNameOwnTargetNode}</div>
              <div>{proposal.targetNodeName}</div>
              <div>{proposal.targetNodeUuid}</div>
            </div>
          ) : (
            'External Node'
          );
        },
      },
      {
        field: DataGridFieldEnum.IS_VALIDATOR,
        disableColumnMenu: true,
        sortable: false,
        filterable: true,
        headerName: t('proposal_type'),
        valueFormatter: (params) => (params.value ? t('validator_node') : t('relay_node')),
        width: 300,
      },
      {
        field: DataGridFieldEnum.APPROVAL_STATUS,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        headerName: t('approval_status'),
        renderCell: (params: GridCellParams) => {
          const proposal = params.row;
          return (
            <div className={classes.nodeArea}>
              {proposal.status === 'proposed' && <div>{this.props.t('common:proposed')}</div>}
              {proposal.status === 'approved' && <div>{this.props.t('common:approved')}</div>}
              {proposal.status === 'rejected' && <div>{this.props.t('common:rejected')}</div>}
              <div className={classes.nodeDetail}>
                <div>
                  {t('approved_nodes')}:{' '}
                  <span className={classes.nodeNumber}>{proposal.approvedAddresses.length}</span>
                </div>
                <div>
                  {t('num_of_validators')}:{' '}
                  <span className={classes.nodeNumber}>{proposal.signerAddresses.length}</span>
                </div>
              </div>
            </div>
          );
        },
        width: 300,
      },
    ];

    const items: Array<Item> = proposals.map((cluster) => ({
      ...cluster,
      id: cluster.address,
    }));

    return (
      <DataGrid
        className={classes.dataGridWrapper}
        rows={items}
        columns={columns}
        autoHeight
        localeText={{
          toolbarColumns: t('data_grid.columns'),
          toolbarFilters: t('data_grid.filters'),
          toolbarDensity: t('data_grid.density'),
          filterPanelColumns: t('data_grid.columns'),
          filterPanelOperators: t('data_grid.operators'),
          columnsPanelTextFieldLabel: t('data_grid.find_column'),
          columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
          columnsPanelDragIconLabel: t('data_grid.reorder_column'),
          columnsPanelShowAllButton: t('data_grid.show_all'),
          columnsPanelHideAllButton: t('data_grid.hide_all'),
          toolbarDensityCompact: t('data_grid.density_compact'),
          toolbarDensityStandard: t('data_grid.density_standard'),
          toolbarDensityComfortable: t('data_grid.density_comfortable'),
          filterOperatorContains: t('data_grid.contains'),
          filterOperatorEquals: t('data_grid.equals'),
          filterOperatorStartsWith: t('data_grid.startsWith'),
          filterOperatorEndsWith: t('data_grid.endsWith'),
          filterOperatorIsEmpty: t('data_grid.isEmpty'),
          filterOperatorIsNotEmpty: t('data_grid.isNotEmpty'),
          filterOperatorIsAnyOf: t('data_grid.isAnyOf'),
          filterOperatorIs: t('data_grid.is'),
          filterOperatorNot: t('data_grid.isNot'),
          noRowsLabel: t('data_grid.no_rows'),
        }}
        hideFooter
        isRowSelectable={() => false}
        getRowHeight={() => 'auto'}
      />
    );
  }
}

const styles = createStyles({
  root: {},
  tableBodyCellCustom: {
    maxWidth: 150,
  },
  address: {
    width: '35%',
  },
  tableBodyCellCustoms: {
    maxWidth: 'unset',
  },
  stateIcon: {
    display: 'inline-block',
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: persianGreenColor,
    marginRight: 7,
  },
  nodeArea: {
    display: 'flex',
    alignItems: 'center',
  },
  nodeDetail: {
    ...defaultFont,
    fontSize: 13,
    color: lightSlateGreyColor,
    marginLeft: 30,
  },
  nodeNumber: {
    color: denimColor,
  },
  dataGridWrapper: {
    width: '100%',
    backgroundColor: whiteColor,
    border: 'none',
  },
  nodeCell: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 10,
    paddingBottom: 10,
  },
});

enum DataGridFieldEnum {
  ADDRESS = 'address',
  NODE = 'node',
  IS_VALIDATOR = 'isValidator',
  APPROVAL_STATUS = 'status',
}

type Item = {
  id: string;
  address: string;
  targetNodeUuid?: string;
  accountNameOwnTargetNode?: string;
  targetNodeName?: string;
  isValidator: boolean;
  status: string;
  approvedAddresses: string[];
  signerAddresses: string[];
};

export default withStyles(styles)(withTranslation(['admin', 'common'])(NetworkDetailProposal));
