import { createTypeAsyncAction } from '@gu-corp/redux-async-lib';
import AdminGApiClient from './gapi/admin-gapi-client';
import * as network from './gapi/query/network';
import * as QueryProposal from './gapi/query/proposal';
import {
  MutationApplyHardForkToNodeArgs,
  MutationControlBlockExplorerArgs,
  MutationControlNodeArgs,
  MutationUpdateBlockGasLimitArgs,
  MutationUpdateNodeVersionArgs,
  QueryGetGenesisJsonArgs,
  QueryListNetworksArgs,
  QueryListProposalHistoryArgs,
} from '~/gapi/gtypes';
import { IStore } from '../configure-store';

export * from './gapi/query/network';
export * from './gapi/query/proposal';

export const destroyNetwork = createTypeAsyncAction(
  'ADMIN_DESTROY_NETWORK',
  async (args: network.MutationDestroyNetworkArgs) => {
    const client = new AdminGApiClient({});
    return client.destroyNetwork(args);
  },
);

export const deleteCluster = createTypeAsyncAction(
  'ADMIN_DELETE_CLUSTER',
  async (args: network.MutationDeleteClusterArgs) => {
    const client = new AdminGApiClient({});
    return client.deleteCluster(args);
  },
);

export const deleteNode = createTypeAsyncAction(
  'ADMIN_DELETE_NODE',
  async (args: network.MutationDeleteNodeArgs) => {
    const client = new AdminGApiClient({});
    return await client.deleteNode(args);
  },
);

export const getSigners = createTypeAsyncAction(
  'ADMIN_GET_SIGNERS',
  async (args: QueryProposal.QueryGetSignersArgs) => {
    const client = new AdminGApiClient({});
    return client.getSigners(args);
  },
);

export const getProposalStatus = createTypeAsyncAction(
  'ADMIN_GET_PROPOSAL_STATUS',
  async (args: QueryProposal.QueryGetProposalStatusArgs) => {
    const client = new AdminGApiClient({});
    return client.getProposalStatus(args);
  },
);

export const listProposalHistory = createTypeAsyncAction(
  'LIST_PROPOSAL_HISTORY',
  async (args: QueryListProposalHistoryArgs, store: IStore) => {
    const { listProposalHistory } = store.appState;
    const client = new AdminGApiClient({});
    return client.listProposalHistory({
      ...args,
      page: {
        pageIndex: listProposalHistory.pageInfo.pageIndex,
        pageSize: listProposalHistory.pageInfo.pageSize,
        ...args.page,
      },
      filter: {
        ...listProposalHistory.filter,
        ...args.filter,
      },
    });
  },
);

export const controlNode = createTypeAsyncAction(
  'CONTROL_NODE',
  async (args: MutationControlNodeArgs) => {
    const client = new AdminGApiClient({});
    return await client.controlNode(args);
  },
);

export const updateNodeVersion = createTypeAsyncAction(
  'UPDATE_NODE_VERSION_RESULT_TYPE',
  async (args: MutationUpdateNodeVersionArgs) => {
    const client = new AdminGApiClient({});
    return client.updateNodeVersion(args);
  },
);

export const applyHardForkToNode = createTypeAsyncAction(
  'APPLY_HARD_FORK_TO_NODE',
  async (args: MutationApplyHardForkToNodeArgs) => {
    const client = new AdminGApiClient({});
    return client.applyHardForkToNode(args);
  },
);

export const updateBlockGasLimit = createTypeAsyncAction(
  'UPDATE_GAS_LIMIT_RESULT_TYPE',
  async (args: MutationUpdateBlockGasLimitArgs) => {
    const client = new AdminGApiClient({});
    return client.updateBlockGasLimit(args);
  },
);

export const getGenesisJson = createTypeAsyncAction(
  'GET_GENESIS_JSON',
  async (args: QueryGetGenesisJsonArgs) => {
    const client = new AdminGApiClient({});
    return client.getGenesisJson(args);
  },
);

export const listNetworks = createTypeAsyncAction(
  'LIST_NETWORKS',
  async (args: QueryListNetworksArgs, store: IStore) => {
    const { networkList } = store.appState;
    const client = new AdminGApiClient({});
    return client.listNetworks({
      ...args,
      page: {
        pageIndex: networkList.pageInfo.pageIndex,
        pageSize: networkList.pageInfo.pageSize,
        ...args.page,
      },
      filter: {
        ...networkList.filter,
        ...args.filter,
      },
      order: args.order || networkList.order,
    });
  },
);

export const controlExplorer = createTypeAsyncAction(
  'CONTROL_EXPLORER',
  async (args: MutationControlBlockExplorerArgs) => {
    const client = new AdminGApiClient({});
    return await client.controlExplorer(args);
  },
);
