import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import { defaultFontMedium } from '~/styles/themes/common-styles/font';
import { nightRiderColor } from '~/styles/themes/common-styles/color';
import SortIcon from '~/components/common/sort-icon';

interface Props extends Omit<TableCellProps, 'classes'>, WithStyles<typeof styles> {
  sort?: 'DESC' | 'ASC';
}

const TableHeadCell: React.StatelessComponent<Props> = ({
  classes,
  children,
  className,
  sort,
  onClick,
  ...tableCellProps
}) => (
  <TableCell
    {...tableCellProps}
    className={classes.root}
    classes={{
      head: classes.head,
    }}
  >
    <div className={classes.contentWrap} onClick={onClick}>
      <div className={classes.content}>{children}</div>
      {sort && <SortIcon order={sort} />}
    </div>
  </TableCell>
);

const styles = createStyles({
  root: {
    paddingLeft: 24,
    paddingRight: 30,
    color: nightRiderColor,
  },
  head: {
    fontSize: '0.75rem',
  },
  contentWrap: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {},
  arrowIcon: {},
});

export default withStyles(styles)(TableHeadCell);
