import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import * as ProfileActions from '~/stores/actions/profile-action';
import LoadingComponent from '~/components/common/loading';
import ErrorFallback from '~/components/common/error-fallback';
import { IStore } from '~/stores/configure-store';
import Unauthenticated from '~/components/common/UnauthenticatedError';

const AdminProfileContext = createContext<any>({} as any);

export const useAdminProfile = () => useContext(AdminProfileContext);

export const withAdminProfile = <P, S>(ChildComponent: any) => {
  const WithAdminProfileHOC = (props: P) => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const [error, setError] = useState<any>();
    const profile = useSelector((store: IStore) => store.appState.profile);
    const fetchAdminProfile = useCallback(async () => {
      try {
        await dispatch(ProfileActions.getAdminProfile());
        setError(null);
      } catch (err) {
        setError(err);
      }
    }, [dispatch]);

    useEffect(() => {
      if (!profile) {
        fetchAdminProfile();
      } else {
        i18n.changeLanguage(profile.preference.language);
        moment.tz.setDefault(profile.preference.timezone);
      }
    }, [dispatch, fetchAdminProfile, i18n, profile]);

    if (error && error.message == 'Unauthenticated') {
      return <Unauthenticated />;
    } else if (error) {
      return <ErrorFallback />;
    }

    if (profile) {
      return (
        <AdminProfileContext.Provider value={profile}>
          <ChildComponent {...props} />
        </AdminProfileContext.Provider>
      );
    }

    return <LoadingComponent />;
  };

  WithAdminProfileHOC.displayName = 'WithAdminProfileHOC';
  hoistNonReactStatics(WithAdminProfileHOC, ChildComponent);

  return WithAdminProfileHOC;
};
