import React from 'react';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBodyCustom from '~/components/common/table-body';
import TableHeadCustom from '~/components/common/table-head';
import TableHeadRowCustom from '~/components/common/table-head-row';
import TableHeadCellCustom from '~/components/common/table-head-cell';
import SearchInput from '~/components/common/search-input';
import NodeTableRow from './node-table-row';
import NodeTypeSelector from './node-type-selector';
import { INetworkSummary, IClusterUnit, INodeUnit } from '~/types/admin-types';
import { Proposal, INodeDisplay } from '~/types/network-types';
import { Order, getComparator, stableSort, HeadCell } from '~/utilities/sort-utils';
import { displayNodeType, displayNodeStatusAdmin } from '~/utilities/render-utils';

interface Props extends WithStyles<typeof styles>, WithTranslation {
  summary: INetworkSummary;
}

interface DispProps {}

interface State {
  searchText: string;
  nodeType?: 'signer' | 'transaction';
  order: Order;
  orderBy: SortableHeadCellId;
}

class NetworkDetailNodes extends React.Component<Props & DispProps, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchText: '',
      nodeType: undefined,
      order: 'ASC',
      orderBy: 'nodeState',
    };
  }

  onSearchInputChange = (e) => {
    this.setState({ searchText: e.target.value });
  };

  onNodeTypeChange = (nodeType?: 'signer' | 'transaction') => {
    this.setState({ nodeType });
  };

  public render() {
    const { classes, t, summary } = this.props;
    const { searchText, nodeType, order, orderBy } = this.state;

    const items = this.getItems();

    return (
      <div className={classes.root}>
        <div className={classes.topLine}>
          <SearchInput
            data-testid="searh-node-input"
            value={searchText}
            onChange={this.onSearchInputChange}
            placeholder={t('search_node_placeholder')}
            classes={{ root: classes.searchInput }}
          />
          <NodeTypeSelector
            value={nodeType}
            onChange={this.onNodeTypeChange}
            data-testid="change-node-type"
          />
        </div>
        <Table id="admin-network-nodes-list" className={classes.nodeTable}>
          <colgroup>
            <col width="auto" />
            <col width="auto" />
            <col width="auto" />
            <col width="14%" />
            <col width="18%" />
            <col width="12%" />
            <col width="auto" />
          </colgroup>
          <TableHeadCustom>
            <TableHeadRowCustom>
              {headCells.map((headCell) => (
                <TableHeadCellCustom
                  key={headCell.id}
                  onClick={headCell.sortable ? this.onTableCellHeadClick(headCell.id) : undefined}
                  style={{ cursor: headCell.sortable ? 'pointer' : 'unset' }}
                  sort={orderBy === headCell.id ? order : undefined}
                >
                  <span>{t(headCell.label)}</span>
                </TableHeadCellCustom>
              ))}
              <TableHeadCellCustom
                classes={{ root: classes.tableHeadCellLastColumn }}
              ></TableHeadCellCustom>
            </TableHeadRowCustom>
          </TableHeadCustom>
          <TableBodyCustom>
            {items.map((item) => (
              <NodeTableRow key={item.node.nodeUuid} item={item} />
            ))}
          </TableBodyCustom>
        </Table>
      </div>
    );
  }

  getItems = () => {
    const { summary } = this.props;
    const { network, proposals } = summary;
    const { searchText, nodeType, order, orderBy } = this.state;

    const items: Array<Item> = [];
    for (const cluster of network.clusters) {
      for (const node of cluster.nodes) {
        if (
          searchText &&
          !cluster.clusterName.includes(searchText) &&
          !node.nodeName.includes(searchText)
        ) {
          continue;
        }

        if (nodeType && node.nodeInfo.signer !== (nodeType === 'signer')) {
          continue;
        }

        items.push({
          clusterName: cluster.clusterName,
          nodeName: node.nodeName,
          networkUuid: network.networkUuid,
          nodeType: displayNodeType(node, proposals as Proposal[]),
          instanceId: node.serverInfo.instanceId,
          nodeState: displayNodeStatusAdmin(node),
          cluster,
          node,
          summary,
        });
      }
    }

    return stableSort<Item>(items, getComparator<SortableHeadCellId>(order, orderBy));
  };

  private onTableCellHeadClick = (id: SortableHeadCellId) => () => {
    const { order, orderBy } = this.state;
    this.setState({
      orderBy: id,
      order: orderBy === id && order === 'ASC' ? 'DESC' : 'ASC',
    });
  };
}

const styles = createStyles({
  root: {
    marginTop: 20,
    padding: 20,
  },
  topLine: {
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    flex: 1,
    marginRight: 20,
  },
  nodeTable: {
    marginTop: 10,
  },
  tableHeadCellCommon: {
    padding: '4px 0px 4px 2%',
    wordBreak: 'break-all',
  },
  tableHeadCellLastColumn: {
    paddingRight: 24,
    padding: 0,
  },
});

export default withStyles(styles)(withTranslation('admin')(NetworkDetailNodes));

export type Item = {
  clusterName: string;
  nodeName: string;
  networkUuid: string;
  nodeType: string;
  instanceId: string;
  nodeState: INodeDisplay;
  summary: INetworkSummary;
  cluster: IClusterUnit;
  node: INodeUnit;
};
type SortableHeadCellId = keyof Pick<Item, 'nodeState'>;
type UnsortableHeadCellId = keyof Omit<Item, 'nodeState'>;
const headCells: Array<HeadCell<SortableHeadCellId, UnsortableHeadCellId>> = [
  { id: 'clusterName', sortable: false, label: 'cluster' },
  { id: 'nodeName', sortable: false, label: 'node_name' },
  { id: 'networkUuid', sortable: false, label: 'blockchain_id' },
  { id: 'nodeType', sortable: false, label: 'node_type' },
  { id: 'instanceId', sortable: false, label: 'instance_id' },
  { id: 'nodeState', sortable: true, label: 'status' },
];
