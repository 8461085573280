import React from 'react';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import DeleteNetworkDialog from '../delete-network-dialog';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ImgIcon from '~/components/common/img-icon';
import { denimColor, nightRiderColor } from '~/styles/themes/common-styles/color';
import { defaultFont } from '~/styles/themes/common-styles/font';
import { Item } from '..';

interface Props extends WithStyles<typeof styles>, WithTranslation {
  network: Item;
}

interface State {
  openDeleteNetworkDialog: boolean;
  menuAnchorEl: HTMLElement | null;
}

class NetworkOption extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      openDeleteNetworkDialog: false,
      menuAnchorEl: null,
    };
  }

  public render() {
    const { classes, network } = this.props;
    const { openDeleteNetworkDialog } = this.state;

    return (
      <>
        {this.renderOptionPopover}
        <DeleteNetworkDialog
          network={network}
          open={openDeleteNetworkDialog}
          onClose={this.onCloseDeleteNetworkDialog}
        />
        <IconButton onClick={this.onOpenOptionPopover} data-testid="network-option-button">
          <ImgIcon className={classes.menuIcon} imgUrl="/images/icons/options-ico.png" />
        </IconButton>
      </>
    );
  }

  onOpenOptionPopover = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ menuAnchorEl: e.currentTarget });
  };
  onCloseOptionPopover = () => {
    this.setState({ menuAnchorEl: null });
  };

  get renderOptionPopover() {
    const { classes, t } = this.props;
    const { menuAnchorEl } = this.state;

    return (
      <Popover
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={this.onCloseOptionPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <List>
          <ListItem
            data-testid="delete-network-item"
            button
            className={classes.listOptionItem}
            onClick={this.onOpenDeleteNetworkDialog}
          >
            <ImgIcon className={classes.deleteIcon} imgUrl="/images/icons/delete_ico.png" />
            {t('delete')}
          </ListItem>
        </List>
      </Popover>
    );
  }

  onOpenDeleteNetworkDialog = () => {
    this.setState({ openDeleteNetworkDialog: true });
  };
  onCloseDeleteNetworkDialog = () => {
    this.setState({ openDeleteNetworkDialog: false });
  };
}

const styles = createStyles({
  root: {},
  tableHeadCustom: {
    marginTop: 16,
  },
  networkUuid: {
    cursor: 'pointer',
    color: denimColor,
  },
  menuIcon: {},
  listOptionItem: {
    height: 46,
    width: 210,
    ...defaultFont,
    fontSize: 15,
    color: nightRiderColor,
  },
  deleteIcon: {
    marginRight: 8,
  },
});

export default withStyles(styles)(withTranslation('admin')(NetworkOption));
