import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { IStore } from '~/stores/configure-store';
import SideBar from './SideBar';
import Header from './Header';
import CustomSnackBar from '../custom-snackbar';
import * as AppActions from '~/stores/actions/app-action';
import { ISnackBarState } from '~/types/app-types';
import Toolbar from '@mui/material/Toolbar';

export const SIDE_BAR_WIDTH = 340;
export const APP_BAR_HEIGHT = 64;
export const MENU_RESPONSIVE_WIDTH = 1200;
export const SMALL_SCREEN_WIDTH = 992;

interface StateProps {
  snackBarState?: ISnackBarState;
}

interface DispProps {
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => void;
  closeSnackBar: () => void;
}

interface Props extends StateProps, DispProps, WithStyles<typeof styles>, WithTranslation {}

interface State {
  openMenu: boolean;
  isTemporarySideBar: boolean;
  isSmallScreen: boolean;
}

class Layout extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      openMenu: true,
      isTemporarySideBar: false,
      isSmallScreen: false,
    };
  }

  public componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  private updateWindowDimensions = () => {
    this.setState({
      openMenu: window.innerWidth > MENU_RESPONSIVE_WIDTH,
      isTemporarySideBar: window.innerWidth < MENU_RESPONSIVE_WIDTH,
      isSmallScreen: window.innerWidth < SMALL_SCREEN_WIDTH,
    });
  };

  render() {
    const { classes, children, snackBarState, t, openSnackBar, closeSnackBar } = this.props;
    const { openMenu, isTemporarySideBar, isSmallScreen } = this.state;

    return (
      <div className={classes.root} data-testid="layout">
        <Header
          isTemporarySideBar={isTemporarySideBar}
          onToggleDrawer={this.onToggleDrawer}
          isSmallScreen={isSmallScreen}
          classes={{ appBar: classes.headerAppBar }}
        />
        <SideBar
          open={openMenu}
          isTemporarySideBar={isTemporarySideBar}
          onClose={this.closeMenu}
          classes={{ drawerPaper: classes.sideBarDrawerPaper }}
        />
        <div
          className={classNames(
            classes.rightSide,
            isTemporarySideBar && classes.rightSideTemporarySideBar,
          )}
        >
          <div className={classes.contentWrap}>
            <Toolbar />
            <div className={classes.content}>{children}</div>
          </div>
        </div>
        {snackBarState && <CustomSnackBar snackBarState={snackBarState} onClose={closeSnackBar} />}
      </div>
    );
  }

  private closeMenu = () => {
    this.setState({
      openMenu: false,
    });
  };

  private onToggleDrawer = () => {
    this.setState({
      openMenu: !this.state.openMenu,
    });
  };
}

const styles = (theme) =>
  createStyles({
    root: {},
    sideBarDrawerPaper: {
      width: SIDE_BAR_WIDTH,
    },
    headerAppBar: {
      height: APP_BAR_HEIGHT,
    },
    rightSide: {
      position: 'relative',
      marginLeft: SIDE_BAR_WIDTH,
      transition: 'all 250ms ease 0s',
    },
    rightSideTemporarySideBar: {
      marginLeft: 0,
    },
    contentWrap: {
      minHeight: 'calc(100vh - 64px)',
    },
    content: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      alignItems: 'flex-start',
    },
    // customSnackBar: {
    //   left: 24,
    // },
  });

const mapStateToProps = (store: IStore): StateProps => ({
  snackBarState: store.appState.snackBarState,
});

const mapDispatchToProps = (dispatch): DispProps => ({
  openSnackBar: (args: AppActions.OpenSnackBarArgs) => dispatch(AppActions.openSnackBar(args)),
  closeSnackBar: () => dispatch(AppActions.closeSnackBar()),
});

export default withStyles(styles)(
  withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Layout)),
);
