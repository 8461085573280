import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Switch, Route } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { defaultFont, defaultFontBold } from '~/styles/themes/common-styles/font';
import {
  gainsboroColor,
  manateeColor,
  denimColor,
  nightRiderColor,
} from '~/styles/themes/common-styles/color';
import IconButton from '@mui/material/IconButton';
import LicenseMonthTable from '~/components/common/billing-table-month';
import LicenseDateTable from '~/components/common/billing-table-date';
import LoadingIcon from '~/components/common/loading-icon';

import { IStore } from '~/stores/configure-store';
import * as SummaryActions from '~/stores/actions/summary-action';
import { IAccountSummary } from '~/types/admin-types';

interface DispProps {
  listAccountBillingsSummary: (
    args: SummaryActions.QueryListAccountBillingsSummaryArgs,
  ) => Promise<SummaryActions.LIST_ACCOUNT_BILLING_SUMMARY_RESULT_TYPE>;
}

interface StateProps {
  isLoading: boolean;
}

interface Props
  extends RouteComponentProps<{ accountUuid: string; subtab: string }>,
    WithTranslation,
    WithStyles<typeof styles> {
  account: IAccountSummary;
}

interface State {}

class AccountLicenseHistory extends React.Component<Props & DispProps & StateProps, State> {
  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    const { accountUuid } = this.props.match.params;
    this.props.listAccountBillingsSummary({ accountUuid }).catch(console.log);
  }

  public render() {
    const { classes, t, isLoading, account } = this.props;
    const { accountUuid, subtab } = this.props.match.params;

    if (isLoading) {
      return (
        <div className={classes.loadingArea}>
          <LoadingIcon />
        </div>
      );
    }
    const dispMonth = subtab ? subtab.split('-').join('/') : '';

    return (
      <div>
        <div className={classes.titleArea}>
          {dispMonth ? (
            <div className={classes.detailArea}>
              <IconButton
                data-testid="navigate-to-history"
                className={classes.backIcon}
                onClick={(_) => this.props.history.push(`/account/${accountUuid}/history`)}
                children={<img src="/images/icons/back_icon.svg" alt="" />}
              />
              <span className={classes.licenseTitle}>{dispMonth}</span>
            </div>
          ) : (
            <span className={classes.licenseTitle}>{t('usage_history')}</span>
          )}
        </div>
        <Switch>
          <Route path="/account/:accountUuid/history" exact={true} component={LicenseMonthTable} />
          <Route
            path="/account/:accountUuid/history/:subtab"
            render={() => <LicenseDateTable account={account} />}
          />
        </Switch>
      </div>
    );
  }
}

const styles = createStyles({
  root: {},
  loadingArea: {
    margin: '100px 0',
    textAlign: 'center',
  },
  customTable: {
    borderCollapse: 'separate',
    borderSpacing: '0 33px',
  },
  customTableBodyCell: {
    borderBottom: 'none',
    padding: '0 22px',
    borderLeft: `1px solid ${gainsboroColor}`,
    '&:first-child': {
      borderLeft: 'none',
    },
    verticalAlign: 'top',
  },
  customBorderNoneTableCell: {
    borderBottom: 'none',
    paddingLeft: 0,
  },
  label: {
    ...defaultFontBold,
    fontSize: 13,
    color: manateeColor,
    marginBottom: 20,
  },
  downloadBtn: {
    ...defaultFont,
    width: 104,
    height: 34,
    color: denimColor,
    borderColor: denimColor,
  },
  titleArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 20px 10px',
  },
  licenseTitle: {
    ...defaultFontBold,
    fontSize: 16,
    color: nightRiderColor,
  },
  backIcon: {
    padding: 4,
    marginRight: 6,
  },
  detailArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const mapStateToProps = (store: IStore) => ({
  isLoading: SummaryActions.listAccountBillingsSummary.isPending(store),
});

const mapDispatchToProps = (dispatch): DispProps => ({
  listAccountBillingsSummary: (args: SummaryActions.QueryListAccountBillingsSummaryArgs) =>
    dispatch(SummaryActions.listAccountBillingsSummary(args)),
});

export default withStyles(styles)(
  withTranslation()(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountLicenseHistory)),
  ),
);
