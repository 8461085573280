import React from 'react';
// Redux
// Component
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// Style
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { whiteColor, denimColor, silverColor } from '~/styles/themes/common-styles/color';
import { defaultFontBold } from '~/styles/themes/common-styles/font';
import { LGPelorousDenim } from '~/styles/themes/common-styles/misc';
// Type

interface IProps extends WithStyles<typeof styles> {
  isValid?: boolean;
  isSubmitting: boolean;
  label: string;
  submittingLabel?: string;
  circularProgressSize?: number;
  kind?: 'float' | 'flat' | 'text';
  id?: string;
}

interface IDispProps {
  onClick?: (e) => void;
}

const submitButton: React.StatelessComponent<IProps & IDispProps> = ({
  classes,
  isValid,
  isSubmitting,
  label,
  submittingLabel,
  circularProgressSize,
  onClick,
  kind,
  id,
}) => {
  return (
    <Button
      type="submit"
      disabled={!isValid || isSubmitting}
      className={classNames(classes.root, {
        [classes.flat]: kind === 'flat',
        [classes.float]: kind === 'float',
        [classes.text]: kind === 'text',
      })}
      classes={{ disabled: classes.disabled }}
      onClick={onClick}
      id={id}
    >
      {isSubmitting ? (
        <>
          {submittingLabel && <span className={classes.labelText}>{submittingLabel}</span>}
          <CircularProgress size={circularProgressSize} className={classes.loadingIcon} />
        </>
      ) : (
        label
      )}
    </Button>
  );
};

submitButton.defaultProps = {
  circularProgressSize: 20,
  kind: 'float',
};

const styles = createStyles({
  root: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    width: 160,
    height: 36,
    textTransform: 'none',
  },
  flat: {
    backgroundColor: denimColor,
    '&:hover': {
      backgroundColor: denimColor,
    },
  },
  float: {
    backgroundColor: 'rgb(224,224,224) !important',
    backgroundImage: LGPelorousDenim,
    '&:hover': {
      backgroundImage: LGPelorousDenim,
    },
    '&:disabled': {
      backgroundImage: 'none',
    },
  },
  text: {
    background: 'none',
  },
  disabled: {
    backgroundColor: 'rgb(224,224,224)',
  },
  labelText: {
    marginRight: 10,
  },
  loadingIcon: {
    color: silverColor,
  },
});

export default withStyles(styles)(submitButton);
