import React, { useCallback, useEffect, useState } from 'react';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { whiteColor } from '~/styles/themes/common-styles/color';
import { ROWS_PER_PAGE_OPTIONS } from '~/constants/common';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { GridCellParams, GridColumns } from '@mui/x-data-grid';
import { INetworkSummary } from '~/types/admin-types';
import { useHistory } from 'react-router-dom';
import CustomDataGridToolbar from '~/components/common/custom-data-grid-toolbar';

interface IProps extends WithStyles<typeof styles> {
  summary: INetworkSummary;
}

const OrganizationTab = (props: IProps) => {
  const { classes, summary } = props;
  const { t } = useTranslation('admin');
  const history = useHistory();
  const [pageSize, setPageSize] = useState(30);

  const columns: GridColumns = [
    {
      field: DataGridFieldEnum.UUID,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      headerName: t('account_UUID'),
      width: 150,
    },
    {
      field: DataGridFieldEnum.DISPLAY_NAME,
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      headerName: t('display_name'),
      width: 300,
    },
    {
      field: DataGridFieldEnum.EMAIL,
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      headerName: t('contact_email'),
      width: 350,
    },
    {
      field: DataGridFieldEnum.ROLE,
      disableColumnMenu: true,
      sortable: true,
      filterable: false,
      headerName: t('role'),
      width: 150,
    },
  ];

  const onRowClick = useCallback(
    (params) => {
      history.push(`/account/${params.row.uuid}/info`);
    },
    [history],
  );

  const onPageSizeChange = useCallback((newPageSize: number) => {
    setPageSize(newPageSize);
  }, []);

  const items: Array<Item> = (summary.accounts || []).map((account) => ({
    id: account.uuid,
    consortiumRole: account.consortiumRole,
    name: account.name,
    uuid: account.uuid,
    email: account.email,
  }));

  return (
    <div className={classes.dataGridWrapper}>
      <DataGrid
        rows={items}
        columns={columns}
        components={{
          Toolbar: CustomDataGridToolbar,
        }}
        classes={{ root: classes.gridRoot }}
        localeText={{
          toolbarColumns: t('data_grid.columns'),
          toolbarFilters: t('data_grid.filters'),
          toolbarDensity: t('data_grid.density'),
          filterPanelColumns: t('data_grid.columns'),
          filterPanelOperators: t('data_grid.operators'),
          columnsPanelTextFieldLabel: t('data_grid.find_column'),
          columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
          columnsPanelDragIconLabel: t('data_grid.reorder_column'),
          columnsPanelShowAllButton: t('data_grid.show_all'),
          columnsPanelHideAllButton: t('data_grid.hide_all'),
          toolbarDensityCompact: t('data_grid.density_compact'),
          toolbarDensityStandard: t('data_grid.density_standard'),
          toolbarDensityComfortable: t('data_grid.density_comfortable'),
          filterOperatorContains: t('data_grid.contains'),
          filterOperatorEquals: t('data_grid.equals'),
          filterOperatorStartsWith: t('data_grid.startsWith'),
          filterOperatorEndsWith: t('data_grid.endsWith'),
          filterOperatorIsEmpty: t('data_grid.isEmpty'),
          filterOperatorIsNotEmpty: t('data_grid.isNotEmpty'),
          filterOperatorIsAnyOf: t('data_grid.isAnyOf'),
          filterOperatorIs: t('data_grid.is'),
          filterOperatorNot: t('data_grid.isNot'),
          noRowsLabel: t('data_grid.no_rows'),
        }}
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        isRowSelectable={() => false}
        onRowClick={onRowClick}
      />
    </div>
  );
};

const styles = createStyles({
  dataGridWrapper: {
    height: 500,
    width: '100%',
    backgroundColor: whiteColor,
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  },
  gridRoot: {
    borderRadius: 0,
    '& .MuiDataGrid-cell': {
      whiteSpace: 'pre-wrap',
      maxHeight: 'none !important',
      outline: 'none !important',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0,
    },
  },
  tooltip: {
    backgroundColor: 'rgb(20, 26, 31)',
  },
  cellActivity: {
    minHeight: 76,
    paddingTop: 8,
    paddingBottom: 8,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
});

export enum DataGridFieldEnum {
  EMAIL = 'email',
  DISPLAY_NAME = 'name',
  UUID = 'uuid',
  ROLE = 'consortiumRole',
}

export interface Item {
  id: string;
  uuid: string;
  consortiumRole?: string;
  email: string;
  name: string;
}

export default withStyles(styles)(OrganizationTab);
