import moment from 'moment-timezone';
import * as Gtypes from '../gapi/gtypes';

// Accounts
export const ACCOUNTS_PAGE_INDEX_DEFAULT = 0;
export const ACCOUNTS_PAGE_SIZE_DEFAULT = 10;
export const ACCOUNTS_ORDER_BY_DEFAULT: Gtypes.InputListAccountsOrder = {
  orderType: 'DESC',
  orderBy: 'createAt',
};

// Notifications
export const getNotificationsStartDateDefault = (tz?: string) =>
  (tz ? moment().tz(tz) : moment()).subtract(1, 'year').startOf('day').toISOString(); // start of this day last year
export const getNotificationsEndDateDefault = (tz?: string) =>
  (tz ? moment().tz(tz) : moment()).endOf('days').toISOString(); // end of today
export const NOTIFICATIONS_PAGE_INDEX_DEFAULT = 0;
export const NOTIFICATIONS_PAGE_SIZE_DEFAULT = 20;
export const NOTIFICATIONS_ORDER_DEFAULT: Gtypes.ListAccountAlertsOrder = {
  orderType: 'DESC',
  orderBy: 'createAt',
};

// AWS accounts
export const AWS_ACCOUNTS_FILTER: Gtypes.ListAwsAccountsFilterInput = {
  text: '',
  plan: undefined,
};

// Admin notifications
export const getAdminNotificationsStartDateDefault = (tz?: string) =>
  (tz ? moment().tz(tz) : moment()).subtract(1, 'year').startOf('day').toISOString(); // start of this day last year
export const getAdminNotificationsEndDateDefault = (tz?: string) =>
  (tz ? moment().tz(tz) : moment()).endOf('days').toISOString(); // end of today
export const ADMIN_NOTIFICATIONS_FOR_ALL: Array<Gtypes.AlertType> = [
  'information',
  'important',
  'warning',
  'error',
];
export const ADMIN_NOTIFICATIONS_FOR_ADMIN: Array<Gtypes.AlertType> = [];
export const ADMIN_NOTIFICATIONS_FOR_USER: Array<Gtypes.AlertType> = [];
export const ADMIN_NOTIFICATIONS_PAGE_INDEX_DEFAULT = 0;
export const ADMIN_NOTIFICATIONS_PAGE_SIZE_DEFAULT = 20;

// Stats
export const ACCOUNT_STATS_YEAR_DEFAULT = moment().year().toString();
export const USER_STATS_YEAR_DEFAULT = moment().year().toString();

// Proposals
export const PROPOSALS_PAGE_INDEX_DEFAULT = 0;
export const PROPOSALS_PAGE_SIZE_DEFAULT = 10;

// Users
export const USERS_PAGE_INDEX_DEFAULT = 0;
export const USERS_PAGE_SIZE_DEFAULT = 10;
export const USERS_ORDER_BY_DEFAULT: Gtypes.InputListUsersOrder = {
  orderType: 'DESC',
  orderBy: 'createAt',
};

// Networks
export const NETWORKS_PAGE_INDEX_DEFAULT = 0;
export const NETWORKS_PAGE_SIZE_DEFAULT = 10;
export const NETWORKS_ORDER_BY_DEFAULT: Gtypes.InputListNetworksOrder = {
  orderType: 'DESC',
  orderBy: 'createAt',
};
