import GApiClient from '~/gapi/gapi-client';
import * as QueryAccount from './query/account';
import {
  listAccountAlertsQuery,
  QueryListAccountAlertsArgs,
  LIST_ACCOUNT_ALERTS_RESULT_TYPE,
  listAdminAlertsQuery,
  QueryListAdminAlertsArgs,
  LIST_ADMIN_ALERTS_RESULT_TYPE,
  GET_ADMIN_ALERT_RESULT_TYPE,
  getAdminAlertQuery,
} from './query/alert';
import * as QueryNetwork from './query/network';
import * as QuerySummary from './query/summary';
import * as QueryProfile from './query/profile';
import * as AWSAccountQuery from './query/aws-account';
import * as QueryStats from './query/stats';
import * as QueryProposal from './query/proposal';
import * as QueryUser from './query/user';
import { getHostFromClient } from '~/utilities/utils';
import { env } from '~/env';
import { UPDATE_ADMIN_USER_RESULT_TYPE } from './query/account';
import {
  MutationAddAccountMemberArgs,
  MutationApplyHardForkToNodeArgs,
  MutationChangeAccountPrimaryOwnerArgs,
  MutationControlBlockExplorerArgs,
  MutationControlNodeArgs,
  MutationRemoveAccountMemberArgs,
  MutationUpdateAdminUserArgs,
  MutationUpdateBlockGasLimitArgs,
  MutationUpdateNodeVersionArgs,
  QueryGetAdminAlertArgs,
  QueryGetGenesisJsonArgs,
  QueryListNetworksArgs,
  QueryListProposalHistoryArgs,
  QueryListUsersArgs,
} from '~/gapi/gtypes';

export default class AdminGApiClient extends GApiClient {
  constructor({ token, baseUrl }: { token?: string; baseUrl?: string }) {
    const backendApiUrl = env.REACT_APP_BACKEND_API_URL;
    super({
      baseUrl: baseUrl || backendApiUrl || getHostFromClient(),
      endPoint: '/gapi/admin',
      token,
    });
  }

  public async listAccounts(args: QueryAccount.QueryListAccountsArgs) {
    return this.doQuery<QueryAccount.LIST_ACCOUNTS_RESULT_TYPE, QueryAccount.QueryListAccountsArgs>(
      this.endPoint,
      QueryAccount.listAccountsQuery,
      args,
    );
  }

  public async getAccount(args: QueryAccount.QueryGetAccountArgs) {
    return this.doQuery<QueryAccount.GET_ACCOUNT_RESULT_TYPE, QueryAccount.QueryGetAccountArgs>(
      this.endPoint,
      QueryAccount.getAccountQuery,
      args,
    );
  }

  public async listAccountAlerts(args: QueryListAccountAlertsArgs) {
    return this.doQuery<LIST_ACCOUNT_ALERTS_RESULT_TYPE, QueryListAccountAlertsArgs>(
      this.endPoint,
      listAccountAlertsQuery,
      args,
    );
  }

  public async listAdminAlerts(args: QueryListAdminAlertsArgs) {
    return this.doQuery<LIST_ADMIN_ALERTS_RESULT_TYPE, QueryListAdminAlertsArgs>(
      this.endPoint,
      listAdminAlertsQuery,
      args,
    );
  }

  public async getAdminAlert(args: QueryGetAdminAlertArgs) {
    return this.doQuery<GET_ADMIN_ALERT_RESULT_TYPE, QueryGetAdminAlertArgs>(
      this.endPoint,
      getAdminAlertQuery,
      args,
    );
  }

  public async listAccountMembers(args: QueryAccount.QueryListAccountMembersArgs) {
    return this.doQuery<
      QueryAccount.LIST_ACCOUNT_MEMBERS_RESULT_TYPE,
      QueryAccount.QueryListAccountMembersArgs
    >(this.endPoint, QueryAccount.listAccountMembersQuery, args);
  }

  public async addAccountMember(args: MutationAddAccountMemberArgs) {
    return this.doMutate<QueryAccount.ADD_ACCOUNT_MEMBER_RESULT_TYPE, MutationAddAccountMemberArgs>(
      this.endPoint,
      QueryAccount.addAccountMemberMutation,
      args,
    );
  }

  public async changeAccountPrimaryOwner(args: MutationChangeAccountPrimaryOwnerArgs) {
    return this.doMutate<
      QueryAccount.CHANGE_ACCOUNT_PRIMARY_OWNER_RESULT_TYPE,
      MutationChangeAccountPrimaryOwnerArgs
    >(this.endPoint, QueryAccount.changeAccountPrimaryOwnerMutation, args);
  }

  public async removeAccountMember(args: MutationRemoveAccountMemberArgs) {
    return this.doMutate<
      QueryAccount.REMOVE_ACCOUNT_MEMBER_RESULT_TYPE,
      MutationRemoveAccountMemberArgs
    >(this.endPoint, QueryAccount.removeAccountMemberMutation, args);
  }

  public async listAccountNetworks(args: QueryAccount.QueryListAccountNetworksArgs) {
    return this.doQuery<
      QueryAccount.LIST_ACCOUNT_NETWORKS_RESULT_TYPE,
      QueryAccount.QueryListAccountNetworksArgs
    >(this.endPoint, QueryAccount.listAccountNetworksQuery, args);
  }

  public async listAdminUsers() {
    return this.doQuery<QueryAccount.LIST_ADMIN_USERS_RESULT_TYPE, void>(
      this.endPoint,
      QueryAccount.listAdminUsersQuery,
    );
  }

  public async listMemberUsers() {
    return this.doQuery<QueryAccount.LIST_MEMBER_USERS_RESULT_TYPE, void>(
      this.endPoint,
      QueryAccount.listMemberUsersQuery,
    );
  }

  public async listSalesSummary(args: QuerySummary.QueryListSalesSummaryArgs) {
    return this.doQuery<
      QuerySummary.LIST_SALES_SUMMARY_RESULT_TYPE,
      QuerySummary.QueryListSalesSummaryArgs
    >(this.endPoint, QuerySummary.listSalesSummaryQuery, args);
  }

  public async getNetworkSummary(args: QuerySummary.QueryGetNetworkSummaryArgs) {
    return this.doQuery<
      QuerySummary.GET_NETWORK_SUMMARY_RESULT_TYPE,
      QuerySummary.QueryGetNetworkSummaryArgs
    >(this.endPoint, QuerySummary.getNetworkSummaryQuery, args);
  }

  public async createAdminUser(args: QueryAccount.MutationCreateAdminUserArgs) {
    return this.doMutate<
      QueryAccount.CREATE_ADMIN_RESULT_TYPE,
      QueryAccount.MutationCreateAdminUserArgs
    >(this.endPoint, QueryAccount.createAdminUserMutation, args);
  }

  public async updateAdminUser(args: MutationUpdateAdminUserArgs) {
    return this.doMutate<UPDATE_ADMIN_USER_RESULT_TYPE, MutationUpdateAdminUserArgs>(
      this.endPoint,
      QueryAccount.updateAdminUserMutation,
      args,
    );
  }

  public async deleteAdminUser(args: QueryAccount.MutationDeleteAdminUserArgs) {
    return this.doMutate<
      QueryAccount.DELETE_ADMIN_RESULT_TYPE,
      QueryAccount.MutationDeleteAdminUserArgs
    >(this.endPoint, QueryAccount.deleteAdminUserMutation, args);
  }

  public async listAccountLicensesSummary(args: QuerySummary.QueryListAccountLicensesSummaryArgs) {
    return this.doQuery<
      QuerySummary.LIST_ACCOUNT_LICENSES_SUMMARY_RESULT_TYPE,
      QuerySummary.QueryListAccountLicensesSummaryArgs
    >(this.endPoint, QuerySummary.listAccountLicensesSummaryQuery, args);
  }

  public async listAccountBillingsSummary(args: QuerySummary.QueryListAccountBillingsSummaryArgs) {
    return this.doQuery<
      QuerySummary.LIST_ACCOUNT_BILLING_SUMMARY_RESULT_TYPE,
      QuerySummary.QueryListAccountBillingsSummaryArgs
    >(this.endPoint, QuerySummary.listAccountBillingsSummaryQuery, args);
  }

  public async listAdminSummary(args: QuerySummary.QueryListAdminSummaryArgs) {
    return this.doQuery<
      QuerySummary.LIST_ADMIN_SUMMARY_RESULT_TYPE,
      QuerySummary.QueryListAdminSummaryArgs
    >(this.endPoint, QuerySummary.listAdminSummaryQuery, args);
  }

  public async listAccountCouponsSummary(args: QuerySummary.QueryListAccountCouponsSummaryArgs) {
    return this.doQuery<
      QuerySummary.LIST_ACCOUNT_COUPONS_SUMMARY_RESULT_TYPE,
      QuerySummary.QueryListAccountCouponsSummaryArgs
    >(this.endPoint, QuerySummary.listAccountCouponsSummaryQuery, args);
  }

  public async listAllCouponsSummary(args: QuerySummary.QueryListAllCouponsSummaryArgs) {
    return this.doQuery<
      QuerySummary.LIST_ALL_COUPON_SUMMARY_RESULT_TYPE,
      QuerySummary.QueryListAllCouponsSummaryArgs
    >(this.endPoint, QuerySummary.listAllCouponsSummaryQuery, args);
  }

  public async listAWSAccounts(args: AWSAccountQuery.QueryToListAWSAccountsArgs) {
    return this.doQuery<
      AWSAccountQuery.LIST_AWS_ACCOUNTS_RESULT_TYPE,
      AWSAccountQuery.QueryToListAWSAccountsArgs
    >(this.endPoint, AWSAccountQuery.listAWSAccountsQuery, args);
  }

  public async createAWSAccount() {
    return this.doMutate<AWSAccountQuery.CREATE_AWS_ACCOUNT_RESULT_TYPE, void>(
      this.endPoint,
      AWSAccountQuery.createAWSAccountMutation,
    );
  }

  public async removeAWSAccount(args: AWSAccountQuery.MutationRemoveAwsAccountArgs) {
    return this.doMutate<
      AWSAccountQuery.REMOVE_AWS_ACCOUNT_RESULT_TYPE,
      AWSAccountQuery.MutationRemoveAwsAccountArgs
    >(this.endPoint, AWSAccountQuery.removeAWSAccountMutation, args);
  }

  public async changeAccountPlan(args: QueryAccount.MutationChangeAccountPlanArgs) {
    return this.doMutate<
      QueryAccount.CHANGE_ACCOUNT_PLAN_RESULT_TYPE,
      QueryAccount.MutationChangeAccountPlanArgs
    >(this.endPoint, QueryAccount.changeAccountPlanMutation, args);
  }

  public async removeEnterpriseAccount(args: QueryAccount.MutationRemoveEnterpriseAccountArgs) {
    return this.doMutate<
      QueryAccount.REMOVE_ENTERPRISE_ACCOUNT_RESULT_TYPE,
      QueryAccount.MutationRemoveEnterpriseAccountArgs
    >(this.endPoint, QueryAccount.removeEnterpriseAccountMutation, args);
  }

  public async addCouponToAccount(args: QueryAccount.MutationAddCouponToAccountArgs) {
    return this.doMutate<
      QueryAccount.ADD_COUPON_TO_ACCOUNT_RESULT_TYPE,
      QueryAccount.MutationAddCouponToAccountArgs
    >(this.endPoint, QueryAccount.addCouponToAccountMutation, args);
  }

  public async editCouponToAccount(args: QueryAccount.MutationEditCouponToAccountArgs) {
    return this.doMutate<
      QueryAccount.EDIT_COUPON_TO_ACCOUNT_RESULT_TYPE,
      QueryAccount.MutationEditCouponToAccountArgs
    >(this.endPoint, QueryAccount.editCouponToAccountMutation, args);
  }

  public async destroyNetwork(args: QueryNetwork.MutationDestroyNetworkArgs) {
    return this.doMutate<
      QueryNetwork.DESTROY_NETWORK_RESULT_TYPE,
      QueryNetwork.MutationDestroyNetworkArgs
    >(this.endPoint, QueryNetwork.destroyNetworkMutation, args);
  }

  public async deleteCluster(args: QueryNetwork.MutationDeleteClusterArgs) {
    return this.doMutate<
      QueryNetwork.DELETE_CLUSTER_RESULT_TYPE,
      QueryNetwork.MutationDeleteClusterArgs
    >(this.endPoint, QueryNetwork.deleteClusterMutation, args);
  }

  public async deleteNode(args: QueryNetwork.MutationDeleteNodeArgs) {
    return this.doMutate<QueryNetwork.DELETE_NODE_RESULT_TYPE, QueryNetwork.MutationDeleteNodeArgs>(
      this.endPoint,
      QueryNetwork.deleteNodeMutation,
      args,
    );
  }

  public async getAdminProfile() {
    return this.doQuery<QueryProfile.GET_ADMIN_PROFILE_RESULT_TYPE, any>(
      this.endPoint,
      QueryProfile.getAdminProfileQuery,
    );
  }

  public async updateAdminProfile(args: QueryProfile.MutationUpdateAdminProfileArgs) {
    return this.doMutate<
      QueryProfile.UPDATE_ADMIN_PROFILE_RESULT_TYPE,
      QueryProfile.MutationUpdateAdminProfileArgs
    >(this.endPoint, QueryProfile.updateAdminProfileMutation, args);
  }

  public async getAccountStats(args: QueryStats.QueryGetAccountStatsArgs) {
    return this.doQuery<
      QueryStats.GET_ACCOUNT_STATS_RESULT_TYPE,
      QueryStats.QueryGetAccountStatsArgs
    >(this.endPoint, QueryStats.getAccountStatsQuery, args);
  }

  public async getUserStats(args: QueryStats.QueryGetUserStatsArgs) {
    return this.doQuery<QueryStats.GET_USER_STATS_RESULT_TYPE, QueryStats.QueryGetUserStatsArgs>(
      this.endPoint,
      QueryStats.getUserStatsQuery,
      args,
    );
  }

  public async getSigners(args: QueryProposal.QueryGetSignersArgs) {
    return this.doQuery<
      QueryProposal.ADMIN_GET_SIGNERS_RESULT_TYPE,
      QueryProposal.QueryGetSignersArgs
    >(this.endPoint, QueryProposal.getSignersQuery, args);
  }

  public async getProposalStatus(args: QueryProposal.QueryGetProposalStatusArgs) {
    return this.doQuery<
      QueryProposal.ADMIN_GET_PROPOSAL_STATUS_RESULT_TYPE,
      QueryProposal.QueryGetProposalStatusArgs
    >(this.endPoint, QueryProposal.getProposalStatusQuery, args);
  }

  public async listProposalHistory(args: QueryListProposalHistoryArgs) {
    return this.doMutate<
      QueryProposal.LIST_PROPOSAL_HISTORY_RESULT_TYPE,
      QueryListProposalHistoryArgs
    >(this.endPoint, QueryProposal.listProposalHistoryQuery, args);
  }

  public async controlNode(args: MutationControlNodeArgs) {
    return this.doMutate<QueryNetwork.CONTROL_NODE_RESULT_TYPE, MutationControlNodeArgs>(
      this.endPoint,
      QueryNetwork.controlNodeMutation,
      args,
    );
  }

  public async updateNodeVersion(args: MutationUpdateNodeVersionArgs) {
    return this.doMutate<
      QueryNetwork.UPDATE_NODE_VERSION_RESULT_TYPE,
      MutationUpdateNodeVersionArgs
    >(this.endPoint, QueryNetwork.updateNodeVersion, args);
  }

  public async applyHardForkToNode(args: MutationApplyHardForkToNodeArgs) {
    return this.doMutate<
      QueryNetwork.APPLY_HARD_FORK_TO_NODE_RESULT_TYPE,
      MutationApplyHardForkToNodeArgs
    >(this.endPoint, QueryNetwork.applyHardForkToNode, args);
  }

  public async updateBlockGasLimit(args: MutationUpdateBlockGasLimitArgs) {
    return this.doMutate<
      QueryNetwork.UPDATE_GAS_LIMIT_RESULT_TYPE,
      MutationUpdateBlockGasLimitArgs
    >(this.endPoint, QueryNetwork.updateBlockGasLimit, args);
  }

  public async getGenesisJson(args: QueryGetGenesisJsonArgs) {
    return this.doQuery<QueryNetwork.GET_GENESIS_JSON_RESULT_TYPE, QueryGetGenesisJsonArgs>(
      this.endPoint,
      QueryNetwork.getGenesisJsonQuery,
      args,
    );
  }

  public async getSummaryStats() {
    return this.doQuery<QueryStats.GET_SUMMARY_STATS_RESULT_TYPE, any>(
      this.endPoint,
      QueryStats.getSummaryStatsQuery,
    );
  }

  public async listUsers(args: QueryListUsersArgs) {
    return this.doQuery<QueryUser.LIST_USERS, QueryListUsersArgs>(
      this.endPoint,
      QueryUser.listUsersQuery,
      args,
    );
  }

  public async listNetworks(args: QueryListNetworksArgs) {
    return this.doQuery<QueryNetwork.LIST_NETWORKS_RESULT_TYPE, QueryListNetworksArgs>(
      this.endPoint,
      QueryNetwork.listNetworksQuery,
      args,
    );
  }

  public async controlExplorer(args: MutationControlBlockExplorerArgs) {
    return this.doMutate<
      QueryNetwork.CONTROL_EXPLORER_RESULT_TYPE,
      MutationControlBlockExplorerArgs
    >(this.endPoint, QueryNetwork.controlBlockExplorerMutation, args);
  }
}
