import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import {
  DatePicker as InlineDatePicker,
  DatePickerProps as DatePickerInlineProps,
} from '@mui/x-date-pickers/DatePicker';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  brightGreyColor,
  hawkesBlueColor,
  nightRiderColor,
} from '~/styles/themes/common-styles/color';

interface IProps {
  datePickerInlineProps: Omit<DatePickerInlineProps<Date, Date>, 'renderInput'>;
}

const DatePicker: React.FunctionComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  datePickerInlineProps,
}) => {
  const onKeyDown = React.useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <div className={classes.root}>
      <InlineDatePicker
        {...datePickerInlineProps}
        InputProps={{
          classes: { input: classes.dateAndTimePickerInput },
          disableUnderline: true,
          endAdornment: <img src="/images/icons/down_ico_off.svg" alt="" />,
          ...datePickerInlineProps.InputProps,
        }}
        renderInput={(params) => <TextField {...params} helperText={null} onKeyDown={onKeyDown} />}
      />
    </div>
  );
};

const styles = createStyles({
  root: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 12,
    paddingRight: 12,
    border: `1px solid ${hawkesBlueColor}`,
    borderRadius: 4,
    width: 120,
  },
  dateAndTimePickerInput: {
    ...defaultFont,
    fontSize: 14,
    color: brightGreyColor,
    '&:focus': {
      color: 'transparent',
      textShadow: `0 0 0 ${nightRiderColor}`,
    },
    cursor: 'pointer',
    width: '100%',
  },
  arrowIcon: {
    cursor: 'pointer',
  },
});

export default withStyles(styles)(DatePicker);
