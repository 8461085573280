import React from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import { defaultFont } from '~/styles/themes/common-styles/font';
import { nightRiderColor, ghostWhiteColor } from '~/styles/themes/common-styles/color';

interface Props extends Omit<TableCellProps, 'classes'>, WithStyles<typeof styles> {}

const TableBodyCell: React.StatelessComponent<Props> = ({
  classes,
  className,
  children,
  ...tableCellProps
}) => {
  return (
    <TableCell {...tableCellProps} className={classNames(classes.root, classes.content, className)}>
      {children}
    </TableCell>
  );
};

const styles: StyleRules = {
  root: {
    ...defaultFont,
    fontSize: 14,
    color: nightRiderColor,
    maxWidth: 0,
    padding: 0,
  },
  content: {
    paddingLeft: 24,
    paddingRight: 30,
  },
};

export default withStyles(styles)(TableBodyCell);
