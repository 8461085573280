import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';

import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';

// Component
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import JSONPretty from 'react-json-pretty';

// React i18next
import { useTranslation } from 'react-i18next';

interface IProps extends WithStyles<typeof styles> {
  genesisJson?: string;
  open: boolean;
  onClose: () => void;
}

const GenesisJsonViewDialog = (props: IProps) => {
  const { classes, open, genesisJson, onClose } = props;
  const { t } = useTranslation('admin');

  return (
    <CustomDialog open={open} onClose={onClose} classes={{ paper: classes.customDialog }}>
      <CustomDialogTitle>
        <div id="member-genesis-json">{t('genesis_json')}</div>
      </CustomDialogTitle>
      <CustomDialogContent>
        <div className={classes.formSection}>
          <JSONPretty
            style={{ color: '#ffffff', overflowX: 'auto', padding: '10px' }}
            space={4}
            data={genesisJson}
          />
        </div>
      </CustomDialogContent>
    </CustomDialog>
  );
};

const styles = createStyles({
  root: {},
  customDialog: {
    maxWidth: '900px',
  },
  formSection: {
    ...defaultFontMedium,
    fontSize: 14,
    marginBottom: 50,
    backgroundColor: '#272823',
  },
  loadingArea: {
    textAlign: 'center',
  },
});

export default withStyles(styles)(GenesisJsonViewDialog);
