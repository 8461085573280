import * as Gtypes from '../../../../gapi/gtypes';

export const accountQuery = `{
  accountUuid
  displayName
  email
  addr
  plan
  lang
  serverNum
  balance
  createAt
  paymentAddr {
    country
    postalCode
    state
    city
    address1
    address2
  }
  status
  contact {
    accountantEmail
    systemAdminEmail
  }
  provider {
    aws {
      awsAccountUuid
      awsAccountId
    }
  }
}`;

export const adminQuery = `{
  adminUuid
  id
  name
  email
  role
  createAt
}`;

export const memberQuery = `{
  uid
  createAt
  updateAt
}`;

export const couponQuery = `{
  id
  name
  code
  amount
  balance
  createAt
  deleteAt
  expiredAt
}`;

export const listAccountsQuery = `query ($order: InputListAccountsOrder, $filter: InputListAccountsFilter, $page: InputPageInfo) {
  listAccounts(order: $order, filter: $filter, page: $page) {
    accounts ${accountQuery}
    pageInfo {
      pageIndex
      pageSize
      totalPages
      totalItems
    }
    filter {
      searchText
    }
    order {
      orderType
      orderBy
    }
  }
}`;
export type QueryListAccountsArgs = Gtypes.QueryListAccountsArgs;
export type LIST_ACCOUNTS_RESULT_TYPE = {
  listAccounts: Gtypes.ListAccountSummary;
};

export const getAccountQuery = `query ($accountUuid: String!) {
  getAccount(accountUuid: $accountUuid) ${accountQuery}
}`;
export type QueryGetAccountArgs = Gtypes.QueryGetAccountArgs;
export type GET_ACCOUNT_RESULT_TYPE = {
  getAccount: Gtypes.AccountSummary;
};

export const listAccountMembersQuery = `query ($accountUuid: String!) {
  listAccountMembers(accountUuid: $accountUuid) {
    uid
    name
    role
    isPrimaryOwner
    email
    hasProfile
    createAt
    lastloginAt
  }
}`;
export type QueryListAccountMembersArgs = Gtypes.QueryListAccountMembersArgs;
export type LIST_ACCOUNT_MEMBERS_RESULT_TYPE = {
  listAccountMembers: Array<Gtypes.AccountMember>;
};

export const listAccountNetworksQuery = `query ($accountUuid: String!) {
  listAccountNetworks(accountUuid: $accountUuid) {
    uuid
    name
    count {
      account
      cluster
      node
    }
  }
}`;
export type QueryListAccountNetworksArgs = Gtypes.QueryListAccountNetworksArgs;
export type LIST_ACCOUNT_NETWORKS_RESULT_TYPE = {
  listAccountNetworks: Array<Gtypes.AccountNetworkSummary>;
};

export const changeAccountPlanMutation = `mutation ($accountUuid: String!, $newPlan: AccountPlan!) {
  changeAccountPlan(accountUuid: $accountUuid, newPlan: $newPlan) ${accountQuery}
}`;

export type MutationChangeAccountPlanArgs = Gtypes.MutationChangeAccountPlanArgs;

export type CHANGE_ACCOUNT_PLAN_RESULT_TYPE = {
  changeAccountPlan: Gtypes.AccountSummary;
};

export const listAdminUsersQuery = `query {
  listAdminUsers ${adminQuery}
}`;

export type LIST_ADMIN_USERS_RESULT_TYPE = {
  listAdminUsers: Gtypes.AdminUser[];
};

export const listMemberUsersQuery = `query {
  listMemberUsers ${memberQuery}
}`;

export type LIST_MEMBER_USERS_RESULT_TYPE = {
  listMemberUsers: Gtypes.MemberUser[];
};

export const createAdminUserMutation = `mutation ($input: CreateAdminUserInput!) {
  createAdminUser(input: $input) ${adminQuery}
}`;

export type MutationCreateAdminUserArgs = Gtypes.MutationCreateAdminUserArgs;

export type CREATE_ADMIN_RESULT_TYPE = {
  createAdminUser: Gtypes.AdminUser;
};

export const updateAdminUserMutation = `mutation ($input: UpdateAdminUserInput!) {
  updateAdminUser(input: $input)
}`;

export type UPDATE_ADMIN_USER_RESULT_TYPE = {
  updateAdminUser: boolean;
};

export const deleteAdminUserMutation = `mutation ($adminUuid: String!) {
  deleteAdminUser(adminUuid: $adminUuid)
}`;

export type MutationDeleteAdminUserArgs = Gtypes.MutationDeleteAdminUserArgs;

export type DELETE_ADMIN_RESULT_TYPE = {
  deleteAdminUser: boolean;
};

export const removeEnterpriseAccountMutation = `mutation ($accountUuid: String!) {
  removeEnterpriseAccount(accountUuid: $accountUuid)
}`;

export type MutationRemoveEnterpriseAccountArgs = Gtypes.MutationRemoveEnterpriseAccountArgs;

export type REMOVE_ENTERPRISE_ACCOUNT_RESULT_TYPE = {
  removeEnterpriseAccount: boolean;
};

export const addCouponToAccountMutation = `mutation ($input: AddCouponAdminInput!) {
  addCouponToAccount(input: $input) ${couponQuery}
}`;

export type MutationAddCouponToAccountArgs = Gtypes.MutationAddCouponToAccountArgs;

export type ADD_COUPON_TO_ACCOUNT_RESULT_TYPE = {
  addCouponToAccount: Gtypes.AdminCoupon;
};

export const editCouponToAccountMutation = `mutation ($input: EditCouponAdminInput!) {
  editCouponToAccount(input: $input)
}`;

export type MutationEditCouponToAccountArgs = Gtypes.MutationEditCouponToAccountArgs;

export type EDIT_COUPON_TO_ACCOUNT_RESULT_TYPE = {
  editCouponToAccount: boolean;
};

export type ADD_ACCOUNT_MEMBER_RESULT_TYPE = {
  addAccountMember: Gtypes.AccountMember;
};
export const addAccountMemberMutation = `mutation ($input: AddAccountMemberInput!) {
  addAccountMember(input: $input) {
    uid
    name
    role
    isPrimaryOwner
    email
    hasProfile
    createAt
    lastloginAt
  }
}`;

export type CHANGE_ACCOUNT_PRIMARY_OWNER_RESULT_TYPE = {
  changeAccountPrimaryOwner: Boolean;
};
export const changeAccountPrimaryOwnerMutation = `mutation ($input: ChangeAccountPrimaryOwnerInput!) {
  changeAccountPrimaryOwner(input: $input)
}`;

export type REMOVE_ACCOUNT_MEMBER_RESULT_TYPE = {
  removeAccountMember: Boolean;
};
export const removeAccountMemberMutation = `mutation ($input: RemoveAccountMemberInput!) {
  removeAccountMember(input: $input)
}`;
