import React, { useMemo, useState } from 'react';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ImgIcon from '~/components/common/img-icon';
import { denimColor, nightRiderColor, pattensBlueColor } from '~/styles/themes/common-styles/color';
import { defaultFont } from '~/styles/themes/common-styles/font';
import { INetwork, IBlockExplorerControlType } from '~/types/network-types';
import RebuildBlockExpDialog from '../rebuild-explorer/rebuild-block-exp-dialog';
import { IClusterUnit } from '~/types/admin-types';
import * as NetworkActions from '~/stores/actions/network-action';
import { useDispatch } from 'react-redux';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  network: INetwork;
  cluster: IClusterUnit;
}

const ExplorerOption = (props: IProps) => {
  const { t } = useTranslation('admin');
  const dispatch = useDispatch();
  const { network, cluster, classes } = props;
  const [openRebuildExplorerDialog, setOpenRebuildExplorerDialog] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const status = cluster.explorer?.serverInfo.status;

  const onOpenRebuildExplorerDialog = (e) => {
    setOpenRebuildExplorerDialog(true);
  };

  const onCloseRebuildExplorerDialog = () => {
    setOpenRebuildExplorerDialog(false);
  };

  const onOpenOptionPopover = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (status === 'pending') {
      return;
    }
    setMenuAnchorEl(e.currentTarget);
  };
  const onCloseOptionPopover = () => {
    setMenuAnchorEl(null);
  };

  const controlExplorerStart = async () => {
    onSelectControlExplorerAction('start');
  };

  const controlExplorerStop = async () => {
    onSelectControlExplorerAction('stop');
  };

  const controlExplorerReboot = async () => {
    onSelectControlExplorerAction('reboot');
  };

  const controlExplorerRebootForce = async () => {
    onSelectControlExplorerAction('restartForce');
  };

  const controlExplorerRebuild = async (cleanBlockscoutDb = false) => {
    onSelectControlExplorerAction('rebuild', cleanBlockscoutDb);
  };

  const onSelectControlExplorerAction = async (
    action: IBlockExplorerControlType,
    cleanBlockscoutDb?: boolean,
  ) => {
    onCloseOptionPopover();

    await dispatch(
      NetworkActions.controlExplorer({
        input: {
          action: action,
          clusterUuid: cluster.clusterUuid,
          networkUuid: network.networkUuid,
          cleanBlockscoutDb,
        },
      }),
    );
  };

  return (
    <>
      <Popover
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={onCloseOptionPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <List>
          {status === 'alive' && (
            <ListItem
              button
              className={classes.listOptionItem}
              onClick={controlExplorerStop}
              data-testid="stop-button"
            >
              {t('stop')}
            </ListItem>
          )}
          {(status === 'alive' || status === 'failure') && (
            <>
              <ListItem
                button
                className={classes.listOptionItem}
                onClick={controlExplorerReboot}
                data-testid="reboot-button"
              >
                {t('reboot')}
              </ListItem>

              <ListItem
                button
                className={classes.listOptionItem}
                onClick={onOpenRebuildExplorerDialog}
                data-testid="rebuild-button"
              >
                {t('rebuild')}
              </ListItem>
              <ListItem
                button
                className={classes.listOptionItem}
                onClick={controlExplorerRebootForce}
                data-testid="force-reboot-button"
              >
                {t('forceReboot')}
              </ListItem>
            </>
          )}
          {status === 'dead' && (
            <>
              <div className={classes.horizontalSeparate} />
              <ListItem
                button
                className={classes.listOptionItem}
                onClick={controlExplorerStart}
                data-testid="start-button"
              >
                {t('start')}
              </ListItem>
            </>
          )}
        </List>
      </Popover>
      {cluster.explorer && (
        <RebuildBlockExpDialog
          open={openRebuildExplorerDialog}
          blockExplorer={cluster.explorer}
          onSubmit={controlExplorerRebuild}
          onClose={onCloseRebuildExplorerDialog}
        />
      )}
      <IconButton onClick={onOpenOptionPopover} data-testid="open-menu-button">
        <ImgIcon className={classes.menuIcon} imgUrl="/images/icons/options-ico.png" />
      </IconButton>
    </>
  );
};

const styles = createStyles({
  root: {},
  tableHeadCustom: {
    marginTop: 16,
  },
  networkUuid: {
    cursor: 'pointer',
    color: denimColor,
  },
  menuIcon: {},
  listOptionItem: {
    height: 46,
    width: 210,
    ...defaultFont,
    fontSize: 15,
    color: nightRiderColor,
  },
  horizontalSeparate: {
    height: 1,
    width: '100%',
    backgroundColor: pattensBlueColor,
  },
});

export default withStyles(styles)(withTranslation('admin')(ExplorerOption));
