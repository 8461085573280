import React from 'react';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import { snowColor } from '~/styles/themes/common-styles/color';

interface Props extends Omit<TableRowProps, 'classes'>, WithStyles<typeof styles> {}

const TableHeadRow: React.StatelessComponent<Props> = ({
  classes,
  children,
  className,
  ...tableRowProps
}) => (
  <TableRow {...tableRowProps} className={classes.root}>
    {children}
  </TableRow>
);

const styles: StyleRules = {
  root: {},
};

export default withStyles(styles)(TableHeadRow);
