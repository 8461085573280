import { createTypeAction } from '@gu-corp/redux-async-lib';
import { ISnackBarType } from '~/types/app-types';

export type OpenSnackBarArgs = {
  type: ISnackBarType;
  message: string;
};

export const openSnackBar = createTypeAction('OPEN_SNACK_BAR', (args: OpenSnackBarArgs) => args);

export const closeSnackBar = createTypeAction('CLOSE_SNACK_BAR', (_) => _);
