import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { container } from '~/styles/themes/common-styles/grid-member';
import { orientColor, whiteColor } from '~/styles/themes/common-styles/color';
import { defaultFont } from '~/styles/themes/common-styles/font-guest';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IProps extends WithStyles<typeof styles>, WithTranslation {}

const Footer: React.FC<IProps> = ({ classes, t }) => {
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.footerContent}>
          <div className={classes.label}>{t('gu_blockchain_cloud_footer')}</div>
        </div>
      </div>
    </div>
  );
};

const styles = createStyles({
  container,
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 3,
    backgroundColor: orientColor,
  },
  footerContent: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    ...defaultFont,
    flex: 1,
    color: whiteColor,
    fontSize: 12,
  },
});

export default withStyles(styles)(withTranslation()(Footer));
