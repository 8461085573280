import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import classNames from 'classnames';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import moment from 'moment-timezone';
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogAction from '~/components/common/custom-dialog-actions';
import CustomInput from '~/components/common/custom-input';
import CustomInputNumCommaFormat from '~/components/common/custom-input-num-comma-format';
import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';
import {
  dimGrayColor,
  romanColor,
  whiteSmokeColor,
  pattensBlueColor,
} from '~/styles/themes/common-styles/color';
import { IStore } from '~/stores/configure-store';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
import { momentDateConvert } from '~/utilities/moment-utils';
import { IAccountSummary } from '~/types/admin-types';
import * as SummaryActions from '~/stores/actions/summary-action';
import * as AccountActions from '~/stores/actions/account-action';
import { MAX_COUPON_NAME_LENGTH } from '~/constants/validation';
import TextField from '@mui/material/TextField';
import SubmitButton from '~/components/common/custom-submit-button';
import { CONVERT_PRICE_TO_CURRENCY, DEFAULT_COUPON_BALANCE_ADMIN_MAX } from '~/constants/common';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface StateProps {}

interface DispProps {
  addCouponToAccount: (
    args: AccountActions.MutationAddCouponToAccountArgs,
  ) => Promise<AccountActions.ADD_COUPON_TO_ACCOUNT_RESULT_TYPE>;
  listAccountCouponsSummary: (
    args: SummaryActions.QueryListAccountCouponsSummaryArgs,
  ) => Promise<SummaryActions.LIST_ACCOUNT_COUPONS_SUMMARY_RESULT_TYPE>;
}

interface Props extends StateProps, DispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
  account: IAccountSummary;
}

interface State {}

class AddCouponDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, classes, open, onClose } = this.props;

    const validateSchema = Yup.object().shape<FormValues>({
      name: Yup.string()
        .required('required_field')
        .max(
          MAX_COUPON_NAME_LENGTH,
          t('too_many_character_of_coupon_name_error_message', { value: MAX_COUPON_NAME_LENGTH }),
        )
        .test(
          'check-trailing-spaces',
          'trailing_spaces_not_allowed_to_name',
          function (name: string) {
            return (name || '').trim() !== '' ? true : false;
          },
        ),
      balance: Yup.number()
        .required('required_field')
        .min(0.01, 'validate_minimum')
        .max(
          DEFAULT_COUPON_BALANCE_ADMIN_MAX,
          t('validate_maximum', { val: DEFAULT_COUPON_BALANCE_ADMIN_MAX }),
        ),
      expired: Yup.date()
        .required('required_field')
        .test('check-date-format', 'invalid_date_format', function (date: Date) {
          return moment(date).isValid();
        }),
    });

    return (
      <CustomDialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={this.onSubmit}
          render={({ isValid, isSubmitting }) => (
            <Form>
              <CustomDialogTitle className={classes.dialogTitle}>
                {t('add_coupon_dialog_title')}
              </CustomDialogTitle>
              <CustomDialogContent classes={{ root: classes.dialogContent }}>
                <div title="name-field">
                  <Field name="name" render={this.nameField} />
                </div>
                <div title="balance-field">
                  <Field name="balance" render={this.balanceField} />
                </div>
                <div title="expired-field">
                  <Field name="expired" render={this.expiredField} />
                </div>
              </CustomDialogContent>
              <CustomDialogAction classes={{ root: classes.dialogAction }}>
                <Button
                  data-testid="cancel-button"
                  variant="outlined"
                  disabled={isSubmitting}
                  onClick={onClose}
                  className={classes.cancelBtn}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  data-testid="submit-button"
                  type="submit"
                  id="add-coupon-submit"
                  isLoading={isSubmitting}
                  label={t('add_coupon')}
                  submittingLabel={t('adding')}
                  disabled={isSubmitting || !isValid}
                />
              </CustomDialogAction>
            </Form>
          )}
        />
      </CustomDialog>
    );
  }

  private nameField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('add_coupon_dialog_name_label')}</div>
          {!!form.errors.name && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.name)}
            </div>
          )}
        </div>
        <div>
          <CustomInput {...field} placeholder={t('add_coupon_dialog_name_place_holder')} />
        </div>
      </>
    );
  };

  private balanceField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('add_coupon_dialog_balace_label')}</div>
          {!!form.errors.balance && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.balance === 'validate_minimum'
                ? t(form.errors.balance, { val: 0.01 })
                : t(form.errors.balance)}
            </div>
          )}
        </div>
        <div>
          <CustomInputNumCommaFormat
            {...field}
            id="add_coupon_dialog_balance_"
            placeholder={t('add_coupon_dialog_balace_place_holder')}
            decimalScale={2}
            fixedDecimalScale={true}
            isAllowed={(value) => {
              const numberValue =
                value !== undefined ? Number(value.toString().replace(/,/g, '')) : undefined;
              const floatValue: number | undefined = numberValue;
              return floatValue === undefined || floatValue >= 0;
            }}
            onChange={(values) => {
              const { formattedValue, value, floatValue } = values;
              form.setFieldValue(field.name, floatValue);
            }}
          />
        </div>
      </>
    );
  };

  private expiredField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('add_coupon_dialog_expired_label')}</div>
          {!!form.errors.expired && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {t(form.errors.expired as string)}
            </div>
          )}
        </div>
        <div>
          <DatePicker
            className={classes.datePicker}
            value={new Date(field.value)}
            views={['day']}
            onChange={(date) => form.setFieldValue('expired', date)}
            minDate={moment(initialValues.expired)}
            inputFormat={'YYYY/MM/DD'}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.textField}
                fullWidth
                variant="outlined"
                helperText={null}
              />
            )}
          />
        </div>
      </>
    );
  };

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { onClose, account, addCouponToAccount, listAccountCouponsSummary } = this.props;
    const { setSubmitting } = formikActions;

    try {
      await addCouponToAccount({
        input: {
          accountUuid: account.accountUuid,
          couponName: values.name,
          couponCode: '', // not specified
          amount: Math.round(values.balance * CONVERT_PRICE_TO_CURRENCY),
          expireDate: moment(values.expired).utc().endOf('day').toISOString(),
        },
      });

      // reload coupon list
      await listAccountCouponsSummary({ accountUuid: account.accountUuid }).catch((_) => void 0);
      onClose();
    } catch (err) {
      setSubmitting(false);
    }
  };
}

const styles = createStyles({
  dialogPaper: {
    maxWidth: 440,
  },
  dialogTitle: {},
  dialogContent: {
    paddingLeft: 80,
    paddingRight: 80,
    overflow: 'unset',
  },
  dialogAction: {
    paddingLeft: 80,
    paddingRight: 80,
    paddingBottom: 50,
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
    marginTop: 12,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  expiredDatePicker: {},
  datePicker: {},
  textField: {
    '& .MuiInputBase-root': {
      border: `1px solid ${pattensBlueColor}`,
      height: 40,
      backgroundColor: whiteSmokeColor,
      borderRadius: 4,
      fontSize: 13,
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiOutlinedInput-notchedOutline:hover': {
      border: 0,
    },
  },
  cancelBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
    marginLeft: 0,
    width: 120,
  },
  expiredFieldDatePicker: {
    width: '100%',
  },
  expiredFieldDatePickerInput: {
    ...defaultFont,
    border: `1px solid ${pattensBlueColor}`,
    padding: '0 15px',
    borderRadius: 4,
    backgroundColor: whiteSmokeColor,
    width: '100%',
    height: 38,
    fontSize: 13,
    cursor: 'pointer',
  },
});

const mapStateToProps = (store: IStore): StateProps => ({});

const mapDispatchToProps = (dispatch): DispProps => ({
  addCouponToAccount: (args: AccountActions.MutationAddCouponToAccountArgs) =>
    dispatch(AccountActions.addCouponToAccount(args)),
  listAccountCouponsSummary: (args: SummaryActions.QueryListAccountCouponsSummaryArgs) =>
    dispatch(SummaryActions.listAccountCouponsSummary(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation('admin')(AddCouponDialog)),
);

type FormValues = {
  name: string;
  balance: number;
  expired: Date;
};

const initialValues: FormValues = {
  name: '',
  balance: 1,
  expired: moment().utc().toDate(),
};
