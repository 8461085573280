import React from 'react';
import { connect } from 'react-redux';
// Component
import Button from '@mui/material/Button';
import SubmitButton from './custom-submit-button';
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomInput from './custom-input';
import CustomSelect from './custom-select';
import { IStore } from '~/stores/configure-store';
// Form
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import classNames from 'classnames';
import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';
import { romanColor, whiteSmokeColor, dimGrayColor } from '~/styles/themes/common-styles/color';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';
import * as AccountActions from '~/stores/actions/account-action';
import { MAX_PROFILE_NAME_LENGTH } from '~/constants/validation';

import { timezoneOptions } from '~/utilities/moment-utils';
import { IAdminProfile } from '~/types/admin-types';
import { AdminRoleEnum } from '~/gapi/gtypes';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
}

interface StateProps {
  profile?: IAdminProfile;
}

interface IDispProps {
  createAdminUser: (
    args: AccountActions.MutationCreateAdminUserArgs,
  ) => Promise<AccountActions.CREATE_ADMIN_RESULT_TYPE>;
}

interface IState {}

class AddUserDialog extends React.Component<IProps & IDispProps & StateProps, IState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  public render() {
    const { classes, open, onClose, t } = this.props;
    const validateSchema = Yup.object().shape<FormValues>({
      email: Yup.string().required(t('required_field')).email(t('invalid_email')),
      name: Yup.string()
        .trim()
        .required(t('required_field'))
        .max(
          MAX_PROFILE_NAME_LENGTH,
          t('too_many_character_of_user_name_error_message', { value: MAX_PROFILE_NAME_LENGTH }),
        ),
      timezone: Yup.string().required(this.props.t('required_field')),
      role: Yup.string().required(this.props.t('required_field')),
    });

    return (
      <CustomDialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={this.onSubmit}
          render={({ isValid, isSubmitting }) => (
            <Form>
              <CustomDialogTitle>
                <div id="admin-users-add-title">{t('add_user')}</div>
              </CustomDialogTitle>
              {/* Render Form Field */}
              <CustomDialogContent>
                <div title="email-field">
                  <Field name="email" render={this.emailField} />
                </div>
                <div title="username-field">
                  <Field name="name" render={this.nameField} />
                </div>
                <div title="role-field">
                  <Field name="role" render={this.adminRoleField} />
                </div>
                <div title="timezone-field">
                  <Field name="timezone" render={this.notificationTimezone} />
                </div>
              </CustomDialogContent>
              {/* Render Submit Button */}
              <CustomDialogActions>
                <Button
                  data-testid="cancel-button"
                  id="admin-users-add-cancel"
                  disabled={isSubmitting}
                  className={classes.leftBtn}
                  variant="outlined"
                  onClick={onClose}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  data-testid="submit-button"
                  id="admin-users-add-submit"
                  type="submit"
                  isLoading={isSubmitting}
                  label={t('create')}
                  submittingLabel={t('creating')}
                  disabled={!isValid || isSubmitting}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    );
  }

  private emailField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('email_address')}</div>
          {!!form.errors.email && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.email}
            </div>
          )}
        </div>
        <div>
          <CustomInput {...field} id="admin-user-add-email" placeholder={t('email_address')} />
        </div>
      </>
    );
  };

  private nameField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('user_name')}</div>
          {!!form.errors.name && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.name}
            </div>
          )}
        </div>
        <div>
          <CustomInput {...field} id="admin-user-add-name" placeholder={t('user_name')} />
        </div>
      </>
    );
  };

  private notificationTimezone = ({ field, form }: FieldProps<FormValues>) => {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('timezone')}</div>
          {!!form.errors.timezone && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.timezone}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            valueSelected={field.value}
            placeholder={this.props.t('select_timezone')}
            items={timezoneOptions}
          />
        </div>
      </>
    );
  };

  private adminRoleField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, profile, t } = this.props;
    const options: { value: AdminRoleEnum; label: string }[] =
      profile?.role === 'owner'
        ? [
            { value: 'admin', label: t('admin') },
            { value: 'operator', label: t('operator') },
            { value: 'billing_operator', label: t('billing_operator') },
          ]
        : profile?.role === 'admin'
        ? [
            { value: 'operator', label: t('operator') },
            { value: 'billing_operator', label: t('billing_operator') },
          ]
        : [];

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('role')}</div>
          {!!form.errors.role && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.role}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            valueSelected={field.value}
            placeholder={t('select_role')}
            items={options}
          />
        </div>
      </>
    );
  };

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { setSubmitting } = formikActions;
    const { onClose, createAdminUser } = this.props;
    const params = {
      input: {
        email: values.email,
        name: values.name.trim(),
        timezone: values.timezone,
        role: values.role as AdminRoleEnum,
      },
    };

    await createAdminUser(params).catch((_) => void 0);
    setSubmitting(false);
    onClose();
  };
}

const styles = createStyles({
  root: {},
  dialogPaper: {
    maxWidth: 700,
    minWidth: 512,
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
    marginTop: 12,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

const mapStateToProps = (store: IStore): StateProps => ({
  profile: store.appState.profile,
});

const mapDispatchToProps = (dispatch): IDispProps => ({
  createAdminUser: (args: AccountActions.MutationCreateAdminUserArgs) =>
    dispatch(AccountActions.createAdminUser(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation('admin')(AddUserDialog)),
);

type FormValues = {
  email: string;
  name: string;
  timezone: string;
  role: string;
};

const initialValues: FormValues = {
  email: '',
  name: '',
  timezone: '',
  role: '',
};
