import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#134A84',
      contrastText: '#FFFFFF',
    },
    grey: red,
    mode: 'light',
  },
});
theme.components = {
  MuiTextField: {
    defaultProps: {
      variant: 'standard',
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        '& p': {
          margin: 0,
        },
      },
    },
  },
};
export { theme };
