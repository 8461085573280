import * as React from 'react';

// component
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid';

import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Theme } from '@mui/material/styles';

// Styles
import { withStyles, createStyles, WithStyles } from '@mui/styles';

// i18n
import { withTranslation, WithTranslation } from 'react-i18next';

interface QuickSearchToolbarProps {
  clearSearch?: () => void;
  onChange: () => void;
  value: string;
  placeholder?: string;
  isQuickSearch?: boolean;
}

interface Props extends QuickSearchToolbarProps, WithTranslation, WithStyles<typeof styles> {}
const CustomDataGridToolbar = (props: Props) => {
  const { classes, value, placeholder, isQuickSearch, onChange, clearSearch } = props;

  return (
    <div className={classes.root}>
      <GridToolbarContainer>
        <GridToolbarColumnsButton data-testid="toolbar-columns-button" />
        <GridToolbarFilterButton data-testid="toolbar-filter-button" />
        <GridToolbarDensitySelector data-testid="toolbar-density-button" />
      </GridToolbarContainer>
      {isQuickSearch && (
        <TextField
          data-testid="search-input"
          variant="standard"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={classes.textField}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: value && !!clearSearch ? 'visible' : 'hidden' }}
                onClick={clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      )}
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 1, 0.5),
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    textField: {
      flex: 0.5,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      margin: theme.spacing(1, 0.5, 1.5),
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  });

export default withStyles(styles)(withTranslation('admin')(CustomDataGridToolbar));
