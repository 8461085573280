import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import TextField, { TextFieldProps as MuiTextFieldPropsType } from '@mui/material/TextField';
import { pattensBlueColor } from '~/styles/themes/common-styles/color';
import * as moment from 'moment';
import classNames from 'classnames';

interface IProps extends Omit<DatePickerProps<Date, moment.Moment>, 'renderInput' | 'onChange'> {
  inputText?: string;
  renderInput?: (props: MuiTextFieldPropsType) => React.ReactElement;
  onChange: (date: Date) => void;
}

const CustomDatePicker: React.FunctionComponent<IProps & WithStyles<typeof styles>> = ({
  classes,
  inputText,
  renderInput,
  onChange,
  ...other
}) => {
  const onKeyDown = React.useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleOnChange = React.useCallback(
    (date: moment.Moment | null) => {
      if (date) {
        onChange(date.toDate());
      }
    },
    [onChange],
  );

  return (
    <div className={classes.root}>
      <DatePicker
        {...other}
        onChange={handleOnChange}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classNames({
              [classes.textField]: true,
              [classes.customInputTextField]: !!inputText,
            })}
            fullWidth
            variant="outlined" // default inline date picker
            helperText={null}
            onKeyDown={onKeyDown}
            inputProps={{ ...params.inputProps, value: inputText || params.inputProps?.value }}
          />
        )}
      />
    </div>
  );
};

const styles = createStyles({
  root: {},
  textField: {
    '& .MuiInputBase-root': {
      height: 32,
      width: 160,
      fontSize: '14px',
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      caretColor: 'transparent',
      borderColor: pattensBlueColor,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiOutlinedInput-notchedOutline:hover': {
      border: 0,
    },
  },
  customInputTextField: {
    '& .MuiInputBase-root': {
      width: 230,
    },
  },
});

export default withStyles(styles)(CustomDatePicker);
