import React from 'react';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFont } from '~/styles/themes/common-styles/font';
import { pattensBlueColor } from '~/styles/themes/common-styles/color';
// Component
import ImgIcon from './img-icon';

interface IProps extends WithStyles<typeof styles> {
  placeholder?: string;
  onChange?: any;
  disable?: boolean;
  id?: string;
  value?: string;
}

const searchInput: React.StatelessComponent<IProps> = ({
  classes,
  placeholder,
  onChange,
  disable,
  id,
  value,
}) => (
  <div className={classes.root}>
    <ImgIcon className={classes.searchIcon} imgUrl="/images/icons/search_ico.png" />
    <input
      value={value}
      type="text"
      placeholder={placeholder}
      onChange={onChange}
      className={classes.input}
      disabled={disable}
      id={id}
    />
  </div>
);

const styles = createStyles({
  root: {
    display: 'flex',
    height: 32,
    width: 200,
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: pattensBlueColor,
    borderRadius: 4,
    paddingLeft: 10,
    paddingRight: 10,
  },
  searchIcon: {
    width: 16,
    height: 16,
  },
  input: {
    ...defaultFont,
    fontSize: 14,
    lineHeight: '16px',
    border: 'none',
    width: '100%',
    marginLeft: 10,
  },
});

export default withStyles(styles)(searchInput);
