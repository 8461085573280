import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as semver from 'semver';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';

// Component
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/custom-submit-button';
import CustomSelect from '~/components/common/custom-select';

import { INode, ICluster, INetwork } from '~/types/network-types';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Field, Form, FieldProps, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  snowColor,
  pattensBlueColor,
  lightSlateGreyColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

import { nodeGethSelection } from '~/types/network-selection';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { MIN_GETH_SUPPORT_EXTERNAL_CLEF_VERSION } from '~/constants/validation';
import Grid from '@mui/material/Grid';

interface IProps extends WithStyles<typeof styles> {
  network: INetwork;
  cluster: ICluster;
  node: INode;
  open: boolean;
  onClose: () => void;
}

type FormValues = {
  nodeVersion: string;
  enableRebuildNode?: boolean;
};

const UpdateNodeVersionDialog = (props: IProps) => {
  const { classes, open, node, network, cluster, onClose } = props;
  const [checked, setChecked] = useState<boolean>(false);
  const isNodeUpdating = useSelector((store: IStore) =>
    NetworkActions.updateNodeVersion.isPending(store),
  );
  const { t } = useTranslation(['admin']);
  const dispatch = useDispatch();

  const initialValues: FormValues = {
    nodeVersion: node.nodeInfo.version,
  };

  const validateSchema = Yup.object().shape<FormValues>({
    nodeVersion: Yup.string().required(t('required_field')),
  });

  const nodeVersionFilters = useMemo(() => {
    return nodeGethSelection.filter(({ value }) => {
      return node.signerInfo?.externalClef
        ? semver.gt(value, MIN_GETH_SUPPORT_EXTERNAL_CLEF_VERSION)
        : true;
    });
  }, [node]);

  const onCheckboxChange = useCallback((value, form: FormikProps<FormValues>) => {
    form.setFieldValue('enableRebuildNode', value);
    setChecked(value);
  }, []);

  const onNodeVersionChange = useCallback(
    (event, form: FormikProps<FormValues>) => {
      form.setFieldValue('nodeVersion', event.target.value);
      if (semver.lt(event.target.value, node.nodeInfo.version)) {
        onCheckboxChange(true, form);
      } else {
        onCheckboxChange(false, form);
      }
    },
    [node, onCheckboxChange],
  );

  const nodeVersionField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <>
          <div className={classes.formLabelLine}>
            <div className={classes.formLabel}>{t('node_version')}</div>
            {!!form.errors.nodeVersion && form.touched.nodeVersion && (
              <div className={classNames(classes.formLabel, classes.formError)}>
                {form.errors.nodeVersion}
              </div>
            )}
          </div>
          <div>
            <CustomSelect
              {...field}
              data-testid="node-version-selection"
              id="member-cluster-add-ver-node"
              valueSelected={field.value}
              placeholder={t('select_node_version')}
              items={nodeVersionFilters}
              onChange={(event) => onNodeVersionChange(event, form)}
            />
          </div>
        </>
      );
    },
    [classes, nodeVersionFilters, onNodeVersionChange, t],
  );

  const enableRebuildNodeCheckbox = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <div>
          <FormControlLabel
            {...field}
            disabled={semver.lte(form.values.nodeVersion, node.nodeInfo.version)}
            checked={checked}
            className={classes.formControlLabel}
            control={
              <Checkbox
                color="default"
                onChange={(e, value) => onCheckboxChange(value, form)}
                data-testid="rebuild-node-checkbox"
              />
            }
            label={<span className={classes.formLabel}>{t('enable_rebuild_node')}</span>}
          />
        </div>
      );
    },
    [
      checked,
      classes.formControlLabel,
      classes.formLabel,
      node.nodeInfo.version,
      onCheckboxChange,
      t,
    ],
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      const { setSubmitting } = formikActions;
      const { nodeVersion, enableRebuildNode } = values;

      try {
        await dispatch(
          NetworkActions.updateNodeVersion({
            input: {
              networkUuid: network.networkUuid,
              clusterUuid: cluster.clusterUuid,
              nodeUuid: node.nodeUuid,
              nodeVersion,
              enableRebuildNode,
            },
          }),
        );
        onClose();
      } catch (error) {}
      setSubmitting(false);
    },
    [cluster.clusterUuid, dispatch, network.networkUuid, node.nodeUuid, onClose],
  );

  const renderWarningText = useCallback(
    (values: FormValues) => {
      if (semver.lt(values.nodeVersion, node.nodeInfo.version)) {
        return (
          <div id="member-node-rebuild-attention" className={classes.warningText}>
            {t('are_you_sure_you_want_to_downgrade_this_node')}
          </div>
        );
      } else if (checked) {
        return (
          <div id="member-node-rebuild-attention" className={classes.warningText}>
            {t('are_you_sure_you_want_to_rebuild_this_node')}
          </div>
        );
      }
      return <></>;
    },
    [checked, classes.warningText, node.nodeInfo.version, t],
  );

  const handleCloseDialog = useCallback(() => {
    setChecked(false);
    onClose();
  }, [onClose]);

  return (
    <>
      <CustomDialog classes={{ paper: classes.dialog }} open={open} onClose={handleCloseDialog}>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={onSubmit}
          render={({ isValid, isSubmitting, values }) => (
            <Form>
              <CustomDialogTitle>
                <div id="member-upgrade-node-ver-title">{t('change_node_version')}</div>
              </CustomDialogTitle>
              <CustomDialogContent>
                <div id="member-node-del-detail" className={classes.content}>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <div className={classes.itemLabel}>{t('node_name')}</div>
                      <div className={classes.itemValue}>{node.nodeName}</div>
                    </Grid>
                    <Grid item md={6}>
                      <div className={classes.itemLabel}>{t('cluster_name')}</div>
                      <div className={classes.itemValue}>{cluster.clusterName}</div>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.formSection}>
                  <Field name="nodeVersion" render={nodeVersionField} />
                </div>
                <div className={classes.formSection}>
                  <Field name="enableRebuildNode" render={enableRebuildNodeCheckbox} />
                </div>
                {renderWarningText(values)}
              </CustomDialogContent>
              <CustomDialogActions>
                <Button
                  data-testid="cancel-button"
                  id="member-upgrade-node-ver-cancel"
                  disabled={isSubmitting || isNodeUpdating}
                  className={classes.leftBtn}
                  variant="contained"
                  onClick={handleCloseDialog}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  data-testid="submit-button"
                  id="member-upgrade-node-ver-submit"
                  type="submit"
                  disabled={!isValid || semver.eq(values.nodeVersion, node.nodeInfo.version)}
                  isLoading={isSubmitting || isNodeUpdating}
                  label={t('update')}
                  submittingLabel={t('updating')}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    </>
  );
};

const styles = createStyles({
  root: {},
  content: {
    padding: 15,
    backgroundColor: snowColor,
    borderRadius: 4,
    border: `1px solid ${pattensBlueColor}`,
    boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
    wordBreak: 'break-word',
  },
  itemLabel: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  itemValue: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
  },
  dialog: { minWidth: 700 },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formControlLabel: {
    marginLeft: '-14px',
    '& .MuiCheckbox-root': {
      padding: '12px',
    },
  },
  formSection: {
    marginTop: 10,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  btnArea: {
    marginTop: 30,
    textAlign: 'right',
  },
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
  },
});

export default withStyles(styles)(UpdateNodeVersionDialog);
