import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { defaultFont } from '~/styles/themes/common-styles/font';
import { whiteColor, blackRussianColor, gainsboroColor } from '~/styles/themes/common-styles/color';
import { IStore } from '~/stores/configure-store';
import Layout from '~/components/common/Layout';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import LoadingIcon from '~/components/common/loading-icon';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withAdminProfile } from '~/hooks/with-admin-profile';
import { compose } from 'redux';

// components
import DatePicker from '~/components/common/member-date-picker';

import * as SummaryActions from '~/stores/actions/summary-action';
import { ISummaryAdmin, AllCouponSummary, IAdminProfile } from '~/types/admin-types';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import { displayCurrency } from '~/utilities/payment-utils';
import * as ProfileActions from '~/stores/actions/profile-action';
import { Button } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { MAX_CSV_COUPON_DATA, MAX_CSV_OUTPUT_DATA, PRODUCT_NAME } from '~/constants/common';
import { AppRoutePath } from '~/AppRouter';
import { withAuthorization } from '~/hooks/with-authorization';

interface StateProps {
  isLoading: boolean;
  isCouponLoading: boolean;
  adminSummaryList: ISummaryAdmin;
  couponList: AllCouponSummary;
  profile?: IAdminProfile;
  isProfileLoading: boolean;
}

interface DispProps {
  getAdminProfile: () => Promise<ProfileActions.GET_ADMIN_PROFILE_RESULT_TYPE>;
  listAdminSummary: (
    args: SummaryActions.QueryListAdminSummaryArgs,
  ) => Promise<SummaryActions.LIST_ADMIN_SUMMARY_RESULT_TYPE>;
  listAllCouponsSummary: (
    args: SummaryActions.QueryListAllCouponsSummaryArgs,
  ) => Promise<SummaryActions.LIST_ALL_COUPON_SUMMARY_RESULT_TYPE>;
}

interface Props extends RouteComponentProps<{}>, WithTranslation, WithStyles<typeof styles> {}

interface State {
  couponStartDate: string;
  couponEndDate: string;
  paymentStartDate: string;
  paymentEndDate: string;
}

class Summary extends React.Component<Props & DispProps & StateProps, State> {
  constructor(props) {
    super(props);
    const { i18n, profile } = this.props;
    if (profile) {
      i18n.changeLanguage(profile.preference.language);
    }

    this.state = {
      couponStartDate: moment().startOf('month').toISOString(),
      couponEndDate: moment().endOf('month').toISOString(),
      paymentStartDate: moment().startOf('month').toISOString(),
      paymentEndDate: moment().endOf('month').toISOString(),
    };
  }

  public render() {
    const { classes, t, isLoading, isCouponLoading, isProfileLoading } = this.props;

    return (
      <Layout>
        <div className={classes.root}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Link
              component={RouterLink}
              to={AppRoutePath.Dashboard}
              color="text.primary"
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            </Link>
            <Typography color="text.primary">{t('summary')}</Typography>
          </Breadcrumbs>
          <div className={classes.contentPaper}>
            {isProfileLoading ? (
              <div className={classes.loadingArea}>
                <LoadingIcon />
              </div>
            ) : (
              <>
                <div id="payment-summary-container">
                  <div className={classes.subTitle}>
                    <span>{t('payment_summary')}</span>
                  </div>
                  {isLoading ? (
                    <div className={classes.loadingArea}>
                      <LoadingIcon />
                    </div>
                  ) : (
                    <>
                      <div className={classes.datePickerArea}>
                        <div className={classes.startMonthPicker}>
                          <div className={classes.datePickerLabel}>{t('start_month')}</div>
                          <DatePicker
                            data-testid="payment-start-month-date"
                            datePickerInlineProps={{
                              value: new Date(this.state.paymentStartDate),
                              views: ['month', 'year'],
                              onChange: this.onStartDatePickerClick,
                              disabled: isLoading,
                              maxDate: new Date(this.state.paymentEndDate),
                              inputFormat: 'YYYY/MM',
                            }}
                            classes={{ root: classes.dtPicker }}
                          />
                        </div>
                        <div className={classes.endMonthPicker}>
                          <div className={classes.datePickerLabel}>{t('end_month')}</div>
                          <DatePicker
                            data-testid="payment-end-month-date"
                            datePickerInlineProps={{
                              value: new Date(this.state.paymentEndDate),
                              onChange: this.onEndDatePickerClick,
                              views: ['month', 'year'],
                              disabled: isLoading,
                              minDate: new Date(this.state.paymentStartDate),
                              inputFormat: 'YYYY/MM',
                            }}
                            classes={{ root: classes.dtPicker }}
                          />
                        </div>
                        <Button
                          data-testid="download-payment-csv-button"
                          id="admin-download-statement-button"
                          variant="contained"
                          color="primary"
                          className={classes.addBtn}
                          startIcon={<GetAppIcon />}
                          onClick={this.onDownloadPaymentCsv}
                        >
                          {t('download_csv')}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
                <div id="coupon-summary-container" className={classes.couponSummaryContainer}>
                  <div className={classes.subTitle}>
                    <span>{t('coupon_summary')}</span>
                  </div>
                  {isCouponLoading ? (
                    <div className={classes.loadingArea}>
                      <LoadingIcon />
                    </div>
                  ) : (
                    <div className={classes.datePickerArea}>
                      <div className={classes.startMonthPicker}>
                        <div className={classes.datePickerLabel}>{t('start_month')}</div>
                        <DatePicker
                          data-testid="coupon-start-month-date"
                          datePickerInlineProps={{
                            value: new Date(this.state.couponStartDate),
                            views: ['month'],
                            onChange: this.onCouponStartDatePickerClick,
                            disabled: isCouponLoading,
                            maxDate: new Date(this.state.couponEndDate),
                            inputFormat: 'YYYY/MM',
                          }}
                          classes={{ root: classes.dtPicker }}
                        />
                      </div>
                      <div className={classes.endMonthPicker}>
                        <div className={classes.datePickerLabel}>{t('end_month')}</div>
                        <DatePicker
                          data-testid="coupon-end-month-date"
                          datePickerInlineProps={{
                            value: new Date(this.state.couponEndDate),
                            onChange: this.onCouponEndDatePickerClick,
                            views: ['month'],
                            disabled: isCouponLoading,
                            minDate: new Date(this.state.couponStartDate),
                            inputFormat: 'YYYY/MM',
                          }}
                          classes={{ root: classes.dtPicker }}
                        />
                      </div>
                      <Button
                        data-testid="download-coupon-csv-button"
                        id="admin-download-coupon-button"
                        variant="contained"
                        color="primary"
                        className={classes.addBtn}
                        startIcon={<GetAppIcon />}
                        onClick={this.onDownloadCouponCsv}
                      >
                        {t('download_csv')}
                      </Button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Layout>
    );
  }

  private onDisplayUSDCurrency = (value: any) => {
    const result = displayCurrency(value).includes(',')
      ? displayCurrency(value).replace(/,/g, '')
      : displayCurrency(value);
    return result;
  };

  private onDisplayJPYCurrency = (value: any) => {
    let result: any;
    const res = value.toString().includes('.') ? value.toString().split('.') : [value];
    if (res.length == 1 || res[1].length < 3) {
      result = Number(value).toFixed(2);
    }
    return result;
  };

  private onDownloadPaymentCsv = async () => {
    const { listAdminSummary } = this.props;
    const { paymentStartDate, paymentEndDate } = this.state;
    await listAdminSummary({
      startDate: paymentStartDate,
      endDate: paymentEndDate,
    }).then(() => {
      this.onExportToCsvPayment();
    });
  };

  private onExportToCsvPayment = () => {
    const { t, adminSummaryList } = this.props;
    const now = moment();
    adminSummaryList.details = adminSummaryList.details.filter((r) => r.id != '');
    const maxDate = Math.max.apply(
      null,
      adminSummaryList.details.map(function (e) {
        return new Date(e.invoicedDate).getTime();
      }),
    );
    const minDate = Math.min.apply(
      null,
      adminSummaryList.details.map(function (e) {
        return new Date(e.invoicedDate).getTime();
      }),
    );
    const fileName = t('summary_billing_filename', {
      startDate: moment(minDate).isValid() ? moment(minDate).format('YYYYMMDD') + '_' : '',
      endDate: moment(maxDate).isValid() ? moment(maxDate).format('YYYYMMDD') + '.' : '',
      product: PRODUCT_NAME,
      yy: now.format('YY'),
      yyyy: now.format('YYYY'),
      mm: now.format('MM'),
      dd: now.format('DD'),
    });
    const options = {
      fieldSeparator: ',',
      filename: fileName,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        // <-- Won't work with useKeysAsHeaders present!
        '検索対象',
        '',
        '',
        '',
        '法人および・組織アカウント情報',
        '',
        '',
        '',
        '請求サマリ',
        '',
        '',
        '',
        '課金明細',
        '',
        '',
        '',
        '',
        'Stripe情報',
        '',
        '',
        '',
        '',
        'その他',
      ],
    };

    const subHeaders: any = [
      {
        // 検索対象
        paymentYear: '支払い年', // t('billing_year'),
        paymentMonth: '支払い月', // t('billing_month'),
        paymentStartDate: '対象開始日', // t('billing_start_date'),
        paymentEndDate: '対象終了日', // t('billing_end_date'),
        // 法人および・組織アカウント情報
        orgAccountName: '組織アカウント名', // t('organization_display_name'),
        accountUuid: 'アカウントUuid', // t('account_uuid'),
        ownerEmailAddress: 'オーナーメールアドレス', // t('owner_email'),
        accountantEmailAddress: '経理担当メールアドレス', // t('accountant_email'),
        // 請求サマリ
        billingDate: '請求日', //  t('billing_date'),
        billingId: '請求ID', // t('billing_id'),
        paymentStatus: '支払い状況', // t('payment_status'),
        billingAmount: '請求金額', // t('billing_amount'),
        // 課金明細
        licenseSubtotal: 'ライセンス合計額', // t('license_amount'),
        couponAmount: 'クーポン利用額', // t('amount_of_coupon_used'),
        preTax: '請求金額（消費税含まず）',
        tax: '消費税', // t('tax'),
        cardPaymentInUSD: 'カード決済額USD', // t('card_billing_amount'),
        // Stripe情報
        exchangeRate: '支払為替レート', // t('payment_exchange_rate'),
        fee: '手数料', // t('fee'),
        net: '純売上', // t('net'),
        status: 'ステータス', // t('stripe_status'),
        stripeBillingDate: '課金日付', // t('stripe_billing_date'),
        // その他
        dateDownloaded: '情報取得年月日時刻', // t('download_datetime'),
      },
    ];

    const data: any =
      (adminSummaryList.details.slice(0, MAX_CSV_OUTPUT_DATA) || []).map((info) => {
        const licenseAmount = info.amount;
        const preTaxAmount = licenseAmount - info.coupon;
        const billingAmount = preTaxAmount + info.tax;
        const succeeded = 'succeeded';
        const invoicedUtc = moment(info.invoicedDate).utc();

        return {
          // 検索対象
          paymentYear: invoicedUtc.clone().format('YYYY'),
          paymentMonth: invoicedUtc.clone().format('MM'),
          paymentStartDate: invoicedUtc.clone().format('YYYY/MM/DD'),
          paymentEndDate: info.paidDate
            ? invoicedUtc.clone().endOf('month').format('YYYY/MM/DD')
            : invoicedUtc.clone().endOf('day').add(14, 'days').format('YYYY/MM/DD'),
          // 法人および・組織アカウント情報
          orgAccountName: info.orgAccountName,
          accountUuid: info.accountUuid,
          ownerEmailAddress: info.ownerEmailAddress,
          accountantEmailAddress: info.accountantEmailAddress,
          // 請求サマリ
          billingDate: invoicedUtc.clone().format('YYYY/MM/DD'),
          billingId: info.id,
          paymentStatus: info.paidDate ? t('paid') : t('unpaid'),
          billingAmount: this.onDisplayUSDCurrency(billingAmount),
          // 課金明細
          licenseSubtotal: this.onDisplayUSDCurrency(licenseAmount),
          couponAmount: this.onDisplayUSDCurrency(info.coupon),
          preTax: this.onDisplayUSDCurrency(preTaxAmount),
          tax: this.onDisplayUSDCurrency(info.tax),
          cardPaymentInUSD: info.status
            ? this.onDisplayUSDCurrency(info.amountInUSD)
            : this.onDisplayUSDCurrency(0),
          // Stripe情報
          exchangeRate: info.status ? info.exchangeRate * 100 : this.onDisplayJPYCurrency(0),
          fee: info.status ? this.onDisplayJPYCurrency(info.fee) : this.onDisplayJPYCurrency(0),
          net: info.status ? this.onDisplayJPYCurrency(info.net) : this.onDisplayJPYCurrency(0),
          status: info.status
            ? info.status == succeeded
              ? t('succeeded')
              : t('failed')
            : info.paidDate
            ? ''
            : t('failed'),
          stripeBillingDate: info.status
            ? invoicedUtc.clone().utcOffset('+0900').format('YYYY/MM/DD HH:mm:ss')
            : '',
          // その他
          dateDownloaded: now.clone().format('YYYY/MM/DD HH:mm:ss'),
        };
      }) || [];

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(subHeaders.concat(data));
  };

  private onDownloadCouponCsv = async () => {
    const { listAllCouponsSummary } = this.props;
    const { couponStartDate, couponEndDate } = this.state;
    await listAllCouponsSummary({
      startDate: couponStartDate,
      endDate: couponEndDate,
    }).then(() => {
      this.onExportToCsvCoupon();
    });
  };

  private onExportToCsvCoupon = () => {
    const { t, couponList } = this.props;
    couponList.details = couponList.details.filter((r) => r.id != '');
    const maxDate = Math.max.apply(
      null,
      couponList.details.map(function (e) {
        return new Date(e.createAt).getTime();
      }),
    );
    const minDate = Math.min.apply(
      null,
      couponList.details.map(function (e) {
        return new Date(e.createAt).getTime();
      }),
    );
    const nowUtc = moment.utc();
    const fileName = t('summary_coupon_filename', {
      startDate: moment(minDate).isValid() ? moment(minDate).format('YYYYMMDD') + '_' : '',
      endDate: moment(maxDate).isValid() ? moment(maxDate).format('YYYYMMDD') + '.' : '',
      productname: PRODUCT_NAME,
      yy: nowUtc.format('YY'),
      yyyy: nowUtc.format('YYYY'),
      mm: nowUtc.format('MM'),
      dd: nowUtc.format('DD'),
    });

    const options = {
      fieldSeparator: ',',
      filename: fileName,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        '検索対象',
        '', // t('search_condition')
        '法人および・組織アカウント情報',
        '', // t('corp_org_information')
        '個別クーポン実績',
        '',
        '',
        '',
        '', // t('individual_coupon_summary')
        'その他', // t('others')
      ],
    };

    const header = [
      {
        // 検索対象
        couponGrantYear: 'クーポン付与年', // t('granted_year'),
        couponGrantMonth: 'クーポン付与月', // t('granted_month'),
        // 法人および・組織アカウント情報
        orgAccountName: '組織アカウント名', // t('org_display_name'),
        accountUuid: 'アカウントUuid', // t('account_UUID'),
        // 個別クーポン実績
        grantDate: '付与日', // t('granted_date'),
        couponName: 'クーポン名称', // t('coupon_name_csv'),
        amountGranted: '付与した金額', // t('coupon_amount'),
        expiryDate: '有効期限', // t('expired_date'),
        balanceGranted: 'クーポン残高', // t('coupon_balance'),
        // その他
        downloadDate: '情報取得年月日時刻', // t('download_datetime'),
      },
    ];

    const dataCoupon: any =
      (couponList.details.slice(0, MAX_CSV_COUPON_DATA) || []).map((coupon) => {
        const expiredAtUtc = moment(coupon.expiredAt).utc().toDate();
        const createDateUtc = moment(coupon.createAt).utc().toDate();
        const expiredAtFormat = moment(coupon.expiredAt).utc().format('YYYY/MM/DD');
        const createDateFormat = moment(coupon.createAt).utc().format('YYYY/MM/DD');
        const amount = displayCurrency(coupon.amount).replace(/,/g, '');
        let balance = displayCurrency(coupon.balance).replace(/,/g, '');
        const grantYear = createDateUtc.getFullYear();
        const grantMonth = createDateUtc.getMonth() + 1;
        const currentDate = moment.utc().toDate();
        if (currentDate > expiredAtUtc) {
          balance = '0.00';
        }

        const downloadDatetime = moment().format('YYYY/MM/DD HH:mm:ss');

        return {
          // 検索対象
          couponGrantYear: grantYear,
          couponGrantMonth: grantMonth,
          // 法人および・組織アカウント情報
          orgAccountName: coupon.orgAccountName,
          accountUuid: coupon.accountUuid,
          // 個別クーポン実績
          grantDate: createDateFormat,
          couponName: coupon.name,
          amountGranted: amount,
          expiryDate: expiredAtFormat,
          balanceGranted: balance,
          // その他
          downloadDate: downloadDatetime,
        };
      }) || [];

    const data = header.concat(dataCoupon);
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  };

  onStartDatePickerClick = (date) => {
    this.setState({
      paymentStartDate: moment(date).startOf('month').toISOString(),
    });
  };

  onEndDatePickerClick = (date) => {
    this.setState({
      paymentEndDate: moment(date).endOf('month').toISOString(),
    });
  };

  private onCouponStartDatePickerClick = (date) => {
    this.setState({
      couponStartDate: moment(date).startOf('month').toISOString(),
    });
  };

  private onCouponEndDatePickerClick = (date) => {
    this.setState({
      couponEndDate: moment(date).endOf('month').toISOString(),
    });
  };
}

const styles = (theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: 'auto',
      paddingRight: 0,
    },
    tableLayout: {
      tableLayout: 'fixed',
    },
    pageTitleArea: {
      ...defaultFont,
      display: 'flex',
      justifyContent: 'space-between',
    },
    addBtn: {
      width: 190,
      marginLeft: 50,
      '& span': {
        justifyContent: 'center !important',
      },
    },
    iconAddBtn: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
    tableCellHeadCreated: {
      paddingRight: 10,
    },
    tableCellHeadDeleteIcon: {
      paddingRight: '0px !important',
    },
    tableHeadCellCommon: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: 0,
      textOverflow: 'ellipsis',
      padding: '4px 0px 4px 2%',
    },
    contentPaper: {
      ...defaultFont,
      marginTop: 24,
      boxShadow: `0 2px 6px 0 ${blackRussianColor}`,
      border: `solid 1px ${gainsboroColor}`,
      backgroundColor: whiteColor,
      padding: '3%',
      borderRadius: 4,
    },
    loadingArea: {
      margin: '100px 0',
      textAlign: 'center',
    },
    datePickerArea: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 20,
    },
    startMonthPicker: {
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
    },
    endMonthPicker: {
      display: 'flex',
      alignItems: 'center',
    },
    datePickerLabel: {
      marginRight: 5,
      fontSize: 14,
    },
    [theme.breakpoints.between('sm', 'sm')]: {
      datePickerLabel: {
        fontSize: 13,
        marginRight: 5,
        color: '#7b90a3',
      },
      dtPicker: {
        width: 100,
        boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
      },
    },
    subTitle: {
      fontSize: 20,
      fontWeight: 'bolder',
    },
    couponSummaryContainer: {
      marginTop: 30,
    },
  });

const mapStateToProps = (store: IStore): StateProps => ({
  adminSummaryList: store.appState.adminSummaryList,
  couponList: store.appState.couponList,
  isLoading: SummaryActions.listAdminSummary.isPending(store),
  isCouponLoading: SummaryActions.listAllCouponsSummary.isPending(store),
  isProfileLoading: ProfileActions.getAdminProfile.isPending(store),
  profile: store.appState.profile,
});

const mapDispatchToProps = (dispatch): DispProps => ({
  listAdminSummary: (args: SummaryActions.QueryListAdminSummaryArgs) =>
    dispatch(SummaryActions.listAdminSummary(args)),
  listAllCouponsSummary: (args: SummaryActions.QueryListAllCouponsSummaryArgs) =>
    dispatch(SummaryActions.listAllCouponsSummary(args)),
  getAdminProfile: () => dispatch(ProfileActions.getAdminProfile()),
});

export default compose(
  withFirebaseUser(),
  withAdminProfile,
  withAuthorization(['owner', 'admin', 'operator', 'billing_operator']),
  withStyles(styles),
  withTranslation('admin'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Summary);
