import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter, RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/Language';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ProfilePopover from './profile-popover';
import LanguageMenu from './language-menu';
import { useTheme } from '@mui/material/styles';

import { defaultFont } from '~/styles/themes/common-styles/font';
import { denimColor, whiteColor } from '~/styles/themes/common-styles/color';

import { IStore } from '~/stores/configure-store';
import { IAdminProfile } from '~/types/admin-types';

interface IStateProps {
  profile?: IAdminProfile;
}

interface IDispProps {}

interface IProps
  extends IStateProps,
    IDispProps,
    RouteComponentProps<{}>,
    WithTranslation,
    WithStyles<typeof styles> {
  isTemporarySideBar: boolean;
  isSmallScreen: boolean;
  onToggleDrawer: () => void;
}

interface IState {
  userMenuAnchorEl: HTMLElement | null;
  languageMenuAnchorEl: HTMLElement | null;
}

class Header extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      userMenuAnchorEl: null,
      languageMenuAnchorEl: null,
    };
  }

  render() {
    const { classes, profile, isTemporarySideBar, isSmallScreen, onToggleDrawer } = this.props;
    const { userMenuAnchorEl, languageMenuAnchorEl } = this.state;

    return (
      <>
        {profile && (
          <ProfilePopover
            profile={profile}
            open={Boolean(userMenuAnchorEl)}
            anchorEl={userMenuAnchorEl}
            onClose={this.onCloseUserMenu}
          />
        )}
        <AppBar className={classes.appBar} color="inherit">
          <Toolbar>
            <div className={classes.toolbarContent}>
              {isTemporarySideBar && (
                <IconButton onClick={onToggleDrawer}>
                  <MenuIcon />
                </IconButton>
              )}
              <RouterLink to="/" data-testid="logo-home-page">
                <img className={classes.logo} src="/images/icons/logo_guest.svg" alt="" />
              </RouterLink>
              <div className={classes.toolbarContentRight}>
                {isSmallScreen ? (
                  <IconButton onClick={this.onOpenLanguageMenu} data-testid="language-icon-button">
                    <LanguageIcon />
                  </IconButton>
                ) : (
                  <div
                    className={classes.language}
                    onClick={this.onOpenLanguageMenu}
                    data-testid="language-button"
                  >
                    <LanguageIcon color="action" />
                    <Typography className={classes.languageText} variant="body1">
                      {profile?.preference.language}
                    </Typography>
                    <ExpandMoreIcon color="action" />
                  </div>
                )}
                <LanguageMenu
                  profile={profile}
                  open={Boolean(languageMenuAnchorEl)}
                  onClose={this.onCloseLanguageMenu}
                  anchorEl={languageMenuAnchorEl}
                />
                <div
                  className={classes.avatar}
                  onClick={this.onOpenUserMenu}
                  data-testid="profile-button"
                >
                  <AccountCircleIcon color="action" />
                  <ArrowDropDownIcon color="action" />
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </>
    );
  }

  private onOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ userMenuAnchorEl: event.currentTarget });
  };

  private onCloseUserMenu = () => {
    this.setState({ userMenuAnchorEl: null });
  };

  private onOpenLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ languageMenuAnchorEl: event.currentTarget });
  };

  private onCloseLanguageMenu = () => {
    this.setState({ languageMenuAnchorEl: null });
  };
}

const styles = (theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      color: theme.palette.text.secondary,
    },
    toolbarRoot: {},
    toolbarContent: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    toolbarContentRight: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 0,
      gap: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        gap: 0,
      },
    },
    avatar: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      margin: 0,
    },
    language: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      margin: 0,
    },
    languageText: {
      color: 'GrayText',
      textTransform: 'uppercase',
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    logo: {
      color: '#3E5B72',
      height: 30,
      display: 'block',
      pointerEvents: 'none',
      fontWeight: 700,
      whiteSpace: 'nowrap',
    },
  });

const mapStateToProps = (store: IStore) => ({
  profile: store.appState.profile,
});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  withTranslation('admin')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))),
);
