import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import TableRow, { TableRowProps } from '@mui/material/TableRow';

interface Props extends Omit<TableRowProps, 'classes'>, WithStyles<typeof styles> {}

const TableBodyRow: React.StatelessComponent<Props> = ({ classes, children, ...tableRowProps }) => (
  <TableRow className={classes.root} {...tableRowProps}>
    {children}
  </TableRow>
);

const styles = createStyles({
  root: {
    height: 48,
  },
});

export default withStyles(styles)(TableBodyRow);
