import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';
import { IStore } from '~/stores/configure-store';

import { IAccountSummary, IAdminUser, ICouponSummary } from '~/types/admin-types';
import * as SummaryActions from '~/stores/actions/summary-action';
import CustomDataGridToolbar from '~/components/common/custom-data-grid-toolbar';

// Components
import CouponOption from './coupon-row-option';
import CustomFooter from './custom-data-grid-footer';
import { whiteColor } from '~/styles/themes/common-styles/color';

// Utils
import { displayCurrency } from '~/utilities/payment-utils';

interface DispProps {
  listAccountCouponsSummary: (
    args: SummaryActions.QueryListAccountCouponsSummaryArgs,
  ) => Promise<SummaryActions.LIST_ACCOUNT_COUPONS_SUMMARY_RESULT_TYPE>;
}

interface StateProps {
  coupons: ICouponSummary;
  isLoading: boolean;
  profile?: IAdminUser;
}

interface Props
  extends DispProps,
    StateProps,
    RouteComponentProps<{}>,
    WithStyles<typeof styles>,
    WithTranslation {
  account: IAccountSummary;
}

interface State {}

class AccountDetailCoupons extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const { account, listAccountCouponsSummary } = this.props;
    await listAccountCouponsSummary({ accountUuid: account.accountUuid });
  }

  public render() {
    const { classes, coupons, isLoading, account, profile, t } = this.props;

    const columns: GridColumns = [
      {
        field: DataGridFieldEnum.CREATED_AT,
        disableColumnMenu: true,
        filterable: false,
        sortable: true,
        headerName: t('granted_date'),
        width: 250,
        valueFormatter: ({ value }) => t('granted_date_value', { date: new Date(value as string) }),
      },
      {
        field: DataGridFieldEnum.NAME,
        disableColumnMenu: true,
        sortable: false,
        filterable: true,
        headerName: t('coupon_name'),
        width: 200,
      },
      {
        field: DataGridFieldEnum.EXPIRED_AT,
        disableColumnMenu: true,
        sortable: true,
        filterable: false,
        width: 250,
        headerName: t('expired_date'),
        valueFormatter: ({ value }) => t('expired_date_value', { date: new Date(value as string) }),
      },
      {
        field: DataGridFieldEnum.BALANCE,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        width: 250,
        headerName: t('coupon_balance'),
        valueFormatter: ({ value }) => `$ ${displayCurrency(value)}`,
      },
      {
        field: DataGridFieldEnum.ACTION,
        type: 'actions',
        sortable: false,
        filterable: false,
        headerName: t('action'),
        disableColumnMenu: true,
        renderHeader: () => <></>,
        headerClassName: classes.cellAction,
        renderCell: (params: GridCellParams) => {
          return ['admin', 'owner'].includes(profile?.role || '') ? (
            <CouponOption coupon={params.row as Item} account={account as IAccountSummary} />
          ) : undefined;
        },
      },
    ];

    let totalBalance = 0;
    coupons.details.forEach((coupon) => {
      if (moment(coupon.expiredAt).isAfter(Date.now())) {
        totalBalance = totalBalance + coupon.balance;
      }
    });

    const items: Array<Item> =
      (coupons.details || []).map((coupon) => {
        const expiredUtc = moment(coupon.expiredAt).utc().toDate();
        return {
          id: coupon.id,
          createAt: coupon.createAt,
          name: coupon.name,
          expiredAt: coupon.expiredAt,
          balance: expiredUtc > moment().utc().toDate() ? coupon.balance : 0,
        };
      }) || [];

    return (
      <div className={classes.dataGridWrapper}>
        <DataGrid
          isRowSelectable={() => false}
          rows={items}
          columns={columns}
          classes={{ root: classes.gridRoot }}
          components={{
            Toolbar: CustomDataGridToolbar,
            Footer: CustomFooter,
          }}
          componentsProps={{
            footer: { totalBalance: totalBalance },
          }}
          localeText={{
            toolbarColumns: t('data_grid.columns'),
            toolbarFilters: t('data_grid.filters'),
            toolbarDensity: t('data_grid.density'),
            filterPanelColumns: t('data_grid.columns'),
            filterPanelOperators: t('data_grid.operators'),
            columnsPanelTextFieldLabel: t('data_grid.find_column'),
            columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
            columnsPanelDragIconLabel: t('data_grid.reorder_column'),
            columnsPanelShowAllButton: t('data_grid.show_all'),
            columnsPanelHideAllButton: t('data_grid.hide_all'),
            toolbarDensityCompact: t('data_grid.density_compact'),
            toolbarDensityStandard: t('data_grid.density_standard'),
            toolbarDensityComfortable: t('data_grid.density_comfortable'),
            filterOperatorContains: t('data_grid.contains'),
            filterOperatorEquals: t('data_grid.equals'),
            filterOperatorStartsWith: t('data_grid.startsWith'),
            filterOperatorEndsWith: t('data_grid.endsWith'),
            filterOperatorIsEmpty: t('data_grid.isEmpty'),
            filterOperatorIsNotEmpty: t('data_grid.isNotEmpty'),
            filterOperatorIsAnyOf: t('data_grid.isAnyOf'),
            filterOperatorIs: t('data_grid.is'),
            filterOperatorNot: t('data_grid.isNot'),
            noRowsLabel: t('data_grid.no_rows'),
          }}
          loading={isLoading}
        />
      </div>
    );
  }
}

const styles = (theme) =>
  createStyles({
    root: {},
    dataGridWrapper: {
      height: 500,
      width: '100%',
      backgroundColor: whiteColor,
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(0.5),
      },
    },
    cellAction: {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
    },
    gridRoot: {
      border: 'none',
      '& .MuiDataGrid-cell': {
        whiteSpace: 'pre-wrap',
        maxHeight: 'none !important',
        outline: 'none !important',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    couponName: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  });

const mapStateToProps = (store: IStore) => ({
  profile: store.appState.profile,
  coupons: store.appState.coupons,
  isLoading: SummaryActions.listAccountCouponsSummary.isPending(store),
});

const mapDispatchToProps = (dispatch): DispProps => ({
  listAccountCouponsSummary: (args: SummaryActions.QueryListAccountCouponsSummaryArgs) =>
    dispatch(SummaryActions.listAccountCouponsSummary(args)),
});

export default withStyles(styles)(
  withTranslation(['admin', 'common'])(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountDetailCoupons)),
  ),
);

type Item = {
  id: string;
  createAt: string;
  name: string;
  expiredAt: string;
  balance: number;
};

enum DataGridFieldEnum {
  NAME = 'name',
  NETWORK_UUID = 'uuid',
  BALANCE = 'balance',
  ACTION = 'action',
  CREATED_AT = 'createAt',
  EXPIRED_AT = 'expiredAt',
}
