import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
// React i18next
import { withTranslation, WithTranslation } from 'react-i18next';
// Component
import Header from './Header';
import Footer from './Footer';

interface IProps {}

interface IState {}

class Layout extends React.Component<IProps & WithStyles<typeof styles> & WithTranslation, IState> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    const { classes, t, children } = this.props;
    return (
      <div className={classes.root}>
        <Header />
        {children}
        <Footer />
      </div>
    );
  }
}

const styles = createStyles({
  root: {},
});

export default withStyles(styles)(withTranslation()(Layout));
