import React, { useEffect } from 'react';
import { useAdminProfile } from './with-admin-profile';
import { AdminRoleEnum } from '~/gapi/gtypes';
import PageNotPermission from '~/components/NotPermision';

export const withAuthorization = (allowedRoles: Array<AdminRoleEnum>) => (WrappedComponent) => {
  const WithAuthorizationHOC = (props) => {
    const admin = useAdminProfile();
    if (admin && allowedRoles.includes(admin.role)) {
      return <WrappedComponent {...props} />;
    } else {
      return <PageNotPermission />;
    }
  };

  WithAuthorizationHOC.displayName = 'WithAuthorizationHOC';

  return WithAuthorizationHOC;
};
