import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import CustomDialog from '../../../custom-dialog';
import CustomDialogTitle from '../../../custom-dialog-title';
import CustomDialogContent from '../../../custom-dialog-content';
import CustomDialogActions from '../../../custom-dialog-actions';
import CustomSelect from '~/components/common/custom-select';
import CustomInput from '../../../custom-input';
import SubmitButton from '../../../custom-submit-button';
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  denimColor,
  dimGrayColor,
  romanColor,
  whiteColor,
  whiteSmokeColor,
} from '~/styles/themes/common-styles/color';

import { IStore } from '~/stores/configure-store';
import * as ProfileActions from '~/stores/actions/profile-action';
import { IAdminProfile } from '~/types/admin-types';
import { MAX_PROFILE_NAME_LENGTH } from '~/constants/validation';

import { timezoneOptions } from '~/utilities/moment-utils';
import { LOCALE_SUPPORTED } from '~/constants/common';

interface IStateProps {}

interface IDispProps {
  updateAdminProfile: (
    args: ProfileActions.MutationUpdateAdminProfileArgs,
  ) => Promise<ProfileActions.UPDATE_ADMIN_PROFILE_RESULT_TYPE>;
}

interface IProps
  extends IStateProps,
    IDispProps,
    RouteComponentProps<{}>,
    WithTranslation,
    WithStyles<typeof styles> {
  profile: IAdminProfile;
  open: boolean;
  onClose: () => void;
}

interface IState {}

class ProfileSettingDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  private validateSchema = Yup.object().shape<FormValues>({
    name: Yup.string()
      .trim()
      .required(this.props.t('required_field'))
      .max(
        MAX_PROFILE_NAME_LENGTH,
        this.props.t('too_many_character_of_user_name_error_message', {
          value: MAX_PROFILE_NAME_LENGTH,
        }),
      ),
    language: Yup.string().required(this.props.t('required_field')),
    timezone: Yup.string().required(this.props.t('required_field')),
  });

  render() {
    const { classes, open, onClose, t, profile } = this.props;
    const editProfileInitialValues: FormValues = {
      name: profile.name,
      language: profile.preference.language,
      timezone: profile.preference.timezone,
    };

    return (
      <CustomDialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
        <CustomDialogTitle classes={{ root: classes.dialogTitleRoot }}>
          <div>
            <img src="/images/icons/setting_ico.svg" className={classes.settingIcon} />
            {t('profile_settings')}
          </div>
        </CustomDialogTitle>
        <Formik
          initialValues={editProfileInitialValues}
          validationSchema={this.validateSchema}
          enableReinitialize
          onSubmit={this.onUpdateProfileSubmit}
          render={({ isValid, isSubmitting }) => (
            <Form>
              <>
                <CustomDialogContent classes={{ root: classes.dialogContentRoot }}>
                  <div className={classes.avatar}>
                    <img
                      src={profile.photoUrl || '/images/icons/avatar_ico.png'}
                      className={classes.avatarIcon}
                    />
                  </div>
                  <div className={classes.fields}>
                    <div>
                      <Field name="name" render={this.displayNameField} />
                    </div>
                    <div className={classes.formSection}>
                      <Field name="language" render={this.languageField} />
                    </div>
                    <div className={classes.formSection}>
                      <Field name="timezone" render={this.notificationTimezone} />
                    </div>
                  </div>
                </CustomDialogContent>
                <CustomDialogActions
                  classes={{
                    root: classes.dialogActionsRoot,
                    spacing: classes.dialogActionsAction,
                  }}
                >
                  <div className={classes.btnArea}>
                    <Button
                      data-testid="cancel-button"
                      disabled={isSubmitting}
                      className={classes.leftBtn}
                      variant="contained"
                      onClick={onClose}
                    >
                      {this.props.t('cancel')}
                    </Button>
                    <SubmitButton
                      data-testid="update-button"
                      id="admin-aws-accounts-add-submit"
                      type="submit"
                      isLoading={isSubmitting}
                      label={t('update')}
                      submittingLabel={t('updating')}
                      disabled={!isValid || isSubmitting}
                    />
                  </div>
                </CustomDialogActions>
              </>
            </Form>
          )}
        />
      </CustomDialog>
    );
  }

  private displayNameField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('display_name')}</div>
          {!!form.errors.name && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.name}
            </div>
          )}
        </div>
        <div>
          <CustomInput
            {...field}
            data-testid="display-name-input"
            placeholder={this.props.t('input_display_name')}
          />
        </div>
      </>
    );
  };

  private languageField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes } = this.props;
    const langSelection = LOCALE_SUPPORTED.map((l) => ({ value: l.lang, label: l.name }));

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('language')}</div>
          {!!form.errors.language && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.language}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            data-testid="language-select"
            valueSelected={field.value}
            placeholder={this.props.t('select_language')}
            items={langSelection}
          />
        </div>
      </>
    );
  };

  private notificationTimezone = ({ field, form }: FieldProps<FormValues>) => {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{this.props.t('timezone')}</div>
          {!!form.errors.timezone && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.timezone}
            </div>
          )}
        </div>
        <div>
          <CustomSelect
            {...field}
            data-testid="timezone-select"
            valueSelected={field.value}
            placeholder={this.props.t('select_timezone')}
            items={timezoneOptions}
          />
        </div>
      </>
    );
  };

  private onUpdateProfileSubmit = async (
    values: FormValues,
    formikActions: FormikActions<FormValues>,
  ) => {
    const { updateAdminProfile } = this.props;
    const { setSubmitting } = formikActions;
    setSubmitting(true);
    try {
      await updateAdminProfile({
        input: {
          name: values.name.trim(),
          language: values.language,
          timezone: values.timezone,
        },
      });
    } catch (error) {
      // Do nothing
    }
    setSubmitting(false);
  };
}

const styles = createStyles({
  root: {},
  // dialog
  dialogPaper: {},
  dialogTitleRoot: {
    textAlign: 'left',
  },
  dialogContentRoot: {
    paddingTop: '10px !important',
    display: 'flex',
    minWidth: 700,
  },
  dialogActionsRoot: {
    textAlign: 'right',
  },
  dialogActionsAction: {
    margin: 0,
  },
  // content
  avatar: {
    marginRight: 50,
  },
  fields: {
    flex: 1,
  },
  settingIcon: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
  formControlLabel: {
    margin: 0,
  },
  formSection: {
    marginTop: 15,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
  },
  formError: {
    color: romanColor,
  },
  avatarIcon: {
    width: 120,
    height: 120,
    borderRadius: 999,
  },
  // submit button
  btnArea: {
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

const mapStateToProps = (store: IStore) => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({
  updateAdminProfile: (args: ProfileActions.MutationUpdateAdminProfileArgs) =>
    dispatch(ProfileActions.updateAdminProfile(args)),
});

export default withStyles(styles)(
  withTranslation('admin')(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileSettingDialog)),
  ),
);

type FormValues = {
  name: string;
  language: string;
  timezone: string;
};
