import React from 'react';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';

import {
  persianGreenColor,
  dimGrayColor,
  romanColor,
  silverColor,
  sunglow,
  whiteColor,
} from '~/styles/themes/common-styles/color';

import CircleIcon from '~/components/common/circle-icon';
import { INodeDisplay } from '~/types/network-types';

interface IProps {
  className?: string;
  circleClassName?: string;
  onClick?: any;
  status: INodeDisplay;
}

const nodeStatusIcon: React.StatelessComponent<IProps & WithStyles<typeof styles>> = ({
  className,
  circleClassName,
  status,
  classes,
}) => {
  const rootClasses = classNames(
    {
      [classes.root]: true,
      [classes.persianGreenBackgroundColor]: status === 'running',
      [classes.romanBackgroundColor]: status === 'stopped' || status === 'error',
      [classes.whiteBackgroundColor]: status === 'pending' || status === 'removing',
      [classes.dimGrayBackgroundColor]: status === 'unknown',
      [classes.sunglowBackgroundColor]: status === 'x_warn_disk',
    },
    className,
  );

  return (
    <>
      {status !== 'pending' && status !== 'removing' ? (
        <CircleIcon className={rootClasses} />
      ) : (
        <>
          <div className={classNames(classes.loadingIconBlock, circleClassName, className)}>
            <CircularProgress size={14} className={classes.loadingIcon} />
          </div>
        </>
      )}
    </>
  );
};

const styles: StyleRules = {
  root: {},
  persianGreenBackgroundColor: {
    backgroundColor: persianGreenColor,
  },
  romanBackgroundColor: {
    backgroundColor: romanColor,
  },
  whiteBackgroundColor: {
    backgroundColor: whiteColor,
  },
  sunglowBackgroundColor: {
    backgroundColor: sunglow,
  },
  dimGrayBackgroundColor: {
    backgroundColor: dimGrayColor,
  },
  loadingIconBlock: {
    display: 'inline-block',
    marginLeft: -3,
  },
  loadingIcon: {
    color: silverColor,
  },
};

export default withStyles(styles)(nodeStatusIcon);
