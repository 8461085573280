import { HARD_FORK_CONFIGURATION } from '~/constants/common';
import { GenesisConfig } from '~/gapi/gtypes';
import { INetwork } from '~/types/network-types';

const bcp47 = require('bcp-47');

export const isClientSide = () => {
  return !!(typeof window !== 'undefined' && window.document && window.document.createElement);
};

export const getHostFromClient = () => {
  if (isClientSide()) {
    return `${location.protocol}//${location.host}`;
  }

  return '';
};

export const copyTextToClipboard = (text: string) => {
  const el = document.createElement('input');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'fixed';
  el.style.left = '-99999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

interface ParsedBCP47String {
  language: string | null; // ISO 639 language code
  extendedLanguageSubtags: Array<string>; // Selected three-character ISO 639 codes
  script: string | null; // Four character ISO 15924 script code
  region: string | null; // Two alphabetical character ISO 3166-1 code, or three digit UN M.49 code
  variants: Array<string>; // 5 to 8 (inclusive) character language variants
  extensions: Array<any>;
  privateuse: Array<string>; // List of private-use subtags
  irregular: string | null; // One of the irregular tags
  regular: string | null; // One of the regular tags
}

/**
 * Parse BCP 47 string tags.
 * Visit https://github.com/wooorm/bcp-47 to get more detail
 * @param {String} language
 * @returns {NavigatorLanguage}
 */
export const parseBCP47String = (bcp47Str: string): ParsedBCP47String => {
  return bcp47.parse(bcp47Str);
};

export interface Geolocation {
  ip: string;
  version: string;
  timezone: string;
}

export interface Address {
  pref: string;
  city: string;
  town: string;
}

export const getRandomInt = (min: number = 0, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const ellipsifyText = (text: string, first = 8, last = 8) =>
  `${text.slice(0, first)}...${text.slice(-last)}`;

export const getLatestHardForkByGenesis = (
  genesisConfig: GenesisConfig,
): { label: string; blockNumber: number } | undefined => {
  const hardForkOderList = HARD_FORK_CONFIGURATION.sort((a, b) => b.order - a.order);
  for (const item of hardForkOderList) {
    if (genesisConfig[item.nameInGenesis] != null) {
      return {
        label: item.label,
        blockNumber: genesisConfig[item.nameInGenesis],
      };
    }
  }
};
