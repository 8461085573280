import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { whiteColor } from '~/styles/themes/common-styles/color';
import { ROWS_PER_PAGE_OPTIONS } from '~/constants/common';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { GridCellParams, GridColumns } from '@mui/x-data-grid';
import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
import { Proposal, QueryListProposalHistoryArgs, SignerStatusChange } from '~/gapi/gtypes';
import { INetworkSummary } from '~/types/admin-types';
import { NETWORK_DETAIL_DIALOG_POLLING_TIMEOUT } from '~/constants/polling';
import Tooltip from '@mui/material/Tooltip';
import { ellipsifyText } from '~/utilities/utils';
import CustomToolbar from './custom-toolbar';

interface IProps extends WithStyles<typeof styles> {
  summary: INetworkSummary;
}

const NetworkDetailProposalHistory = (props: IProps) => {
  const { classes, summary } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation('admin');
  const listProposalHistory = useSelector((store: IStore) => store.appState.listProposalHistory);
  const isFetchProposalHistory = useSelector((store: IStore) =>
    NetworkActions.listProposalHistory.isPending(store),
  );
  const [listRenewing, setListRenewing] = useState(false);
  const { pageIndex, totalItems, pageSize } = listProposalHistory.pageInfo;

  const columns: GridColumns = [
    {
      field: DataGridFieldEnum.BLOCK_NUMBER,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      headerName: t('block'),
      width: 200,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: DataGridFieldEnum.ACTIVITY,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      headerName: t('activity'),
      width: 750,
      renderCell: (params: GridCellParams) => {
        const isEpochBlock = params.row.isEpochBlock;
        const proposals = params.row.proposals;
        const signerStatusChanges = params.row.signerStatusChanges;
        return (
          <div className={classes.cellActivity}>
            {isEpochBlock ? (
              <div>- &nbsp;{t('all_proposals_reset')}</div>
            ) : (
              <>
                {renderProposals(proposals)}
                {renderSignerStatusChanges(signerStatusChanges)}
              </>
            )}
          </div>
        );
      },
    },
  ];

  const items: Array<Item> = (listProposalHistory.proposalHistory || []).map((proposal) => ({
    id: proposal.blockNumber,
    blockNumber: proposal.blockNumber,
    isEpochBlock: proposal.isEpochBlock,
    proposals: proposal.proposals,
    signerStatusChanges: proposal.signerStatusChanges,
  }));

  const renderSignerStatusChanges = useCallback(
    (signerStatusChanges: SignerStatusChange[]) => {
      return signerStatusChanges.map(({ address, authorize }) => {
        return (
          <div style={{ display: 'flex' }} key={address}>
            - &nbsp;
            <Tooltip title={address} classes={{ tooltip: classes.tooltip }}>
              <div>{ellipsifyText(address, 10, 10)}</div>
            </Tooltip>
            &nbsp;become a&nbsp;
            <div>
              {authorize ? (
                <div>
                  <b>{t('validator')}</b> {t('node')}
                </div>
              ) : (
                <div>
                  <b>{t('relay')}</b> {t('node')}
                </div>
              )}
            </div>
          </div>
        );
      });
    },
    [classes, t],
  );

  const renderProposals = useCallback(
    (proposals: Proposal[]) => {
      return proposals.map(({ from, to, authorize }, index) => {
        return (
          <div style={{ display: 'flex' }} key={index}>
            - &nbsp;
            <Tooltip title={from} classes={{ tooltip: classes.tooltip }}>
              <div>{ellipsifyText(from, 10, 10)}</div>
            </Tooltip>
            &nbsp;propose&nbsp;
            <Tooltip title={to} classes={{ tooltip: classes.tooltip }}>
              <div>{ellipsifyText(to, 10, 10)}</div>
            </Tooltip>
            &nbsp;to&nbsp;
            <div>
              {authorize ? (
                <div>
                  <b>{t('validator')}</b> {t('node')}
                </div>
              ) : (
                <div>
                  <b>{t('relay')}</b> {t('node')}
                </div>
              )}
            </div>
          </div>
        );
      });
    },
    [classes, t],
  );

  const fetchProposalHistory = useCallback(
    async (params: Partial<QueryListProposalHistoryArgs> = {}) => {
      await dispatch(
        NetworkActions.listProposalHistory({
          networkUuid: summary.network.networkUuid,
          ...params,
        }),
      ).catch(() => {});
    },
    [dispatch, summary.network.networkUuid],
  );

  const onPageSizeChange = useCallback(
    (pageSize) => {
      fetchProposalHistory({
        page: { pageIndex: 0, pageSize },
      });
    },
    [fetchProposalHistory],
  );

  const onPageChange = useCallback(
    (pageIndex) => {
      fetchProposalHistory({
        page: { pageIndex },
      });
    },
    [fetchProposalHistory],
  );

  const onFilterPage = useCallback(
    (filter) => {
      fetchProposalHistory({
        filter,
        page: {
          pageIndex: 0,
        },
      });
    },
    [fetchProposalHistory],
  );

  useEffect(() => {
    fetchProposalHistory({ page: { pageIndex } });
    const interval = setInterval(async () => {
      setListRenewing(true);
      await fetchProposalHistory();
      setListRenewing(false);
    }, NETWORK_DETAIL_DIALOG_POLLING_TIMEOUT);
    return () => clearInterval(interval);
  }, [fetchProposalHistory, pageIndex]);

  return (
    <div className={classes.dataGridWrapper}>
      <DataGrid
        isRowSelectable={() => false}
        rows={items}
        columns={columns}
        classes={{ root: classes.gridRoot }}
        componentsProps={{
          toolbar: {
            listProposalHistory: listProposalHistory,
            onFilterPage: onFilterPage,
          },
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        localeText={{
          toolbarColumns: t('data_grid.columns'),
          toolbarFilters: t('data_grid.filters'),
          toolbarDensity: t('data_grid.density'),
          filterPanelColumns: t('data_grid.columns'),
          filterPanelOperators: t('data_grid.operators'),
          columnsPanelTextFieldLabel: t('data_grid.find_column'),
          columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
          columnsPanelDragIconLabel: t('data_grid.reorder_column'),
          columnsPanelShowAllButton: t('data_grid.show_all'),
          columnsPanelHideAllButton: t('data_grid.hide_all'),
          toolbarDensityCompact: t('data_grid.density_compact'),
          toolbarDensityStandard: t('data_grid.density_standard'),
          toolbarDensityComfortable: t('data_grid.density_comfortable'),
          filterOperatorContains: t('data_grid.contains'),
          filterOperatorEquals: t('data_grid.equals'),
          filterOperatorStartsWith: t('data_grid.startsWith'),
          filterOperatorEndsWith: t('data_grid.endsWith'),
          filterOperatorIsEmpty: t('data_grid.isEmpty'),
          filterOperatorIsNotEmpty: t('data_grid.isNotEmpty'),
          filterOperatorIsAnyOf: t('data_grid.isAnyOf'),
          filterOperatorIs: t('data_grid.is'),
          filterOperatorNot: t('data_grid.isNot'),
          noRowsLabel: t('data_grid.no_rows'),
        }}
        loading={!listRenewing && isFetchProposalHistory}
        getRowHeight={() => 'auto'}
        paginationMode="server"
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        rowCount={totalItems || 0}
        pageSize={pageSize}
        page={pageIndex}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  );
};

const styles = createStyles({
  dataGridWrapper: {
    height: 500,
    width: '100%',
    backgroundColor: whiteColor,
  },
  gridRoot: {
    borderRadius: 0,
    '& .MuiDataGrid-cell': {
      whiteSpace: 'pre-wrap',
      maxHeight: 'none !important',
      outline: 'none !important',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0,
    },
  },
  tooltip: {
    backgroundColor: 'rgb(20, 26, 31)',
  },
  cellActivity: {
    minHeight: 76,
    paddingTop: 8,
    paddingBottom: 8,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
});

enum DataGridFieldEnum {
  BLOCK_NUMBER = 'blockNumber',
  ACTIVITY = 'activity',
}
export interface Item {
  id: number;
  blockNumber: number;
  isEpochBlock: boolean;
  proposals: Proposal[];
  signerStatusChanges: SignerStatusChange[];
}

export default withStyles(styles)(NetworkDetailProposalHistory);
