import React, { useCallback } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as ProfileActions from '~/stores/actions/profile-action';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { IAdminProfile } from '~/types/admin-types';

interface IDispProps {
  updateAdminProfile: (
    args: ProfileActions.MutationUpdateAdminProfileArgs,
  ) => Promise<ProfileActions.UPDATE_ADMIN_PROFILE_RESULT_TYPE>;
}

interface IProps extends IDispProps, WithTranslation, WithStyles<typeof styles> {
  profile?: IAdminProfile;
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
}

const LanguageMenu: React.FC<IProps> = (props) => {
  const { classes, profile, open, onClose, t, anchorEl, updateAdminProfile } = props;
  const onUpdateLanguage = useCallback(
    async (language) => {
      try {
        await updateAdminProfile({
          input: {
            language: language,
          },
        });
        onClose();
      } catch (error) {
        // Do nothing
      }
    },
    [updateAdminProfile, onClose],
  );

  return (
    <Menu
      data-testid="language-menu-list"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      classes={{ paper: classes.paper }}
    >
      <MenuItem
        data-testid="language-en-item"
        selected={profile?.preference.language === 'en'}
        onClick={() => onUpdateLanguage('en')}
      >
        {t('english')}
      </MenuItem>
      <MenuItem
        data-testid="language-ja-item"
        selected={profile?.preference.language === 'ja'}
        onClick={() => onUpdateLanguage('ja')}
      >
        {t('japanese')}
      </MenuItem>
    </Menu>
  );
};

const styles = createStyles({
  paper: {
    width: 130,
  },
});

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({
  updateAdminProfile: (args: ProfileActions.MutationUpdateAdminProfileArgs) =>
    dispatch(ProfileActions.updateAdminProfile(args)),
});

export default withStyles(styles)(
  withTranslation('admin')(connect(mapStateToProps, mapDispatchToProps)(LanguageMenu)),
);
