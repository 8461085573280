import React from 'react';
// Redux
import { connect } from 'react-redux';
import * as NetworkActions from '~/stores/actions/network-action';
// Component
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import JSONPretty from 'react-json-pretty';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { whiteColor } from '~/styles/themes/common-styles/color';
import { defaultFont } from '~/styles/themes/common-styles/font';
// Type
import { INetworkSummary } from '~/types/admin-types';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';

import { commandSelection } from '~/types/network-selection';
import { SelectChangeEvent } from '@mui/material/Select';
import SubmitButton from '~/components/common/custom-submit-button';
import CustomSelectWithLabel from '~/components/common/custom-select-with-label';

interface IItem {
  label: string;
  value: string;
}

interface IStateProps {}

interface IDispProps {
  getSigners: (
    args: NetworkActions.QueryGetSignersArgs,
  ) => Promise<NetworkActions.ADMIN_GET_SIGNERS_RESULT_TYPE>;
  getProposalStatus: (
    args: NetworkActions.QueryGetProposalStatusArgs,
  ) => Promise<NetworkActions.ADMIN_GET_PROPOSAL_STATUS_RESULT_TYPE>;
}

interface IProps extends IStateProps, IDispProps, WithStyles<typeof styles>, WithTranslation {
  summary: INetworkSummary;
}

interface IState {
  nodesList: IItem[];
  nodeSelected?: string;
  command?: string;
  isGettingSigners: boolean;
  getProposalStatusResult?: string;
  isGettingProposalStatus: boolean;
  getSignersResult?: string;
}

class NetworkDetailDebug extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      nodesList: [],
      command: commandSelection[0].value,
      isGettingSigners: false,
      isGettingProposalStatus: false,
    };
  }

  public componentDidMount() {
    const { summary } = this.props;
    const nodes = summary.network.clusters
      .map((c) => c.nodes || [])
      .reduce((pre, cur) => pre.concat(cur), []);

    const nodesList = nodes.map((i) => ({
      label: i.nodeName,
      value: i.nodeUuid,
    }));
    this.setState({
      nodesList,
      nodeSelected: nodes[0].nodeUuid,
    });
  }

  public render() {
    const { classes, t } = this.props;
    const {
      getSignersResult,
      getProposalStatusResult,
      isGettingSigners,
      isGettingProposalStatus,
      nodesList,
      nodeSelected,
      command,
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.topArea}>
          <div className={classes.selectArea}>
            <CustomSelectWithLabel
              classes={{ root: classes.customSelect, menuListItem: classes.menuListItem }}
              label={t('nodes')}
              onChange={this.onChangeNodeUuid}
              items={nodesList}
              valueSelected={nodeSelected || ''}
              input
              data-testid="change-node-button"
            />
            <CustomSelectWithLabel
              classes={{ root: classes.customSelect, menuListItem: classes.menuListItem }}
              label={t('command')}
              onChange={this.onChangeCommand}
              items={commandSelection}
              valueSelected={command || ''}
              input
              data-testid="change-command-button"
            />
            <SubmitButton
              id="debug-submit"
              isLoading={isGettingSigners || isGettingProposalStatus}
              label={t('run')}
              submittingLabel={t('running')}
              disabled={isGettingSigners || isGettingProposalStatus}
              onClick={this.onGetDebugClick}
              data-testid="get-debug-button"
            />
          </div>
          <div className={classes.center}>
            <div className={classes.label}>{t('download_the_full_log')}</div>
            <IconButton className={classes.downloadBtn}>
              <Icon>get_app</Icon>
            </IconButton>
          </div>
        </div>
        <div>
          {getProposalStatusResult && (
            <div className={classes.debugResult}>
              {
                <JSONPretty
                  style={{ color: '#ffffff', overflowX: 'auto', padding: '10px' }}
                  data={JSON.parse(getProposalStatusResult)}
                />
              }
            </div>
          )}
          {getSignersResult && (
            <div className={classes.debugResult}>
              {
                <JSONPretty
                  style={{ color: '#ffffff', overflowX: 'auto', padding: '10px' }}
                  data={JSON.parse(getSignersResult)}
                />
              }
            </div>
          )}
        </div>
      </div>
    );
  }

  private onGetDebugClick = async () => {
    const { getSigners, getProposalStatus, summary } = this.props;
    const { nodeSelected, command } = this.state;

    if (command === 'get_validator') {
      this.setState({
        isGettingSigners: true,
      });
      const getSignersResult = (
        await getSigners({ networkUuid: summary.network.networkUuid, nodeUuid: nodeSelected || '' })
      ).getSigners;
      this.setState({
        getSignersResult,
        isGettingSigners: false,
        getProposalStatusResult: '',
      });
    }

    if (command === 'get_proposal') {
      this.setState({
        isGettingProposalStatus: true,
      });
      const getProposalStatusResult = (
        await getProposalStatus({
          networkUuid: summary.network.networkUuid,
          nodeUuid: nodeSelected || '',
        })
      ).getProposalStatus;
      this.setState({
        getProposalStatusResult,
        isGettingProposalStatus: false,
        getSignersResult: '',
      });
    }
  };

  private onChangeNodeUuid = (event: SelectChangeEvent) => {
    this.setState({
      nodeSelected: event.target.value,
      getProposalStatusResult: '',
      getSignersResult: '',
    });
  };

  private onChangeCommand = (event: SelectChangeEvent) => {
    this.setState({
      command: event.target.value,
    });
  };
}

const styles = createStyles({
  root: {
    padding: '10px 20px',
  },
  topArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  runBtn: {
    width: 'unset',
    textTransform: 'uppercase',
  },
  selectArea: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  customSelect: {
    marginRight: 20,
    '& .MuiInputBase-root': {
      fontWeight: 300,
      letterSpacing: 'normal',
    },
  },
  menuListItem: {
    padding: '11px 16px',
  },
  label: {
    ...defaultFont,
    background: '#707070',
    color: whiteColor,
    padding: '4px 6px',
    fontSize: 10,
    borderRadius: 4,
  },
  center: {
    textAlign: 'center',
  },
  downloadBtn: {
    marginTop: 4,
    padding: 10,
  },
  debugResult: {
    marginTop: 10,
    backgroundColor: '#272823',
  },
});

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({
  getSigners: (args: NetworkActions.QueryGetSignersArgs) =>
    dispatch(NetworkActions.getSigners(args)),
  getProposalStatus: (args: NetworkActions.QueryGetProposalStatusArgs) =>
    dispatch(NetworkActions.getProposalStatus(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation('admin')(NetworkDetailDebug)),
);
