import React, { useCallback } from 'react';
import moment from 'moment-timezone';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { withTranslation, WithTranslation } from 'react-i18next';
import CustomDatePicker from './custom-date-picker';
import { lightSlateGreyColor } from '~/styles/themes/common-styles/color';

export interface DateRangeParams {
  startDate: Date;
  endDate: Date;
}

export interface CustomDateRangeProps {
  label?: string;
  value?: DateRangeParams;
  minEndDate?: Date;
  inputStartDate?: string;
  inputEndDate?: string;
  onChange: (range: DateRangeParams) => void;
}

interface IProps extends CustomDateRangeProps, WithTranslation, WithStyles<typeof styles> {}

const defaultDateValue = {
  startDate: moment().startOf('month').utc().toDate(),
  endDate: moment().endOf('month').utc().toDate(),
};

const CustomDateRange: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    classes,
    t,
    value = defaultDateValue,
    label,
    inputStartDate,
    inputEndDate,
    onChange,
  } = props;

  const onStartDateChange = useCallback(
    (date: Date) => {
      let startDate = date;
      if (moment(date).isAfter(value.endDate)) {
        startDate = value.endDate;
      }
      onChange({ ...value, startDate });
    },
    [onChange, value],
  );

  const onEndDateChange = useCallback(
    (date: Date) => {
      let endDate = date;
      if (moment(endDate).isBefore(value.startDate)) {
        endDate = value.startDate;
      }
      onChange({ ...value, endDate });
    },
    [onChange, value],
  );

  return (
    <div className={classes.datePickerArea}>
      <Typography className={classes.label}>{label || ''}</Typography>
      <div className={classes.startDatePicker}>
        <CustomDatePicker
          data-testid="start-date"
          value={new Date(value.startDate)}
          onChange={onStartDateChange}
          inputFormat={t('date_format')}
          inputText={inputStartDate}
        />
      </div>
      <div className={classes.datePickerSeparator}>~</div>
      <div className={classes.endDatePicker}>
        <CustomDatePicker
          data-testid="end-date"
          value={new Date(value.endDate)}
          onChange={onEndDateChange}
          inputFormat={t('date_format')}
          inputText={inputEndDate}
        />
      </div>
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    datePickerArea: {
      display: 'flex',
      alignItems: 'center',
    },
    label: {
      marginRight: 10,
      fontSize: '14px',
    },
    startDatePicker: {
      display: 'flex',
      alignItems: 'center',
    },
    endDatePicker: {
      display: 'flex',
      alignItems: 'center',
    },
    datePickerSeparator: {
      marginLeft: 10,
      marginRight: 10,
      fontSize: 13,
      color: lightSlateGreyColor,
    },
  });

export default withStyles(styles)(withTranslation('admin')(CustomDateRange));
