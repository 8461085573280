import React, { useCallback, useEffect, useState } from 'react';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CardActionArea from '@mui/material/CardActionArea';

interface IProps extends WithStyles<typeof styles> {
  title: string;
  content?: string | number;
  loading: boolean;
  link?: string;
}

const DashboardSummary = (props: IProps) => {
  const { classes, loading, title, content, link = '' } = props;

  return (
    <Card className={classes.root}>
      <Link id="dashboard-summary-box" to={link}>
        <CardActionArea>
          <CardContent>
            <>
              <Typography
                gutterBottom
                variant="subtitle1"
                fontWeight={500}
                component="div"
                align="center"
              >
                {title}
              </Typography>
              {loading ? (
                <Skeleton animation="wave" height={15} width="80%" />
              ) : (
                <Typography
                  variant="body2"
                  color="#1D79C4"
                  align="center"
                  fontWeight={400}
                  fontSize={'2rem'}
                >
                  {content ?? '-'}
                </Typography>
              )}
            </>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
};

const styles = createStyles({
  root: {},
});

export default withStyles(styles)(DashboardSummary);
