import React from 'react';
import firebase from 'firebase';
import { getI18n, WithTranslation, withTranslation } from 'react-i18next';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { lightSlateGreyColor, denimColor } from '~/styles/themes/common-styles/color';
import { defaultFontBold, defaultFont } from '~/styles/themes/common-styles/font';
import FirebaseAuthLocalized from './firebase-auth-localized';

interface IProps {}

interface IState {}

class FirebaseAdminIUI extends React.Component<
  IProps & WithTranslation & WithStyles<typeof styles>,
  IState
> {
  private uiConfig;
  private i18n;

  constructor(props) {
    super(props);

    this.i18n = getI18n();

    // move from componentWillMount
    firebase.auth().useDeviceLanguage();
    this.uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl): boolean => false,
      },
      signInFlow: 'popup',
      credentialHelper: 'none',
      // signInSuccessUrl: '/fapi/profile',
      signInOptions: [
        // firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
    };
  }

  render() {
    const { classes, t } = this.props;
    return (
      <div>
        <div className={classes.block}>
          <div className={classes.pleaseSignIn}>{t('admin_sign_in')}</div>
          <div className={classes.blockchain}>G.U. Blockchain Admin</div>
        </div>
        <div key={this.i18n.language} className={classes.firebaseUI}>
          <FirebaseAuthLocalized
            config={this.uiConfig}
            auth={firebase.auth()}
            language={this.i18n.language}
          />
        </div>
      </div>
    );
  }
}
const styles = createStyles({
  block: {
    textAlign: 'center',
    marginBottom: 20,
  },
  pleaseSignIn: {
    ...defaultFont,
    fontSize: 18,
    color: lightSlateGreyColor,
  },
  blockchain: {
    ...defaultFontBold,
    color: lightSlateGreyColor,
    fontSize: 28,
  },
  logoutLink: {
    color: denimColor,
    cursor: 'pointer',
  },
  firebaseUI: {
    '& .firebaseui-idp-google': {
      maxWidth: 300,
      height: 40,
      borderRadius: 4,
      border: 'solid 1px #d8dfe5',
      backgroundColor: '#fafafa',
    },
    '& .firebaseui-idp-google > .firebaseui-idp-text': {
      fontSize: 0,
    },
    '& .firebaseui-idp-google > .firebaseui-idp-text::before': {
      ...defaultFontBold,
      content: '"Login with Google Account"',
      width: 193,
      height: 19,
      fontSize: 16,
      color: denimColor,
    },
    '& .mdl-button--raised': {
      boxShadow: 'none',
    },
  },
});

export default withStyles(styles)(withTranslation('common')(FirebaseAdminIUI));
