import { createTypeAsyncAction } from '@gu-corp/redux-async-lib';
import AdminGApiClient from './gapi/admin-gapi-client';
import * as ProfileQuery from './gapi/query/profile';

export * from './gapi/query/profile';

export const getAdminProfile = createTypeAsyncAction('GET_ADMIN_PROFILE', async () => {
  const client = new AdminGApiClient({});
  return client.getAdminProfile();
});

export const updateAdminProfile = createTypeAsyncAction(
  'UPDATE_ADMIN_PROFILE',
  async (args: ProfileQuery.MutationUpdateAdminProfileArgs) => {
    const client = new AdminGApiClient({});
    return client.updateAdminProfile(args);
  },
);
