import React, { useMemo } from 'react';
import _ from 'lodash';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import CustomDataGridToolbar from '~/components/common/custom-data-grid-toolbar';
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';
import { ROWS_PER_PAGE_OPTIONS, DATA_GRID_SORTING_ORDER } from '~/constants/common';
import ExplorerOption from './explorer-option';
import { whiteColor } from '~/styles/themes/common-styles/color';
import { IClusterUnit, INetworkSummary } from '~/types/admin-types';
import { displayBlockExplorerStatus } from '~/utilities/render-utils';
import NodeStatusIcon from '~/components/common/node-status-icon';
import { INodeDisplay } from '~/types/network-types';

interface IProps extends WithStyles<typeof styles> {
  summary: INetworkSummary;
}

const ExplorerTab = (props: IProps) => {
  const { classes, summary } = props;
  const { t } = useTranslation('admin');

  const columns: GridColumns = [
    {
      field: DataGridFieldEnum.CLUSTER_UUID,
      disableColumnMenu: true,
      filterable: true,
      sortable: false,
      headerName: t('cluster_uuid'),
      width: 200,
      renderCell: (params: GridCellParams) => {
        return <div className={classes.wrapText}>{params.value}</div>;
      },
    },
    {
      field: DataGridFieldEnum.CLUSTER_NAME,
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      headerName: t('cluster_name'),
      width: 200,
    },
    {
      field: DataGridFieldEnum.VERSION,
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      headerName: t('version'),
      width: 150,
    },
    {
      field: DataGridFieldEnum.INSTANCE_ID,
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      headerName: t('instance_id'),
      width: 200,
    },
    {
      field: DataGridFieldEnum.STATUS,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      headerName: t('status'),
      renderCell: (params: GridCellParams) => (
        <>
          <NodeStatusIcon
            classes={{ root: classes.nodeIcon }}
            status={(params.row as Item).status}
            className={classes.nodeStatusIcon}
          />
          <span>{(params.row as Item).status}</span>
        </>
      ),
      width: 150,
    },
    {
      field: DataGridFieldEnum.ACTION,
      sortable: false,
      filterable: false,
      headerName: t('action'),
      type: 'actions',
      disableColumnMenu: true,
      renderHeader: () => <></>,
      headerClassName: classes.cellAction,
      renderCell: (params: GridCellParams) => (
        <ExplorerOption cluster={(params.row as Item).cluster} network={summary.network} />
      ),
    },
  ];

  const items: Array<Item> = useMemo(() => {
    return summary.network.clusters
      .filter((c) => !!c.explorer)
      .map((cluster) => ({
        cluster,
        id: cluster.clusterUuid,
        clusterName: cluster.clusterName,
        clusterUuid: cluster.clusterUuid,
        explorerUuid: cluster.explorer?.explorerUuid,
        version: cluster.explorer?.blockscoutInfo.backend.version,
        status: displayBlockExplorerStatus(cluster),
        instanceId: cluster.explorer?.serverInfo.instanceId,
      }));
  }, [summary]);

  return (
    <div className={classes.dataGridWrapper}>
      <DataGrid
        rows={items}
        columns={columns}
        components={{
          Toolbar: CustomDataGridToolbar,
        }}
        classes={{ root: classes.gridRoot }}
        localeText={{
          toolbarColumns: t('data_grid.columns'),
          toolbarFilters: t('data_grid.filters'),
          toolbarDensity: t('data_grid.density'),
          filterPanelColumns: t('data_grid.columns'),
          filterPanelOperators: t('data_grid.operators'),
          columnsPanelTextFieldLabel: t('data_grid.find_column'),
          columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
          columnsPanelDragIconLabel: t('data_grid.reorder_column'),
          columnsPanelShowAllButton: t('data_grid.show_all'),
          columnsPanelHideAllButton: t('data_grid.hide_all'),
          toolbarDensityCompact: t('data_grid.density_compact'),
          toolbarDensityStandard: t('data_grid.density_standard'),
          toolbarDensityComfortable: t('data_grid.density_comfortable'),
          filterOperatorContains: t('data_grid.contains'),
          filterOperatorEquals: t('data_grid.equals'),
          filterOperatorStartsWith: t('data_grid.startsWith'),
          filterOperatorEndsWith: t('data_grid.endsWith'),
          filterOperatorIsEmpty: t('data_grid.isEmpty'),
          filterOperatorIsNotEmpty: t('data_grid.isNotEmpty'),
          filterOperatorIsAnyOf: t('data_grid.isAnyOf'),
          filterOperatorIs: t('data_grid.is'),
          filterOperatorNot: t('data_grid.isNot'),
          noRowsLabel: t('data_grid.no_rows'),
        }}
        isRowSelectable={() => false}
        checkboxSelection={false}
        paginationMode="server"
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        sortingOrder={DATA_GRID_SORTING_ORDER}
        disableSelectionOnClick
      />
    </div>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: 'auto',
      paddingRight: 0,
    },
    nodeIcon: {
      marginRight: 7,
    },
    nodeStatusIcon: {
      marginRight: 7,
      verticalAlign: 'middle',
    },
    dataGridWrapper: {
      height: 500,
      width: '100%',
      marginTop: 24,
      backgroundColor: whiteColor,
      [theme.breakpoints.up('sm')]: {
        marginTop: 32,
      },
      '& .MuiDataGrid-row': {
        cursor: 'pointer',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
    },
    cellAction: {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
    },
    gridRoot: {
      '& .MuiDataGrid-row': {
        maxHeight: 'none !important',
      },
      '& .MuiDataGrid-cell': {
        whiteSpace: 'pre-wrap',
        maxHeight: 'none !important',
        outline: 'none !important',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    topLine: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'end',
    },
    button: {
      margin: 0,
    },
    wrapText: {
      textWrap: 'auto',
    },
  });

export default withStyles(styles)(ExplorerTab);

export type Item = {
  id: string;
  clusterUuid: string;
  clusterName: string;
  status: INodeDisplay;
  cluster: IClusterUnit;
  explorerUuid?: string;
  version?: string;
  instanceId?: string;
};

enum DataGridFieldEnum {
  CLUSTER_NAME = 'clusterName',
  CLUSTER_UUID = 'clusterUuid',
  EXPLORER_UUID = 'explorerUuid',
  INSTANCE_ID = 'instanceId',
  VERSION = 'version',
  STATUS = 'status',
  ACTION = 'action',
}
