import * as Gtypes from '../../../../gapi/gtypes';

const summaryMonthQuery = `{
  billingId
  coupon
  invoicedDate
  canceledDate
  paidDate
  startDate
  endDate
  numDays
  amount
  taxFee
  taxRate
  licenses {
    licenseItemId
    licenseName
    size
    qty
    unitPrice
    subTotalPrice
  }
}`;

const billingQuery = `{
  startPeriod
  endPeriod
  details {
    id
    amount
    coupon
    tax
    receipt
    result
    invoicedDate
    canceledDate
    paidDate
    licenses {
      licenseItemId
      licenseName
      size
      qty
      unitPrice
      startDate
      endDate
      numDays
      amount
    }
  }
}`;

export const genesisConfigQuery = `{
  chainId
  homesteadBlock
  eip150Block
  eip150Hash
  eip155Block
  eip158Block
  byzantiumBlock
  constantinopleBlock
  petersburgBlock
  istanbulBlock
  muirGlacierBlock
  berlinBlock
  londonBlock
  clique {
    period
    epoch
  }
}`;

export const nodeQuery = `{
  nodeUuid
  nodeName
  nodeType
  nodeInfo {
    program
    version
    signer
    signerStatus
    enode
    status
    coinbasePrivateKey
    coinbaseAddress
    peerCount
    gasPrice
    balanceEth
    balanceWei
    blockGasLimit
    latestBlock
  }
  serverType
  serverInfo {
    az
    ip
    status
    instanceId
    instanceImage
    instanceType
    publicDns
    volumeSize
    volumeUsed
  }
  signerInfo {
    externalClef {
      username
      host
      port
      status
    }
  }
}`;

export const explorerQuery = `{
  explorerUuid
  serverInfo {
    az
    ip
    status
    instanceId
    instanceImage
    instanceType
    explorerType
    endpointUrl
    volumeSize
    volumeUsed
  }
  blockscoutInfo {
    rpcUrl
    backend {
      version
    }
  }
}`;

export const clusterQuery = `{
  accountUuid
  accountName
  clusterUuid
  clusterName
  clusterType
  endpointId
  endpointUrl
  provider
  region
  status
  nodes ${nodeQuery}
  explorer ${explorerQuery}
  listeners {
    type
    port
    inbound {
      cidr
      desc
    }
  }
}`;

export const networkQuery = `{
  networkId
  networkUuid
  networkName
  networkDescription
  clusters ${clusterQuery}
  blockchainInfo {
    networkId
    chainId
    blockInterval
    blockGasLimit
    allocAddress
    allocBalance
    signerAddresses
    defaultGasPrice
    currencyName
    currencySymbol
    genesisConfig ${genesisConfigQuery}
    epochBlock
  }
}`;

const accountCouponsQuery = `{
  startPeriod
  endPeriod
  details {
    id
    name
    code
    amount
    balance
    createAt
    deleteAt
    expiredAt
  }
}`;

const proposalQuery = `{
  address
  targetNodeUuid
  targetNodeName
  accountUuidOwnTargetNode
  accountNameOwnTargetNode
  isValidator
  approvedAddresses
  signerAddresses
  status
}`;

export const listSalesSummaryQuery = `query ($startDate: String, $endDate: String) {
  listSalesSummary(startDate: $startDate, endDate: $endDate) {
    licenses {
      licenseItemId
      licenseName
      size
      qty
      unitPrice
      subTotalPrice
    }
    totalPrice
    totalCoupon
    totalTax
  }
}`;

export const summaryAdminQuery = `{
  startPeriod
  endPeriod
  details {
    id
    amount
    coupon
    tax
    receipt
    result
    invoicedDate
    canceledDate
    paidDate
    licenses {
      licenseItemId
      licenseName
      size
      qty
      unitPrice
      startDate
      endDate
      numDays
    }
    corporateName
    orgAccountName
    accountUuid
    destinationAddress
    orgOwner
    ownerEmailAddress
    accountant
    accountantEmailAddress
    exchangeRate
    amountInUSD
    fee
    net
    status
    created
  }
}`;

const allCouponsQuery = `{
  startPeriod
  endPeriod
  details {
    id
    name
    code
    amount
    balance
    createAt
    deleteAt
    expiredAt
    corporateName
    orgAccountName
    accountUuid
  }
}`;

export type QueryListSalesSummaryArgs = Gtypes.QueryListSalesSummaryArgs;

export type LIST_SALES_SUMMARY_RESULT_TYPE = {
  listSalesSummary: Gtypes.SalesSummary;
};

export const listAccountLicensesSummaryQuery = `query ($accountUuid: String!) {
  listAccountLicensesSummary(accountUuid: $accountUuid) {
    thisMonth ${summaryMonthQuery}
    nextMonth ${summaryMonthQuery}
  }
}`;

export type QueryListAccountLicensesSummaryArgs = Gtypes.QueryListAccountLicensesSummaryArgs;

export type LIST_ACCOUNT_LICENSES_SUMMARY_RESULT_TYPE = {
  listAccountLicensesSummary: Gtypes.LicenseSummaryAdmin;
};

export const getNetworkSummaryQuery = `query ($networkUuid: String!) {
  getNetworkSummary(networkUuid: $networkUuid) {
    network ${networkQuery}
    accounts {
      uuid
      name
      email
      consortiumRole
    }
    proposals ${proposalQuery}
  }
}`;

export type QueryGetNetworkSummaryArgs = Gtypes.QueryGetNetworkSummaryArgs;

export type GET_NETWORK_SUMMARY_RESULT_TYPE = {
  getNetworkSummary: Gtypes.NetworkSummary;
};

export const listAccountBillingsSummaryQuery = `query ($accountUuid: String!, $startDate: String, $endDate: String) {
  listAccountBillingsSummary(accountUuid: $accountUuid, startDate: $startDate, endDate: $endDate) ${billingQuery}
}`;

export type QueryListAccountBillingsSummaryArgs = Gtypes.QueryListAccountBillingsSummaryArgs;

export type LIST_ACCOUNT_BILLING_SUMMARY_RESULT_TYPE = {
  listAccountBillingsSummary: Gtypes.BillingSummaryAdmin;
};

export const listAccountCouponsSummaryQuery = `query ($accountUuid: String!, $startDate: String, $endDate: String) {
  listAccountCouponsSummary(accountUuid: $accountUuid, startDate: $startDate, endDate: $endDate) ${accountCouponsQuery}
}`;

export type QueryListAccountCouponsSummaryArgs = Gtypes.QueryListAccountCouponsSummaryArgs;

export type LIST_ACCOUNT_COUPONS_SUMMARY_RESULT_TYPE = {
  listAccountCouponsSummary: Gtypes.CouponSummaryAdmin;
};

export const listAdminSummaryQuery = `query ($startDate: String, $endDate: String) {
  listAdminSummary(startDate: $startDate, endDate: $endDate) ${summaryAdminQuery}
}`;

export type QueryListAdminSummaryArgs = Gtypes.QueryListAdminSummaryArgs;

export type LIST_ADMIN_SUMMARY_RESULT_TYPE = {
  listAdminSummary: Gtypes.SummaryAdmin;
};

export const listAllCouponsSummaryQuery = `query ($startDate: String, $endDate: String) {
  listAllCouponsSummary(startDate: $startDate, endDate: $endDate) ${allCouponsQuery}
}`;

export type QueryListAllCouponsSummaryArgs = Gtypes.QueryListAllCouponsSummaryArgs;

export type LIST_ALL_COUPON_SUMMARY_RESULT_TYPE = {
  listAllCouponsSummary: Gtypes.AllCouponSummary;
};
