import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import * as AlertActions from '~/stores/actions/alert-action';
import * as AppActions from '~/stores/actions/app-action';
import { AppRoutePath } from '~/AppRouter';
// Component
import Layout from '../common/Layout';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import Typography from '@mui/material/Typography';
import LoadingIcon from '~/components/common/loading-icon';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { lightSlateGreyColor, whiteColor } from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';

import { compose } from 'redux';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withAdminProfile } from '~/hooks/with-admin-profile';
import { withAuthorization } from '~/hooks/with-authorization';
import { useParams } from 'react-router-dom';
import { AdminAlert } from '~/gapi/gtypes';

interface IProps extends WithStyles<typeof styles> {}

const NotificationDetail = (props: IProps) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('admin');
  const params = useParams<{ alertUuid: string }>();
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>();
  const [adminAlert, setAdminAlert] = useState<AdminAlert>();

  const { alertUuid } = params;

  const renderAlertType = useCallback(
    (type: string) => {
      switch (type) {
        case 'important':
          return (
            <>
              <img src="/images/icons/alert-important.svg" className={classes.iconsAlert} alt="" />
              <span className={classes.typeAlert}>{t('common:important')}</span>
            </>
          );
        case 'information':
          return (
            <>
              <img src="/images/icons/alert-infomation.svg" className={classes.iconsAlert} alt="" />
              <span className={classes.typeAlert}>{t('common:information')}</span>
            </>
          );
        case 'warning':
          return (
            <>
              <img src="/images/icons/alert-warning.svg" className={classes.iconsAlert} alt="" />
              <span className={classes.typeAlert}>{t('common:warning')}</span>
            </>
          );
        case 'error':
          return (
            <>
              <img src="/images/icons/alert-error.svg" className={classes.iconsAlert} alt="" />
              <span className={classes.typeAlert}>{t('common:error')}</span>
            </>
          );
        default:
          return null;
      }
    },
    [classes.iconsAlert, classes.typeAlert, t],
  );

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await dispatch(AlertActions.getAdminAlert({ alertUuid }));
        setAdminAlert(result.getAdminAlert);
      } catch {
        dispatch(AppActions.openSnackBar({ type: 'error', message: t('notification_not_found') }));
      }
      setLoading(false);
    })();
  }, [alertUuid, dispatch, t]);

  useEffect(() => {
    if (alertUuid) {
      dispatch(AlertActions.getAdminAlert({ alertUuid }));
    }
  }, [dispatch, alertUuid]);

  return (
    <Layout>
      <div className={classes.root}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Link
            component={RouterLink}
            to={AppRoutePath.Dashboard}
            color="text.primary"
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          </Link>
          <Link
            component={RouterLink}
            to={
              location.pathname.includes(AppRoutePath.AdminNotifications)
                ? AppRoutePath.AdminNotifications
                : AppRoutePath.UserNotifications
            }
            color="text.primary"
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {location.pathname.includes(AppRoutePath.AdminNotifications)
              ? t('admin_notifications')
              : t('user_notifications')}
          </Link>
          <Typography color="text.primary">{alertUuid ? `ID: ${alertUuid}` : ''}</Typography>
        </Breadcrumbs>
        <div className={classes.contentPaper}>
          {loading && (
            <div className={classes.loadingArea}>
              <LoadingIcon />
            </div>
          )}
          {!loading && !adminAlert && <div>Notification doesn't exist</div>}
          {!loading && adminAlert && (
            <Card className={classes.card}>
              <CardContent>
                <div className={classes.header}>
                  <Typography className={classes.typeArea}>
                    {renderAlertType(adminAlert.type as string)}
                  </Typography>
                  <Typography variant="body2">
                    {t('date_hour_value', { date: new Date(adminAlert.createAt) })}
                  </Typography>
                </div>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  fontWeight={450}
                  component="div"
                  marginBottom={'20px'}
                >
                  {adminAlert.title[i18n.language]}
                </Typography>
                <Typography variant="body2" color="text.secondary" whiteSpace={'break-spaces'}>
                  {adminAlert.description?.[i18n.language]}
                </Typography>
              </CardContent>
            </Card>
          )}
        </div>
      </div>
    </Layout>
  );
};

const styles = createStyles({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 'auto',
    paddingRight: 0,
  },
  card: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  typeArea: {
    display: 'flex',
    alignItems: 'center',
  },
  iconsAlert: {
    width: 16,
    height: 16,
    marginRight: 5,
  },
  typeAlert: {
    fontSize: 16,
    color: lightSlateGreyColor,
  },
  loadingArea: {
    margin: '100px 0',
    textAlign: 'center',
  },
  contentPaper: {
    marginTop: 24,
    borderRadius: 4,
  },
});

export default compose(
  withFirebaseUser(),
  withAdminProfile,
  withAuthorization(['owner', 'admin', 'operator']),
  withStyles(styles),
)(NotificationDetail);
