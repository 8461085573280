import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { DEFAULT_DASH_NOTIFICATIONS_SIZE } from '~/constants/common';
import { IStore } from '~/stores/configure-store';
import * as AlertActions from '~/stores/actions/alert-action';
import * as StatsActions from '~/stores/actions/stats-action';

interface ICommonQueryParams {
  interval?: number;
  disabled?: boolean;
}

export const useGetSummaryStatsQuery = (params?: ICommonQueryParams) => {
  const dispatch = useDispatch();
  const summaryStats = useSelector((store: IStore) => store.appState.summaryStats);
  const isFetching = useSelector((store: IStore) => StatsActions.getSummaryStats.isPending(store));

  const loading = useMemo(() => isFetching && !summaryStats, [isFetching, summaryStats]);

  useEffect(() => {
    if (!params?.disabled) {
      dispatch(StatsActions.getSummaryStats());
      if (params?.interval) {
        const interval = setInterval(async () => {
          dispatch(StatsActions.getSummaryStats());
        }, params.interval);
        return () => clearInterval(interval);
      }
    }
  }, [dispatch, params?.disabled, params?.interval]);

  return { data: summaryStats, loading };
};

export const useAdminNotificationQuery = (params?: ICommonQueryParams) => {
  const dispatch = useDispatch();
  const adminNotificationStats = useSelector(
    (store: IStore) => store.appState.adminNotificationStats,
  );
  const isFetching = useSelector((store: IStore) =>
    AlertActions.listAdminNotificationStats.isPending(store),
  );

  const loading = useMemo(
    () => isFetching && !adminNotificationStats,
    [adminNotificationStats, isFetching],
  );

  const listAlertsArgs = useMemo(
    () => ({
      filter: {
        startDate: moment().subtract(1, 'month').startOf('day').toISOString(),
        endDate: moment().endOf('day').toISOString(),
      },
      page: {
        pageIndex: 0,
        pageSize: DEFAULT_DASH_NOTIFICATIONS_SIZE,
      },
    }),
    [],
  );

  useEffect(() => {
    if (!params?.disabled) {
      dispatch(AlertActions.listAdminNotificationStats(listAlertsArgs));
      if (params?.interval) {
        const interval = setInterval(async () => {
          AlertActions.listAdminNotificationStats(listAlertsArgs);
        }, params.interval);
        return () => clearInterval(interval);
      }
    }
  }, [dispatch, listAlertsArgs, params?.disabled, params?.interval]);

  return { data: adminNotificationStats, loading };
};

export const useUserNotificationQuery = (params?: ICommonQueryParams) => {
  const dispatch = useDispatch();
  const userNotificationStats = useSelector(
    (store: IStore) => store.appState.userNotificationStats,
  );
  const isFetching = useSelector((store: IStore) =>
    AlertActions.listUserNotificationStats.isPending(store),
  );

  const loading = useMemo(
    () => isFetching && !userNotificationStats,
    [userNotificationStats, isFetching],
  );

  const listAlertsArgs = useMemo(
    () => ({
      filter: {
        startDate: moment().subtract(1, 'month').startOf('day').toISOString(),
        endDate: moment().endOf('day').toISOString(),
      },
      page: {
        pageIndex: 0,
        pageSize: DEFAULT_DASH_NOTIFICATIONS_SIZE,
      },
    }),
    [],
  );

  useEffect(() => {
    if (!params?.disabled) {
      dispatch(AlertActions.listUserNotificationStats(listAlertsArgs));
      if (params?.interval) {
        const interval = setInterval(async () => {
          AlertActions.listUserNotificationStats(listAlertsArgs);
        }, params.interval);
        return () => clearInterval(interval);
      }
    }
  }, [dispatch, listAlertsArgs, params?.disabled, params?.interval]);

  return { data: userNotificationStats, loading };
};
