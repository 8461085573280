import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
// Component
import Button from '@mui/material/Button';
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomInput from './custom-input';
import SubmitButton from './submit-button';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// Form
import * as Yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import classNames from 'classnames';
import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';
import {
  romanColor,
  whiteSmokeColor,
  dimGrayColor,
  nightRiderColor,
} from '~/styles/themes/common-styles/color';
// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';
import { IStore } from '~/stores/configure-store';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
  type: 'guest' | 'member';
}

interface IDispProps {}

interface IState {}

class AddUserDialog extends React.Component<IProps & IDispProps, IState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  public render() {
    const { classes, open, onClose, t, type } = this.props;

    return (
      <CustomDialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validateSchema}
          onSubmit={this.onSubmit}
          render={({ isValid, isSubmitting, errors }) => (
            <Form>
              <CustomDialogTitle>
                <div id="admin-users-create-member-info-title">
                  {type === 'member'
                    ? t('create_member_information')
                    : t('create_guest_information')}
                </div>
              </CustomDialogTitle>
              {/* Render Form Field */}
              <CustomDialogContent>
                <div className={classes.formLabelLineDate}>
                  <div className={classes.formLabel}>{t('date')}</div>
                  {!!errors.endDate && (
                    <div className={classNames(classes.formLabel, classes.formError)}>
                      {errors.endDate}
                    </div>
                  )}
                </div>
                <div className={classes.dateArea}>
                  <Field name="startDate" render={this.startDateField} />
                  <div className={classes.toText}>{t('to')}</div>
                  <Field name="endDate" render={this.endDateField} />
                </div>
                <Field name="importance" render={this.importanceField} />
                <Field name="linkUrl" render={this.linkUrlField} />
                <Field name="text" render={this.textField} />
              </CustomDialogContent>
              {/* Render Submit Button */}
              <CustomDialogActions>
                <Button
                  data-testid="cancel-button"
                  id="admin-users-create-member-info-cancel"
                  disabled={isSubmitting}
                  className={classes.leftBtn}
                  variant="outlined"
                  onClick={onClose}
                >
                  {t('cancel')}
                </Button>
                <SubmitButton
                  data-testid="create-button"
                  id="admin-users-create-member-info-submit"
                  label={t('create')}
                  submittingLabel={t('creating')}
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                />
              </CustomDialogActions>
            </Form>
          )}
        />
      </CustomDialog>
    );
  }

  private startDateField = ({ field, form }: FieldProps<FormValues>) => {
    return <TextField {...field} type="date" />;
  };

  private endDateField = ({ field, form }: FieldProps<FormValues>) => {
    return <TextField {...field} type="date" />;
  };

  private importanceField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('importance')}</div>
          {!!form.errors.importance && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.importance}
            </div>
          )}
        </div>
        <RadioGroup {...field} className={classes.radioGroup}>
          <FormControlLabel
            value="normal"
            control={
              <Radio
                classes={{
                  root: classes.radioButton,
                  checked: classes.radioButtonChecked,
                }}
              />
            }
            label={t('normal')}
          />
          <FormControlLabel
            value="important"
            control={
              <Radio
                classes={{
                  root: classes.radioButton,
                  checked: classes.radioButtonChecked,
                }}
              />
            }
            label={t('important')}
          />
        </RadioGroup>
      </>
    );
  };

  private linkUrlField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('link_url')}</div>
          {!!form.errors.linkUrl && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.linkUrl}
            </div>
          )}
        </div>
        <div>
          <CustomInput {...field} id="admin-info-link-url-input" placeholder={t('input')} />
        </div>
      </>
    );
  };

  private textField = ({ field, form }: FieldProps<FormValues>) => {
    const { classes, t } = this.props;
    return (
      <>
        <div className={classes.formLabelLine}>
          <div className={classes.formLabel}>{t('text')}</div>
          {!!form.errors.text && (
            <div className={classNames(classes.formLabel, classes.formError)}>
              {form.errors.text}
            </div>
          )}
        </div>
        <div>
          <TextField
            {...field}
            id="admin-info-text-input"
            className={classes.textInput}
            placeholder="内容を入力ください"
            inputProps={{
              maxLength: 120,
            }}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            helperText={`${field.value.length}/120`}
          />
        </div>
      </>
    );
  };

  private onSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
    const { setSubmitting } = formikActions;
    const { onClose } = this.props;
    setSubmitting(true);
  };
}

const styles = createStyles({
  root: {},
  dialogPaper: {
    maxWidth: 700,
  },
  dateArea: {
    display: 'flex',
    alignItems: 'center',
  },
  toText: {
    ...defaultFontMedium,
    color: nightRiderColor,
    margin: '0 15px',
    fontSize: 12,
  },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
    color: nightRiderColor,
    marginTop: 15,
  },
  radioGroup: {
    flexDirection: 'row',
    paddingLeft: 6,
  },
  radioButton: {
    padding: 6,
  },
  radioButtonChecked: {
    color: '#004098 !important',
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabelLineDate: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '72%',
  },
  formError: {
    color: romanColor,
  },
  textInput: {
    width: '100%',
  },
  helperText: {
    textAlign: 'right',
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

const mapStateToProps = (store: IStore) => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation('admin')(AddUserDialog)),
);

type FormValues = {
  startDate: string;
  endDate: string;
  importance?: 'normal' | 'important';
  linkUrl: string;
  text: string;
};

const initialValues: FormValues = {
  startDate: moment().format('YYYY-MM-DD'),
  endDate: '',
  importance: 'normal',
  linkUrl: '',
  text: '',
};

const validateSchema = Yup.object().shape<FormValues>({
  startDate: Yup.string()
    .required('Required')
    .test('check-date', 'Start date must be less than end date', function (value: any) {
      return moment(value).valueOf() < moment(this.parent.endDate).valueOf();
    }),
  endDate: Yup.string()
    .required('Required')
    .test('check-date', 'End date must be greater than start date', function (value: any) {
      return moment(value).valueOf() > moment(this.parent.startDate).valueOf();
    }),
  linkUrl: Yup.string().required('Required'),
  text: Yup.string().required('Required'),
});
