import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import clsx from 'clsx';

import { defaultFont } from '~/styles/themes/common-styles/font';
import { brightGreyColor } from '~/styles/themes/common-styles/color';

interface IItem {
  value: string;
  label: string;
}

interface IProps extends WithStyles<typeof styles> {
  onChange?: (event: SelectChangeEvent) => void;
  items: IItem[];
  disabled?: boolean;
  placeholder?: string;
  valueSelected?: string;
  className?: string;
  name?: string;
  onBlur?: any;
  input?: boolean;
  label?: string;
}

const CustomSelectWithLabel: React.FunctionComponent<IProps> = ({
  classes,
  onChange,
  items,
  disabled,
  placeholder,
  valueSelected,
  className,
  name,
  onBlur,
  input,
  label,
}) => {
  const arr = items.concat();
  let value = valueSelected;
  const placeHolderItem: IItem = {
    value: '',
    label: placeholder || '',
  };
  if (!valueSelected) {
    arr.unshift(placeHolderItem);
    value = placeHolderItem.value;
  }

  if (!input) {
    return (
      <Select
        className={clsx(classes.select, className)}
        disabled={disabled}
        value={value}
        name={name}
        onBlur={onBlur}
        MenuProps={{ classes: { paper: classes.paperRoot } }}
        classes={{
          select: clsx(classes.root, classes.selectMenu),
        }}
        IconComponent={(_) => (
          <img className={classes.arrowIcon} src="/images/icons/arrow-down.svg" alt="" />
        )}
        onChange={onChange}
        disableUnderline
      >
        {arr.map((item, index) => (
          <MenuItem
            className={classes.menuListItem}
            key={index}
            value={item.value}
            style={{ display: item.value === placeHolderItem.value ? 'none' : 'inherit' }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    );
  }

  return (
    <TextField
      select
      className={className}
      name={name}
      onBlur={onBlur}
      classes={{
        root: classes.root,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      label={label}
      value={valueSelected}
      onChange={onChange as any}
    >
      {arr.map((item, index) => (
        <MenuItem
          data-testid={`menu-${item.label}-item`}
          className={classes.menuListItem}
          key={index}
          value={item.value}
          style={{ display: item.value === placeHolderItem.value ? 'none' : 'inherit' }}
        >
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

const styles = createStyles({
  root: {},
  paperRoot: {
    maxHeight: 250,
  },
  selectMenu: {
    position: 'relative',
    minWidth: 92,
    padding: '7px 0px 4px 10px',
  },
  selectRoot: {},
  select: {
    width: '100%',
    height: '100%',
    fontWeight: 'inherit',
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '&>.MuiSelect-select': {
      letterSpacing: 'normal',
    },
  },
  placeholderText: {
    ...defaultFont,
    fontSize: 13,
    color: brightGreyColor,
  },
  arrowIcon: {
    position: 'absolute',
    top: 13,
    right: 9,
    cursor: 'pointer',
  },
  menuListItem: {
    height: 'auto',
    '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.14)',
    },
  },
});

export default withStyles(styles)(CustomSelectWithLabel);
