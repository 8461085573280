import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import { defaultFont, defaultFontMedium } from '~/styles/themes/common-styles/font';

import { IStore } from '~/stores/configure-store';
import * as AccountActions from '~/stores/actions/account-action';
import { useDispatch, useSelector } from 'react-redux';

// Component
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/custom-submit-button';

import { Formik, Field, Form, FieldProps, FormikActions } from 'formik';
import * as Yup from 'yup';
import {
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
  snowColor,
  pattensBlueColor,
} from '~/styles/themes/common-styles/color';

// React i18next
import { useTranslation } from 'react-i18next';
import { IAdminUser } from '~/types/admin-types';
import { MAX_PROFILE_NAME_LENGTH } from '~/constants/validation';
import CustomInput from '~/components/common/custom-input';
import { AdminRoleEnum } from '~/gapi/gtypes';
import CustomSelect from '~/components/common/custom-select';

interface IProps extends WithStyles<typeof styles> {
  open: boolean;
  user: IAdminUser;
  onClose: () => void;
}

type FormValues = {
  role: string;
  name: string;
};

const UpdateAdminUserDialog = (props: IProps) => {
  const { classes, open, user, onClose } = props;
  const isUpdating = useSelector((store: IStore) =>
    AccountActions.updateAdminUser.isPending(store),
  );
  const profile = useSelector((store: IStore) => store.appState.profile);
  const { t } = useTranslation('admin');
  const dispatch = useDispatch();

  const initialValues: FormValues = {
    role: user.role,
    name: user.name,
  };

  const validateSchema = Yup.object().shape<FormValues>({
    name: Yup.string()
      .trim()
      .required(t('required_field'))
      .max(
        MAX_PROFILE_NAME_LENGTH,
        t('too_many_character_of_user_name_error_message', { value: MAX_PROFILE_NAME_LENGTH }),
      ),
    role: Yup.string().required(t('required_field')),
  });

  const adminUserRoleOptions: { value: AdminRoleEnum; label: string }[] = useMemo(
    () =>
      profile?.role === 'owner'
        ? [
            { value: 'owner', label: t('owner') },
            { value: 'admin', label: t('admin') },
            { value: 'operator', label: t('operator') },
            { value: 'billing_operator', label: t('billing_operator') },
          ]
        : profile?.role === 'admin'
        ? [
            { value: 'operator', label: t('operator') },
            { value: 'billing_operator', label: t('billing_operator') },
          ]
        : [],
    [profile?.role, t],
  );

  const nameField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <>
          <div className={classes.formLabelLine}>
            <div className={classes.formLabel}>{t('user_name')}</div>
            {!!form.errors.name && (
              <div className={classNames(classes.formLabel, classes.formError)}>
                {form.errors.name}
              </div>
            )}
          </div>
          <div>
            <CustomInput {...field} id="admin-user-add-name" placeholder={t('user_name')} />
          </div>
        </>
      );
    },
    [classes, t],
  );

  const adminRoleField = useCallback(
    ({ field, form }: FieldProps<FormValues>) => {
      return (
        <>
          <div className={classes.formLabelLine}>
            <div className={classes.formLabel}>{t('role')}</div>
            {!!form.errors.role && (
              <div className={classNames(classes.formLabel, classes.formError)}>
                {form.errors.role}
              </div>
            )}
          </div>
          <div>
            <CustomSelect
              {...field}
              valueSelected={field.value}
              placeholder={t('select_role')}
              items={adminUserRoleOptions}
            />
          </div>
        </>
      );
    },
    [classes, adminUserRoleOptions, t],
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikActions<FormValues>) => {
      const { setSubmitting } = formikActions;
      const { role, name } = values;
      try {
        await dispatch(
          AccountActions.updateAdminUser({
            input: {
              adminUuid: user.adminUuid,
              role: role as AdminRoleEnum,
              name: name,
            },
          }),
        );
        await dispatch(AccountActions.listAdminUsers());
        onClose();
      } catch (error) {}
      setSubmitting(false);
    },
    [user.adminUuid, dispatch, onClose],
  );

  return (
    <CustomDialog classes={{ paper: classes.dialog }} open={open} onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={validateSchema}
        onSubmit={onSubmit}
        render={({ isValid, isSubmitting, values }) => (
          <Form>
            <CustomDialogTitle>
              <div id="admin-update-user-title">{t('update_admin_user')}</div>
            </CustomDialogTitle>
            <CustomDialogContent>
              <div className={classes.formSection}>
                <div title="username-field">
                  <Field name="name" render={nameField} />
                </div>
                <div title="role-field">
                  <Field name="role" render={adminRoleField} />
                </div>
              </div>
              {values.role === 'owner' && (
                <div id="admin-update-user-attention" className={classes.warningText}>
                  {t('are_you_sure_you_want_to_transfer_owner_to_this_admin')}
                </div>
              )}
            </CustomDialogContent>
            <CustomDialogActions>
              <Button
                data-testid="cancel-button"
                id="admin-update-user-cancel"
                disabled={isSubmitting || isUpdating}
                className={classes.leftBtn}
                variant="contained"
                onClick={onClose}
              >
                {t('cancel')}
              </Button>
              <SubmitButton
                data-testid="update-button"
                classes={{ root: classes.submitBtn }}
                id="admin-update-user-submit"
                type="submit"
                disabled={!isValid || isSubmitting || isUpdating}
                isLoading={isSubmitting || isUpdating}
                label={t('update')}
                submittingLabel={t('updating')}
              />
            </CustomDialogActions>
          </Form>
        )}
      />
    </CustomDialog>
  );
};

const styles = createStyles({
  root: {},
  content: {
    padding: 15,
    backgroundColor: snowColor,
    borderRadius: 4,
    border: `1px solid ${pattensBlueColor}`,
    boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
    wordBreak: 'break-word',
  },
  dialog: { minWidth: 700 },
  formLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    marginBottom: 5,
    marginTop: 12,
  },
  formSection: {
    marginTop: 10,
  },
  formLabelLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formError: {
    color: romanColor,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
  },
  submitBtn: {
    width: 120,
  },
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
});

export default withStyles(styles)(UpdateAdminUserDialog);
