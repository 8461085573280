import React from 'react';
import classNames from 'classnames';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  denimColor,
  pattensBlueColor,
  nightRiderColor,
  silverColor,
  persianGreenColor,
  romanColor,
} from '~/styles/themes/common-styles/color';
import DeleteClusterDialog from './delete-cluster-dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ImgIcon from '~/components/common/img-icon';
import { IClusterUnit, IClusterStatus, INetworkAccountSummary } from '~/types/admin-types';
import OptionButton from '~/components/common/option-button';

interface Props extends WithStyles<typeof styles>, WithTranslation {
  networkUuid: string;
  cluster: IClusterUnit;
}

interface State {
  menuAnchorEl: HTMLElement | null;
  openDeleteHardDialog: boolean;
  openDeleteSoftDialog: boolean;
}

class ClusterOptions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      menuAnchorEl: null,
      openDeleteHardDialog: false,
      openDeleteSoftDialog: false,
    };
  }

  public render() {
    const { classes, t, networkUuid, cluster } = this.props;
    const { openDeleteHardDialog, openDeleteSoftDialog } = this.state;

    return (
      <>
        <DeleteClusterDialog
          force={true}
          cluster={cluster}
          networkUuid={networkUuid}
          open={openDeleteHardDialog}
          onClose={this.onCloseDeleteHardDialog}
        />
        <DeleteClusterDialog
          force={false}
          cluster={cluster}
          networkUuid={networkUuid}
          open={openDeleteSoftDialog}
          onClose={this.onCloseDeleteSoftDialog}
        />
        {this.renderOptionPopover}
        <OptionButton
          id="admin-user-option"
          onClick={this.onOpenOptionPopover}
          data-testid="cluster-option"
        />
      </>
    );
  }

  onOpenDeleteHardDialog = () => {
    this.setState({ openDeleteHardDialog: true });
    this.onCloseOptionPopover();
  };
  onCloseDeleteHardDialog = () => {
    this.setState({ openDeleteHardDialog: false });
  };
  onOpenDeleteSoftDialog = () => {
    this.setState({ openDeleteSoftDialog: true });
    this.onCloseOptionPopover();
  };
  onCloseDeleteSoftDialog = () => {
    this.setState({ openDeleteSoftDialog: false });
  };

  get renderOptionPopover() {
    const { classes, t } = this.props;
    const { menuAnchorEl } = this.state;

    return (
      <Popover
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={this.onCloseOptionPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <List>
          <ListItem
            button
            className={classes.listOptionItem}
            onClick={this.onOpenDeleteSoftDialog}
            data-testid="soft-delete-item"
          >
            <ImgIcon className={classes.deleteIcon} imgUrl="/images/icons/delete_ico.png" />
            {t('delete_soft')}
          </ListItem>
          <div className={classes.horizontalSeparate}></div>
          <ListItem
            button
            className={classes.listOptionItem}
            onClick={this.onOpenDeleteHardDialog}
            data-testid="hard-delete-item"
          >
            <ImgIcon className={classes.deleteIcon} imgUrl="/images/icons/delete_ico.png" />
            {t('delete_hard')}
          </ListItem>
        </List>
      </Popover>
    );
  }

  onOpenOptionPopover = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ menuAnchorEl: e.currentTarget });
  };
  onCloseOptionPopover = () => {
    this.setState({ menuAnchorEl: null });
  };

  clusterStatusRender = (status: IClusterStatus) => {
    const { classes } = this.props;
    type ClusterStatusText = 'running' | 'pending' | 'removing' | 'stopped' | 'error';
    const statusMap: { [key in IClusterStatus]: ClusterStatusText } = {
      alive: 'running',
      pending: 'pending',
      removing: 'removing',
      dead: 'stopped',
      failure: 'error',
    };
    const statusText = statusMap[status];

    return (
      <div className={classes.clusterStatus}>
        {statusText === 'pending' ||
          (statusText === 'removing' && (
            <CircularProgress size={14} className={classes.loadingIcon} />
          ))}
        {statusText === 'running' && (
          <div className={classNames(classes.statusIcon, classes.runningIcon)} />
        )}
        {statusText === 'stopped' ||
          (statusText === 'error' && (
            <div className={classNames(classes.statusIcon, classes.stoppedIcon)} />
          ))}
        <span className={classes.statusText}>{statusText}</span>
      </div>
    );
  };
}

const styles = createStyles({
  textClusterId: {
    color: denimColor,
  },
  menuIcon: {},
  listOptionItem: {
    height: 46,
    width: 210,
    ...defaultFont,
    fontSize: 15,
    color: nightRiderColor,
  },
  deleteIcon: {
    marginRight: 8,
  },
  horizontalSeparate: {
    height: 1,
    width: '100%',
    backgroundColor: pattensBlueColor,
  },
  clusterStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  loadingIcon: {
    color: silverColor,
  },
  statusIcon: {
    width: 10,
    height: 10,
    borderRadius: '50%',
  },
  runningIcon: {
    backgroundColor: persianGreenColor,
  },
  stoppedIcon: {
    backgroundColor: romanColor,
  },
  statusText: {
    marginLeft: 10,
  },
  tableBodyCellCommon: {
    padding: '4px 0px 4px 2% !important',
    wordBreak: 'break-all',
  },
  networkClusterOptionIcon: {
    padding: 0,
  },
});

export default withStyles(styles)(withTranslation('admin')(ClusterOptions));
