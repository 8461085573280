import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { renderRoutes, RouteConfig } from 'react-router-config';
import Dashboard from '~/components/Dashboard';
import Accounts from '~/components/Accounts';
import Users from '~/components/Users';
import AWSAccounts from '~/components/AwsAccounts';
import Sales from '~/components/Sales';
import AdminNotifications from '~/components/AdminNotifications';
import UserNotifications from '~/components/UserNotifications';
import NotificationDetail from '~/components/NotificationDetail';
import Networks from '~/components/Networks';
import NetworkDetail from '~/components/NetworkDetail';
import AdminUsers from '~/components/AdminUsers';
import AccountDetail from './components/AccountDetail';
import InfomationGuest from '~/components/InformationGuest';
import InfomationMember from '~/components/InformationMember';
import Summary from '~/components/Summary';
import PageNotFound from '~/components/NotFound';
import AboutPage from './components/About';
import Auth from '~/components/Auth';

export enum AppRoutePath {
  Dashboard = '/',
  Accounts = '/accounts',
  AccountDetail = '/account/:accountUuid/:tab?/:subtab?',
  AWSAccounts = '/aws',
  Sales = '/sales',
  Users = '/users',
  AdminNotifications = '/admin-notifications',
  UserNotifications = '/user-notifications',
  AdminNotificationDetail = '/admin-notifications/:alertUuid',
  UserNotificationDetail = '/user-notifications/:alertUuid',
  Networks = '/networks',
  NetworkDetail = '/networks/:networkUuid/:tab?/:subtab?',
  AdminUsers = '/admin-users',
  InfomationGuest = '/infomation-guest',
  InfomationMember = '/infomation-member',
  Summary = '/summary',
  Auth = '/auth',
  About = '/about',
}

export const routes: RouteConfig[] = [
  {
    path: AppRoutePath.Dashboard,
    component: Dashboard,
    exact: true,
  },
  {
    path: AppRoutePath.Accounts,
    component: Accounts,
    exact: true,
  },
  {
    path: AppRoutePath.AccountDetail,
    component: AccountDetail,
  },
  {
    path: AppRoutePath.AWSAccounts,
    component: AWSAccounts,
    exact: true,
  },
  {
    path: AppRoutePath.Sales,
    component: Sales,
    exact: true,
  },
  {
    path: AppRoutePath.Networks,
    component: Networks,
    exact: true,
  },
  {
    path: AppRoutePath.NetworkDetail,
    component: NetworkDetail,
  },
  {
    path: AppRoutePath.AdminNotifications,
    component: AdminNotifications,
    exact: true,
  },
  {
    path: AppRoutePath.UserNotifications,
    component: UserNotifications,
    exact: true,
  },
  {
    path: AppRoutePath.AdminNotificationDetail,
    component: NotificationDetail,
    exact: true,
  },
  {
    path: AppRoutePath.UserNotificationDetail,
    component: NotificationDetail,
    exact: true,
  },
  {
    path: AppRoutePath.Users,
    component: Users,
    exact: true,
  },
  {
    path: AppRoutePath.AdminUsers,
    component: AdminUsers,
    exact: true,
  },
  {
    path: AppRoutePath.InfomationGuest,
    component: InfomationGuest,
    exact: true,
  },
  {
    path: AppRoutePath.InfomationMember,
    component: InfomationMember,
    exact: true,
  },
  {
    path: AppRoutePath.Summary,
    component: Summary,
    exact: true,
  },
  {
    path: AppRoutePath.Auth,
    component: Auth,
    exact: true,
  },
  {
    path: AppRoutePath.About,
    component: AboutPage,
    exact: true,
  },
  {
    path: '*',
    component: PageNotFound,
    exact: true,
  },
];

export const AppRouter = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return renderRoutes(routes);
};
