import React from 'react';
import { connect } from 'react-redux';
// Component
import Button from '@mui/material/Button';
import SubmitButton from './custom-submit-button';
import CustomDialog from './custom-dialog';
import CustomDialogTitle from './custom-dialog-title';
import CustomDialogContent from './custom-dialog-content';
import CustomDialogActions from './custom-dialog-actions';
import CustomInput from './custom-input';
import { IStore } from '~/stores/configure-store';

// Style
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { defaultFont, defaultFontBold } from '~/styles/themes/common-styles/font';
import { romanColor, dimGrayColor, whiteSmokeColor } from '~/styles/themes/common-styles/color';

// React i18next
import { WithTranslation, withTranslation } from 'react-i18next';

import * as AccountActions from '~/stores/actions/account-action';
import { IAdminUser } from '~/types/admin-types';

interface IProps extends WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  user: IAdminUser;
  onClose: () => void;
}

interface IDispProps {
  deleteAdminUser: (
    args: AccountActions.MutationDeleteAdminUserArgs,
  ) => Promise<AccountActions.DELETE_ADMIN_RESULT_TYPE>;
}

interface IState {
  isSubmitting: boolean;
}

class DeleteUserDialog extends React.Component<IProps & IDispProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  componentWillUnmount() {
    this.setSubmitting(false);
  }

  public render() {
    const { classes, open, onClose, user, t } = this.props;
    const { isSubmitting } = this.state;

    return (
      <CustomDialog open={open} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
        <CustomDialogTitle>
          <div id="admin-users-remove-title">{t('delete_user')}</div>
        </CustomDialogTitle>
        <CustomDialogContent>
          <div>
            <div className={classes.formLabel}>{t('email_address')}</div>
            <CustomInput id="admin-user-remove-email" placeholder={user.email} disabled={true} />
          </div>
          <div>
            <div className={classes.formLabel}>{t('user_name')}</div>
            <CustomInput id="admin-user-remove-name" placeholder={user.name} disabled={true} />
          </div>
          <div id="admin-user-remove-note" className={classes.warningText}>
            {t('are_you_sure_you_want_to_delete_this_user')}
          </div>
        </CustomDialogContent>
        {/* Render Submit Button */}
        <CustomDialogActions>
          <Button
            data-testid="cancel-button"
            id="admin-users-remove-cancel"
            disabled={isSubmitting}
            className={classes.leftBtn}
            variant="outlined"
            onClick={onClose}
          >
            {t('cancel')}
          </Button>
          <SubmitButton
            data-testid="delete-button"
            id="admin-users-remove-submit"
            isLoading={isSubmitting}
            label={t('delete')}
            submittingLabel={t('deleting')}
            disabled={isSubmitting}
            onClick={this.onRemoveUser}
          />
        </CustomDialogActions>
      </CustomDialog>
    );
  }

  private onRemoveUser = async () => {
    const { onClose, deleteAdminUser, user } = this.props;
    this.setSubmitting(true);
    await deleteAdminUser({ adminUuid: user.adminUuid }).catch((_) => void 0);
    onClose();
  };

  private setSubmitting = (value: boolean) => {
    this.setState({
      isSubmitting: value,
    });
  };
}

const styles = createStyles({
  root: {},
  dialogPaper: {
    maxWidth: 620,
    minWidth: 512,
  },
  warningText: {
    ...defaultFont,
    fontSize: 18,
    color: romanColor,
    textAlign: 'center',
    marginTop: 25,
  },
  formLabel: {
    ...defaultFontBold,
    fontSize: 12,
    marginBottom: 5,
    marginTop: 12,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

const mapStateToProps = (store: IStore) => ({});

const mapDispatchToProps = (dispatch): IDispProps => ({
  deleteAdminUser: (args: AccountActions.MutationDeleteAdminUserArgs) =>
    dispatch(AccountActions.deleteAdminUser(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation('admin')(DeleteUserDialog)),
);
