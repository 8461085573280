import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogAction from '~/components/common/custom-dialog-actions';
import { defaultFont, defaultFontBold } from '~/styles/themes/common-styles/font';
import {
  dimGrayColor,
  nightRiderColor,
  whiteSmokeColor,
} from '~/styles/themes/common-styles/color';
import {
  MutationChangeAccountPlanArgs,
  CHANGE_ACCOUNT_PLAN_RESULT_TYPE,
} from '~/stores/actions/gapi/query/account';
import * as AccountAction from '~/stores/actions/account-action';
import { IStore } from '~/stores/configure-store';
import { connect } from 'react-redux';
import SubmitButton from '~/components/common/custom-submit-button';

interface StateProps {}

interface DispProps {
  changeAccountPlan: (
    args: MutationChangeAccountPlanArgs,
  ) => Promise<CHANGE_ACCOUNT_PLAN_RESULT_TYPE>;
}

interface Props extends StateProps, DispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  changeAccountArgs: MutationChangeAccountPlanArgs;
  onClose: () => void;
}

interface State {
  isSubmitting: boolean;
}

class ChangeAccountPlanDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isSubmitting: false,
    };
  }

  render() {
    const { t, classes, open, onClose, changeAccountArgs } = this.props;
    const { isSubmitting } = this.state;

    return (
      <CustomDialog classes={{ paper: classes.dialogContentArea }} open={open} onClose={onClose}>
        <CustomDialogTitle>{t('attention')}</CustomDialogTitle>
        <CustomDialogContent>
          <div className={classes.content}>
            {t('change_account_plan_dialog_content', {
              plan: changeAccountArgs.newPlan === 'personal' ? t('normal') : t('enterprise'),
            })}
          </div>
        </CustomDialogContent>
        <CustomDialogAction classes={{ root: classes.dialogAction }}>
          <Button
            data-testid="cancel-button"
            variant="outlined"
            onClick={onClose}
            className={classes.cancelBtn}
          >
            {t('cancel')}
          </Button>
          <SubmitButton
            data-testid="change-button"
            id="confirm-change-account-type"
            onClick={this.onSubmit}
            isLoading={isSubmitting}
            label={t('change')}
            submittingLabel={t('changing')}
            disabled={isSubmitting}
          />
        </CustomDialogAction>
      </CustomDialog>
    );
  }

  private onSubmit = async () => {
    this.setState({
      isSubmitting: true,
    });
    try {
      await this.props.changeAccountPlan(this.props.changeAccountArgs);
      this.props.onClose();
    } catch (error) {
      // Do nothing
    }
    this.setState({
      isSubmitting: false,
    });
  };
}

const styles = createStyles({
  content: {
    ...defaultFontBold,
    textAlign: 'center',
    color: nightRiderColor,
    fontSize: 16,
  },
  dialogContentArea: {
    maxWidth: 480,
  },
  dialogAction: {
    paddingBottom: 80,
    justifyContent: 'center',
  },
  cancelBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
  },
});

const mapStateToProps = (store: IStore): StateProps => ({});

const mapDispatchToProps = (dispatch): DispProps => ({
  changeAccountPlan: (args: MutationChangeAccountPlanArgs) =>
    dispatch(AccountAction.changeAccountPlan(args)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation('admin')(ChangeAccountPlanDialog)),
);
