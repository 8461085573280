import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
  defaultFontExtraBold,
} from '~/styles/themes/common-styles/font';
import {
  gainsboroColor,
  manateeColor,
  denimColor,
  dodgerBlue4Color,
  romanColor,
} from '~/styles/themes/common-styles/color';
import classNames from 'classnames';
import Table from '@mui/material/Table';
import TableHeadCustom from '~/components/common/table-head';
import TableHeadRowCustom from '~/components/common/table-head-row';
import TableHeadCellCustom from '~/components/common/table-head-cell';
import TableBodyCustom from '~/components/common/table-body';
import TableBodyCellCustom from '~/components/common/table-body-cell';
import TableBodyRowCustom from '~/components/common/table-body-row';
import LoadingIcon from '~/components/common/loading-icon';
import { IStore } from '~/stores/configure-store';

import {
  IAccountSummary,
  IActiveLicenseSummaryDetail,
  IAccountLicenseSummary,
} from '~/types/admin-types';
import { displayCurrency } from '~/utilities/payment-utils';
import * as SummaryActions from '~/stores/actions/summary-action';
import { LICENSE_TYPE_ID_STORAGE } from '~/constants/common';

interface Props extends RouteComponentProps<{}>, WithStyles<typeof styles>, WithTranslation {
  account: IAccountSummary;
}

interface DispProps {
  listAccountLicensesSummary: (
    args: SummaryActions.QueryListAccountLicensesSummaryArgs,
  ) => Promise<SummaryActions.LIST_ACCOUNT_LICENSES_SUMMARY_RESULT_TYPE>;
}

interface StateProps {
  licenses: IAccountLicenseSummary;
  isLoading: boolean;
}

interface State {}

class AccountLicenseActive extends React.Component<Props & DispProps & StateProps, State> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { account, listAccountLicensesSummary } = this.props;
    listAccountLicensesSummary({ accountUuid: account.accountUuid }).catch(console.log);
  }

  public render() {
    const { classes, t, isLoading, licenses } = this.props;

    if (isLoading || licenses.active === void 0) {
      return (
        <div className={classes.loadingArea}>
          <LoadingIcon />
        </div>
      );
    }
    const grouped = this.groupLicenses();

    return (
      <>
        <div>
          <Table id="admin-account-licenses-list">
            <TableHeadCustom classes={{ root: classes.tableHeadCustom }}>
              <TableHeadRowCustom>
                <TableHeadCellCustom classes={{ root: classes.tableCellBoldTypeCustom }}>
                  {t('common:type')}
                </TableHeadCellCustom>
                <TableHeadCellCustom classes={{ root: classes.tableCellBoldTypeCustom }}>
                  {t('common:size')}
                </TableHeadCellCustom>
                <TableHeadCellCustom classes={{ root: classes.tableCellBoldTypeCustom }}>
                  {t('common:quantity')}
                </TableHeadCellCustom>
                <TableHeadCellCustom classes={{ root: classes.tableCellBoldTypeCustom }}>
                  {t('common:month_current')}
                </TableHeadCellCustom>
                <TableHeadCellCustom classes={{ root: classes.tableCellBoldTypeCustom }}>
                  {t('common:month_next')}
                </TableHeadCellCustom>
              </TableHeadRowCustom>
            </TableHeadCustom>
            <TableBodyCustom>
              {Object.entries(grouped.hash.cur).map(([k, v]) => {
                const nextSum = grouped.hash.next[k]
                  ? displayCurrency(grouped.hash.next[k].subTotalPrice)
                  : 0;
                return (
                  <TableBodyRowCustom key={`license-active-${k}`} lang="en">
                    <TableBodyCellCustom>{v.licenseName}</TableBodyCellCustom>
                    <TableBodyCellCustom>
                      {v.licenseItemId !== LICENSE_TYPE_ID_STORAGE ? '' : '1'}
                      {t(v.size)}
                    </TableBodyCellCustom>
                    <TableBodyCellCustom>{v.qty} License</TableBodyCellCustom>
                    <TableBodyCellCustom>$ {displayCurrency(v.subTotalPrice)}</TableBodyCellCustom>
                    <TableBodyCellCustom>$ {nextSum}</TableBodyCellCustom>
                  </TableBodyRowCustom>
                );
              })}

              <TableBodyRowCustom>
                <TableBodyCellCustom
                  colSpan={4}
                  classes={{ root: classes.tableCellBoldTypeCustom }}
                >
                  {t('sub_total')}
                </TableBodyCellCustom>
                <TableBodyCellCustom classes={{ root: classes.tableCellBoldTypeCustom }}>
                  $ {displayCurrency(grouped.total.next)}
                </TableBodyCellCustom>
              </TableBodyRowCustom>
              <TableBodyRowCustom>
                <TableBodyCellCustom colSpan={4}>{t('amount_of_coupon_used')}</TableBodyCellCustom>
                <TableBodyCellCustom classes={{ root: classes.couponValue }}>
                  {`-$ ` + displayCurrency(grouped.total.nextcouponamount)}
                </TableBodyCellCustom>
              </TableBodyRowCustom>
              <TableBodyRowCustom>
                <TableBodyCellCustom colSpan={4}>{t('tax')}</TableBodyCellCustom>
                <TableBodyCellCustom>$ {displayCurrency(grouped.tax.next)}</TableBodyCellCustom>
              </TableBodyRowCustom>
              <TableBodyRowCustom>
                <TableBodyCellCustom colSpan={4} classes={{ root: classes.total }}>
                  {t('total')}
                </TableBodyCellCustom>
                <TableBodyCellCustom classes={{ root: classes.total }}>
                  ${' '}
                  {displayCurrency(
                    grouped.total.next - grouped.total.nextcouponamount + grouped.tax.next,
                  )}
                </TableBodyCellCustom>
              </TableBodyRowCustom>
            </TableBodyCustom>
          </Table>
        </div>
      </>
    );
  }

  private groupLicenses = () => {
    // create hashed map from array (group by and sum 'qty','subTotalPrice' value)
    const { active } = this.props.licenses;

    if (!active) {
      return {
        hash: {
          cur: {},
          next: {},
        },
        total: {
          cur: 0,
          curcouponamount: 0,
          next: 0,
          nextcouponamount: 0,
        },
        tax: {
          cur: 0,
          next: 0,
        },
      };
    }

    const hashNext = active.nextMonth
      .map((s) => s.licenses)
      .reduce((pre, cur) => {
        pre.push(...cur);
        return pre;
      }, [])
      .reduce((pre, cur) => {
        const itemId = cur.licenseItemId.toString();
        if (pre[itemId]) {
          pre[itemId].qty += cur.qty;
          pre[itemId].subTotalPrice += cur.subTotalPrice;
        } else {
          pre[itemId] = { ...cur };
        }
        return pre;
      }, {} as { [key: string]: IActiveLicenseSummaryDetail });

    const hashThis = active.thisMonth
      .map((s) => s.licenses)
      .reduce((pre, cur) => {
        pre.push(...cur);
        return pre;
      }, [])
      .reduce((pre, cur) => {
        const itemId = cur.licenseItemId.toString();
        if (pre[itemId]) {
          pre[itemId].qty += cur.qty;
          pre[itemId].subTotalPrice += cur.subTotalPrice;
        } else {
          pre[itemId] = { ...cur };
        }

        if (!hashNext[itemId]) {
          hashNext[itemId] = {
            ...cur,
            qty: 0,
            subTotalPrice: 0,
          };
        }
        return pre;
      }, {} as { [key: string]: IActiveLicenseSummaryDetail });

    return {
      hash: {
        cur: hashThis,
        next: hashNext,
      },
      total: {
        cur: active.thisMonth.map((s) => s.amount).reduce((pre, cur) => pre + cur, 0),
        curcouponamount: active.thisMonth.map((s) => s.coupon).reduce((pre, cur) => pre + cur, 0),
        next: active.nextMonth.map((s) => s.amount).reduce((pre, cur) => pre + cur, 0),
        nextcouponamount: active.nextMonth.map((s) => s.coupon).reduce((pre, cur) => pre + cur, 0),
      },
      tax: {
        cur: active.thisMonth.map((s) => s.taxFee).reduce((pre, cur) => pre + cur, 0),
        next: active.nextMonth.map((s) => s.taxFee).reduce((pre, cur) => pre + cur, 0),
      },
    };
  };
}

const styles = createStyles({
  root: {},
  loadingArea: {
    margin: '100px 0',
    textAlign: 'center',
  },
  customTable: {
    borderCollapse: 'separate',
    borderSpacing: '0 33px',
  },
  customTableBodyCell: {
    borderBottom: 'none',
    padding: '0 22px',
    borderLeft: `1px solid ${gainsboroColor}`,
    '&:first-child': {
      borderLeft: 'none',
    },
    verticalAlign: 'top',
  },
  customBorderNoneTableCell: {
    borderBottom: 'none',
    paddingLeft: 0,
  },
  tableHeadCustom: {
    marginTop: 16,
  },
  tableCellBoldTypeCustom: {
    ...defaultFontExtraBold,
    color: '#000000',
  },
  label: {
    ...defaultFontBold,
    fontSize: 13,
    color: manateeColor,
    marginBottom: 20,
  },
  downloadBtn: {
    ...defaultFont,
    width: 104,
    height: 34,
    color: denimColor,
    borderColor: denimColor,
  },
  infoItem: {
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    flex: 1,
    minWidth: 0,
  },
  noteSpacer: {
    display: 'inline-block',
    width: 30,
    textAlign: 'center',
  },
  subTotal: {
    marginRight: 20,
    textAlign: 'right',
  },
  subTitle: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  total: {
    ...defaultFontExtraBold,
    color: `${dodgerBlue4Color} !important`,
  },
  couponValue: {
    color: `${romanColor} !important`,
  },
});

const mapStateToProps = (store: IStore) => ({
  licenses: store.appState.licenseSummary,
  isLoading: SummaryActions.listAccountLicensesSummary.isPending(store),
});

const mapDispatchToProps = (dispatch): DispProps => ({
  listAccountLicensesSummary: (args: SummaryActions.QueryListAccountLicensesSummaryArgs) =>
    dispatch(SummaryActions.listAccountLicensesSummary(args)),
});

export default withStyles(styles)(
  withTranslation(['admin', 'common'])(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountLicenseActive)),
  ),
);
