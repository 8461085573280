import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
//component
import Paper from '@mui/material/Paper';
import AuthLayout from '../AuthLayout';
import { container } from '~/styles/themes/common-styles/grid-member';
import { defaultFontBold } from '~/styles/themes/common-styles/font';
import { denimColor, lightSlateGreyColor } from '~/styles/themes/common-styles/color';
import firebase from 'firebase';
import { useDispatch } from 'react-redux';

interface IProps extends WithStyles<typeof styles> {}

/**
 * Unauthenticated component
 */
const UnauthenticatedError: React.FunctionComponent<IProps> = (props: IProps) => {
  const { classes } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const forceLogout = useCallback(async () => {
    await firebase.auth().signOut();
    dispatch({ type: 'RESET_STORE' });
  }, [dispatch]);

  return (
    <AuthLayout>
      <div className={classes.root}>
        <div className={classes.paperArea}>
          <div style={{ width: 480 }}>
            <Paper className={classes.paper} elevation={1}>
              <div className={classes.block}>
                <div className={classes.blockchain}>G.U. Blockchain Admin</div>
              </div>
              <div className={classes.content}>
                {t('unauthenticated_error')}
                <a href="https://bccloud.zendesk.com">
                  <div className={classes.contactUs}>{t('error_page_contact_us_text')}</div>
                </a>
                <br />
                <span className={classes.logoutLink} onClick={forceLogout}>
                  {t('logout')}
                </span>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      backgroundImage: `url('/images/backgrounds/guest_main_bg.svg')`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
    },
    paperArea: {
      ...container,
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      paddingTop: 100,
      paddingBottom: 50,
      justifyContent: 'center',
    },
    paper: {
      maxWidth: 540,
      width: '100%',
      padding: 60,
      borderRadius: 10,
    },
    block: {
      textAlign: 'center',
      marginBottom: 20,
    },
    blockchain: {
      ...defaultFontBold,
      color: lightSlateGreyColor,
      fontSize: 28,
    },
    logoutLink: {
      color: denimColor,
      cursor: 'pointer',
    },
    contactUs: {
      marginTop: 10,
      fontSize: 16,
      color: '#1d79c4',
      textDecoration: 'underline',
    },
    content: {
      textAlign: 'center',
      fontFamily: 'bold',
    },
  });

export default withStyles(styles)(UnauthenticatedError);
