export default {
  english: 'English',
  japanese: '日本語',
  an_error_occured: '処理中にエラーが発生しました',
  dashboard: 'ダッシュボード',
  accounts: 'アカウント',
  sales: '売上高',
  notifications: 'お知らせ',
  admin_users: '管理者ユーザー',
  notification: 'お知らせ',
  contact: '連絡先',
  accounts_search_placeholder: 'アカウントUUID、AWSアカウントID、表示名、メールアドレスで検索',
  search_placeholder: 'アカウントUUID、AWSアカウントID、組織アカウント名、メールアドレスで検索',
  search_account_member_placeholder: 'アカウント名、メールアドレスで検索',
  search_aws_account_placeholder: 'AWSアカウント、AWSアカウントID、表示名、UUIDで検索',
  search_node_placeholder: 'クラスター名、ノード名で検索',
  search_header_input_placeholder: 'Search transactions, invoices or help',
  granted_date: '付与日',
  granted_date_value: '{{date, YYYY/MM/DD}}',
  coupon_name: 'クーポン名',
  expired_date: '有効期限',
  expired_date_value: '{{date, YYYY/MM/DD}}',
  coupon_balance: 'クーポン残高',
  account_UUID: 'アカウントUUID',
  display_name: '表示名',
  contact_email: 'メールアドレス',
  monthly_license_fee: '月間ライセンス料',
  balance: '残高',
  create_date: '作成日',
  create_date_value: '{{date, YYYY.MM.DD}}',
  last_login: 'ラストログイン',
  last_login_value: '{{date, YYYY.MM.DD}}',
  accountant_Email: '経理担当者メールアドレス',
  system_admin_Email: 'システム管理者メールアドレス',
  no_set: '未設定',
  show_more: 'さらに表示',
  lisense_type: 'ライセンスタイプ',
  size: 'サイズ',
  price: '価格',
  count_quanlity: 'カウント(数量)',
  sub_total: '小計',
  current_display: '現在の表示',
  total_sales: '売上高',
  to: '〜',
  type: 'タイプ',
  title: 'タイトル',
  date: '日付',
  date_hour_value: '{{date, YYYY/MM/DD HH:mm}}',
  email: 'メール',
  user_name: 'ユーザー名',
  created: '作成日',
  add_user: 'ユーザーの追加',
  add_aws: 'Add AWS',
  email_address: 'メールアドレス',
  are_you_sure_you_want_to_delete_this_user: 'このユーザーを削除しますか？',
  create: '作成',
  creating: '作成中',
  delete: '削除',
  deleting: '削除中',
  delete_user: 'ユーザーの削除',
  detail: '詳細',
  info: '情報',
  members: 'メンバー',
  networks: 'ネットワーク',
  coupons: 'クーポン',
  lock_account: 'アカウントロック',
  download_active_lisense_list: 'アクティブなライセンスリストをダウンロード',
  download_lisense_history: 'ライセンス履歴をダウンロード',
  download: 'ダウンロード',
  open_active_lisense_list: 'アクティブなライセンスリストを開く',
  open_lisense_history: '使用履歴を開く',
  open: '開く',
  true: 'TRUE',
  false: 'FALSE',
  date_value: '{{date, YYYY/MM/DD}}',
  start_date: '開始日',
  end_date: '終了日',
  date_format: 'YYYY/MM/DD',
  member_uuid: 'メンバーUUID',
  role: '権限',
  network_uuid: 'ネットワークUUID',
  network_id: 'ネットワークID',
  network_name: 'ネットワーク名',
  num_accounts: 'アカウント数',
  num_clusters: 'クラスター数',
  num_nodes: 'ノード数',
  overview: '概要',
  proposal: '提案',
  num_of_cluster: 'クラスター数',
  num_of_nodes: 'ノード数',
  cluster_id: 'クラスターID',
  blockchain_node_count: 'ノード数',
  approval_status: '承認ステータス',
  endpoint: 'エンドポイント',
  block_explorer: 'ブロックエクスプローラー',
  node: 'ノード',
  nodes: 'ノード',
  cluster: 'クラスター',
  node_name: 'ノード名',
  blockchain_id: 'ブロックチェインID',
  node_type: 'ノードタイプ',
  instance_id: 'インスタンスID',
  states: 'States',
  proposal_type: '提案タイプ',
  proposed_date: '提案日',
  approved_nodes: '承認されたノード',
  rejected_nodes: '拒否されたノード',
  num_of_singles: 'Signerノード数',
  alert_createAt: '{{date, YYYY/MM/DD - HH:mm:ss}}',
  user_createAt: '{{date, YYYY/MM/DD - HH:mm:ss}}',
  account_count: 'アカウント数',
  monthly: '月間',
  last_year: '前年',
  user_count: 'ユーザー数',
  total: '合計',
  tax: '消費税',
  log: 'Log',
  run: 'Run',
  running: 'Running',
  command: 'Command',
  download_the_full_log: 'すべてのログをダウンロード',
  infomation: 'インフォメーション',
  guest: 'ゲスト',
  member: 'メンバー',
  information_guest: 'インフォメーション - ゲスト',
  information_member: 'インフォメーション - メンバー',
  create_info: 'インフォメーションを作成',
  create_member_information: 'メンバーインフォメーションの作成',
  create_guest_information: 'ゲストインフォメーションの作成',
  importance: 'Importance',
  normal: 'ノーマル',
  important: '重要',
  link_url: 'リンク URL',
  text: 'テキスト',
  link: 'リンク',
  cancel: 'キャンセル',
  aws_accounts: 'AWSアカウント',
  aws_account: 'AWSアカウント',
  aws_account_id: 'AWSアカウントID',
  uuid: 'UUID',
  users: 'ユーザー数',
  plan: 'プラン',
  are_you_sure_you_want_to_add_aws_account: '本当にAWSアカウントを追加しますか？',
  license: 'ライセンス',
  history: '履歴',
  add_coupon: 'クーポンの追加',
  edit: '編集',
  change_account_type: '組織アカウントタイプを変更',
  remove_aws: 'AWS アカウントを削除する',
  remove: '削除する',
  are_you_sure_you_want_to_remove_aws_account: 'この AWS アカウントを削除してもよろしいですか?',
  enterprise: 'エンタープライズ',
  change: '変更',
  changing: '変更中',
  attention: 'アテンション',
  change_account_plan_dialog_content: '{{plan}}に変更しますか？',
  aws_account_not_exist: 'AWSアカウントが存在しません',
  aws_account_not_available: '利用可能なAWSアカウントがありません',
  aws_account_creation_failed: 'AWS アカウントの作成に失敗しました',
  billing_data_corrupted: '課金データが不整合のため集計できませんでした',
  you_cant_change_the_plan_the_account_select_same_plan:
    'この組織アカウントはエンタープライズプランを利用中か、または現在のものと同じプランを選択しているため変更できません',
  you_cant_change_the_plan_the_account_still_has_networks:
    'この組織アカウントに紐付くネットワークが存在するため、利用プランを変更出来ません',
  you_cant_change_the_plan_the_account_still_has_licenses:
    'この組織アカウントに紐付く有効なライセンスが存在するため、利用プランを変更出来ません',
  you_cant_change_the_plan_the_account_has_unpaid_bill:
    'この組織アカウントに未払いの請求があるため、利用プランを変更出来ません',
  add_coupon_dialog_title: 'クーポンの追加',
  edit_coupon_dialog_title: 'クーポンの編集',
  add_coupon_dialog_name_label: 'クーポン名',
  edit_coupon_dialog_name_label: 'クーポン名',
  add_coupon_dialog_name_place_holder: '初回登録キャンペーン',
  add_coupon_dialog_balace_label: 'クーポン金額（USD ドル単位）',
  add_coupon_dialog_balace_place_holder: 'クーポン残高',
  add_coupon_dialog_expired_label: '有効期限',
  edit_coupon_dialog_expired_label: '有効期限',
  adding: '作成中',
  amount_must_be_greater_than_zero: '金額は 0 より大きい数字を指定してください',
  invalid_date_format: '日付が正しくありません',
  validate_minimum: '{{ val }} 以上を入力してください',
  validate_maximum: '{{ val }} 以下で入力してください',
  trailing_spaces_not_allowed_to_name: '名前に空白文字列は使えません',
  required_field: 'この項目は必須です',
  amount_of_coupon_used: 'クーポン利用額',
  num_servers: '所有ノード数',
  status: 'Status',
  cluster_going_to_stop:
    'クラスターのバリデータをTxノードに変更することに失敗しました。インスタンスを停止します。',
  delete_soft: '削除 (Soft)',
  delete_hard: '削除 (Hard)',
  delete_hard_cluster_title: 'クラスター削除 (Hard)',
  delete_soft_cluster_title: 'クラスター削除 (Soft)',
  delete_node: 'ノード削除',
  delete_hard_cluster_warning:
    'Signerノードが残っているので、GoastのSignerノードは残ります。本当にこのクラスターを削除しますか？',
  delete_soft_cluster_warning:
    'SignerノードがTransactionノードに自動的に変更され、削除されます。このクラスターを削除しますか？',
  delete_cluster_input_placeholder: 'クラスター名を入力してください。',
  cluster_name: 'クラスター名',
  destroy_network_dialog_title: 'ネットワーク削除',
  number_of_nodes: 'ノード数',
  number_of_cluster: 'クラスター数',
  destroy_network_dialog_warning: '本当にこのネットワークを削除しますか？',
  destroy_network_dialog_input_placeholder: 'ネットワーク名を入力してください。',
  profile_settings: 'プロファイル設定',
  update: '更新',
  language: '言語',
  timezone: 'タイムゾーン',
  select_timezone: 'タイムゾーンを選択',
  input_display_name: '表示名を入力',
  select_language: '言語を選択',
  updating: '更新中',
  description: '詳細',
  organizations: '組織アカウント',
  organization_count: '{{ count }} 組織アカウント',
  organization_count_plural: '{{ count }} 組織アカウント',
  num_of_nodes_cluster: 'ノード/クラスター',
  node_count: 'ノード数',
  invalid_intake_host_configuration: 'DataDog ログ送信先の設定に問題があります',
  error_connecting_to_dataDog: 'DataDog ログ送信処理に失敗しました',
  no_token_provided: '認証トークンが指定されていません',
  session_cookie_does_not_set: '認証クッキーが見つかりません',
  account_not_exist: '組織アカウントが存在しません',
  invalid_email: '無効なメールアドレスです',
  invalid_email_address_format: 'メールアドレスの書式が正しくありません',
  can_not_find_out_administrator: '管理者ユーザーが見つかりません',
  can_not_update_Profile: 'プロファイルを更新できません',
  can_not_get_list_member: 'メンバー一覧を取得できません',
  can_not_add_member: 'メンバーを追加できません',
  can_not_remove_member: 'メンバーを削除できません',
  can_not_create_account: '組織アカウントを作成できません',
  can_not_update_account: '組織アカウントを更新できません',
  can_not_remove_account: '組織アカウントを削除できません',
  can_not_get_list_network: 'ネットワーク一覧を取得できません',
  can_not_create_network: 'ネットワークを作成できません',
  can_not_update_network: 'ネットワーク情報を更新できません',
  can_not_destroy_network: 'ネットワークを削除できません',
  can_not_create_cluster: 'クラスターを作成できません',
  can_not_update_cluster: 'クラスター情報を更新できません',
  can_not_get_list_network_accounts: 'ネットワークに関連付けられたアカウント一覧を取得できません',
  can_not_find_out_invoice: '請求情報が見つかりません',
  can_not_find_out_cluster: 'クラスターが見つかりません',
  can_not_find_out_node: 'ノードが見つかりません',
  can_not_register_address: '住所情報を登録できません',
  account_is_not_exist: '組織アカウントが見つかりません',
  can_not_find_out_network: 'ネットワークが見つかりません',
  can_not_find_out_cluster_to_update: '更新するクラスターが見つかりません',
  can_not_find_out_network_to_delete: '削除するネットワークが見つかりません',
  can_not_find_out_cluster_to_delete: '削除するクラスターが見つかりません',
  can_not_add_node: 'ノードを追加で来ません',
  can_not_find_out_network_to_update: '更新するネットワークが見つかりません',
  can_not_find_out_node_to_update: '更新するノードが見つかりません',
  can_not_update_node: 'ノード情報を更新できません',
  can_not_find_out_node_to_delete: '削除するノードが見つかりません',
  user_profile_does_not_exist: 'ユーザーのプロファイルが見つかりません',
  admin_profile_does_not_exist: '管理者のプロファイルが見つかりません',
  AWS_account_was_created_but_not_found_in_database:
    'AWSアカウントが作成されましたがデータベースに存在しません',
  the_cluster_has_all_of_validator_nodes_of_network:
    'Validator ノードを持つ唯一のクラスターにつき削除できません',
  the_node_is_last_one_of_network: 'ネットワークで唯一のノードにつき削除できません',
  sender_account_does_not_exist: '送信元アカウントが見つかりません',
  receiver_account_does_not_exist: '受信元アカウントが見つかりません',
  network_does_not_exist: 'ネットワークが見つかりません',
  node_does_not_exist: 'ノードが見つかりません',
  account_UUID_does_not_exist: 'ログインユーザーが選択中の組織アカウントが見つかりません',
  account_does_not_exist: 'アカウントが見つかりません',
  proposed_node_does_not_exist: '提案対象のノードが見つかりません',
  network_already_exist_in_account: 'ネットワークは既にアカウントと関連付けられています',
  invitation_does_not_exist: '招待が見つかりません',
  you_dont_have_permission_to_response_the_invitation: '招待を承認・拒否する権限がありません',
  invitation_was_already_responsed: '招待に返信済みです',
  invitation_was_canceled: '招待はキャンセル済みです',
  query_result_is_empty: '検索結果がありません',
  alert_does_not_exist: '通知が見つかりません',
  member_does_not_exist: 'ユーザーが存在しません',
  alert_already_set_read: '通知は既に既読となっています',
  possible_0_validator_node_can_not_send_proposal:
    'ネットワークから Validator ノードがなくなる可能性があるため提案を送信できません',
  admin_does_not_exist: '管理者ユーザーが見つかりません',
  failed_to_finded_user_profile_of_specified_uid: 'ユーザーのプロファイルが見つかりません',
  node_id: 'ノードID',
  organization: '組織',
  signing_address: '署名アドレス',
  enode: 'enode',
  region: '地域',
  instance_type: 'インスタンスタイプ',
  block_explorer_instance_type: 'ブロックエクスプローラのインスタンスタイプ',
  delete_node_warning: 'このノードを削除しますか？',
  delete_node_input_placeholder: 'ノード名を入力してください。',
  filter: 'フィルタ',
  all_user: 'すべてのユーザー',
  for_admin: '管理者向け',
  for_user: 'ユーザー向け',
  too_many_character_of_profile_name_error_message:
    'アカウント名は{{ value }}文字以内にしてください',
  too_many_character_of_coupon_name_error_message: 'クーポン名は{{ value }}文字以内にしてください',
  too_many_character_of_user_name_error_message: 'ユーザー名は{{ value }}文字以内にしてください',
  data_grid: {
    find_column: '項目を検索',
    print: '印刷',
    equals: '等しい',
    between: 'の間に',
    contains: 'を含む',
    startsWith: 'で始まる',
    endsWith: 'で終わる',
    isEmpty: '空です',
    isNotEmpty: '空ではありません',
    isAnyOf: 'のいずれか',
    is: 'は',
    isNot: 'ではありません',
    filters: 'フィルタ',
    filter_value: 'フィルタ値',
    operators: '条件',
    column_title: '項目のタイトル',
    reorder_column: '並べ替え列',
    show_all: '全て表示',
    hide_all: '全て非表示',
    columns: '項目',
    no_rows: '行なし',
    density: '表示サイズ',
    density_compact: '小',
    density_standard: '中',
    density_comfortable: '大',
  },
  refresh: 'リフレッシュ',
  action: 'アクション',
  account_type: '組織アカウントタイプ',
  // Summary Menu
  summary: '集計',
  summary_billing_filename:
    '{{startDate}}{{endDate}}{{product}}.支払い実績_{{yy}}年{{mm}}月{{dd}}日',
  start_month: '開始月',
  end_month: '終了月',
  paid: '支払済み',
  unpaid: '未払い',
  unpaid_exist: '未払い有り',
  download_csv: 'CSVダウンロード',
  billing_year: '支払い年',
  billing_month: '支払い月',
  billing_start_date: '対象開始日',
  billing_end_date: '対象終了日',
  product_id: '製品ID',
  product_name: '製品名',
  corporation_name: '法人名',
  organization_display_name: '組織アカウント名',
  account_uuid: 'アカウントUuid',
  transfer_address: '送信先アドレス',
  organization_owner: '組織のオーナー',
  owner_email: 'オーナーメールアドレス',
  accountant_email: '経理担当メールアドレス',
  billing_date: '請求日',
  billing_id: '請求ID',
  payment_status: '支払い状況',
  billing_amount: '請求金額',
  payment_failed: '決済失敗',
  license_item_id: 'ライセンスID',
  license_name: 'ライセンス名',
  unit_price: '単価',
  quantity: '数量',
  coupon_id: 'クーポンID',
  card_billing_amount: 'カード決済額USD',
  payment_exchange_rate: '支払為替レート',
  fee: '手数料',
  net: '純売上',
  stripe_status: 'ステータス',
  stripe_billing_date: '課金日付',
  license_amount: 'ライセンス合計額',
  download_datetime: '情報取得年月日時刻',
  succeeded: '成功',
  failed: '失敗',
  payment_summary: '支払い・クーポン利用実績',
  // Summary Coupon
  granted_year: 'クーポン付与年',
  granted_month: 'クーポン付与月',
  org_display_name: '組織アカウント名',
  coupon_type: 'クーポン種別',
  coupon_name_csv: 'クーポン名称',
  coupon_amount: '付与した金額',
  grantor_coupon: '付与者',
  usage_status_coupon: '利用状況',
  coupon_summary: 'クーポン付与実績',
  search_condition: '検索対象',
  corp_org_information: '法人および・組織アカウント情報',
  individual_coupon_summary: '個別クーポン実績',
  others: 'その他',
  summary_coupon_filename:
    '{{startDate}}{{endDate}}{{productname}}.クーポン付与実績_{{yy}}年{{mm}}月{{dd}}日',
  // Registration Modal
  howToRegisterAccount: '新規アカウントの登録方法',
  registration_title: '初回登録の方へ',
  registration_content:
    'はじめてG.U.Blockchain Cloudをお使いの方で、既にGoogleアカウントをお持ちの場合は、 [Login with Google Account]を選択していただいてGoogleアカウント認証いただくことで、初回登録が自動的に行われます。Googleアカウント以外のメールアドレスをご使用になる場合、 [Login with Email Address] ボタンを押していただき、登録したいメールアドレスを入力していただくと、初回登録用に名前とパスワード入力ボックスが出現しますので、そちらから初期登録を行ってください。',
  block: 'ブロック',
  activity: '活動',
  proposal_history: '提案履歴',
  all_proposals_reset: 'すべての提案がリセットされます',
  signer: 'Signer',
  transaction: 'Transaction',
  from_block: 'このブロックから',
  to_block: 'このブロックまで',
  address: '住所',
  error_alloc_address: '40桁の16進数（a-f、0-9）が必要です。',
  update_admin_user: '管理者ユーザーを更新する',
  select_role: 'ロールを選択する',
  owner: 'オーナー',
  admin: '管理者',
  operator: 'オペレーター',
  billing_operator: '請求オペレーター',
  are_you_sure_you_want_to_transfer_owner_to_this_admin:
    'この管理者ユーザーに所有者ロールを移行してもよろしいですか？',
  rebuild: '再構築',
  rebuild_node: 'ノードの再構築',
  are_you_sure_you_want_to_downgrade_this_node:
    'ノードのダウングレードでは、再構築してネットワーク データをリセットし、他のノードから再フェッチする必要があります。',
  are_you_sure_you_want_to_rebuild_this_node:
    'ノードを再構築すると、ネットワーク データがリセットされ、他のノードから再取得されます。\nこのノードを再構築しますか?',
  reboot: '再起動',
  change_node_version: 'ノード バージョンの更新',
  enable_rebuild_node: 'ノードの再構築を有効にする',
  apply_latest_genesis_config: '最新のジェネシス設定を適用する',
  change_block_gas_limit: 'Block Gas Limitを変更する',
  node_version: 'ノードのバージョン',
  latest_block: '最新ブロック',
  latest_hard_fork: '最新のハードフォーク',
  block_gas_limit: 'Block Gas Limit',
  input_gas_limit: 'Block Gas Limitを入力',
  genesis_json: 'genesis.json',
  submit: '送信',
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
  small_v1: 'Small (v1)',
  medium_v1: 'Medium (v1)',
  large_v1: 'Large (v1)',
  xlarge_v1: 'XLarge (v1)',
  '2xlarge_v1': '2XLarge (v1)',
  small_v2: 'Small (v2)',
  medium_v2: 'Medium (v2)',
  large_v2: 'Large (v2)',
  business_v2: 'Business (v2)',
  businesslarge_v2: 'Business Large (v2)',
  mega_v2: 'Mega (v2)',
  metal_v2: 'Metal (Max TPS) (v2)',
  small_v3: 'Small (v3)',
  medium_v3: 'Medium (v3)',
  large_v3: 'Large (v3)',
  business_v3: 'Business (v3)',
  businesslarge_v3: 'Business Large (v3)',
  mega_v3: 'Mega (v3)',
  metal_v3: 'Metal (Max TPS) (v3)',
  last_login_at: '最終ログイン日',
  create_org_account_limit: '組織アカウントの作成制限',
  new_accounts: '新しいアカウント',
  new_users: '新しい利用者',
  proposals: 'プロポーザル',
  debug: 'デバッグ',
  networks_search_placeholder: 'ネットワーク UUID、ネットワーク名で検索',
  num_of_accounts: 'アカウント数',
  num_of_users: 'ユーザー数',
  recent_users_in_month: '今月利用したユーザー',
  estimated_sales_in_month: '今月の推定売上高',
  num_of_networks: 'ネットワークの数',
  admin_notifications: '管理者への通知',
  user_notifications: 'ユーザーへの通知',
  last_error_in_month: '直近の30日間で最新の30件のみが表示されます',
  from: 'から',
  this_month: '今月',
  last_week: '先週',
  last_month: '先月',
  last_quarter: '先期',
  information: '情報',
  warning: '警告',
  error: 'エラー',
  add_member: 'メンバー追加',
  input_email_address: 'メールアドレスを入力',
  transfer_primary_owner: 'プライマリーオーナーを譲渡',
  delete_member: 'メンバー削除',
  are_you_sure_you_want_to_delete_this_member: '本当にこのメンバーを削除してもよろしいでしょうか？',
  Are_you_sure_you_want_to_transfer_the_primary_owner:
    'プライマリーオーナーを譲渡してもよろしいでしょうか？',
  date_time_format: 'MM/DD/YYYY - HH:mm',
  users_search_placeholder: '表示名、連絡先メールアドレスで検索',
  validator: 'Validator',
  relay: 'Relay',
  validator_node: 'Validator Node',
  relay_node: 'Relay Node',
  start: '起動',
  num_of_validators: 'Validatorノード数',
  version: 'バージョン',
  cluster_uuid: 'クラスターUUID',
  can_not_control_block_explorer: 'ブロックエクスプローラーを制御できません',
  forceReboot: '強制再起動',
  stop: '停止',
  block_exp_type: 'Block Explorer タイプ',
  clean_blockscout_db: ' Blockscout データベースをクリーンアップする',
  are_you_sure_you_want_to_clean_db:
    'Blockscout データベースをクリーンアップすると、データがリセットされ、アーカイブ ノードから再フェッチされます。\nBlockscout データベースをクリーンアップしてもよろしいですか?',
  for_days: '{{ value }} 日間',
};
