import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Switch, Route } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  blackRussianColor,
  gainsboroColor,
  dodgerBlue4Color,
  dimGrayColor,
} from '~/styles/themes/common-styles/color';
import { IStore } from '~/stores/configure-store';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import Layout from '../common/Layout';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';

// component
import AccountDetailInfo from './info-tab';
import AccountContact from './contact-tab';
import AccountDetailMembers from './members-tab';
import AccountNotifications from './notifications-tab';
import AccountDetailCoupons from './coupons-tab';
import NetworksTab from './networks-tab';
import AccountLicenseActive from './license-active-tab';
import AccountLicenseHistory from './license-history-tab';
import LoadingIcon from '~/components/common/loading-icon';

import * as AccountActions from '~/stores/actions/account-action';
import {
  IAccountMember,
  IAccountNetworkSummary,
  IAccountSummary,
  IAccountSummaryList,
  IAdminProfile,
} from '~/types/admin-types';

import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withAdminProfile } from '~/hooks/with-admin-profile';
import { compose } from 'redux';

import { AppRoutePath } from '~/AppRouter';
import { withAuthorization } from '~/hooks/with-authorization';
import ContactTab from './contact-tab';

interface StateProps {
  accountList: IAccountSummaryList;
  isGetAccountLoading: boolean;
  isListAccountMembersLoading: boolean;
  isListAccountNetworksLoading: boolean;
  profile?: IAdminProfile;
}

interface DispProps {
  getAccount: (
    args: AccountActions.QueryGetAccountArgs,
  ) => Promise<AccountActions.GET_ACCOUNT_RESULT_TYPE>;
  listAccountMembers: (
    args: AccountActions.QueryListAccountMembersArgs,
  ) => Promise<AccountActions.LIST_ACCOUNT_MEMBERS_RESULT_TYPE>;
  listAccountNetworks: (
    args: AccountActions.QueryListAccountNetworksArgs,
  ) => Promise<AccountActions.LIST_ACCOUNT_NETWORKS_RESULT_TYPE>;
}

interface Props
  extends StateProps,
    DispProps,
    RouteComponentProps<{ accountUuid: string; tab: string }>,
    WithTranslation,
    WithStyles<typeof styles> {}

interface State {
  account?: IAccountSummary;
  memberList?: Array<IAccountMember>;
  networkList?: Array<IAccountNetworkSummary>;
}

const LinkTab = (props) => <Tab component={RouterLink} {...props} />;

class AccountDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      account: undefined,
    };
  }

  componentDidMount() {
    this.getAccount();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.accountList !== this.props.accountList) {
      this.getAccount();
    }
  }

  public render() {
    const {
      classes,
      t,
      profile,
      isGetAccountLoading,
      isListAccountMembersLoading,
      isListAccountNetworksLoading,
    } = this.props;
    const { account, memberList, networkList } = this.state;
    const role = profile?.role || '';
    const showInfo = ['owner', 'admin', 'operator', 'billing_operator'].includes(role);
    const showContact = ['owner', 'admin', 'operator', 'billing_operator'].includes(role);
    const showAlerts = ['owner', 'admin', 'operator'].includes(role);
    const showMembers = ['owner', 'admin', 'operator'].includes(role);
    const showNetworks = ['owner', 'admin', 'operator'].includes(role);
    const showLicense = ['owner', 'admin', 'operator', 'billing_operator'].includes(role);
    const showHistory = ['owner', 'admin', 'operator', 'billing_operator'].includes(role);
    const showCoupon = ['owner', 'admin', 'operator', 'billing_operator'].includes(role);

    const { pathname } = this.props.location;
    const { accountUuid, tab } = this.props.match.params;
    const tabs: Array<{ label: string; tabName: string; url: string; component: JSX.Element }> = [];

    if (!tab) {
      this.props.history.push(`/account/${accountUuid}/info`);
    }

    if (account && !isGetAccountLoading) {
      if (showInfo) {
        tabs.push({
          label: t('info'),
          tabName: 'info',
          url: `/account/${accountUuid}/info`,
          component: <AccountDetailInfo account={account} />,
        });
      }
      if (showContact) {
        tabs.push({
          label: t('contact'),
          tabName: 'contact',
          url: `/account/${accountUuid}/contact`,
          component: <AccountContact account={account} />,
        });
      }
      if (showAlerts) {
        tabs.push({
          label: t('notification'),
          tabName: 'notification',
          url: `/account/${accountUuid}/notification`,
          component: <AccountNotifications account={account} />,
        });
      }
      if (showMembers) {
        tabs.push({
          label: t('members'),
          tabName: 'members',
          url: `/account/${accountUuid}/members`,
          component: (
            <AccountDetailMembers
              account={account}
              members={memberList}
              listAccountMembers={this.listAccountMembers}
              isLoading={isListAccountMembersLoading}
            />
          ),
        });
      }
      if (showNetworks) {
        tabs.push({
          label: t('networks'),
          tabName: 'networks',
          url: `/account/${accountUuid}/networks`,
          component: (
            <NetworksTab
              isLoading={isListAccountNetworksLoading}
              networks={networkList}
              account={account}
              listAccountNetworks={this.listAccountNetworks}
            />
          ),
        });
      }
      if (showLicense) {
        tabs.push({
          label: t('license'),
          tabName: 'license',
          url: `/account/${accountUuid}/license`,
          component: <AccountLicenseActive account={account} />,
        });
      }
      if (showHistory) {
        tabs.push({
          label: t('history'),
          tabName: 'history',
          url: `/account/${accountUuid}/history`,
          component: <AccountLicenseHistory account={account} />,
        });
      }
      if (showCoupon) {
        tabs.push({
          label: t('coupons'),
          tabName: 'coupons',
          url: `/account/${accountUuid}/coupons`,
          component: <AccountDetailCoupons account={account} />,
        });
      }
    }

    const tabValue = tabs
      .map((tab, index) => tab.tabName)
      .findIndex((tabName) => pathname.includes(tabName));

    return (
      <Layout>
        <div className={classes.root}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Link
              data-testid="navigate-to-dashboard"
              component={RouterLink}
              to={AppRoutePath.Dashboard}
              color="text.primary"
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            </Link>
            <Link
              data-testid="navigate-to-account"
              component={RouterLink}
              to={AppRoutePath.Accounts}
              color="text.primary"
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {t('accounts')}
            </Link>
            <Typography color="text.primary">{accountUuid ? `ID: ${accountUuid}` : ''}</Typography>
          </Breadcrumbs>
          <div className={classes.contentPaper}>
            {isGetAccountLoading && (
              <div className={classes.loadingArea}>
                <LoadingIcon />
              </div>
            )}
            {!isGetAccountLoading && !account && <div>Account doesn't exist</div>}
            {!!tabs.length && (
              <>
                <Tabs
                  id="admin-account-tab"
                  value={tabValue}
                  indicatorColor="primary"
                  classes={{
                    root: classes.tabsRoot,
                    indicator: classes.tabIndicator,
                  }}
                  textColor="primary"
                >
                  {tabs.map((val) => (
                    <LinkTab
                      data-testid="navigate-to-tab"
                      classes={{ root: classes.tabCustom, selected: classes.tabSelected }}
                      label={val.label}
                      to={val.url}
                    />
                  ))}
                </Tabs>
                {tabs.map((tab, index) => {
                  if (!pathname.includes(tab.tabName)) {
                    return null;
                  }
                  return <>{tab.component}</>;
                })}
              </>
            )}
          </div>
        </div>
      </Layout>
    );
  }

  private getAccount = async () => {
    const { accountUuid } = this.props.match.params;
    const { accountList } = this.props;
    let account: IAccountSummary | undefined = (accountList.accounts || []).find(
      (account) => account.accountUuid === accountUuid,
    );
    if (!account) {
      try {
        account = (await this.props.getAccount({ accountUuid })).getAccount;
      } catch (error) {
        // Do nothing
      }
    }
    this.setState({
      account,
    });
  };

  private listAccountMembers = async () => {
    const { account } = this.state;
    if (account) {
      const members = (await this.props.listAccountMembers({ accountUuid: account.accountUuid }))
        .listAccountMembers;
      this.setState({
        memberList: members,
      });
    }
  };

  private listAccountNetworks = async () => {
    const { account } = this.state;
    if (account) {
      const networks = (await this.props.listAccountNetworks({ accountUuid: account.accountUuid }))
        .listAccountNetworks;
      this.setState({
        networkList: networks,
      });
    }
  };
}

const styles = createStyles({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 'auto',
    paddingRight: 0,
  },
  loadingArea: {
    margin: '100px 0',
    textAlign: 'center',
  },
  headerLabel: {
    color: `${dimGrayColor} !important`,
  },
  tabsRoot: {
    borderBottom: `1px solid ${gainsboroColor}`,
  },
  tabIndicator: {
    background: dodgerBlue4Color,
    width: '145px !important',
    '@media (min-width: 576px)': {
      width: '125px !important',
    },
  },
  tabSelected: {
    color: `${dodgerBlue4Color} !important`,
  },
  tabCustom: {
    ...defaultFont,
    textTransform: 'none',
    fontSize: 17,
    minWidth: '145px !important',
    '@media (min-width: 576px)': {
      minWidth: '125px !important',
    },
  },
  contentPaper: {
    marginTop: 24,
    boxShadow: `0 2px 6px 0 ${blackRussianColor}`,
    border: `solid 1px ${gainsboroColor}`,
    borderRadius: 4,
    backgroundColor: whiteColor,
  },
});

const mapStateToProps = (store: IStore): StateProps => ({
  profile: store.appState.profile,
  accountList: store.appState.accountList,
  isGetAccountLoading: AccountActions.getAccount.isPending(store),
  isListAccountMembersLoading: AccountActions.listAccountMembers.isPending(store),
  isListAccountNetworksLoading: AccountActions.listAccountNetworks.isPending(store),
});

const mapDispatchToProps = (dispatch): DispProps => ({
  getAccount: (args: AccountActions.QueryGetAccountArgs) =>
    dispatch(AccountActions.getAccount(args)),
  listAccountMembers: (args: AccountActions.QueryListAccountMembersArgs) =>
    dispatch(AccountActions.listAccountMembers(args)),
  listAccountNetworks: (args: AccountActions.QueryListAccountNetworksArgs) =>
    dispatch(AccountActions.listAccountNetworks(args)),
});

export default compose(
  withFirebaseUser(),
  withAdminProfile,
  withAuthorization(['owner', 'admin', 'operator', 'billing_operator']),
  withStyles(styles),
  withTranslation('admin'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AccountDetail);
