import { createTypeAsyncAction } from '@gu-corp/redux-async-lib';
import AdminGApiClient from './gapi/admin-gapi-client';
import { IStore } from '~/stores/configure-store';
import { QueryListUsersArgs } from '~/gapi/gtypes';
export * from './gapi/query/user';

export const listUsers = createTypeAsyncAction(
  'LIST_USERS',
  async (args: QueryListUsersArgs, store: IStore) => {
    const { userList } = store.appState;
    const client = new AdminGApiClient({});
    return client.listUsers({
      ...args,
      page: {
        pageIndex: userList.pageInfo.pageIndex,
        pageSize: userList.pageInfo.pageSize,
        ...args.page,
      },
      filter: {
        ...userList.filter,
        ...args.filter,
      },
      order: args.order || userList.order,
    });
  },
);
