import React from 'react';
import { connect } from 'react-redux';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
  defaultFontRegular,
} from '~/styles/themes/common-styles/font';
import {
  snowColor,
  pattensBlueColor,
  nightRiderColor,
  lightSlateGreyColor,
  romanColor,
  dimGrayColor,
  whiteSmokeColor,
} from '~/styles/themes/common-styles/color';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogAction from '~/components/common/custom-dialog-actions';
import CustomInput from '~/components/common/custom-input';
import { IStore } from '~/stores/configure-store';
import * as NetworkActions from '~/stores/actions/network-action';
import { Item } from '..';
import SubmitButton from '~/components/common/custom-submit-button';

interface StateProps {}
interface DispProps {
  destroyNetwork: (
    args: NetworkActions.MutationDestroyNetworkArgs,
  ) => Promise<NetworkActions.DESTROY_NETWORK_RESULT_TYPE>;
}

interface Props extends StateProps, DispProps, WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
  network: Item;
}

interface State {
  isSubmitting: boolean;
  inputValue: string;
}

class DeleteNetwork extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isSubmitting: false,
      inputValue: '',
    };
  }

  public render() {
    const { classes, t, open, network } = this.props;
    const { isSubmitting, inputValue } = this.state;

    return (
      <CustomDialog open={open} onClose={this.onClose}>
        <CustomDialogTitle className={classes.dialogTitle}>
          {t('destroy_network_dialog_title')}
        </CustomDialogTitle>
        <CustomDialogContent classes={{ root: classes.dialogContent }}>
          <div className={classes.clusterInfo}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <div className={classes.clusterInfoItemLabel}>{t('network_name')}</div>
                <div className={classes.clusterInfoItemValue}>{network.networkName}</div>
              </Grid>
              <Grid item sm={6}>
                <div className={classes.clusterInfoItemLabel}>{t('network_id')}</div>
                <div className={classes.clusterInfoItemValue}>{network.networkUuid}</div>
              </Grid>
              <Grid item sm={6}>
                <div className={classes.clusterInfoItemLabel}>{t('number_of_nodes')}</div>
                <div className={classes.clusterInfoItemValue}>{network.numOfNodes}</div>
              </Grid>
              <Grid item sm={6}>
                <div className={classes.clusterInfoItemLabel}>{t('number_of_cluster')}</div>
                <div className={classes.clusterInfoItemValue}>{network.numOfClusters}</div>
              </Grid>
            </Grid>
          </div>
          <div className={classes.warning}>{t('destroy_network_dialog_warning')}</div>
          <div className={classes.input}>
            <div className={classes.inputLabel}>{t('network_name')}</div>
            <CustomInput
              value={inputValue}
              onChange={this.onInputChange}
              placeholder={t('destroy_network_dialog_input_placeholder')}
            />
          </div>
        </CustomDialogContent>
        <CustomDialogAction classes={{ root: classes.dialogAction }}>
          <Button
            data-testid="close-delete-network-dialog-button"
            variant="outlined"
            disabled={isSubmitting}
            onClick={this.onClose}
            className={classes.cancelBtn}
          >
            {t('cancel')}
          </Button>
          <SubmitButton
            id="delete-network-submit"
            data-testid="submit-delete-network-dialog-button"
            isLoading={isSubmitting}
            label={t('delete')}
            submittingLabel={t('deleting')}
            disabled={isSubmitting || inputValue !== network.networkName}
            onClick={this.onSubmit}
          />
        </CustomDialogAction>
      </CustomDialog>
    );
  }

  onInputChange = (e) => {
    this.setState({
      inputValue: e.target.value,
    });
  };

  onSubmit = async () => {
    const { destroyNetwork, network } = this.props;
    this.setState({ isSubmitting: true });
    try {
      await destroyNetwork({
        input: {
          networkUuid: network.networkUuid,
        },
      });
      this.onClose();
    } catch (error) {
      // Do nothing
    }
    this.setState({ isSubmitting: false });
  };

  onClose = () => {
    this.props.onClose();
    this.onResetState();
  };

  onResetState = () => {
    this.setState({
      isSubmitting: false,
      inputValue: '',
    });
  };
}

const styles = createStyles({
  dialogTitle: {},
  // content
  dialogContent: {},
  clusterInfo: {
    border: `1px solid ${pattensBlueColor}`,
    borderRadius: 4,
    backgroundColor: snowColor,
    padding: 30,
  },
  clusterInfoItemLabel: {
    ...defaultFontMedium,
    fontSize: 12,
    color: nightRiderColor,
    textAlign: 'left',
  },
  clusterInfoItemValue: {
    ...defaultFontRegular,
    fontSize: 12,
    color: lightSlateGreyColor,
    textAlign: 'left',
  },
  warning: {
    marginTop: 30,
    ...defaultFontRegular,
    fontSize: 18,
    lineHeight: '24px',
    color: romanColor,
    textAlign: 'center',
  },
  input: {
    marginTop: 30,
  },
  inputLabel: {
    ...defaultFontBold,
    fontSize: 12,
    color: nightRiderColor,
    marginBottom: 5,
  },
  // actions
  dialogAction: {},
  cancelBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    marginRight: 10,
    marginLeft: 0,
    width: 115,
  },
});

const mapStateToProps = (store: IStore): StateProps => ({});

const mapDispatchToProps = (dispatch): DispProps => ({
  destroyNetwork: (args: NetworkActions.MutationDestroyNetworkArgs) =>
    dispatch(NetworkActions.destroyNetwork(args)),
});

export default withStyles(styles)(
  withTranslation('admin')(connect(mapStateToProps, mapDispatchToProps)(DeleteNetwork)),
);
