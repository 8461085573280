export default {
  hello: 'こんにちは',
  brand: 'G.U. Blockchain Cloud',
  already_have_an_account_log_in_here: 'すでに組織アカウントをお持ちの方 → ',
  login_here: 'こちら →',
  get_started: '始める',
  why_GU_Chain: 'Blockchain Cloudとは？',
  solutions: '利用例',
  plan: 'Plan',
  plans: 'プラン',
  members: 'メンバー',
  projects: 'プロジェクト',
  users: 'ユーザ',
  storage: 'ストレージ',
  more: 'More',
  month: 'month',
  let_is_try: "Let's Try",
  what_are_you_waiting_for: 'さあ、ブロックチェーンを始めましょう。',
  contact_sales_detail:
    '使い方は根本的にシンプルで、誰もがシームレスに体験でき、どこからでもアクセスできます。',
  pricing: '価格',
  contact: 'お問い合わせ',
  sign_in: 'ログイン',
  congraturation: 'Congraturation!',
  you_can_successfully_create_a_new_account: '組織アカウントが作成されました',
  have_a_nice_blockchain_world: 'ブロックチェーンの世界にようこそ',
  start: '始める',
  'loading...': '読込中...',
  login_success: 'ログイン成功',
  please_complete_email_verification: 'メールの確認を完了してください',
  please_select_login_provider: 'ログインに利用するアカウントを選択してください。',
  singin_by_xxx: 'ようこそ {{ name }} さん。ログインしています...',
  signin_take_a_log_time: '組織アカウント情報を登録しています。しばらくお待ち下さい...',
  copyright_2016_openid_foundation: 'Copyright 2016 OpenID Foundation',
  about_account: '組織アカウントについて',
  chooser_privacy_policy: 'ChooserPrivacy Policy',
  'G.U.Blockchain Cloud': 'G.U.Blockchain Cloud',
  'subscribe_to_g.u.chain_via_email': 'Subscribe to G.U.Chain via Email',
  please_register_email: 'G.U.Chain の最新情報をお届けします',
  subscribe: '購読',
  FIND_A_STORE: 'FIND A STORE',
  CONTACT_US: 'CONTACT US',
  'ORDERING_&_PAYMENT': 'ORDERING & PAYMENT',
  SHIPPING: 'SHIPPING',
  RETURNS: 'RETURNS',
  FAQ: 'FAQ',
  SIZING_GUIDE: 'SIZING GUIDE',
  'ABOUT_G.U.Chain': 'ABOUT G.U.Chain',
  WORK_WITH_US: 'WORK WITH US',
  PRIVACY_POLICY: 'PRIVACY POLICY',
  'TERMS_&_CONDITIONS': 'TERMS & CONDITIONS',
  PRESS_ENQUIRIES: 'PRESS ENQUIRIES',
  started_section_description:
    "It's radically simple to use and universally accessible with a seamless experience for all.",
  why_GU_chain_description:
    'Create, customize and manage your private chain or consortium chain for your business with click button simplicity and save months and millions.',
  landing_page_subtitle:
    'The Enterprise Ethereum Blockchain Cloud platform for building global, consitium networks.',
  sub_total: 'Sub Total',
  basic_support: 'Basic Support',
  total_monthly_cost: 'Total Money Cost',
  '10GB_storage': '10GB Storage',
  nodes: 'Nodes',
  easy_to_use_ui_ux: '使いやすいUI/UX',
  easy_to_use_ui_ux_detail:
    '複雑なブロックチェーン・ネットワーク運用をいかに簡単にするか、というテーマをもとに、Blockchain Cloudは作られました。非常に高度なエンジニアでなくてもBlockchain Cloudを利用すれば簡単に独自ブロックチェーンの作成・運用保守が可能です。更に組織をまたいだコンソーシアムチェーンも簡単に作成・利用することが出来ます。',
  easy_to_create_blockchain: 'ブロックチェーンを簡単に構築',
  easy_to_create_blockchain_detail:
    'あなたのブロックチェーン・ネットワークを立ち上げるのに必要なことは、ボタンを押し必要事項を入力するだけです。数分待つだけであなたのネットワークが立ち上がり、統計情報確認画面ではネットワークが動作していることが直ぐに確認出来ます。立ち上がったネットワークを利用するためのEndpoint URLを通じて、適切にロードバランシングされた可用性の高いブロックチェーンネットワークに対する開発もすぐに始めることが出来ます。',
  smart_monitoring: 'スマートモニタリング',
  smart_monitoring_detail:
    '安定したブロックチェーンネットワークを運用するためには運用ツールが必要です。Blockchain Cloudのノードごとのメトリクス画面では ノードごとの状態を簡単に確認することができます。CPU、メモリ、ストレージ、ピア数など 様々な情報をグラフィカルに確認することができます。',
  read_more: '詳しくはこちら',
  about_gu_chain_cloud: 'G.U. Blockchain Cloud について',
  get_free: '始める',
  contact_sales: 'お問い合わせ',
  easy_to_make_block_chain: '誰でも簡単に立ち上げ',
  easy_to_make_block_chain_des:
    '自分たちのカスタマイズされたブロックチェーンをものの数分で立ち上げることが可能です。',
  simple_and_easy_to_use: '使いやすいインターフェースを',
  simple_and_easy_to_use_des:
    '誰でもトレーニングする必要なく簡単に利用できるユーザインタフェースを実現しました。',
  enterprise_grade: '企業利用でも安心',
  enterprise_grade_des:
    'エンタープライズでの本番運用にも耐えうるサポートにより、ブロックチェーンネットワークの運用を支援します。',
  cookie_settings: 'Cookie設定',
  accept_use_of_cookies: 'Accept Use of Cookies',
  accept_call_cookies: 'すべてのCookieを受け入れる',
  blockchain_for_modern_business_network: 'ブロックチェーン構築運用サービスの決定版',
  blockchain_for_modern_business_network_detail:
    'G.U.Blockchain Cloudは、ビジネス運用のためのブロックチェーン構築と保守を飛躍的に簡単にするクラウドサービスです。',
  accept_use_of_cookies_detail:
    '当社では、当社のトラフィックを分析するためにクッキーを使用しています。また、お客様のサイト利用に関する情報を、お客様が提供した他の情報や、お客様のサービス利用から収集した情報と組み合わせた分析パートナーと共有します。',
  from: 'から',
  total_per_month: 'Total ( per month )',
  view_more: '詳しく見る',
  pricing_detail:
    'G.U.Blockchain Cloudは、ビジネス運用のためのブロックチェーン構築と保守を飛躍的に簡単にするクラウドサービスです。',
  learn_more: 'Learn More',
  for_starter: 'For Starter',
  for_professional: 'For Professional',
  starting_at: 'Starting at',
  example: 'Example',
  developer: 'Developer',
  standard: 'Standard',
  enterprise: 'Enterprise',
  prices: 'Prices',
  hour: 'hour',
  tps_limitation: 'TPS Limitation',
  start_free: '新規登録',
  free_trial: 'Free Trial',
  single_cluster: 'シングルクラスター',
  single_node: 'Single Node',
  multiple_clusters: 'マルチクラスター',
  multiple_node: 'Multiple Node',
  free: '無料',
  plan_price_from: '<1>${{price}}</1>〜',
  start_with_coupon: '${{price}}クーポンを使って始める',
  about_gu_chain_cloud_detail:
    '数クリックでビジネスのプライベートチェーンやコンソーシアムチェーンを作成、カスタマイズ、管理ができ、構築の時間と費用を節約できます。',
  privacy_policy: 'プライバシーポリシー',
  support: 'サポート',
  terms: '規約',
  commerce: '特定商取引法に基づく表記',
  single_cluster_detail:
    'ブロックチェーンの学習やテストアプリケーションを開発する為のチーム向けプランです。14日間の無料トライアルができます。',
  multiple_node_detail:
    'ブロックチェーンの学習やテストアプリケーションを開発する為のチーム向けプランです。14日間の無料トライアルができます。',
  '14days_free': '14日間無料',
  multiple_Nodes: 'Multiple Nodes',
  for_business: 'For Business',
  for_enterprise: 'For Enterprise',
  gu_blockchain_cloud_footer: '© G.U.Technologies, Inc.',
  not_found_page_title: 'Not Found',
  not_found_page_description: 'お探しのページが存在しないか、移動されています。',
  not_found_page_top_page_button_label: 'トップページ',
  not_found_page_contact_us_text: 'お問い合わせはこちら',
  error_page_title: 'Error',
  error_page_description: 'システムエラーが発生しました。<br>時間をおいてお試しください。',
  error_page_top_page_button_label: 'トップページ',
  error_page_contact_us_text: 'お問い合わせはこちら',
  for_developer_test: 'For Developer, Test',
  for_developer_test_detail:
    'ブロックチェーン学習の初心者やオープンなテストネットでは不適当な製品開発時のテストネットワークの構築に最適な構成です。',
  for_production: 'For Production',
  for_production_detail:
    '平均tpsが1-10程度、かつ最大200tps程度で、Readのアクセスが月間3000万リクエスト超えない小規模なプロダクションレベルのネットワークに最適な構成です。',
  for_business_detail:
    '平均200tps程度、最大はそれ以上のReadアクセスが月間5億リクエストを超えない中規模なプロダクションレベルのネットワークに最適な構成です。',
  server_cost: 'サーバコスト',
  ethereum_node_price: 'Ethereum Node価格',
  price_page_prices: '価格',
  price_page_ethereum_node_price: 'Ethereum Node Price',
  price_page_storage: 'Storage',
  price_page_cluster: 'Cluster',
  price_page_month: '価格（月額）',
  price_page_hour: '一時間あたり',
  micro: 'Micro',
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
  extra_large: 'Extra Large',
  basic_capacity_unit_1gb: '基本容量単位 1GB',
  default_capacity: 'ストレージ容量',
  cluster: 'Cluster',
  assumed_average_tps: '想定平均TPS',
  limit_req_month: '月間リクエスト上限',
  enterprise_cluster: 'Enterprise Cluster',
  contact_us: 'Contact Us',
  unlimited: 'unlimited',
  blockchain_exporer_license: 'Blockchain Exporer License',
  storage_price: 'ストレージ価格',
  total: '合計',
  reference_composition: '参考構成',
  price_page_reference_description: 'それぞれの構成を選んだときの見積もり価格です。',
  lisence_detail_link: 'ライセンスの詳細に関してはここをクリック',
  plan_page_title: 'プラン',
  plan_page_description:
    'G.U.Blockchain Cloudは、ビジネス運用のためのブロックチェーン構築と保守を飛躍的に簡単にするクラウドサービスです。',
  normal: 'Normal',
  plan_page_normal_description:
    'クレジットカード決済により利用できるノードです。Small以上のクラスタを利用する場合である程度長期間利用することが想定される場合は、請求払いにも対応可能ですので、コンタクトよりお問い合わせ下さい。',
  plan_page_enterprise_description:
    '人的なサポートも含めた、カスタマイズ可能なブロックチェーンをお望みの場合は、Enterpriseプランを選択できます。Enterpriseプランをお望みの場合は、コンタクトよりお問い合わせください。',
  cluster_type: 'Cluster Type',
  ethereum_node: 'Ethereum Node',
  blockchain_explorer: 'Blockchain Explorer',
  multi_user: 'Multi User',
  private_network: 'Private Network',
  consotium_network: 'Consotium Network',
  unlimited_endpoint: 'Unlimited Endpoint',
  multi_cluster: 'Multi Cluster',
  multi_resion: 'Multi Resion',
  enterprise_support: 'Enterprise Support',
  on_premise: 'On-Premise',
  plan_page_learn_more_ethereum_node_title: 'Ethereum Node',
  plan_page_learn_more_ethereum_node_description:
    '一つのブロックチェーンノードを構成するためのサーバです。',
  plan_page_learn_more_storage_title: 'Storage',
  plan_page_learn_more_storage_description:
    'ブロックチェーンデータが保存される不揮発性のストレージです。',
  plan_page_learn_more_cluster_title: 'Cluster',
  plan_page_learn_more_cluster_description:
    'すべてのクラスタには、ロードバランサー及びWAFによるDoSアタック防止機構が含まれています。月間のリクエストを超えた場合は、アクセスが遮断されます。',
  maximum_monthly_requests: '月間最大リクエスト数',
  plan_page_learn_more_license_title: 'Blockchain Exporer License',
  plan_page_learn_more_license_description:
    'ブロックチェーンの状態をグラフィカルかつリアルタイムに表示することができるツールです。',
  law_sale_page_title: '特定商取引法に基づく表記',
  pricing_plan_page_pricing_detail_ethereum_node: 'Ethereum Node',
  pricing_plan_page_pricing_detail_ethereum_node_description:
    '一つのブロックチェーンノードを構成するためのサーバです。',
  pricing_plan_page_pricing_detail_server_cost: 'サーバコスト',
  pricing_plan_page_pricing_detail_assumed_tps: '想定TPS（書き込み速度）',
  pricing_plan_page_pricing_detail_storage_base_unit: 'ストレージ（基本単位1GB/月額：$0.20）',
  pricing_plan_page_pricing_detail_price_monthly: '価格（月額）',
  pricing_plan_page_pricing_detail_per_hour: '一時間あたり',
  pricing_plan_page_pricing_detail_average: '平均',
  pricing_plan_page_pricing_detail_maximum: '最大',
  pricing_plan_page_pricing_detail_default_capacity: '既定の容量',
  pricing_plan_page_pricing_detail_ethereum_node_bottom_note:
    '※Micro、Small、Microのみ、ある程度高負荷状態が続くと20%まで能力が削減されます。',
  pricing_plan_page_pricing_detail_cluster: 'Cluster',
  pricing_plan_page_pricing_detail_cluster_description:
    'すべてのクラスタには、ロードバランサー及びWAFによるDoSアタック防止機構が含まれています。月間のリクエストを超えた場合は、アクセスが遮断されます。',
  pricing_plan_page_pricing_detail_estimated_rps: '想定RPS（平均リクエスト速度）',
  pricing_plan_page_pricing_detail_monthyly_request_limit: '月間リクエスト上限',
  pricing_plan_page_pricing_detail_blockchain_exporer_license: 'Blockchain Exporer License',
  pricing_plan_page_pricing_detail_blockchain_exporer_license_description:
    'ブロックチェーンの状態をグラフィカルかつリアルタイムに表示することができるツールです。',
  pricing_plan_page_pricing_detail_server_price: 'サーバー価格',
  pricing_plan_page_pricing_detail_storage: 'ストレージ',
  pricing_plan_page_pricing_detail_storage_prices: 'ストレージ価格',
  pricing_plan_page_pricing_detail_monthly_total_price: '合計価格（月額）',
  pricing_plan_page_pricing_detail_erc20_note:
    '※想定TPS及び想定RPSは、ERC20トークンの送金によるスマートコントラクト実行を測定した数値を元にしています。',
  pricing_plan_page_pricing_detail_minimum_invoice_note: '※最低請求金額は、$0.5となります。',
  pricing_plan_page_cluster_type: 'Cluster Type',
  admin_sign_in: 'サインインしてください',
  not_permission_page_description: 'コンテンツを閲覧する権限がありません',
};
