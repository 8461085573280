import { GridSortDirection } from '@mui/x-data-grid';

// Sorting
export const DATA_GRID_SORTING_ORDER: GridSortDirection[] = ['desc', 'asc'];
// Pagination
export const ROWS_PER_PAGE_OPTIONS: Array<number> = [10, 20, 30];
export const DEFAULT_PAGE: number = 0;
export const DEFAULT_PAGE_SIZE = 10;

export const CONVERT_PRICE_TO_CURRENCY = 100;
export const LICENSE_TYPE_ID_STORAGE = 7;
export const PRODUCT_NAME = 'B.C Cloud';
export const MAX_CSV_OUTPUT_DATA = 10000;
export const MAX_CSV_COUPON_DATA = 10000;
export const DEFAULT_ACIDLEN_AMAZON = 12;
export const PAYMENT_TYPE_RESULT_FAIL = 0;
export const PAYMENT_TYPE_RESULT_OK = 1;
export const DEFAULT_COUPON_BALANCE_ADMIN_MAX = 100000;
export const DEFAULT_COUNTRY_CODE = 'JP';
export const DEFAULT_DASH_NOTIFICATIONS_SIZE = 30;

export const LOCALE_SUPPORTED: {
  lang: string;
  code: string;
  name: string;
}[] = [
  {
    lang: 'en',
    code: 'en-US',
    name: 'English',
  },
  {
    lang: 'ja',
    code: 'ja-JP',
    name: 'Japanese',
  },
];

// Tax
export const TAX_PERCENTAGE = '10%';
export const INVOICE_NO = 'T7011001136239';

export const DEFAULT_GAS_LIMIT = 100000000,
  MIN_GAS_LIMIT = 5000,
  MAX_GAS_LIMIT = Number.MAX_SAFE_INTEGER; // Use max safe int js https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER

// Hard fork list
export const HARD_FORK_CONFIGURATION: Array<{
  label: string;
  value: string;
  nameInGenesis: string;
  order: number;
  incompatibleGeths: Array<string>;
}> = [
  {
    label: 'MuirGlacier',
    value: 'muirGlacier',
    nameInGenesis: 'muirGlacierBlock',
    order: 6,
    incompatibleGeths: [],
  },
  {
    label: 'Berlin',
    value: 'berlin',
    nameInGenesis: 'berlinBlock',
    order: 9,
    incompatibleGeths: ['1.9.25'],
  },
  {
    label: 'London',
    value: 'london',
    nameInGenesis: 'londonBlock',
    order: 10,
    incompatibleGeths: ['1.9.25'],
  },
];
