import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import red from '@mui/material/colors/red';
import orange from '@mui/material/colors/orange';
import { Box, Grid, IconButton } from '@mui/material';
import { AlertType, AlertTitle } from '~/gapi/gtypes';
import {
  blackRussianColor,
  hawkesBlueColor,
  whiteColor,
} from '~/styles/themes/common-styles/color';
import classNames from 'classnames';

interface IProps extends WithStyles<typeof styles> {
  title: string;
  link: string;
  loading: boolean;
  notifications: Array<{ alertUuid: string; title: AlertTitle; type: AlertType; createAt: string }>;
}

const NotificationSummary = (props: IProps) => {
  const { classes, loading, title, notifications, link } = props;
  const { t, i18n } = useTranslation('admin');

  const renderNotifications = useMemo(() => {
    return notifications.map((val) => {
      return (
        <RouterLink
          id="dashboard-summary-box"
          to={`${link}/${val.alertUuid}`}
          data-testid="navigate-to-notification-detail"
        >
          <ListItem
            key={val.alertUuid}
            className={classNames(classes.listItem, val.type === 'error' && classes.errorItem)}
          >
            <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'}>
              <Grid item xs={10}>
                <Typography
                  variant="subtitle1"
                  color={val.type === 'error' ? red[700] : orange[700]}
                >
                  {val.title[i18n.language]}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {t('date_hour_value', { date: new Date(val.createAt) })}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        </RouterLink>
      );
    });
  }, [classes.errorItem, classes.listItem, i18n.language, link, notifications, t]);

  return (
    <Box className={classes.root} boxShadow={1} padding={2} borderRadius={1}>
      <div className={classes.header}>
        <Typography gutterBottom variant="subtitle1" fontWeight={450} component="div">
          {title}&nbsp;
          <Typography component={'span'} variant="body2" color="text.secondary">
            ({t('last_error_in_month')})
          </Typography>
        </Typography>
        <RouterLink
          id="dashboard-summary-box"
          to={link}
          data-testid="navigate-to-notification-page"
        >
          <IconButton>
            <OpenInNewIcon />
          </IconButton>
        </RouterLink>
      </div>

      {loading ? (
        <>
          <Skeleton animation="wave" height={20} style={{ marginBottom: 10 }} />
          <Skeleton animation="wave" height={20} style={{ marginBottom: 10 }} />
          <Skeleton animation="wave" height={20} style={{ marginBottom: 10 }} />
        </>
      ) : (
        !!renderNotifications.length && (
          <List className={classes.listNotifications}>{renderNotifications}</List>
        )
      )}
    </Box>
  );
};

const styles = createStyles({
  root: {
    backgroundColor: whiteColor,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  listItem: {
    width: '100%',
    borderRadius: 10,
    marginBottom: 5,
    cursor: 'pointer',
  },
  errorItem: {
    borderColor: red[500],
    backgroundColor: red[50],
    '&:hover': {
      backgroundColor: red[100],
    },
  },
  listNotifications: {
    maxHeight: 300,
    overflowY: 'scroll',
    boxShadow: `0 2px 6px 0 ${blackRussianColor}`,
    border: `1px solid ${hawkesBlueColor}`,
    borderRadius: 4,
    padding: 10,
  },
});

export default withStyles(styles)(NotificationSummary);
