import React, { useEffect } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import {
  defaultFont,
  defaultFontBold,
  defaultFontMedium,
} from '~/styles/themes/common-styles/font';
import {
  lightSlateGreyColor,
  pattensBlueColor,
  snowColor,
  whiteColor,
} from '~/styles/themes/common-styles/color';
import { LGPelorousDenim } from '~/styles/themes/common-styles/misc';

// Component
import CustomDialog from '~/components/common/custom-dialog';
import CustomDialogTitle from '~/components/common/custom-dialog-title';
import CustomDialogContent from '~/components/common/custom-dialog-content';
import CustomDialogActions from '~/components/common/custom-dialog-actions';
import SubmitButton from '~/components/common/custom-submit-button';

import { romanColor, dimGrayColor, whiteSmokeColor } from '~/styles/themes/common-styles/color';
// React i18next
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { ICluster, INode } from '~/types/network-types';

export type AlertType = 'info' | 'warning' | 'error';
interface IProps extends WithStyles<typeof styles> {
  open: boolean;
  node: INode;
  cluster: ICluster;
  onClose: () => void;
  onSubmit: () => void;
}

const CustomConfirmDialog = (props: IProps) => {
  const { classes, open, node, cluster, onClose, onSubmit } = props;
  const { t } = useTranslation('admin');

  useEffect(() => {
    return () => {
      onClose();
    };
  }, [onClose]);

  return (
    <CustomDialog open={open} onClose={onClose}>
      <CustomDialogTitle>
        <div id="member-custom-cf-dialog-title">{t('rebuild_node')}</div>
      </CustomDialogTitle>
      <CustomDialogContent>
        <div id="member-node-del-detail" className={classes.content}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <div className={classes.itemLabel}>{t('node_name')}</div>
              <div className={classes.itemValue}>{node.nodeName}</div>
            </Grid>
            <Grid item md={6}>
              <div className={classes.itemLabel}>{t('cluster_name')}</div>
              <div className={classes.itemValue}>{cluster.clusterName}</div>
            </Grid>
          </Grid>
        </div>
        <div id="member-custom-cf-dialog-attention" className={classes.warningText}>
          {t('are_you_sure_you_want_to_rebuild_this_node')}
        </div>
      </CustomDialogContent>
      <CustomDialogActions>
        <Button
          data-testid="cancel-button"
          id="member-custom-cf-dialog-cancel"
          className={classes.leftBtn}
          variant="contained"
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
        <SubmitButton
          data-testid="submit-button"
          id="member-custom-cf-dialog-submit"
          label={t('submit')}
          onClick={onSubmit}
        />
      </CustomDialogActions>
    </CustomDialog>
  );
};

const styles = createStyles({
  root: {},
  content: {
    padding: 15,
    backgroundColor: snowColor,
    borderRadius: 4,
    border: `1px solid ${pattensBlueColor}`,
    boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.05)`,
    wordBreak: 'break-word',
  },
  itemLabel: {
    ...defaultFontMedium,
    fontSize: 12,
  },
  itemValue: {
    ...defaultFont,
    fontSize: 12,
    color: lightSlateGreyColor,
  },
  warningText: {
    ...defaultFont,
    fontSize: 16,
    color: romanColor,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  leftBtn: {
    ...defaultFont,
    color: dimGrayColor,
    fontSize: 14,
    height: 36,
    backgroundColor: whiteSmokeColor,
    '&:hover': {
      backgroundColor: whiteSmokeColor,
    },
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
  },
  submitBtn: {
    ...defaultFontBold,
    fontSize: 16,
    color: whiteColor,
    width: 160,
    height: 36,
    textTransform: 'none',
    backgroundColor: 'rgb(224,224,224) !important',
    backgroundImage: LGPelorousDenim,
    '&:hover': {
      backgroundImage: LGPelorousDenim,
    },
    '&:disabled': {
      backgroundImage: 'none',
    },
  },
});

export default withStyles(styles)(CustomConfirmDialog);
