import * as Gtypes from '../../../../gapi/gtypes';

export const awsAccountQuery = `{
  uuid
  email
  name
  plan
  awsAccountId
  awsHostZoneId
  userCount
  createAt
  updateAt
}`;

export type QueryToListAWSAccountsArgs = Gtypes.QueryListAwsAccountsArgs;
export const listAWSAccountsQuery = `query($filter: ListAWSAccountsFilterInput) {
  listAWSAccounts(filter: $filter) ${awsAccountQuery}
}`;
export type LIST_AWS_ACCOUNTS_RESULT_TYPE = {
  listAWSAccounts: Gtypes.AwsAccount[];
};

export const createAWSAccountMutation = `mutation {
  createAWSAccount ${awsAccountQuery}
}`;
export type CREATE_AWS_ACCOUNT_RESULT_TYPE = {
  createAWSAccount: Gtypes.AwsAccount;
};

export type MutationRemoveAwsAccountArgs = Gtypes.MutationRemoveAwsAccountArgs;
export const removeAWSAccountMutation = `mutation ($awsAccountUuid: String!) {
  removeAWSAccount(awsAccountUuid: $awsAccountUuid)
}`;
export type REMOVE_AWS_ACCOUNT_RESULT_TYPE = {
  removeAWSAccount: Boolean;
};
