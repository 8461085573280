import React from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import TimelineIcon from '@mui/icons-material/Timeline';
import TokenIcon from '@mui/icons-material/Token';
import { IStore } from '~/stores/configure-store';
import { connect } from 'react-redux';
import { IAdminProfile } from '~/types/admin-types';
import ListItemButton from '@mui/material/ListItemButton';

interface IStateProps {
  profile?: IAdminProfile;
}

interface IDispProps {}

interface Props
  extends RouteComponentProps<{}>,
    WithStyles<typeof styles>,
    IStateProps,
    WithTranslation {
  open: boolean;
  isTemporarySideBar: boolean;
  onClose: () => void;
}

interface State {
  openInformationMenu: boolean;
}

class SideBar extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      openInformationMenu: true,
    };
  }

  render() {
    const { classes, t, open, onClose, isTemporarySideBar, profile } = this.props;
    const { openInformationMenu } = this.state;
    const { path } = this.props.match;
    const role = profile?.role || '';
    const showDashboard = ['owner', 'admin', 'operator', 'billing_operator'].includes(role);
    const showAccounts = ['owner', 'admin', 'operator', 'billing_operator'].includes(role);
    const showAwsAccount = ['owner', 'admin', 'operator'].includes(role);
    const showSales = ['owner', 'admin', 'operator', 'billing_operator'].includes(role);
    const showAdminAlert = ['owner', 'admin', 'operator'].includes(role);
    const showUserAlert = ['owner', 'admin', 'operator'].includes(role);
    const showAdminUser = ['owner', 'admin'].includes(role);
    const showSummary = ['owner', 'admin', 'operator', 'billing_operator'].includes(role);
    const showUsers = ['owner', 'admin', 'operator'].includes(role);
    const showNetworks = ['owner', 'admin', 'operator'].includes(role);

    const links: any[] = [];
    if (showDashboard) {
      links.push(
        <Link id="admin-side-menu-dashboard" to="/" data-testid="navigate-to-dashboard">
          <ListItem button selected={path === '/'} className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon} children={<DashboardOutlinedIcon />} />
            <ListItemText primary={t('dashboard')} />
          </ListItem>
        </Link>,
      );
    }

    return (
      <Drawer
        variant={isTemporarySideBar ? 'temporary' : 'persistent'}
        open={isTemporarySideBar ? open : true}
        onClose={onClose}
        data-testid="side-bar"
        classes={{ root: classes.drawer, paper: classes.drawerPaper }}
      >
        <List>
          <Toolbar />
          {showDashboard && (
            <Link data-testid="navaigate-to-Dashboard" id="admin-side-menu-dashboard" to="/">
              <ListItem button selected={path === '/'} className={classes.listItem}>
                <ListItemIcon
                  className={classes.listItemIcon}
                  children={<DashboardOutlinedIcon />}
                />
                <ListItemText primary={t('dashboard')} />
              </ListItem>
            </Link>
          )}
          {showNetworks && (
            <Link data-testid="navaigate-to-Networks" id="admin-side-menu-networks" to="/networks">
              <ListItem button selected={path.includes('/networks')} className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon} children={<DeviceHubIcon />} />
                <ListItemText primary={t('networks')} />
              </ListItem>
            </Link>
          )}
          {showAccounts && (
            <Link data-testid="navigate-to-accounts" id="admin-side-menu-accounts" to="/accounts">
              <ListItem button selected={path.includes('/accounts')} className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon} children={<GroupsIcon />} />
                <ListItemText primary={t('accounts')} />
              </ListItem>
            </Link>
          )}
          {showUsers && (
            <Link data-testid="navigate-to-users" id="admin-side-menu-users" to="/users">
              <ListItemButton selected={path.includes('/users')} className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon} children={<GroupOutlinedIcon />} />
                <ListItemText primary={t('users')} />
              </ListItemButton>
            </Link>
          )}
          {showAwsAccount && (
            <Link
              data-testid="navigate-to-aws-accounts"
              id="admin-side-menu-aws-accounts"
              to="/aws"
            >
              <ListItem button selected={path.includes('/aws')} className={classes.listItem}>
                <ListItemIcon
                  className={classes.listItemIcon}
                  children={<SupervisorAccountOutlinedIcon />}
                />
                <ListItemText primary={t('aws_accounts')} />
              </ListItem>
            </Link>
          )}
          {showSales && (
            <Link data-testid="navigate-to-sales" id="admin-side-menu-sales" to="/sales">
              <ListItem button selected={path.includes('/sales')} className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon} children={<LoyaltyOutlinedIcon />} />
                <ListItemText primary={t('sales')} />
              </ListItem>
            </Link>
          )}
          {showAdminAlert && (
            <Link
              data-testid="navigate-to-admin-alert"
              id="admin-side-menu-notifications"
              to="/admin-notifications"
            >
              <ListItem
                button
                selected={path.includes('/admin-notifications')}
                className={classes.listItem}
              >
                <ListItemIcon
                  className={classes.listItemIcon}
                  children={<NotificationsOutlinedIcon />}
                />
                <ListItemText primary={t('admin_notifications')} />
              </ListItem>
            </Link>
          )}
          {showUserAlert && (
            <Link
              data-testid="navigate-to-user-alert"
              id="admin-side-menu-notifications"
              to="/user-notifications"
            >
              <ListItem
                button
                selected={path.includes('/user-notifications')}
                className={classes.listItem}
              >
                <ListItemIcon
                  className={classes.listItemIcon}
                  children={<NotificationsOutlinedIcon />}
                />
                <ListItemText primary={t('user_notifications')} />
              </ListItem>
            </Link>
          )}
          {showAdminUser && (
            <Link data-testid="navigate-to-admin-user" id="admin-side-menu-users" to="/admin-users">
              <ListItem
                button
                selected={path.includes('/admin-users')}
                className={classes.listItem}
              >
                <ListItemIcon
                  className={classes.listItemIcon}
                  children={<AdminPanelSettingsOutlinedIcon />}
                />
                <ListItemText primary={t('admin_users')} />
              </ListItem>
            </Link>
          )}
          {showSummary && (
            <Link data-testid="navigate-to-summary" id="admin-side-menu-summary" to="/summary">
              <ListItem button selected={path.includes('/summary')} className={classes.listItem}>
                <ListItemIcon
                  className={classes.listItemIcon}
                  children={<SummarizeOutlinedIcon />}
                />
                <ListItemText primary={t('summary')} />
              </ListItem>
            </Link>
          )}
        </List>
      </Drawer>
    );
  }

  private onCollapseInformationMenu = () => {
    this.setState({
      openInformationMenu: !this.state.openInformationMenu,
    });
  };
}

const mapStateToProps = (store: IStore): IStateProps => ({
  profile: store.appState.profile,
});

const styles = (theme) =>
  createStyles({
    drawer: {
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerPaper: {
      padding: theme.spacing(2),
    },
    listItem: {
      borderRadius: 4,
      height: 48,
      alignItems: 'center',
    },
    listItemIcon: {
      minWidth: 40,
      '& img': {
        width: 23,
        height: 23,
      },
    },
    listSubItemGutters: {
      paddingLeft: 30,
      '& span': {
        fontSize: 14,
      },
    },
  });

export default withStyles(styles)(
  connect(mapStateToProps)(withTranslation('admin')(withRouter(SideBar))),
);
