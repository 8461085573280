import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import TableBody, { TableBodyProps } from '@mui/material/TableBody';

interface Props extends Omit<TableBodyProps, 'classes'>, WithStyles<typeof styles> {}

const tableBody: React.StatelessComponent<Props> = ({
  classes,
  children,
  className,
  ...tableBodyProps
}) => (
  <TableBody {...tableBodyProps} className={classes.root}>
    {children}
  </TableBody>
);

const styles = createStyles({
  root: {},
});

export default withStyles(styles)(tableBody);
