import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { IStore } from '~/stores/configure-store';
import HomeIcon from '@mui/icons-material/Home';
import Layout from '~/components/common/Layout';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CustomDataGridToolbar from '~/components/common/custom-data-grid-toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import * as NetworkActions from '~/stores/actions/network-action';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import { ROWS_PER_PAGE_OPTIONS, DATA_GRID_SORTING_ORDER } from '~/constants/common';
import { OrderByEnum } from '~/enums/common';
import { AdminListNetworkOrderEnum, QueryListNetworksArgs } from '~/gapi/gtypes';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withAdminProfile } from '~/hooks/with-admin-profile';
import { compose } from 'redux';
import { AppRoutePath } from '~/AppRouter';
import { withAuthorization } from '~/hooks/with-authorization';
import NetworkOption from './network-option';
import { whiteColor } from '~/styles/themes/common-styles/color';

const SEARCH_INPUT_DELAY = 300;

interface IProps extends WithStyles<typeof styles> {}

const Networks = (props: IProps) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation('admin');
  const history = useHistory();
  const networkList = useSelector((store: IStore) => store.appState.networkList);
  const isLoading = useSelector((store: IStore) => NetworkActions.listNetworks.isPending(store));
  const profile = useSelector((store: IStore) => store.appState.profile);
  const [searchText, setSearchText] = useState(networkList.filter?.searchText || '');
  const { orderBy, orderType } = networkList.order ?? {};
  const { totalItems, pageIndex: page, pageSize } = networkList.pageInfo;

  const listNetworks = useCallback(
    async (args?: QueryListNetworksArgs) => {
      await dispatch(NetworkActions.listNetworks(args));
    },
    [dispatch],
  );

  const columns: GridColumns = [
    {
      field: DataGridFieldEnum.NETWORK_UUID,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      headerName: t('network_uuid'),
      width: 350,
    },
    {
      field: DataGridFieldEnum.NAME,
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      headerName: t('network_name'),
      width: 200,
    },
    {
      field: DataGridFieldEnum.NUM_OF_ACCOUNT,
      disableColumnMenu: true,
      sortable: false,
      filterable: true,
      headerName: t('num_accounts'),
      width: 150,
    },
    {
      field: DataGridFieldEnum.NUM_OF_CLUSTER,
      disableColumnMenu: true,
      sortable: true,
      filterable: true,
      headerName: t('num_clusters'),
      width: 150,
    },
    {
      field: DataGridFieldEnum.NUM_OF_NODE,
      disableColumnMenu: true,
      sortable: true,
      filterable: false,
      headerName: t('num_nodes'),
      width: 150,
    },
    {
      field: DataGridFieldEnum.CREATED_AT,
      disableColumnMenu: true,
      sortable: true,
      filterable: false,
      headerName: t('create_date'),
      valueFormatter: ({ value }) => t('create_date_value', { date: value }),
      width: 150,
    },
    {
      field: DataGridFieldEnum.ACTION,
      sortable: false,
      filterable: false,
      headerName: t('action'),
      type: 'actions',
      disableColumnMenu: true,
      renderHeader: () => <></>,
      headerClassName: classes.cellAction,
      renderCell: (params: GridCellParams) => {
        return ['owner', 'admin'].includes(profile?.role || '') ? (
          <NetworkOption network={params.row as Item} data-testid="network-option" />
        ) : undefined;
      },
    },
  ];

  const items: Array<Item> = (networkList.networks || []).map((network) => ({
    id: network.networkUuid,
    networkUuid: network.networkUuid,
    networkName: network.networkName,
    numOfAccounts: network.numOfAccounts,
    numOfClusters: network.numOfClusters,
    numOfNodes: network.numOfNodes,
    createAt: network.createAt && moment(network.createAt).toDate(),
  }));

  const sortModel = orderType &&
    orderBy && [
      {
        field: orderBy,
        sort: orderType === OrderByEnum.Desc ? 'desc' : ('asc' as GridSortDirection),
      },
    ];

  const onRowClick = (params) => {
    history.push(`/networks/${params.row.networkUuid}/overview`);
  };

  const onSortModelChange = (model: GridSortModel) => {
    listNetworks({
      order: model[0] && {
        orderBy: model[0].field as AdminListNetworkOrderEnum,
        orderType: model[0].sort === 'asc' ? OrderByEnum.Asc : OrderByEnum.Desc,
      },
      page: { pageIndex: 0 },
    }).catch(console.log);
  };

  const onPageSizeChange = (pageSize) => {
    listNetworks({
      page: { pageIndex: 0, pageSize },
    });
  };

  const onPageChange = (page) => {
    listNetworks({
      page: { pageIndex: page },
    });
  };

  const debouncedOnSearchInputChange = useMemo(
    () =>
      _.debounce((value: string) => {
        listNetworks({
          filter: { searchText: value },
          page: { pageIndex: 0 },
        }).catch(console.log);
      }, SEARCH_INPUT_DELAY),
    [listNetworks],
  );

  const onSearchInputChange = useCallback(
    (value) => {
      setSearchText(value);
      debouncedOnSearchInputChange(value);
    },
    [debouncedOnSearchInputChange],
  );

  const onClickRefreshBtn = () => {
    setSearchText('');
    listNetworks({
      filter: {
        searchText: '',
      },
      order: {
        orderBy: DataGridFieldEnum.CREATED_AT,
        orderType: OrderByEnum.Desc,
      },
      page: { pageIndex: 0 },
    }).catch(console.log);
  };

  useEffect(() => {
    if (!networkList.networks) {
      listNetworks({ page: { pageIndex: 0 } });
    }
  }, [listNetworks, networkList]);

  return (
    <Layout>
      <div className={classes.root}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Link
            data-testid="navigate-to-dashboard"
            component={RouterLink}
            to={AppRoutePath.Dashboard}
            color="text.primary"
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          </Link>
          <Typography color="text.primary">{t('networks')}</Typography>
        </Breadcrumbs>

        <div className={classes.topLine}>
          <Button
            data-testid="refresh-button"
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<RefreshIcon />}
            onClick={onClickRefreshBtn}
          >
            {t('refresh')}
          </Button>
        </div>

        <div className={classes.dataGridWrapper}>
          <DataGrid
            rows={items}
            columns={columns}
            components={{
              Toolbar: CustomDataGridToolbar,
            }}
            componentsProps={{
              toolbar: {
                value: searchText,
                placeholder: t('networks_search_placeholder'),
                isQuickSearch: true,
                onChange: (event) => onSearchInputChange(event.target.value),
                clearSearch: () => onSearchInputChange(''),
              },
              pagination: {
                'data-testid': 'network-pagination',
              },
            }}
            classes={{ root: classes.gridRoot }}
            localeText={{
              toolbarColumns: t('data_grid.columns'),
              toolbarFilters: t('data_grid.filters'),
              toolbarDensity: t('data_grid.density'),
              filterPanelColumns: t('data_grid.columns'),
              filterPanelOperators: t('data_grid.operators'),
              columnsPanelTextFieldLabel: t('data_grid.find_column'),
              columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
              columnsPanelDragIconLabel: t('data_grid.reorder_column'),
              columnsPanelShowAllButton: t('data_grid.show_all'),
              columnsPanelHideAllButton: t('data_grid.hide_all'),
              toolbarDensityCompact: t('data_grid.density_compact'),
              toolbarDensityStandard: t('data_grid.density_standard'),
              toolbarDensityComfortable: t('data_grid.density_comfortable'),
              filterOperatorContains: t('data_grid.contains'),
              filterOperatorEquals: t('data_grid.equals'),
              filterOperatorStartsWith: t('data_grid.startsWith'),
              filterOperatorEndsWith: t('data_grid.endsWith'),
              filterOperatorIsEmpty: t('data_grid.isEmpty'),
              filterOperatorIsNotEmpty: t('data_grid.isNotEmpty'),
              filterOperatorIsAnyOf: t('data_grid.isAnyOf'),
              filterOperatorIs: t('data_grid.is'),
              filterOperatorNot: t('data_grid.isNot'),
              noRowsLabel: t('data_grid.no_rows'),
            }}
            isRowSelectable={() => false}
            checkboxSelection={false}
            loading={isLoading}
            paginationMode="server"
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            rowCount={totalItems || 0}
            pageSize={pageSize}
            page={page}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            sortingMode="server"
            sortingOrder={DATA_GRID_SORTING_ORDER}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            onRowClick={onRowClick}
            disableSelectionOnClick
          />
        </div>
      </div>
    </Layout>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: 'auto',
      paddingRight: 0,
    },
    dataGridWrapper: {
      height: 500,
      width: '100%',
      marginTop: 24,
      backgroundColor: whiteColor,
      [theme.breakpoints.up('sm')]: {
        marginTop: 32,
      },
      '& .MuiDataGrid-row': {
        cursor: 'pointer',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
    },
    cellAction: {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
    },
    gridRoot: {
      '& .MuiDataGrid-row': {
        maxHeight: 'none !important',
      },
      '& .MuiDataGrid-cell': {
        whiteSpace: 'pre-wrap',
        maxHeight: 'none !important',
        outline: 'none !important',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    topLine: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'end',
    },
    button: {
      margin: 0,
    },
  });

export default compose(
  withFirebaseUser(),
  withAdminProfile,
  withAuthorization(['owner', 'admin', 'operator']),
  withStyles(styles),
)(Networks);

export type Item = {
  id: string;
  networkUuid: string;
  networkName: string;
  numOfAccounts: number;
  numOfClusters: number;
  numOfNodes: number;
};

enum DataGridFieldEnum {
  NAME = 'networkName',
  NETWORK_UUID = 'networkUuid',
  NUM_OF_ACCOUNT = 'numOfAccounts',
  NUM_OF_CLUSTER = 'numOfClusters',
  ACTION = 'action',
  NUM_OF_NODE = 'numOfNodes',
  CREATED_AT = 'createAt',
}
