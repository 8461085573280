import * as React from 'react';

// Styles
import { withStyles, createStyles, WithStyles } from '@mui/styles';

// i18n
import { withTranslation, WithTranslation } from 'react-i18next';
import { displayCurrency } from '~/utilities/payment-utils';

interface CustomFooterProps {
  totalBalance: number;
}

interface Props extends CustomFooterProps, WithTranslation, WithStyles<typeof styles> {}

const CustomFooter = (props: Props) => {
  const { classes, totalBalance, t } = props;
  return (
    <div id="admin-sales-total-amount" className={classes.totalSaleArea}>
      Coupon balance : $ {displayCurrency(totalBalance)}
    </div>
  );
};

const styles = (theme) =>
  createStyles({
    totalSaleArea: {
      margin: '30px 10px',
      textAlign: 'start',
    },
  });

export default withStyles(styles)(withTranslation('admin')(CustomFooter));
