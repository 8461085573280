import React from 'react';
import { withStyles, WithStyles, StyleRules } from '@mui/styles';
import TableHead, { TableHeadProps } from '@mui/material/TableHead';
import { snowColor } from '~/styles/themes/common-styles/color';

interface Props extends Omit<TableHeadProps, 'classes'>, WithStyles<typeof styles> {}

const tableHead: React.StatelessComponent<Props> = ({
  classes,
  children,
  className,
  ...tableHeadProps
}) => (
  <TableHead {...tableHeadProps} className={classes.root}>
    {children}
  </TableHead>
);

const styles: StyleRules = {
  root: {
    height: 56,
  },
};

export default withStyles(styles)(tableHead);
