import guest from './guest';
import member from './member';

export default {
  ...guest,
  ...member,
  cannot_connect_to_the_server: 'サーバに接続できませんでした',
  not_supported_browser_alert:
    'お使いのブラウザは、サポートされておりません。Google Chrome, Firefox, Safari, Microsoft Edgeをご利用ください。',
  unknow_error_occured: '未知のエラー',
  fail_to_create_platform_account: 'プラットフォームアカウントを作成できません',
  fail_to_get_account_uuid: 'アカウントUUIDを取得できません',
  account_does_not_exist: 'アカウントが存在しません',
  member_does_not_exist_in_account: 'アカウント内にユーザーが存在しません',
  member_already_exist: 'ユーザーは既に登録されています',
  member_does_not_exist: 'ユーザーが存在しません',
  there_is_only_one_owner_can_not_revoke_owner: '唯一の所有者ユーザーであるため変更できません',
  user_still_have_more_than_1_network_owner_role:
    '所有者に設定されているネットワークが1つ以上あります',
  user_is_the_last_owner_of_a_network:
    '唯一の所有者となっているネットワークが存在するため削除できません',
  can_not_revoke_owner_final: '唯一の所有者ユーザーであるため削除できません',
  cant_not_revoke_last_owner: '最後の所有者を取り消すことはできません',
  account_name_and_email_is_already_used_and_deleted:
    'このアカウント名またはメールアドレスは既に退会済です',
  you_dont_have_a_permission_to_perform_the_account: 'アカウントを操作する権限がありません',
  you_dont_have_a_permission_to_add_a_member_to_account:
    'アカウントにメンバーを追加する権限がありません。',
  you_dont_have_a_permission_to_delete_the_account: 'アカウントを削除する権限がありません',
  you_cant_delete_the_account_the_account_still_has_users:
    '所有者以外のメンバーが残っているため、アカウントを削除できません',
  you_cant_delete_the_account_the_account_still_has_networks:
    'ネットワークが残っているため、アカウントを削除できません',
  you_cant_delete_the_account_the_account_has_unpaid_bill:
    '未払いの請求が残っているため、アカウントを削除できません',
  invitation_rejection_may_not_completed: '招待の取り消しを完了できませんでした',
  cannot_remove_yourself: '自分自身を削除することはできません。',
  can_not_add_member: 'メンバーを追加することはできません',
  can_not_update_member: 'メンバーの更新ができませんでした',
  update_member_success: 'メンバーの更新に成功しました',
  can_not_remove_member: 'メンバーを削除できませんでした',
  remove_member_success: 'メンバーを削除しました',
  change_primary_owner_success: 'メンバーをプライマリオーナーに変更しました',
  can_not_update_account: '組織アカウント情報を更新できませんでした',
  update_account_success: '組織アカウント情報を更新しました',
  can_not_list_providers: 'プロバイダ一覧を取得できませんでした',
  can_not_list_networks: 'ネットワーク一覧を取得できませんでした',
  can_not_update_network_status: 'ネットワーク情報を更新できませんでした',
  can_not_get_account: 'アカウント情報を取得できませんでした',
  can_not_get_network: 'ネットワーク情報を取得できませんでした',
  can_not_get_network_role: 'ネットワーク権限情報を取得できませんでした',
  can_not_create_network: 'ネットワークを作成できませんでした',
  can_not_delete_network: 'ネットワークを削除できませんでした',
  network_not_found_or_denied_to_delete: 'ネットワークが見つかりません',
  delete_network_started: 'ネットワークの削除を開始しました',
  can_not_create_cluster: 'クラスターを作成できませんでした',
  can_not_delete_cluster: 'クラスターを削除できませんでした',
  cluster_not_found_or_not_empty: 'クラスターが見つかりません',
  create_cluster_started: 'クラスターの作成を開始しました',
  delete_cluster_started: 'クラスターの削除を開始しました',
  can_not_create_node: 'ノードを作成できませんでした',
  can_not_update_node: 'ノード情報を更新できませんでした',
  can_not_delete_node: 'ノードを削除できませんでした',
  node_not_found_or_validator: 'ノードが見つかりませんでした',
  delete_node_started: 'ノードの削除を開始しました',
  can_not_control_node: 'ノードの変更に失敗しました',
  can_not_list_account_on_network: 'ネットワークに関連するアカウントの取得に失敗しました',
  can_not_list_network_accesses: 'Can not list network accesses', // TODO: Need to translate
  grant_network_role_success: 'ネットワーク権限の付与に成功しました',
  can_not_revoke_network_role: 'ネットワーク権限の付与に失敗しました',
  revoke_network_role_success: 'ネットワーク権限の削除に成功しました',
  can_not_list_alerts: '通知を取得できませんでした',
  can_not_list_unread_alerts: '未読の通知を取得できませんでした',
  can_not_list_new_unread_alerts: '新規未読通知を取得できませんでした',
  can_not_set_alert_readed: '通知を既読にする事に失敗しました',
  can_not_set_all_alert_readed: 'すべての通知を既読にする事に失敗しました',
  can_not_send_invitation: '招待の送信に失敗しました',
  send_invitation_success: '招待の送信に成功しました',
  can_not_cancel_invitation: '招待のキャンセルに失敗しました',
  cancel_invitation_success: '招待をキャンセルしました',
  can_not_response_invitation: '招待に応答できませんでした',
  accept_invitation_success: '招待を承認しました',
  reject_invitation_success: '招待を拒否しました',
  can_not_get_network_status: 'ネットワーク状態の取得に失敗しました',
  can_not_create_account: '組織アカウントの作成に失敗しました',
  create_account_success: '組織アカウントの作成に成功しました',
  can_not_remove_account: '組織アカウントの削除に失敗しました',
  remove_account_success: '組織アカウントの削除に成功しました',
  can_not_list_proposals: 'プロポーザルリストの取得に失敗しました',
  can_not_create_proposal: 'プロポーザルの作成に失敗しました',
  create_proposal_success: 'プロポーザルを作成しました',
  can_not_vote_proposal: 'プロポーザルへの投票に失敗しました',
  vote_proposal_success: 'プロポーザルへ投票しました',
  can_not_list_active_licenses: 'アクティブなライセンス一覧の取得に失敗しました',
  can_not_list_active_licenses_summary: 'アクティブライセンスのサマリーの取得に失敗しました',
  can_not_list_available_licenses: '有効なライセンス一覧の取得に失敗しました',
  can_not_list_insufficient_licenses: '不十分なライセンス数の取得に失敗しました',
  can_not_list_insufficient_cluster_licenses: '不十分なクラスタライセンスの取得に失敗しました',
  can_not_list_insufficient_node_licenses: '不十分なノードライセンスの取得に失敗しました',
  can_not_list_insufficient_node_server_licenses: '不十分なサーバライセンスの取得に失敗しました',
  can_not_list_insufficient_volume_licenses: '不十分な容量ライセンスの取得に失敗しました',
  can_not_estimate_licenses: 'ライセンスの見積もりに失敗しました',
  can_not_list_billings: '請求一覧を取得できませんでした',
  can_not_list_coupons: 'クーポン一覧を取得できませんでした',
  can_not_list_cards: 'カード情報を取得できませんでした',
  card_registration_required: 'カード情報を登録してください',
  card_registration_failed: 'カード登録に失敗しました',
  card_registration_successfully: 'カード登録に成功しました',
  failed_to_purchase_licenses: 'ライセンスの購入に失敗しました',
  unmatched_coupon_balance: 'クーポン残高が一致しません。もう一度やり直してください',
  licenses_purchased_successfully: 'ライセンスの購入に成功しました',
  failed_to_cancel_licenses_purchase: 'ライセンス購入のキャンセルに失敗しました',
  licenses_purchased_canceled_successfully: 'ライセンス購入をキャンセルしました',
  can_not_list_restrictions: 'セキュリティ状態の取得に失敗しました',
  can_not_set_restrictions: 'セキュリティ状態の更新に失敗しました',
  can_not_set_node_server_type: 'ノードタイプの変更に失敗しました',
  changing_node_server_type_started: 'ノードタイプの変更を開始しました',
  failed_to_set_node_server_type: 'ノードタイプの変更に失敗しました',
  can_not_expand_node_volume_size: 'ノードのデータボリュームサイズの拡張に失敗しました',
  expanding_volume_size_started: 'データボリュームサイズの拡張を開始しました',
  failed_to_expand_node_volume_size: 'データボリュームサイズの拡張に失敗しました',
  can_not_get_node_metrics: 'ノードの統計情報の取得に失敗しました',
  can_not_get_node_log: 'ノードのログ情報の取得に失敗しました',
  can_not_get_block_explorer_metrics: 'ブロックエクスプローラーの統計情報の取得に失敗しました',
  can_not_get_block_explorer_log: 'ブロックエクスプローラーのログ情報の取得に失敗しました',
  add_member_success: 'メンバーが追加されました',
  specified_uid_not_registered_in_system:
    '指定されたUidのユーザのプロファイルは登録されていません。',
  specified_email_not_registered_in_system:
    '指定されたメールアドレスがこのシステムには登録されていません。',
  memmber_already_exist: 'メンバーが既に存在しています',
  network_role_can_not_grant: 'ネットワーク側は付与できません',
  network_can_not_update: 'ネットワークステータスを更新できません',
  'network-updated-successfully': 'ネットワークの更新に成功しました',
  'cluster-updated-successfully': 'クラスターの更新に成功しました',
  'cluster-added-successfully': 'クラスターの作成に成功しました',
  'node-updated-successfully': 'ノードの更新に成功しました',
  'node-added-successfully': 'ノードの作成に成功しました',
  update_profile_successfully: 'プロファイルの更新に成功しました',
  update_restriction_successfully: 'セキュリティ状態の更新に成功しました',
  'custom-domain-added-successfully': 'カスタムドメインの追加に成功しました',
  'can-not-add-custom-domain': 'カスタムドメインの追加に失敗しました',
  'custom-domain-removed-successfully': 'カスタムドメインの削除に成功しました',
  'can-not-remove-custom-domain': 'カスタムドメインの削除に失敗しました',
  unauthenticated_error:
    'このサイトを表示する権限がありません。もし、権限が付与されている場合でもこのページが表示された場合は、下記よりお問い合わせください。',
  invoice_no: 'インボイス登録番号',
  can_not_update_node_version: 'ノードのバージョンを更新できません',
  update_node_version_failed: 'ノードのバージョンを更新できませんでした',
  update_node_version_started: 'ノード バージョンの更新を開始しました',
  can_not_get_genesis_json: 'ネットワークgenesis.jsonを取得できません',
  can_not_update_block_gas_limit: 'ブロックガス制限を更新できません',
  update_gas_limit_start: 'Block Gas Limitの更新が開始されました',
  can_not_update_latest_hard_fork: '最新のハードフォークを更新できません',
  update_latest_hard_fork_start: '最新のハードフォーク開始を更新します',
  reboot_command_sent: '再起動コマンド送信完了しました',
  rebuild_command_sent: '再構築コマンド送信完了しました',
  admin_is_created_successfully: 'ユーザーの追加に成功しました',
  admin_is_updated_successfully: 'ユーザー情報の更新に成功しました',
  admin_is_deleted_successfully: 'ユーザーの削除に成功しました',
  notification_not_found: '通知が見つかりません',
  start_command_sent: '開始コマンドが送信されました',
  stop_command_sent: '停止コマンドが送信されました',
  restartForce_command_sent: '強制再起動コマンドが送信されました',
};
