import React from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { container } from '~/styles/themes/common-styles/grid-member';
import { romanColor } from '~/styles/themes/common-styles/color';
import moment from 'moment-timezone';
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';
import { Button, Theme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
// Translation
import { withTranslation, WithTranslation } from 'react-i18next';
// Type
import { IStore } from '~/stores/configure-store';
import { IBillingSummary, IBillingSummaryDetail } from '~/types/admin-types';
import { displayCurrency } from '~/utilities/payment-utils';

import { whiteColor } from '~/styles/themes/common-styles/color';
import { PAYMENT_TYPE_RESULT_FAIL } from '~/constants/common';

interface IProps
  extends RouteComponentProps<{ accountUuid: string; month: string }>,
    WithStyles<typeof styles>,
    WithTranslation {}

interface IStateProps {
  billingHistory?: IBillingSummary;
}

interface IDispProps {}

interface IState {}

class BillingMonthTable extends React.Component<
  IProps & IDispProps & IStateProps & WithStyles<typeof styles> & WithTranslation,
  IState
> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { classes, t } = this.props;
    const { accountUuid } = this.props.match.params;
    const billingsByMonth = this.groupBillingsMyMonth();

    const columns: GridColumns = [
      {
        field: DataGridFieldEnum.GROUP_MONTH,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        headerName: t('billing_month'),
        width: 250,
        renderCell: (params: GridCellParams) => {
          const bill = params.row;
          const month = moment(bill.groupMonth).utc();
          return month.format('YYYY/MM');
        },
      },
      {
        field: DataGridFieldEnum.PAYMENT_STATUS,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        headerName: t('payment_status'),
        width: 300,
        valueFormatter: ({ value }) => (value ? t('paid') : t('unpaid_exist')),
      },
      {
        field: DataGridFieldEnum.AMOUNT,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        headerName: t('billing_amount'),
        width: 250,
        renderCell: (params: GridCellParams) => {
          const bill = params.row;
          return `$ ${displayCurrency(bill.amount + bill.tax - bill.coupon)}`;
        },
      },
      {
        field: DataGridFieldEnum.ACTION,
        sortable: false,
        filterable: false,
        type: 'actions',
        headerName: t('action'),
        disableColumnMenu: true,
        renderHeader: () => <></>,
        headerClassName: classes.cellAction,
        width: 200,
        renderCell: (params: GridCellParams) => {
          const bill = params.row;
          const month = moment(bill.groupMonth).utc();
          return (
            <Button
              data-testid="open-history-button"
              id="admin-user-add-button"
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={(_) =>
                this.props.history.push(
                  `/account/${accountUuid}/history/${month.format('YYYY-MM')}`,
                )
              }
            >
              {t('open')}
            </Button>
          );
        },
      },
    ];
    const items: Item[] = billingsByMonth.map((bill, index) => ({ ...bill, id: `${index}` }));

    return (
      <div className={classes.dataGridWrapper}>
        <DataGrid
          rows={items}
          columns={columns}
          classes={{ root: classes.gridRoot }}
          localeText={{
            toolbarColumns: t('data_grid.columns'),
            toolbarFilters: t('data_grid.filters'),
            toolbarDensity: t('data_grid.density'),
            filterPanelColumns: t('data_grid.columns'),
            filterPanelOperators: t('data_grid.operators'),
            columnsPanelTextFieldLabel: t('data_grid.find_column'),
            columnsPanelTextFieldPlaceholder: t('data_grid.column_title'),
            columnsPanelDragIconLabel: t('data_grid.reorder_column'),
            columnsPanelShowAllButton: t('data_grid.show_all'),
            columnsPanelHideAllButton: t('data_grid.hide_all'),
            toolbarDensityCompact: t('data_grid.density_compact'),
            toolbarDensityStandard: t('data_grid.density_standard'),
            toolbarDensityComfortable: t('data_grid.density_comfortable'),
            filterOperatorContains: t('data_grid.contains'),
            filterOperatorEquals: t('data_grid.equals'),
            filterOperatorStartsWith: t('data_grid.startsWith'),
            filterOperatorEndsWith: t('data_grid.endsWith'),
            filterOperatorIsEmpty: t('data_grid.isEmpty'),
            filterOperatorIsNotEmpty: t('data_grid.isNotEmpty'),
            filterOperatorIsAnyOf: t('data_grid.isAnyOf'),
            filterOperatorIs: t('data_grid.is'),
            filterOperatorNot: t('data_grid.isNot'),
            noRowsLabel: t('data_grid.no_rows'),
          }}
          isRowSelectable={() => false}
          disableSelectionOnClick
          checkboxSelection={false}
          hideFooterPagination={true}
        />
      </div>
    );
  }

  private groupBillingsMyMonth() {
    const { billingHistory } = this.props;

    if (!billingHistory || billingHistory.details.length < 1) {
      return [];
    }

    const grouped = billingHistory.details
      .filter((d) => d.result !== PAYMENT_TYPE_RESULT_FAIL)
      .reduce((pre, cur) => {
        const group = moment(cur.invoicedDate).utc().startOf('month').toISOString();

        if (pre[group]) {
          pre[group].push(cur);
        } else {
          pre[group] = [cur];
        }
        return pre;
      }, {} as { [key: string]: IBillingSummaryDetail[] });

    return Object.entries(grouped)
      .map((g) => ({
        groupMonth: g[0],
        isPaid: g[1].reduce((a, b) => a && Boolean(b.paidDate), true),
        amount: g[1].reduce((a, b) => a + b.amount, 0),
        coupon: g[1].reduce((a, b) => a + b.coupon, 0),
        tax: g[1].reduce((a, b) => a + b.tax, 0),
      }))
      .sort((a, b) => moment(b.groupMonth).utc().valueOf() - moment(a.groupMonth).utc().valueOf());
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container,
    customCellHead: {
      paddingRight: 0,
    },
    dataGridWrapper: {
      padding: 8,
      height: 600,
      width: '100%',
      backgroundColor: whiteColor,
    },
    gridRoot: {
      border: 'none',
      '& .MuiDataGrid-row': {
        maxHeight: 'none !important',
      },
      '& .MuiDataGrid-cell': {
        whiteSpace: 'pre-wrap',
        maxHeight: 'none !important',
        outline: 'none !important',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    cellAction: {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
    },
    button: {
      margin: 0,
    },
    unPaid: {
      color: `${romanColor}`,
    },
  });

const mapStateToProps = (store: IStore): IStateProps => ({
  billingHistory: store.appState.licenseSummary.history,
});

const mapDispatchToProps = (dispatch): IDispProps => ({});

export default withStyles(styles)(
  withRouter(
    withTranslation('admin')(connect(mapStateToProps, mapDispatchToProps)(BillingMonthTable)),
  ),
);

type Item = {
  groupMonth: string;
  id: string;
  isPaid: boolean;
  amount: number;
  tax: number;
  coupon: number;
};

enum DataGridFieldEnum {
  GROUP_MONTH = 'groupMonth',
  ID = 'id',
  PAYMENT_STATUS = 'isPaid',
  AMOUNT = 'amount',
  ACTION = 'action',
}
