import { ListUsers } from '~/gapi/gtypes';

export const userQuery = `{
  uid
  name
  email
  createOrgAccountLimit
  createAt
  lastloginAt
}`;

export const listUsersQuery = `query ($order: InputListUsersOrder, $page: InputPageInfo, $filter: InputListUsersFilter) {
  listUsers(order: $order, page: $page, filter: $filter) {
    users ${userQuery}
    pageInfo {
      pageIndex
      pageSize
      totalPages
      totalItems
    }
    filter {
      lastloginAt {
        startDate
        endDate
      }
      searchText
    }
    order {
      orderType
      orderBy
    }
  }
}`;
export type LIST_USERS = {
  listUsers: ListUsers;
};
