import * as Gtypes from '../../../../gapi/gtypes';

const accountAlertQuery = `{
  alertUuid
  accountUuid
  networkUuid
  type
  title {
    en
    ja
  }
  description {
    en
    ja
  }
  linkUrl
  createAt
  updateAt
}`;

const alertAdminQuery = `{
  alertUuid
  accountUuid
  networkUuid
  type
  title {
    en
    ja
  }
  description {
    en
    ja
  }
  linkUrl
  createAt
  updateAt
}`;

export const listAccountAlertsQuery = `query ($accountUuid: String!, $page: InputPageInfo, $order: AccountAlertsOrderInput, $filter: AccountAlertsFilterInput) {
  listAccountAlerts(accountUuid: $accountUuid, page: $page, order: $order, filter: $filter, ) {
    alerts ${accountAlertQuery}
    pageInfo {
      pageIndex
      pageSize
      totalPages
      totalItems
    }
    filter {
      types
      startDate
      endDate
    }
    order {
      orderType
      orderBy
    }
  }
}`;
export type QueryListAccountAlertsArgs = Gtypes.QueryListAccountAlertsArgs;
export type LIST_ACCOUNT_ALERTS_RESULT_TYPE = {
  listAccountAlerts: Gtypes.ListAccountAlerts;
};

export const listAdminAlertsQuery = `query ($page: InputPageInfo, $filter: AdminAlertsFilterInput) {
  listAdminAlerts(page: $page, filter: $filter) {
    alerts ${alertAdminQuery}
    pageInfo {
      pageIndex
      pageSize
      totalPages
      totalItems
    }
    filter {
      forAll
      forAdmin
      forUser
      startDate
      endDate
    }
  }
}`;
export type QueryListAdminAlertsArgs = Gtypes.QueryListAdminAlertsArgs;
export type LIST_ADMIN_ALERTS_RESULT_TYPE = {
  listAdminAlerts: Gtypes.ListAdminAlerts;
};

export const getAdminAlertQuery = `query ($alertUuid: String) {
  getAdminAlert(alertUuid: $alertUuid) ${alertAdminQuery}
}`;
export type GET_ADMIN_ALERT_RESULT_TYPE = {
  getAdminAlert: Gtypes.AdminAlert;
};
