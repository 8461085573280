import ApolloClient, { ApolloError } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';
import firebase from 'firebase';

class GApiClient {
  protected baseUrl: string = '';
  protected endPoint: string = '';
  protected authLink?: ApolloLink;
  protected reloadLink: ApolloLink;

  constructor({
    baseUrl,
    endPoint,
    token,
  }: {
    baseUrl: string;
    endPoint: string;
    credentials?: string;
    token?: string;
  }) {
    this.baseUrl = baseUrl;
    this.endPoint = endPoint;
    this.authLink = setContext(async (_, { headers }) => {
      const idToken = await firebase.auth().currentUser?.getIdToken();
      return {
        headers: {
          ...headers,
          authorization: token || idToken,
        },
      };
    });

    // set error link
    this.reloadLink = onError(({ networkError }) => {
      const statusMustReload = [504];

      if (networkError && 'statusCode' in networkError) {
        if (statusMustReload.includes(networkError.statusCode) && window) {
          console.log('[gapi-client] got 504 status ... force reload window');
          window.location.reload();
        }
      }
    });
  }

  protected async doQuery<Q, V>(apiPath: string, query: string, variables?: V) {
    this.validateBrowserOffline();
    const uri = this.baseUrl + apiPath;

    try {
      const httpLink = createHttpLink({
        uri: uri,
      });

      let link = httpLink;

      if (this.authLink) {
        link = this.authLink.concat(httpLink);
      }
      link = this.reloadLink.concat(link);

      const client = new ApolloClient({
        link: link,
        cache: new InMemoryCache({ addTypename: false }),
      });
      const result = await client.query<Q, V>({ query: gql(query), variables });

      return result.data;
    } catch (err) {
      if (err instanceof ApolloError) {
        const graphQLError = err.graphQLErrors[0];
        const apiExtra = graphQLError?.extensions;
        const errorMsg =
          err.networkError?.message === 'Failed to fetch'
            ? 'cannot_connect_to_the_server'
            : graphQLError.message || err.message;
        const error = new Error(errorMsg);
        (error as any).localizedMessages = apiExtra?.localizedMessages;

        throw error;
      }

      throw err;
    }
  }

  protected async doMutate<Q, V>(apiPath: string, mutation: string, variables?: V) {
    this.validateBrowserOffline();
    const uri = this.baseUrl + apiPath;

    try {
      const httpLink = createHttpLink({
        uri: uri,
      });

      let link = httpLink;

      if (this.authLink) {
        link = this.authLink.concat(httpLink);
      }
      link = this.reloadLink.concat(link);

      const client = new ApolloClient({
        link: link,
        cache: new InMemoryCache({ addTypename: false }),
      });
      const result = await client.mutate<Q, V>({ mutation: gql(mutation), variables });

      return result.data as Q;
    } catch (err) {
      if (err instanceof ApolloError) {
        const graphQLError = err.graphQLErrors[0];
        const apiExtra = graphQLError?.extensions;
        const errorMsg =
          err.networkError?.message === 'Failed to fetch'
            ? 'cannot_connect_to_the_server'
            : graphQLError.message || err.message;
        const error = new Error(errorMsg);
        (error as any).localizedMessages = apiExtra?.localizedMessages;

        throw error;
      }

      throw err;
    }
  }

  protected validateBrowserOffline() {
    if (window && !window.navigator.onLine) {
      throw new Error('Network seems offline');
    }
  }
}

export default GApiClient;
