import { INodeType, IConsensus } from './network-types';
import { TFunction } from 'react-i18next';

export const clusterTypeSelection = [
  { value: 'small', label: 'Small' },
  { value: 'medium', label: 'Medium' },
  { value: 'large', label: 'Large' },
  { value: 'mega', label: 'Mega' },
];

export const clusterTypeEnterpriseSelection = [
  ...clusterTypeSelection,
  { value: 'enterprise', label: 'Enterprise' },
];

export const instancesSizeSelection = [
  { value: 'small', label: 'Small (for Test)' },
  { value: 'medium', label: 'Medium (Minimum for Production)' },
  { value: 'large', label: 'Large' },
  { value: 'xlarge', label: 'XLarge' },
  { value: 'twoxlarge', label: '2XLarge' },
  { value: 'business', label: 'Business (Recommend)' },
  { value: 'mega', label: 'Mega' },
  { value: 'metal', label: 'Metal (Max TPS)' },
];

export const blockExplorerSizeOptions = [
  { value: 'small', label: 'Small (for Test)' },
  { value: 'medium', label: 'Medium (Minimum for Production)' },
  { value: 'twoxlarge', label: '2XLarge' },
];

export const instancesSizeEnterpriseSelection = [...instancesSizeSelection];

export const nodeTypesSelection: { value: INodeType; label: string }[] = [
  { value: 'bootnode', label: 'Boot Node' },
  { value: 'geth', label: 'Geth Node' },
  { value: 'parity', label: 'Parity Node' },
  { value: 'block-explorer', label: 'Block Explorer Node' },
];

export const nodeGethSelection: { value: string; label: string }[] = [
  { value: '1.9.25', label: 'Geth 1.9.25' },
  { value: '1.10.8', label: 'Geth 1.10.8' },
  { value: '1.10.17', label: 'Geth 1.10.17' },
  { value: '1.10.18', label: 'Geth 1.10.18' },
  { value: '1.11.6', label: 'Geth 1.11.6' },
  { value: '1.12.2', label: 'Geth 1.12.2' },
];

export const nodeGcModeOptions: { value: string; label: string }[] = [
  { value: 'full', label: 'Full' },
  { value: 'archive', label: 'Archive' },
];

export const consensusTypesSelection: { value: IConsensus; label: string }[] = [
  { value: 'clique', label: 'PoA (Clique)' },
];

export const getProposeNodeSelection = (t: TFunction) => [
  { value: 'signer', label: t('signer_node') },
  { value: 'transaction', label: t('transaction_node') },
];

export const switchCidrInputType: { value: string; label: string }[] = [
  { value: 'custom', label: 'Custom' },
  { value: 'anywhere', label: 'Anywhere' },
  { value: 'myip', label: 'My IP address' },
];

export const getMetricsRangeSelection = (
  t: TFunction,
): Array<{ value: string; label: string; periods: string[] }> => [
  {
    value: '1',
    label: t('metrics_range_option_label_last_hour', { value: 1 }),
    periods: ['10', '20'],
  },
  {
    value: '3',
    label: t('metrics_range_option_label_last_hour_plural', { value: 3 }),
    periods: ['30', '60'],
  },
  {
    value: '6',
    label: t('metrics_range_option_label_last_hour_plural', { value: 6 }),
    periods: ['60', '120'],
  },
  {
    value: '12',
    label: t('metrics_range_option_label_last_hour_plural', { value: 12 }),
    periods: ['120', '240'],
  },
  {
    value: '24',
    label: t('metrics_range_option_label_last_hour_plural', { value: 24 }),
    periods: ['240', '480'],
  },
  {
    value: '72',
    label: t('metrics_range_option_label_last_day_plural', { value: 3 }),
    periods: ['720', '1440'],
  },
  {
    value: '168',
    label: t('metrics_range_option_label_last_week', { value: 1 }),
    periods: ['1440'],
  },
  {
    value: '336',
    label: t('metrics_range_option_label_last_week_plural', { value: 2 }),
    periods: ['4320'],
  },
];

export const getMetricsPeriodSelection = (
  t: TFunction,
): Array<{ value: string; label: string }> => [
  { value: '10', label: t('metrics_period_option_label_minute_plural', { value: 10 }) },
  { value: '20', label: t('metrics_period_option_label_minute_plural', { value: 20 }) },
  { value: '30', label: t('metrics_period_option_label_minute_plural', { value: 30 }) },
  { value: '60', label: t('metrics_period_option_label_hour', { value: 1 }) },
  { value: '120', label: t('metrics_period_option_label_hour', { value: 2 }) },
  { value: '240', label: t('metrics_period_option_label_hour', { value: 4 }) },
  { value: '360', label: t('metrics_period_option_label_hour_plural', { value: 6 }) },
  { value: '480', label: t('metrics_period_option_label_hour_plural', { value: 8 }) },
  { value: '720', label: t('metrics_period_option_label_hour_plural', { value: 12 }) },
  { value: '1440', label: t('metrics_period_option_label_day', { value: 1 }) },
  { value: '4320', label: t('metrics_period_option_label_day', { value: 3 }) },
];

export const commandSelection = [
  { label: 'Get Validator', value: 'get_validator' },
  { label: 'Get Proposal', value: 'get_proposal' },
];
