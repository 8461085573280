import React, { useCallback } from 'react';

// Components
import { TextField, Theme } from '@mui/material';
import { GridFilterInputValueProps } from '@mui/x-data-grid';

// Styles
import { withStyles, createStyles, WithStyles } from '@mui/styles';

// i18n
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props extends GridFilterInputValueProps, WithTranslation, WithStyles<typeof styles> {}

const PlanFilterInput: React.FunctionComponent<Props> = (props) => {
  const { item, classes, t, applyValue } = props;

  const handleFilterChange = useCallback(
    (event) => {
      const value = event.target.value;
      applyValue({ ...item, value: value === 'all' ? undefined : value });
    },
    [applyValue, item],
  );

  return (
    <TextField
      select
      label={t('value')}
      value={item.value || 'all'}
      onChange={handleFilterChange}
      fullWidth
      SelectProps={{
        native: true,
      }}
    >
      {[
        { label: 'All Plan', value: 'all' },
        { label: 'Personal', value: 'personal' },
        { label: 'Enterprise', value: 'enterprise' },
      ].map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </TextField>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(withTranslation('admin')(PlanFilterInput));
