import moment from 'moment-timezone';
import { createTypeAsyncAction } from '@gu-corp/redux-async-lib';
import AdminGApiClient from './gapi/admin-gapi-client';
import * as StatsQuery from './gapi/query/stats';

export * from './gapi/query/stats';

export const getAccountStats = createTypeAsyncAction('GET_ACCOUNT_STATS', async (year: string) => {
  const client = new AdminGApiClient({});
  return client.getAccountStats({
    startDate: moment(year).startOf('year').toISOString(),
    endDate: moment(year).endOf('year').toISOString(),
    groupBy: 'month',
  });
});

export const getUserStats = createTypeAsyncAction('GET_USER_STATS', async (year: string) => {
  const client = new AdminGApiClient({});
  return client.getUserStats({
    startDate: moment(year).startOf('year').toISOString(),
    endDate: moment(year).endOf('year').toISOString(),
    groupBy: 'month',
  });
});

export const getSummaryStats = createTypeAsyncAction('GET_SUMMARY_STATS', async () => {
  const client = new AdminGApiClient({});
  return client.getSummaryStats();
});
