import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import { IStore } from '~/stores/configure-store';
import Layout from '~/components/common/Layout';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import TableBodyCustom from '~/components/common/table-body';
import TableHeadCustom from '~/components/common/table-head';
import TableHeadRowCustom from '~/components/common/table-head-row';
import TableHeadCellCustom from '~/components/common/table-head-cell';
import LoadingIcon from '~/components/common/loading-icon';
import Typography from '@mui/material/Typography';
import TableBodyCellCustom from '~/components/common/table-body-cell';
import TableBodyRowCustom from '~/components/common/table-body-row';
import IconButton from '@mui/material/IconButton';
import { withFirebaseUser } from '~/hooks/with-firebase-auth';
import { withAdminProfile } from '~/hooks/with-admin-profile';
import { compose } from 'redux';
import { AppRoutePath } from '~/AppRouter';

// themes
import { defaultFont } from '~/styles/themes/common-styles/font';
import {
  whiteColor,
  blackRussianColor,
  gainsboroColor,
  denimColor,
} from '~/styles/themes/common-styles/color';
import { oneLineText } from '~/styles/themes/common-styles/misc';

// components
import LgButton from '~/components/common/lg-button';
import CreateInformationDialog from '~/components/common/create-information-dialog';

interface Props extends RouteComponentProps<{}>, WithTranslation, WithStyles<typeof styles> {}

interface DispProps {
  isLoading?: boolean;
}

interface StateProps {}

interface State {
  openCreateInformationGuest: boolean;
}

class InfomationGuest extends React.Component<Props & DispProps & StateProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      openCreateInformationGuest: false,
    };
  }

  public render() {
    const { classes, t, isLoading } = this.props;
    const { openCreateInformationGuest } = this.state;

    return (
      <Layout>
        <div className={classes.root}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Link
              component={RouterLink}
              to={AppRoutePath.Dashboard}
              color="text.primary"
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            </Link>
            <Typography color="text.primary">{t('information_guest')}</Typography>
          </Breadcrumbs>
          <div className={classes.pageTitleArea}>
            <LgButton
              id="admin-user-create-info-guest"
              classes={{ root: classes.addBtn }}
              onClick={this.onopenCreateInformationGuest}
            >
              <img src="/images/icons/add_ico.png" alt="" className={classes.iconAddBtn} />
              <span>{t('create_info')}</span>
            </LgButton>
            <CreateInformationDialog
              type="guest"
              open={openCreateInformationGuest}
              onClose={this.onCloseCreateInformationGuest}
            />
          </div>
          <div className={classes.contentPaper}>
            {isLoading ? (
              <div className={classes.loadingArea}>
                <LoadingIcon />
              </div>
            ) : (
              <>
                <Table id="admin-information-guest-list">
                  <TableHeadCustom>
                    <TableHeadRowCustom>
                      <TableHeadCellCustom classes={{ root: classes.customCellHead }}>
                        <span>{t('importance')}</span>
                      </TableHeadCellCustom>
                      <TableHeadCellCustom classes={{ root: classes.customCellHead }}>
                        <span>{t('text')}</span>
                      </TableHeadCellCustom>
                      <TableHeadCellCustom classes={{ root: classes.customCellHead }}>
                        <span>{t('link')}</span>
                      </TableHeadCellCustom>
                      <TableHeadCellCustom classes={{ root: classes.customCellHead }} sort="DESC">
                        <span>{t('date')}</span>
                      </TableHeadCellCustom>
                      <TableHeadCellCustom padding="checkbox" />
                    </TableHeadRowCustom>
                  </TableHeadCustom>
                  <TableBodyCustom>
                    {listInfos.map((info, index) => (
                      <TableBodyRowCustom key={index}>
                        <TableBodyCellCustom classes={{ root: classes.customCell }}>
                          {info.type}
                        </TableBodyCellCustom>
                        <TableBodyCellCustom classes={{ root: classes.customCell }}>
                          <div className={classes.text}>{info.text}</div>
                        </TableBodyCellCustom>
                        <TableBodyCellCustom classes={{ root: classes.customCell }}>
                          <a href={info.link} className={classes.linkText}>
                            {info.link}
                          </a>
                        </TableBodyCellCustom>
                        <TableBodyCellCustom classes={{ root: classes.customCell }}>
                          {info.date}
                        </TableBodyCellCustom>
                        <TableBodyCellCustom classes={{ root: classes.customCell }}>
                          <div className={classes.actionArea}>
                            <IconButton className={classes.editBtn}>
                              <img src="/images/icons/edit-ico.png" alt="" />
                            </IconButton>
                            <IconButton className={classes.delBtn}>
                              <img src="/images/icons/delete_ico.png" alt="" />
                            </IconButton>
                          </div>
                        </TableBodyCellCustom>
                      </TableBodyRowCustom>
                    ))}
                  </TableBodyCustom>
                </Table>
              </>
            )}
          </div>
        </div>
      </Layout>
    );
  }

  private onopenCreateInformationGuest = () => {
    this.setState({
      openCreateInformationGuest: true,
    });
  };

  private onCloseCreateInformationGuest = () => {
    this.setState({
      openCreateInformationGuest: false,
    });
  };
}

const styles = createStyles({
  root: {
    width: '100%',
    margin: 'auto',
    paddingRight: 0,
  },
  pageTitleArea: {
    ...defaultFont,
    display: 'flex',
    justifyContent: 'end',
  },
  addBtn: {
    width: 190,
    marginTop: 13,
  },
  iconAddBtn: {
    width: 16,
    height: 16,
  },
  contentPaper: {
    ...defaultFont,
    marginTop: 24,
    boxShadow: `0 2px 6px 0 ${blackRussianColor}`,
    border: `solid 1px ${gainsboroColor}`,
    backgroundColor: whiteColor,
  },
  loadingArea: {
    margin: '100px 0',
    textAlign: 'center',
  },
  customCellHead: {
    paddingLeft: 16,
  },
  customCell: {
    padding: '10px 16px',
  },
  text: {
    ...oneLineText,
    maxWidth: 270,
  },
  linkText: {
    ...defaultFont,
    ...oneLineText,
    maxWidth: 200,
    color: denimColor,
    textDecoration: 'none',
    '&:hover': {
      color: denimColor,
    },
  },
  actionArea: {
    display: 'flex',
    alignItems: 'center',
  },
  editBtn: {},
  delBtn: {},
});

const mapStateToProps = (store: IStore) => ({});

const mapDispatchToProps = (dispatch): DispProps => ({});

const listInfos = Array.from({ length: 5 }, () => ({
  type: 'Important',
  text: 'text text text text text text text text text text text text text texttext text text text text text text text text text text text text text',
  link: 'https://gulabs.com/xxxxxxxxxx',
  date: 'YYYY/MM/DD - YYYY/MM/DD',
}));

export default compose(
  withFirebaseUser(),
  withAdminProfile,
  withStyles(styles),
  withTranslation('admin'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(InfomationGuest);
