import React from 'react';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import ChangeStatusAWSDialog from './change-status-aws-dialog';
import OptionButton from '~/components/common/option-button';

import { Item } from '..';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Popover from '@mui/material/Popover';

import { defaultFont } from '~/styles/themes/common-styles/font';

interface Props extends WithStyles<typeof styles>, WithTranslation {
  item: Item;
}

interface DispProps {}

interface State {
  awsOptionAnchorEl?: any;
  openVerifyDetachAWSDialog: boolean;
}

class DeleteAWSAccountOption extends React.Component<Props & DispProps, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openVerifyDetachAWSDialog: false,
    };
  }

  public render() {
    const { t, item, classes } = this.props;
    const { awsOptionAnchorEl, openVerifyDetachAWSDialog } = this.state;

    return (
      <>
        <ChangeStatusAWSDialog
          open={openVerifyDetachAWSDialog}
          onClose={this.handleCloseVerifyDetachAWSDialog}
          item={item}
        />
        <Popover
          open={Boolean(awsOptionAnchorEl)}
          anchorEl={awsOptionAnchorEl}
          onClose={this.onCloseAwsOptionPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={1}
        >
          <List className={classes.listOption}>
            <ListItem
              button
              className={classes.listOptionItem}
              onClick={this.handleOpenVerifyDetachAWSDialog}
              disabled={!!item.userCount}
            >
              <img className={classes.deleteIcon} src="/images/icons/delete_ico.png" alt="" />
              {this.props.t('remove')}
            </ListItem>
          </List>
        </Popover>
        <OptionButton id="network-cluster-button-option" onClick={this.onOpenAwsOptionPopover} />
      </>
    );
  }

  private onOpenAwsOptionPopover = (e) => {
    this.setState({
      awsOptionAnchorEl: e.currentTarget,
    });
  };

  private onCloseAwsOptionPopover = () => {
    this.setState({
      awsOptionAnchorEl: null,
    });
  };

  private handleOpenVerifyDetachAWSDialog = () => {
    this.setState({
      openVerifyDetachAWSDialog: true,
      awsOptionAnchorEl: null,
    });
  };

  private handleCloseVerifyDetachAWSDialog = () => {
    this.setState({
      openVerifyDetachAWSDialog: false,
    });
  };
}

const styles = createStyles({
  root: {},
  TableBodyCellCustom: {
    padding: '4px 0px 4px 2%',
    wordBreak: 'break-all',
  },
  listOption: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listOptionItem: {
    height: 46,
    width: 210,
    ...defaultFont,
    fontSize: 16,
  },
  deleteIcon: {
    marginRight: 8,
  },
});

export default withStyles(styles)(withTranslation('admin')(DeleteAWSAccountOption));
